import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";

import Footer from "../../../components/common/Footer";

// import "../styles/post-details.css";
// import "../styles/ad-details.css";
import "../../../styles/post-details.css";
import "../../../styles/ad-details.css";
import FooterMobile from "../../../components/common/FooterMobile";

import PageNotFound from "../../../pages/PageNotFound";
import UnauthorizedUser from "../../../pages/IncorrectToken";
import RestrictedAccount from "../../../pages/RestrictedProfile";
import Outage from "../../../pages/Outage";
import NotLoggedBanner from "../../../components/banners/NotLoggedBanner";
import GoogleAds from "../../../components/common/GoogleAds";
import GoogleDisplayAds from "../../../components/common/GoogleDisplayAds";
// import Navbar from "../../../components/common/Navbar";
import PageHead from "../../../components/common/PageHead";
import CancelRequestModal from "../../../components/modals/CancelRequestModal";
import NeedToCreateAnAccount from "../../../components/modals/NeedToCreateAnAccount";
// import Alerts from "../../../components/post-details/Alerts";
import Connect from "../../../components/post-details/Connect";
// import Header from "../../../components/post-details/Header";
// import Horoscope from "../../../components/post-details/Horoscope";
// import Parent from "../../../components/post-details/Parent";
// import Personal from "../../../components/post-details/Personal";
import TimeLine from "../../../components/post-details/TimeLine";
import Unavailable from "../../../pages/DisabledAcc.jsx";
import PersonalInformation from "../../templates/profile-view/information-section/personal-information/index.jsx";
import FamilyInformation from "../../templates/profile-view/information-section/family-information/index.jsx";
import HoroscopeInformation from "../../templates/profile-view/information-section/horoscope-information/index.jsx";
import HeaderSection from "../../templates/profile-view/header-section/index.jsx";
import StatusSection from "../../templates/profile-view/status-section/index.jsx";
import Config from "../../../v2/config";
import withLanguageMigrator from "../../hoc/language-migrator/index.jsx";
import PostServiceApi from "../../services/api/post-service/index.js";
import { USER_ROLES } from "../../config/constant/index.js";
import UserApi from "../../services/api/user/index.js";
import { getGenericReportReasons } from "../../features/generic-data/index.js";
import { connect } from "react-redux";
import { setAppSnackNotification } from "../../store/actions/application/index.js";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

class Post extends Component {
  state = {
    postDetails: {},
    interestData: {},
    offlineId: "",
    // pages
    applyMatch: false,
    applyInterest: false,
    applyNormalPost: false,

    isInterestInfo: false,
    interestInfo: {},

    // personal
    postId: "",
    isOffline: true,
    publishedAt: "",
    isExpired: false,
    gender: "",
    fName: "",
    lname: "",
    lnameMasked: "",
    age: "",
    residentCity: "",
    height: "",
    profession: "",
    profession_si: "",
    displayName: "",
    ignorePreferences: false,

    // private
    phone: "",
    showPhone: false,
    phoneVisibilityModal: false,
    email: "",
    showPhotos: false,
    receivedInterestImages: [],
    isIgnored: false,
    autoIgnored: false,
    receivedTime: "",
    receivedTo: "",
    acceptTime: "",
    acceptBy: "",
    unmatchedTime: "",
    unmatchedBy: "",

    likeMessage: "",
    matchMessage: "",
    sentTime: "",
    cancelSentTime: "",
    cancelAcceptTime: "",
    isHideConnect: false,

    undoAutoRejectTime: "",
    undoAutoRejectedBy: "",

    // page loading
    preLoading: true,
    btnLoading: false,

    // alert modal
    alertModal: false,
    alertHeading: "",
    alertMessage: "",
    cancelRequest: false,
    ignoreModal: false,
    ignoreReason: "",
    ignoredBy: "",

    // show interest msg modal
    intMessageModal: false,
    messageModal: false,
    sendingPostId: "",
    sensitiveDataError: "",

    // preference mismatch modal
    preferenceMismatchModal: false,
    preferenceMismatchMessage: "",
    preferenceMismatchMessageSinhala: "",

    // need to create an account
    needToCreateAnAccount: false,

    // report modal
    matchPostId: "",
    reportedPosts: [],
    reasons: [],
    reportModal: false,
    isDissable: true,
    error: false,
    whyReport: "",
    reasonDesc: "",

    // redirects
    RedirectToMatches: false,
    redirectToUnavailabele: false,
    redirectToPageNotFound: false,
    redirectToUnauthorizedUser: false,
    redirectToRestrictedAccount: false,
    outagePage: false,

    // loading
    loadingIgnore: false,
    loading: false,

    intId: "",

    // showIntTooltip
    showIntTooltip: true,
    profileFetched: false,
  };

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const { isAuthInitialized } = this.props;
    if (isAuthInitialized) {
      this.setState({ profileFetched: true }, () => {
        this.getPostInfo();
      });
    }
  };

  componentDidUpdate = () => {
    const { isAuthInitialized } = this.props;
    if (isAuthInitialized && !this.state.profileFetched) {
      this.setState({ profileFetched: true }, () => {
        this.getPostInfo();
      });
    }
  };

  getReportReasons = async () => {
    let reportReasons = [];
    try {
      const response = await getGenericReportReasons();
      if (response.success) {
        reportReasons = response.body;
      }
    } catch (error) {
      console.log("Error", error);
    }
    return reportReasons;
  };

  handleApiErros = (response) => {
    const errorCode = response.body.code;
    switch (errorCode) {
      case 501:
      case 502:
      case 503:
      case 520:
        this.setState({ outagePage: true });
        break;
      case 1001:
        this.setState({ redirectToPageNotFound: true });
        break;
      case 1004:
        this.setState({ redirectToUnauthorizedUser: true });
        break;
      case 1006:
        this.setState({ redirectToUnavailabele: true });
        break;
      case 1514:
        this.setState({ redirectToRestrictedAccount: true });
        break;
      default:
        break;
    }
  };

  getPostInfo = async () => {
    const { location } = this.props;
    const { authenticated, authProfile } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const viewProfileId = searchParams.get("id");

    const queryParams = {};
    if (authProfile.id) {
      queryParams.context = authProfile.id;
    }

    try {
      const response = await PostServiceApi.getPostById({
        id: viewProfileId,
        authneticated: authenticated && !isEmpty(authProfile),
        queryParams,
      });

      if (!response.success) {
        this.handleApiErros(response);
        return;
      }

      const newState = {};
      newState.offlineId = response.body.offlineId ?? "";
      newState.isInterestInfo = response.body.interestInfo ? true : false;
      newState.interestInfo = response.body.interestInfo ?? {};
      let nextFunction = undefined;

      if (response.body.interestInfo) {
        if (response.body.interestInfo.matched) {
          newState.applyMatch = true;
          newState.applyInterest = false;
          newState.applyNormalPost = false;
          nextFunction = this.getMatchedData;
        } else {
          newState.applyMatch = false;
          newState.applyInterest = true;
          newState.applyNormalPost = false;
          nextFunction = this.getInterestedData;
        }
      } else {
        newState.applyNormalPost = true;
        newState.applyMatch = false;
        newState.applyInterest = false;
        nextFunction = this.getNonInterestedData;
      }

      this.setState(newState, () => {
        if (typeof nextFunction === "function") {
          nextFunction(response);
        }
      });
    } catch (error) {
      this.props.setAppSnackNotification({
        severity: "error",
        message: this.props.t("errors.unknownErrorOccurred"),
      });
    }
  };

  getInterestedData = async (response) => {
    let nextState = {};
    const res = { data: response.body };
    const postId = localStorage.getItem("postId");
    const interestId = res.data?.interestInfo?.interestId;

    try {
      const httpResponse = await PostServiceApi.getInterestById({
        postId,
        interestId,
      });

      if (!httpResponse.success) {
        this.handleApiErros(httpResponse);
        return;
      }

      const interest = httpResponse.body?.interest ?? {};

      nextState = {
        ...nextState,
        intId: interest.id,
        postId: interest.post.id,
        isOffline: interest.isOffline,
        publishedAt: interest.likedAt
          ? interest.likedAt
          : interest.post.publishedAt,
        gender: interest.post.personalInfo.gender,

        fName: interest.post.personalInfo.fname,
        displayName: interest.post.personalInfo.displayName,
        lnameMasked: interest.post.personalInfo.lnameMasked,
        residentCity: interest.post.personalInfo.residentCity,

        age: interest.post.personalInfo.age,

        profession:
          interest.post.personalInfo.profession.name === "Other"
            ? interest.post.personalInfo.professionOther
            : interest.post.personalInfo.profession.name,
        profession_si:
          interest.post.personalInfo.profession.name === "Other"
            ? interest.post.personalInfo.professionOther
            : interest.post.personalInfo.profession.name_si,

        height: interest.post.personalInfo.height,

        likeMessage: interest.likeSeen === false ? "" : interest.likeMessage,
        matchMessage: interest.likeSeen === false ? interest.matchMessage : "",

        showInt: interest.likeSeen !== undefined ? false : true,
        showAccept: interest.likeSeen !== undefined ? true : false,
        refuseReason: interest.refuseReason ? interest.refuseReason : "",
        phone: interest.post.phone,
        isIgnored: interest.ignored,
        autoIgnored: interest.autoIgnored,
        likedAt: interest.likedAt,
        isHideConnect:
          interest.timeline &&
          interest.timeline.filter(
            (el) =>
              (el.eventType === "LIKED" && el.actionBy === "SELF") ||
              el.eventType === "LIKE_REFUSED"
          ).length > 0
            ? true
            : false,
        preLoading: false,
        showPhotos: interest.showPhotos,
        receivedInterestImages:
          interest.showPhotos && interest.images ? interest.images : [],
        postDetails: interest.post,
        interestData: interest,
      };

      if (interest.timeline.length > 0) {
        const sentTime = interest.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (sentTime.length > 0) {
          nextState.sentTime = sentTime[0].eventTime;
        }

        const cancelSentTime = interest.timeline.filter(
          (filter) => filter.eventType === "UNLIKED"
        );

        if (cancelSentTime.length > 0) {
          nextState.cancelSentTime = cancelSentTime[0].eventTime;
        }

        const receivedTime = interest.timeline.filter(
          (filter) => filter.eventType === "LIKED"
        );

        if (receivedTime.length > 0) {
          if (receivedTime[0].actionBy === "OTHER_PARTY") {
            nextState.receivedTime = receivedTime[0].eventTime;
            nextState.receivedTo = receivedTime[0].actionBy;
          }
        }

        const cancelAcceptTime = interest.timeline.filter(
          (filter) => filter.eventType === "LIKE_REFUSED"
        );

        if (cancelAcceptTime.length > 0) {
          nextState.cancelAcceptTime = cancelAcceptTime[0].eventTime;
          nextState.ignoredBy = cancelAcceptTime[0].actionBy;
        }
      }

      this.setState(nextState);
    } catch (error) {
      this.props.setAppSnackNotification({
        severity: "error",
        message: this.props.t("errors.unknownErrorOccurred"),
      });
    }
  };

  getNonInterestedData = (response) => {
    const res = { data: response.body };

    this.setState({
      postId: res.data.id,
      isOffline: res.data.isOffline,
      publishedAt: res.data.publishedAt,
      isExpired: res.data.isExpired,
      gender: res.data.personalInfo.gender,

      fName: res.data.personalInfo.fname,
      displayName: res.data.personalInfo.displayName,
      lnameMasked: res.data.personalInfo.lnameMasked,
      age: res.data.personalInfo.age,
      residentCity: res.data.personalInfo.residentCity,
      height: res.data.personalInfo.height,

      profession:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name,
      profession_si:
        res.data.personalInfo.profession.name === "Other"
          ? res.data.personalInfo.professionOther
          : res.data.personalInfo.profession.name_si,

      liked: res.data.liked ? res.data.liked : "",
      matched: res.data.matched ? res.data.matched : "",
      phone: res.data.phone,
      preLoading: false,
      postDetails: res.data,
    });
  };

  getMatchedData = async (response) => {
    const result = { data: response.body };
    let nextState = {};
    const userRole = localStorage.getItem("user_role");
    let userProfile;

    try {
      const reportReasons = await this.getReportReasons();

      if (Array.isArray(reportReasons)) {
        nextState.reasons = reportReasons;
      }

      if (
        userRole === USER_ROLES.OPERATOR ||
        userRole === USER_ROLES.OPERATOR_L2
      ) {
        const id = localStorage.getItem("postId");
        const response = await PostServiceApi.getOperatorManagedPost({ id });
        if (response.success) {
          return;
        }
        userProfile = response.body;
      } else {
        const response = await UserApi.getUserProfiles();
        if (!response.success) {
          return;
        }
        userProfile = Array.isArray(response.body) ? response.body[0] : {};
      }
      nextState.reportedPosts = userProfile.reportedPosts ?? [];

      const httpResponse = await PostServiceApi.getMatchById({
        postId: userProfile.id,
        matchId: result.data.interestInfo.interestId,
      });

      if (!httpResponse.success) {
        this.handleApiErros(httpResponse);
        return;
      }

      const match = httpResponse.body?.match ?? {};
      nextState = {
        ...nextState,
        intId: match.id,
        postId: match.id,
        isOffline: match.isOffline,
        isExpired: match.post.isExpired,
        matchPostId: match.post.id,
        matchedAt: match.matchedAt,
        gender: match.post.personalInfo.gender,
        fName: match.post.personalInfo.fname,
        displayName: match.post.personalInfo.displayName,
        lnameMasked: match.post.personalInfo.lnameMasked,
        lname: match.post.personalInfo.lname,
        residentCity: match.post.personalInfo.residentCity,
        age: match.post.personalInfo.age,
        profession:
          match.post.personalInfo.profession.name === "Other"
            ? match.post.personalInfo.professionOther
            : match.post.personalInfo.profession.name,
        profession_si:
          match.post.personalInfo.profession.name === "Other"
            ? match.post.personalInfo.professionOther
            : match.post.personalInfo.profession.name_si,

        height: match.post.personalInfo.height,

        likeMessage: match.likeMessage,
        matchMessage: match.matchMessage,
        phone: match.post.phone,
        showPhone: match.showPhone,
        email: match.post.email,

        showInt: match.timeline
          ? match.timeline.filter((filter) => filter.eventType === "LIKED")
              .length > 0
            ? match.timeline.filter((filter) => filter.eventType === "LIKED")[0]
                .actionBy === "SELF"
              ? true
              : false
            : false
          : false,
        isIgnored: match.ignored,
        postDetails: match?.post,
        interestData: match,
      };
      const sentTime = match.timeline.filter(
        (filter) => filter.eventType === "LIKED"
      );

      if (sentTime.length > 0) {
        if (sentTime[0].actionBy === "SELF") {
          nextState.sentTime = sentTime[0].eventTime;
        }
      }

      const receivedTime = match.timeline.filter(
        (filter) => filter.eventType === "LIKED"
      );

      if (receivedTime.length > 0) {
        if (receivedTime[0].actionBy === "OTHER_PARTY") {
          nextState.receivedTime = receivedTime[0].eventTime;
          nextState.receivedTo = receivedTime[0].actionBy;
        }
      }

      const acceptTime = match.timeline.filter(
        (filter) => filter.eventType === "MATCHED"
      );

      if (acceptTime.length > 0) {
        nextState.acceptTime = acceptTime[0].eventTime;
        nextState.acceptBy = acceptTime[0].actionBy;
      } else {
        nextState.acceptTime = match.matchedAt ? match.matchedAt : "";
      }

      const unmatchedTime = match.timeline.filter(
        (filter) => filter.eventType === "UNMATCHED"
      );

      if (unmatchedTime.length > 0) {
        nextState.unmatchedTime = unmatchedTime[0].eventTime;
        nextState.unmatchedBy = unmatchedTime[0].actionBy;
      }

      const cancelAcceptTime = match.timeline.filter(
        (filter) => filter.eventType === "LIKE_REFUSED"
      );

      if (cancelAcceptTime.length > 0) {
        nextState.cancelAcceptTime = cancelAcceptTime[0].eventTime;
        nextState.ignoredBy = cancelAcceptTime[0].actionBy;
      }

      const undoAutoRejectTime = match.timeline.filter(
        (filter) => filter.eventType === "UNDO_AUTO_REJECT"
      );

      if (undoAutoRejectTime.length > 0) {
        nextState.undoAutoRejectTime = undoAutoRejectTime[0].eventTime;
        nextState.undoAutoRejectedBy = undoAutoRejectTime[0].actionBy;
      }

      nextState.preLoading = false;
      this.setState(nextState);
    } catch (error) {
      this.props.setAppSnackNotification({
        severity: "error",
        message: this.props.t("errors.unknownErrorOccurred"),
      });
    }
  };

  handleMessageModal = (gender) => {
    this.setState({ showIntTooltip: false, btnLoading: true });
    if (localStorage.getItem("auth_token")) {
      if (localStorage.getItem("post_details")) {
        if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "IN_REVIEW"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Ad Under Review"
              : "සමාලෝචනය යටතේ දැන්වීම පවති",
            alertMessage: this.props.lng
              ? "Our team is reviewing your ad at the moment. You will be able to show interest as soon as the ad goes live."
              : "අපගේ කණ්ඩායම මේ මොහොතේ ඔබගේ දැන්වීම සමාලෝචනය කරමින් සිටී. දැන්වීම සජීවීව ගිය විගස ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status ===
          "PAYMENT_PENDING"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Complete Your Ad"
              : "ඔබේ දැන්වීම සම්පූර්ණ කරන්න",
            alertMessage: this.props.lng
              ? "Please make the payment to complete your ad. You will be able to show interest as soon as the ad goes live."
              : "කරුණාකර ඔබේ දැන්වීම සම්පුර්ණ කිරීම සඳහා ගෙවීමක් කරන්න. දැන්වීම සජීවීව ගිය වහාම ඔබට උනන්දුවක් දැක්විය හැකිය.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng
              ? "Ad Expired"
              : "දැන්වීම කල් ඉකුත් වි ඇත",
            alertMessage: this.props.lng
              ? "Your ad is expired. Please renew your add to respond."
              : "දැන්වීම කල් ඉකුත් වී ඇත. ප්‍රතිචාර දැක්වීම සඳහා දැන්වීම අලුත් කරන්න.",
          });
        } else if (
          JSON.parse(localStorage.getItem("post_details")).status === "DISABLED"
        ) {
          this.setState({
            alertModal: true,
            alertHeading: this.props.lng ? "Ad Disabled" : "දැන්වීම අක්‍රීයයි",
            alertMessage: this.props.lng
              ? "You have disabled your ad. Please contact support to get your ad account re-activated."
              : "ඔබ ඔබේ දැන්වීම අක්‍රීය කර ඇත. ඔබේ දැන්වීම නැවත සක්‍රිය කිරීමට කරුණාකර අපව අමතන්න.",
          });
        } else {
          if (
            JSON.parse(localStorage.getItem("post_details")).personalInfo
              .gender === gender
          ) {
            this.setState({
              alertModal: true,
              alertHeading: this.props.lng
                ? "Response not accepted"
                : "ප්‍රතිචාරය බාර නොගනී",
              alertMessage: this.props.lng
                ? `This profile only accepts responses from ${
                    gender === "MALE" ? "bride" : "groom"
                  } profiles.`
                : `මෙම දැන්වීම පිළිගන්නේ ${
                    gender === "MALE" ? "මනාලියන්" : "මනාලයන්"
                  }ගේ ගිණුම් වලින් ලැබෙන ප්‍රතිචාර පමණි.`,
            });
          } else {
            this.interestValidate();
          }
        }
      } else {
        this.setState({
          alertModal: true,
          alertHeading: this.props.lng
            ? "Complete your ad"
            : "දැන්වීම සම්පූර්ණ කරන්න",
          alertMessage: this.props.lng
            ? "Please complete your ad to send/receive interests."
            : "ප්‍රතිචාර දැක්වීම සදහා ඔබගේ දැන්වීම සම්පූර්ණ කල යුතුය.",
        });
      }
    } else {
      this.setState({ needToCreateAnAccount: true });
    }
    // this.setState({btnLoading: false})
  };

  interestValidate = () => {
    const data = `likedPostId=${this.state.postId}`;

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/validate_interest`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ intMessageModal: true });
      })
      .catch((err) => {
        // this.setState({
        //   preferenceMismatchModal: true,
        // });

        if (err.response.data.code === 1800) {
          this.setState({
            preferenceMismatchMessage:
              "Your age does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              " ඔබේ වයස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (err.response.data.code === 1805) {
          this.setState({
            preferenceMismatchMessage:
              "Your country of residence does not match with the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ පදිංචි රට මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        } else if (
          err.response.data.code === 1802 ||
          err.response.data.code === 1803 ||
          err.response.data.code === 1804
        ) {
          this.setState({
            preferenceMismatchMessage:
              "Your civil status, ethnicity, religion or height does not match the preferences set by this user. You may still show your interest but it will be delivered to user's spam section and the user will not be notified. You may not receive a reply for your request.",
            preferenceMismatchMessageSinhala:
              "ඔබේ සිවිල් තත්වය, ජාතිය, ආගම හෝ උස මෙම පරිශීලකයා නියම කර ඇති අපේක්ශිත නිර්ණායක සමග නොගැලපේ. තවමත් ඔබට ඔබගේ කැමැත්ත පෙන්විය හැක. නමුත් ඔබගේ කැමැත්ත මෙම පරිශීලකයාගේ ගිණුමෙහි  නොගැලපෙන නිර්ණායක සහිත කැමැත්තන් වෙත යන බැවින් ප්‍රතිචාරයක් නොලැබීමට ඉඩ ඇත.",
          });
        }
        this.showInterestOnMismatchedProfile();
      });
  };

  handleShowInterest = (id, message, showPhone, showPhotos = null) => {
    if (localStorage.getItem("auth_token")) {
      const data = `likedPostId=${id}&message=${message}&showPhone=${showPhone}&showPhoto=${showPhotos}&ignorePreferences=${this.state.ignorePreferences}`;
      this.setState({ loading: true });

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/show_interest`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            showAlert: true,
            messageModal: false,
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.code === 1801) {
            this.setState({
              sensitiveDataError: this.props.lng
                ? "Your message contains sensitive personal information such as email addresses, phone numbers or web URLs. Please remove them and retry."
                : "ඔබගේ පණිවිඩයේ විද්‍යුත් ලිපින, දුරකථන අංක හෝ වෙබ් URL වැනි සංවේදී පුද්ගලික තොරතුරු අඩංගු වේ. කරුණාකර ඒවා ඉවත් කර නැවත උත්සාහ කරන්න.",
            });
          }
          this.setState({ loading: false });
        });
    }
  };

  handleAcceptMessageModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.props.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.props.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({
        messageModal: true,
        sendingPostId: this.state.postId,
      });
    }
  };

  handleCancelReqModal = () => {
    this.setState({ cancelRequest: true });
  };

  handleIgnoreReason = (e) => {
    this.setState({ ignoreReason: e.target.value });
  };

  handleIgnoreAllInt = (type) => {
    this.setState({ loadingIgnore: true });
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }?${type === "unmatch" ? "unmatchReasonId" : "rejectReason"}=${
          this.state.ignoreReason
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loadingIgnore: false,
          cancelRequest: false,
          ignoreModal: false,
        });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingIgnore: false });
      });
  };

  handleIgnoreModal = () => {
    if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      this.setState({
        alertModal: true,
        alertHeading: this.props.lng ? "Ad Expired" : "දැන්වීම කල් ඉකුත් වි ඇත",
        alertMessage: this.props.lng
          ? "Your ad is expired. Please renew your ad to respond."
          : "කැමැත්ත පෙන්වීම සඳහා ඔබගේ දැන්වීම අලුත් කරන්න.",
      });
    } else {
      this.setState({ ignoreModal: true });
    }
  };

  handleUnmatchModal = () => {
    this.setState({ unmatchModal: true });
  };

  handleModalClose = () => {
    this.setState({
      alertModal: false,
      messageModal: false,
      intMessageModal: false,
      cancelRequest: false,
      ignoreModal: false,
      unmatchModal: false,
      btnLoading: false,
    });
  };

  handleReport = () => {
    this.setState({ reportModal: true });
  };

  handleReportOptions = (e) => {
    this.setState({ whyReport: e.target.value, isDissable: false });
  };

  handleChange = (e) => {
    this.setState({ reasonDesc: e.target.value });
  };

  // report Ad
  handleReportAd = () => {
    if (this.state.whyReport !== "") {
      this.setState({
        loading: true,
      });

      const reasonId = this.state.reasons.filter(
        (filter) => filter.reason === this.state.whyReport
      );

      const data = `reportedInterest=${this.state.postId}&reasonId=${reasonId[0].id}&reasonOther=${this.state.reasonDesc}`;

      axios
        .post(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/report_post`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: false,
            isDissable: true,
            reportModal: false,
            successAlert: true,
            whyReport: "",
            reasonDesc: "",
          });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    } else {
      this.setState({ error: true });
    }
  };

  handleReportClose = () => {
    this.setState({
      reportModal: false,
      isDissable: true,
      failAlert: false,
      successAlert: false,
      whyReport: "",
      reasonDesc: "",
    });
  };

  freeRenew = () => {
    this.setState({ loading: true });
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/free_renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((res) => {
            if (
              localStorage.getItem("user_role") === "OPERATOR" ||
              localStorage.getItem("user_role") === "OPERATOR_L2"
            ) {
              res.data[0] = res.data;
            }
            if (res.data.length > 0) {
              localStorage.setItem("post_details", JSON.stringify(res.data[0]));
            }

            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handlePhoneVisibilityModal = () => {
    this.setState({ phoneVisibilityModal: true });
  };

  handleGivePhoneVisibility = (e) => {
    e.preventDefault();

    axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          this.state.intId
        }/phone_visibility`,
        "showPhone=true",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ phoneVisibilityModal: false });

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  phoneVisibilityModalClose = () => {
    this.setState({ phoneVisibilityModal: false });
  };

  handlePreferenceMismatchModalClose = () => {
    this.setState({ preferenceMismatchModal: false, btnLoading: false });
  };

  needToCreateAnAccountClose = () => {
    this.setState({ needToCreateAnAccount: false, btnLoading: false });
  };

  showInterestOnMismatchedProfile = () => {
    this.setState({
      preferenceMismatchModal: false,
      btnLoading: false,
      intMessageModal: true,
      ignorePreferences: true,
    });
  };

  render() {
    if (this.state.redirectToUnavailabele) {
      return (
        <Unavailable title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
      );
    } else if (this.state.redirectToPageNotFound) {
      return (
        <PageNotFound title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />
      );
    } else if (this.state.redirectToUnauthorizedUser) {
      return (
        <UnauthorizedUser
          title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
      );
    } else if (this.state.redirectToRestrictedAccount) {
      return (
        <RestrictedAccount
          title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
      );
    } else if (this.state.outagePage) {
      return <Outage title={`Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`} />;
    } else {
      return (
        <React.Fragment>
          <PageHead
            title={this.state.displayName}
            name={` | ${Config.DOMAIN_DISPLAY_NAME}`}
          />
          <div style={{ background: "#dfdfdf" }} className="post-det-div">
            <div style={{ maxWidth: "820px", margin: "auto" }}>
              <GoogleDisplayAds slot="9743846933" />
            </div>
            <Grid
              container
              xs={12}
              sm={10}
              md={8}
              className="cus-inner-page cus-container-post"
            >
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Button
                  variant="contained"
                  className="back-btn-in-detail-pages "
                  onClick={() => this.props.history.goBack()}
                >
                  <i className="fas fa-angle-left"></i> &nbsp;
                  {this.props.lng ? "Back" : "ආපසු යන්න"}
                </Button>
              )}
              <NotLoggedBanner lng={this.props.lng} />

              {/* Refactored sections */}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Grid
                  container
                  direction="column"
                  style={{ rowGap: 20, marginBottom: 20 }}
                >
                  <HeaderSection
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                  <StatusSection
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                </Grid>
              )}

              {!this.state.isHideConnect && (
                <Connect
                  applyMatch={this.state.applyMatch}
                  lng={this.props.lng}
                  preLoading={this.state.preLoading}
                  phone={this.state.phone}
                  isExpired={this.state.isExpired}
                  showPhone={this.state.showPhone}
                  offlineId={this.state.offlineId}
                  email={this.state.email}
                  phoneVisibilityModal={this.state.phoneVisibilityModal}
                  handlePhoneVisibilityModal={this.handlePhoneVisibilityModal}
                  handleGivePhoneVisibility={this.handleGivePhoneVisibility}
                  phoneVisibilityModalClose={this.phoneVisibilityModalClose}
                  isMyProfile={false}
                  fName={this.state.fName}
                  lnameMasked={this.state.lnameMasked}
                  gender={this.state.gender}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  cancelSentTime={this.state.cancelSentTime}
                  unmatchedTime={this.state.unmatchedTime}
                  applyInterest={this.state.applyInterest}
                  applyNormalPost={this.state.applyNormalPost}
                  isInterestInfo={this.state.isInterestInfo}
                  alertModal={this.state.alertModal}
                  alertHeading={this.state.alertHeading}
                  alertMessage={this.state.alertMessage}
                  preferenceMismatchModal={this.state.preferenceMismatchModal}
                  showInterestOnMismatchedProfile={
                    this.showInterestOnMismatchedProfile
                  }
                  preferenceMismatchMessage={
                    this.state.preferenceMismatchMessage
                  }
                  handlePreferenceMismatchModalClose={
                    this.handlePreferenceMismatchModalClose
                  }
                  handleMessageModal={this.handleMessageModal}
                  handleModalClose={this.handleModalClose}
                  postId={this.state.postId}
                  matchPostId={this.state.matchPostId}
                  messageModal={this.state.messageModal}
                  intMessageModal={this.state.intMessageModal}
                  handleShowInterest={this.handleShowInterest}
                  showAccept={this.state.showAccept}
                  isIgnored={this.state.isIgnored}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  handleIgnoreModal={this.handleIgnoreModal}
                  ignoreModal={this.state.ignoreModal}
                  ignoreReason={this.state.ignoreReason}
                  handleIgnoreReason={this.handleIgnoreReason}
                  loadingIgnore={this.state.loadingIgnore}
                  handleAcceptMessageModal={this.handleAcceptMessageModal}
                  sendingPostId={this.state.sendingPostId}
                  commingMsg={this.state.matchMessage}
                  sensitiveDataError={this.state.sensitiveDataError}
                  loading={this.state.loading}
                  showIntTooltip={this.state.showIntTooltip}
                  displayName={this.state.displayName}
                  btnLoading={this.state.btnLoading}
                  likeMessage={this.state.likeMessage}
                  showPhotos={this.state.showPhotos}
                  receivedInterestImages={this.state.receivedInterestImages}
                  intId={this.state.intId}
                  height={this.state.height}
                  age={this.state.age}
                  profession={this.state.profession}
                  profession_si={this.state.profession_si}
                  residentCity={this.state.residentCity}
                  preferenceMismatchMessageSinhala={
                    this.state.preferenceMismatchMessageSinhala
                  }
                />
              )}

              {this.state.isInterestInfo && !this.state.messageModal && (
                <TimeLine
                  lng={this.props.lng}
                  fName={this.state.fName}
                  preLoading={this.state.preLoading}
                  likeMessage={this.state.likeMessage}
                  matchMessage={this.state.matchMessage}
                  showInt={this.state.showInt}
                  isIgnored={this.state.isIgnored}
                  autoIgnored={this.state.autoIgnored}
                  refuseReason={this.state.refuseReason}
                  likedAt={this.state.likedAt}
                  cancelSentTime={this.state.cancelSentTime}
                  cancelAcceptTime={this.state.cancelAcceptTime}
                  ignoredBy={this.state.ignoredBy}
                  unmatchedTime={this.state.unmatchedTime}
                  unmatchedBy={this.state.unmatchedBy}
                  acceptTime={this.state.acceptTime}
                  matchedAt={this.state.matchedAt}
                  acceptBy={this.state.acceptBy}
                  receivedTo={this.state.receivedTo}
                  sentTime={this.state.sentTime}
                  receivedTime={this.state.receivedTime}
                  applyMatch={this.state.applyMatch}
                  applyInterest={this.state.applyInterest}
                  handleCancelReqModal={this.handleCancelReqModal}
                  unmatchModal={this.state.unmatchModal}
                  handleUnmatchModal={this.handleUnmatchModal}
                  handleModalClose={this.handleModalClose}
                  handleIgnoreAllInt={this.handleIgnoreAllInt}
                  loadingIgnore={this.state.loadingIgnore}
                  isMyProfile={false}
                  matchPostId={this.state.matchPostId}
                  reportedPosts={this.state.reportedPosts}
                  reasons={this.state.reasons}
                  handleChange={this.handleChange}
                  handleReport={this.handleReport}
                  reportModal={this.state.reportModal}
                  whyReport={this.state.whyReport}
                  handleReportAd={this.handleReportAd}
                  handleReportOptions={this.handleReportOptions}
                  handleReportClose={this.handleReportClose}
                  error={this.state.error}
                  undoAutoRejectTime={this.state.undoAutoRejectTime}
                  undoAutoRejectedBy={this.state.undoAutoRejectedBy}
                  displayName={this.state.displayName}
                />
              )}

              {/* <div style={{width: '100%', marginBottom: '16px'}}><GoogleInfeedAds slot="7432721829"/></div> */}

              {/* Refactored sections */}
              {!this.state.intMessageModal && !this.state.messageModal && (
                <Grid container direction="column" style={{ rowGap: 20 }}>
                  <PersonalInformation data={this.state.postDetails} />
                  <FamilyInformation data={this.state.postDetails} />
                  <HoroscopeInformation
                    data={this.state.postDetails}
                    interestData={this.state.interestData}
                  />
                </Grid>
              )}
            </Grid>
            <GoogleAds slot="6370303041" />
          </div>
          <div className="create-floor-footer-desktop">
            <Footer lng={this.props.lng} />
          </div>
          <div className="create-floor-footer-mobile">
            <FooterMobile lng={this.props.lng} />
          </div>

          <CancelRequestModal
            lng={this.props.lng}
            cancelRequest={this.state.cancelRequest}
            handleModalClose={this.handleModalClose}
            handleIgnoreAllInt={this.handleIgnoreAllInt}
            loadingIgnore={this.state.loadingIgnore}
          />

          <NeedToCreateAnAccount
            lng={this.props.lng}
            needToCreateAnAccount={this.state.needToCreateAnAccount}
            needToCreateAnAccountClose={this.needToCreateAnAccountClose}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthInitialized: state.application.initialized,
    authProfile: state.application.authProfile,
    authenticated: state.application.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAppSnackNotification,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLanguageMigrator(Post)));
