import { FETCH_ARCHIVED_SPAM_INTEREST_POSTS } from "./types";
import axios from "axios";

export const getArchivedSpamInterestPosts = (page=0) => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
        "postId"
      )}/interests/received?ignored=true&spam=true&pageNum=${page}&pageSize=25`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
    .then((res) => {
      let allMatches = res.data.results;
      let page = res.data.pageNum;
      dispatch({
        type: FETCH_ARCHIVED_SPAM_INTEREST_POSTS,
        payload: {
          archived: allMatches,
          page: page,
          pageSize: 25
        },
      });
    });
};