import { FETCH_INTEREST_POSTS } from "./types";
import axios from "axios";

export const getInterestPosts =
  (page = 0) =>
  (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
          "postId"
        )}/interests/received?archived=false&pageNum=${page}&pageSize=25`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let allMatches = res.data.results;
        let page = res.data.pageNum;
        dispatch({
          type: FETCH_INTEREST_POSTS,
          payload: {
            interests: allMatches,
            page: page,
            pageSize: 25,
          },
        });
      });
  };

export const clearInterestPosts = () => (dispatch) => {
  dispatch({
    type: FETCH_INTEREST_POSTS,
    payload: {
      interests: [],
      page: 0,
      pageSize: 0,
    },
  });
};
