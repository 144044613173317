import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const ListItem = ({ listItem, value, subValue, link, linkType }) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" wrap="nowrap">
      <Typography variant="body2" style={{ color: "#494949", width: "32%" }}>
        {t(listItem)}
      </Typography>
      {value && (
        <Typography variant="body2" style={{ color: "#494949" }}>
          <>
            {`: ${t(value)}`}
            <br />
            {t(subValue)}
          </>
        </Typography>
      )}
      {link && (
        <Typography
          className="icon-box-txt"
          dangerouslySetInnerHTML={{ __html: `: ${link}` }}
        />
      )}
    </Grid>
  );
};

export default ListItem;
