import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  Paper,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import qs from "qs";
import Packages from "../payment/Packages";

import Config from "../../v2/config";

import stripe from "../../images/stripe.png";
import { getDiscountPercentage } from "../../methods/getDiscountPercentage";

import "../../styles/payment-link.css";

class Pay extends Component {
  state = {
    userId: "",
    postId: "",
    sessionId: localStorage.getItem("session_creation")
      ? JSON.parse(localStorage.getItem("session_creation")).sessionId
      : "",

    price: 0,
    name: "",
    email: "",
    phone: "",
    isExpired: false,
    isPromoCodeAllowed: false,
    linkId: "",
    dueAmount: 0,
    discount: 0,
    currency: "",
    currencySymbol: "",
    promo: "",
    isRenew: false,
    countryCode: "LK",
    showCurrencyDropdown: false,
    currencyArr: ["LKR"],
    payingCountryCode: "",
    pricingTierId: "",
    packages: [],
    paymentLinkPackage: "",

    isOnline: false,
    isDeposit: false,

    isPromoApplied: false,
    showPromo: false,

    // errors
    promoError: "",
    chekoutError: false,

    allPayMethods: [],
    selected: "",

    // loading
    loading: false,
    priceLoading: true,
  };

  componentDidMount = () => {
    localStorage.removeItem("promoCode");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/payment_links/${this.props.match.params.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          pricingTierId: res.data.pricingTier.id,
        });
        this.getPackages();
        if (res.data.post.personalInfo.residentCountry.code !== "LK") {
          this.setState({
            currencyArr: [
              ...this.state.currencyArr,
              // res.data.post.personalInfo.residentCountry.currency,
              "USD",
            ],
          });

          // paymentmethods
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
            .then((res) => {
              const isLocalPayment = res.data.filter(
                (el) => el.id === "local_cc"
              );
              if (isLocalPayment.length > 0) {
                this.setState({ showCurrencyDropdown: true });
              }

              this.setState({ allPayMethods: res.data });

              setTimeout(
                () =>
                  this.setState({ allPayMethods: res.data, preLoading: false }),
                1000
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // paymentmethods
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
            .then((res) => {
              this.setState({ allPayMethods: res.data });

              setTimeout(
                () =>
                  this.setState({ allPayMethods: res.data, preLoading: false }),
                1000
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState({ preLoading: false });
            });
        }

        this.setState({
          isRenew: res.data.isRenew,
          isExpired: res.data.isExpired,
          isPromoCodeAllowed: res.data.isPromoCodeAllowed,
          linkId: res.data.linkId,
          userId: res.data.memberId,
          name:
            // res.data.post.personalInfo.fname +
            // " " +
            res.data.post.personalInfo.displayName,
          email: res.data.post.email,
          phone: res.data.post.phone ? res.data.post.phone : "",
          postId: res.data.post.id,
          countryCode: res.data.post.personalInfo.residentCountry.code,
          payingCountryCode:
            res.data.post.personalInfo.residentCountry.code === "LK"
              ? "LK"
              : "AU",
        });

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${res.data.post.id}/checkout_tiered`,
            qs.stringify({
              paymentLinkId: res.data.linkId,
              pricingTierId: res.data.pricingTier.id,
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((res) => {
            localStorage.setItem("session_creation", JSON.stringify(res.data));
            this.setState({
              sessionId: res.data.sessionId,
              price: res.data.amount,
              promoCode: res.data.promoCode ? true : false,
              dueAmount: res.data.amount,
              currency: res.data.currency,
              discountPercentage: res.data.discountPercentage,
              amountBeforeDiscount: res.data.amountBeforeDiscount,
              currencySymbol:
                res.data.currency === "LKR" ? "Rs." : res.data.currency,
              priceLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.code === 1512) {
              this.setState({
                chekoutError: true,
              });
            }
            this.setState({ priceLoading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ preLoading: false });
      });

    this.loadScripts();
  };

  getPackages = () => {
    this.setState({ priceLoading: true, packagesLoading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/pricing_tiers`)
      .then((res) => {
        if (res.data) {
          let oneMOnth = res.data.filter((filter) => filter.id === "1m");
          let threeMonths = res.data.filter((filter) => filter.id === "3m");
          if (oneMOnth.length > 0 && threeMonths.length > 0) {
            this.setState({
              threeMonthsDiscount: Math.floor(
                ((oneMOnth[0].amountUSD - threeMonths[0].amountUSD / 3) /
                  oneMOnth[0].amountUSD) *
                  100
              ),
              threeMonthsDiscountLKR: Math.floor(
                ((oneMOnth[0].amountLKR - threeMonths[0].amountLKR / 3) /
                  oneMOnth[0].amountLKR) *
                  100
              ),
            });
          }
          let twoMonths = res.data.filter((filter) => filter.id === "2m");
          if (oneMOnth.length > 0 && twoMonths.length > 0) {
            this.setState({
              twoMOnthsDiscountLKR: getDiscountPercentage(
                oneMOnth[0].amountLKR,
                twoMonths[0].amountLKR / 2
              ),
              twoMOnthsDiscount: getDiscountPercentage(
                oneMOnth[0].amountUSD,
                twoMonths[0].amountUSD / 2
              ),
            });
          }
        }
        this.setState({ packages: res.data });
        const defaultPackage = res.data.filter((el) => el.preferred === true);
        this.setState({ packagesLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setPackage = (id) => {
    this.setState({
      selectedPackage: id,
      priceLoading: true,
      pricingTierId: id,
    });
    let data = {
      isRenew: this.state.isRenew,
      forceLKR:
        sessionStorage.getItem("force_lkr") &&
        sessionStorage.getItem("force_lkr") === "true"
          ? true
          : false,
      pricingTierId: id,
    };
    if (localStorage.getItem("promoCode")) {
      data.promoCode = localStorage.getItem("promoCode");
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          promoCode: res.data.promoCode ? true : false,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
          discount: res.data.amountBeforeDiscount - res.data.amount,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ priceLoading: false });
      });
  };

  loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);

    // paypal
    const paypalScript = document.createElement("script");

    paypalScript.src = "https://www.paypalobjects.com/api/checkout.js";
    paypalScript.async = true;

    document.body.appendChild(paypalScript);
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  // validate
  validate = () => {
    let promoError = "";

    if (this.state.promo === "") {
      promoError = "Promo code is required";
    }

    if (promoError) {
      this.setState({
        promoError,
      });
      return false;
    }
    return true;
  };

  // checkout
  handleApply = () => {
    if (this.validate()) {
      this.setState({ loading: true });

      let dataObj;
      if (this.state.selectedPackage) {
        dataObj = qs.stringify({
          isRenew: this.state.isRenew,
          paymentMethodId: "paypal",
          forceLKR:
            sessionStorage.getItem("force_lkr") &&
            sessionStorage.getItem("force_lkr") === "true"
              ? true
              : false,
          pricingTierId: this.state.selectedPackage,
          promoCode: this.state.promo,
        });
      } else {
        dataObj = qs.stringify({
          isRenew: this.state.isRenew,
          promoCode: this.state.promo,
          pricingTierId: this.state.pricingTierId,
          paymentLinkId: this.props.match.params.id,
          forceLKR:
            sessionStorage.getItem("force_lkr") &&
            sessionStorage.getItem("force_lkr") === "true"
              ? true
              : false,
        });
      }
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
          dataObj,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("session_creation", JSON.stringify(res.data));
          localStorage.setItem("promoCode", this.state.promo);

          this.setState({
            price: res.data.amount,
            discount: res.data.amountBeforeDiscount - res.data.amount,
            dueAmount: res.data.amount,
            promoCode: res.data.promoCode ? true : false,
            isPromoApplied: true,
            sessionId: res.data.sessionId,
            loading: false,
            promo: "",
            promoError: "",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });

          if (err.response.data.code === 1400) {
            this.setState({
              promoError: this.props.lng
                ? "Invalid promo code"
                : "ප්‍රවර්ධනය කේතය වලංගු නොවේ",
            });
          } else if (err.response.data.code === 1401) {
            this.setState({
              promoError: this.props.lng
                ? "Promo code expired"
                : "ප්‍රවර්ධනය කේතය කල් ඉකුත් වී ඇත",
            });
          } else if (err.response.data.code === 1311) {
            this.setState({
              promoError: this.props.lng
                ? "Promo code valid only for new accounts"
                : "ප්‍රවර්ධනය කේතය නව ගිණුම් සඳහා පමණක් වලංගු වේ",
            });
          } else if (err.response.data.code === 1312) {
            this.setState({
              promoError: this.props.lng
                ? "Promo code invalid for the selected package"
                : "ප්‍රවර්ධනය කේතය තෝරාගත් පැකේජය සඳහා වලංගු නොවේ",
            });
          } else if (err.response.data.code === 1512) {
            this.setState({
              chekoutError: true,
            });
          }
        });
    }
  };

  handleCurrency = (e) => {
    localStorage.removeItem("promoCode");
    this.setState({ currency: e.target.value, priceLoading: true });
    let countryCode = "LK";
    if (e.target.value === "LKR") {
      sessionStorage.setItem("force_lkr", true);
      countryCode = "LK";
    } else {
      sessionStorage.setItem("force_lkr", false);
      countryCode = "US";
    }

    let data;
    if (this.state.selectedPackage) {
      data = {
        isRenew: this.state.isRenew,
        paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
        forceLKR:
          sessionStorage.getItem("force_lkr") &&
          sessionStorage.getItem("force_lkr") === "true"
            ? true
            : false,
        pricingTierId: this.state.selectedPackage,
      };
      if (localStorage.getItem("promoCode")) {
        data.promoCode = localStorage.getItem("promoCode");
      }
    } else {
      this.setState({ selectedPackage: this.state.pricingTierId });
      data = {
        // paymentLinkId: this.props.match.params.id,
        pricingTierId: this.state.pricingTierId,
        isRenew: this.state.isRenew,
        paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
      };
    }

    this.setState({
      payingCountryCode: e.target.value === "LKR" ? "LK" : "AUD",
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("session_creation", JSON.stringify(res.data));
        localStorage.removeItem("promoCode");

        this.setState({
          sessionId: res.data.sessionId,
          price: res.data.amount,
          promoCode: res.data.promoCode ? true : false,
          amountBeforeDiscount: res.data.amountBeforeDiscount,
          discountPercentage: res.data.discountPercentage,
          dueAmount: res.data.amount,
          isRenew: res.data.isRenew,
          currency: res.data.currency,
          currencySymbol:
            res.data.currency === "LKR" ? "Rs." : res.data.currency,
          priceLoading: false,
          discount: 0,
          isPromoApplied: false,
          showPromo: false,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1512) {
          this.setState({
            chekoutError: true,
          });
        }

        this.setState({ priceLoading: false });
      });
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleMethods = (method) => {
    this.setState({ selected: method, notSelectedError: false });
  };

  createSessionCheckout = (id) => {
    this.setState({ selected: id, notSelectedError: false });

    if (id === "paypal") {
      this.setState({ selected: id }, () => {
        this.handlePayPal();
      });
    }
  };

  redirect = () => {
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);

    this.setState({ loadingCard: true });

    if (this.state.ipg === "STRIPE") {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: this.state.sessionId,
        })
        .then(function (result) {
          this.setState({ loadingCard: false });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
        });
    } else {
      document.getElementById("pay-by-genie").click();

      this.setState({ dissableCard: true });
    }
  };

  handleCheckout = () => {
    this.setState({ loading: true });

    if (this.state.selected) {
      this.setState({ notSelectedError: false });
      const isBank = this.state.allPayMethods.filter(
        (el) => el.isBank === true && el.id === this.state.selected
      );

      if (isBank.length > 0) {
        this.props.history.push(
          `/checkout/bank-transfer?code=${isBank[0].region}`
        );
      } else {
        let data;
        if (this.state.selectedPackage) {
          data = {
            isRenew: this.state.isRenew,
            // paymentMethodId: e.target.value === "LKR" ? "local_cc" : "foreign_cc",
            forceLKR:
              sessionStorage.getItem("force_lkr") &&
              sessionStorage.getItem("force_lkr") === "true"
                ? true
                : false,
            pricingTierId: this.state.selectedPackage,
          };
          if (localStorage.getItem("promoCode")) {
            data.promoCode = localStorage.getItem("promoCode");
          }
        } else {
          data = {
            paymentLinkId: this.props.match.params.id,
            pricingTierId: this.state.pricingTierId,
          };
        }

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
            qs.stringify(data),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("session_creation", JSON.stringify(res.data));

            this.setState(
              {
                sessionId: res.data.sessionId,
                ipg: res.data.ipg,
                merchantId:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.merchantId
                    : "",
                firstName:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.firstName
                    : "",
                orderId:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.orderId
                    : "",
                items:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.items
                    : "",
                hash:
                  res.data.ipg === "PAYHERE" ? res.data.payhereParams.hash : "",
                returnUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.returnUrl
                    : "",
                cancelUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.cancelUrl
                    : "",
                notifyUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.notifyUrl
                    : "",
                currency:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.currency
                    : "",
                dueAmount:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.amount
                    : "",
              },
              () => {
                this.redirect();
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });

            if (err.response.data.code === 1400) {
              this.setState({
                promoError: this.props.lng
                  ? "Invalid promo code"
                  : "ප්‍රවර්ධනය කේතය වලංගු නොවේ",
              });
            } else if (err.response.data.code === 1401) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code expired"
                  : "ප්‍රවර්ධනය කේතය කල් ඉකුත් වී ඇත",
              });
            } else if (err.response.data.code === 1311) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code valid only for new accounts"
                  : "ප්‍රවර්ධනය කේතය නව ගිණුම් සඳහා පමණක් වලංගු වේ",
              });
            } else if (err.response.data.code === 1312) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code invalid for the selected package"
                  : "ප්‍රවර්ධනය කේතය තෝරාගත් පැකේජය සඳහා වලංගු නොවේ",
              });
            } else if (err.response.data.code === 1512) {
              this.setState({
                chekoutError: true,
              });
            }
          });
      }
    } else {
      this.setState({
        notSelectedError: this.props.lng
          ? "Please select a payment method"
          : "කරුණාකර ගෙවීම් ක්‍රමයක් තෝරන්න",
        loading: false,
      });
    }
  };

  handlePayPal = () => {
    if (localStorage.getItem("promoCode")) {
      this.runPaypalWithPromo();
    } else {
      this.runPaypalWithoutPromo();
    }
  };

  runPaypalWithoutPromo = () => {
    let isRenew = this.state.isRenew;
    let postId = this.state.postId;
    let selectedPackage = this.state.selectedPackage;
    let paymentLink = this.props.match.params.id;

    let envonment =
      Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP
        ? "sandbox"
        : "production";

    window.paypal.Button.render(
      {
        env: envonment, //'sandbox' Or 'production'
        // Set up the payment:
        // 1. Add a payment callback

        payment: function (data, actions) {
          let dataObj;
          if (selectedPackage) {
            dataObj = {
              isRenew: isRenew,
              paymentMethodId: "paypal",
              forceLKR:
                sessionStorage.getItem("force_lkr") &&
                sessionStorage.getItem("force_lkr") === "true"
                  ? true
                  : false,
              pricingTierId: selectedPackage,
            };
          } else {
            dataObj = {
              isRenew: isRenew,
              paymentMethodId: "paypal",
              paymentLinkId: paymentLink,
              pricingTierId: selectedPackage,
            };
          }
          //2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
              dataObj,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                },
              }
            )
            .then(function (res) {
              // 3. Return res.id from the response
              return res.paypalParams.orderId;
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.code === 1512) {
                this.setState({
                  chekoutError: true,
                });
              }
            });
        },
        // Execute the payment:
        // 1. Add an onAuthorize callback
        onAuthorize: function (data, actions) {
          // 2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/confirm_checkout_paypal`,
              {
                paymentId: data.paymentID,
                payerId: data.payerID,
              }
            )
            .then(function (res) {
              // 3. Show the buyer a confirmation message.

              window.alert("Payment Complete!");
            });
        },
      },
      "#paypal-button"
    );
  };

  runPaypalWithPromo = () => {
    let isRenew = this.state.isRenew;
    let postId = this.state.postId;
    let selectedPackage = this.state.selectedPackage;
    let paymentLink = this.props.match.params.id;
    let envonment =
      Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP
        ? "sandbox"
        : "production";

    window.paypal.Button.render(
      {
        env: envonment, //'sandbox' Or 'production'
        // Set up the payment:
        // 1. Add a payment callback

        payment: function (data, actions) {
          let dataObj;
          if (selectedPackage) {
            dataObj = {
              isRenew: isRenew,
              paymentMethodId: "paypal",
              forceLKR:
                sessionStorage.getItem("force_lkr") &&
                sessionStorage.getItem("force_lkr") === "true"
                  ? true
                  : false,
              pricingTierId: selectedPackage,
              promoCode: localStorage.getItem("promoCode"),
            };
          } else {
            dataObj = {
              isRenew: isRenew,
              paymentMethodId: "paypal",
              paymentLinkId: paymentLink,
              pricingTierId: selectedPackage,
              promoCode: localStorage.getItem("promoCode"),
            };
          }

          //2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
              dataObj,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                },
              }
            )
            .then(function (res) {
              // 3. Return res.id from the response
              return res.paypalParams.orderId;
            });
        },
        // Execute the payment:
        // 1. Add an onAuthorize callback
        onAuthorize: function (data, actions) {
          // 2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/confirm_checkout_paypal`,
              {
                paymentId: data.paymentID,
                payerId: data.payerID,
              }
            )
            .then(function (res) {
              // 3. Show the buyer a confirmation message.
              window.alert("Payment Complete!");
            });
        },
      },
      "#paypal-button"
    );
  };

  render() {
    const payMethods =
      this.state.countryCode === "LK" && this.state.payingCountryCode === "LK"
        ? this.state.allPayMethods
            .filter((el) => el.isBank === false)
            .sort((a, b) => a.sortOrder - b.sortOrder)
        : this.state.countryCode !== "LK" &&
          this.state.payingCountryCode === "LK"
        ? this.state.allPayMethods
            .filter((el) => el.isBank === false && el.id !== "foreign_cc")
            .sort((a, b) => a.sortOrder - b.sortOrder)
        : this.state.allPayMethods
            .filter((el) => el.isBank === false)
            .filter((el) => el.region !== "LK" || el.region === undefined);
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <div className="remember-notice">
              {this.props.lng ? (
                `Ad ID : ${this.state.userId}`
              ) : (
                <span className="sinhala-w-600 sinhala-size-12">
                  දැන්වීම් කේතය : {this.state.userId}
                </span>
              )}
            </div>

            {this.state.chekoutError ? (
              <div
                className="invoice-terms pay-method-div"
                style={{ paddingBottom: 30 }}
              >
                <div
                  className="invoice"
                  style={{ padding: "25px 15px", textAlign: "center" }}
                >
                  {this.props.lng
                    ? "Post not within renewal period"
                    : "ඔබගේ දැන්වීම තවම අලුත් කල නොහැක"}
                </div>
              </div>
            ) : (
              <>
                <div
                  className="invoice-terms pay-method-div"
                  style={{ paddingBottom: 30 }}
                >
                  {this.state.showCurrencyDropdown && (
                    <div className="choose-currency-dropdown-div">
                      <select
                        value={this.state.currency}
                        onChange={this.handleCurrency}
                        className="choose-currency-dropdown"
                      >
                        {this.state.currencyArr.map((el, i) => (
                          <option value={el} key={i}>
                            {el}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div>
                    <Packages
                      lng={this.props.lng}
                      packages={this.state.packages}
                      setPackage={this.setPackage}
                      selectedPackage={this.state.pricingTierId}
                      packagesLoading={this.state.packagesLoading}
                      countryCode={this.state.countryCode}
                      threeMonthsDiscount={this.state.threeMonthsDiscount}
                      twoMOnthsDiscount={this.state.twoMOnthsDiscount}
                      threeMonthsDiscountLKR={this.state.threeMonthsDiscountLKR}
                      twoMOnthsDiscountLKR={this.state.twoMOnthsDiscountLKR}
                    />
                  </div>
                  <div className="invoice">
                    <div className="payment-info-div">
                      <p className="inv-heading" style={{ textAlign: "left" }}>
                        {this.props.lng ? (
                          "Payment for:"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            බිල්පත
                          </span>
                        )}
                      </p>
                      <p className="info">{this.state.name}</p>
                      <p className="info">{this.state.email}</p>
                      <p className="info">{this.state.phone}</p>
                    </div>
                    <Table className="checkout-table" aria-label="simple table">
                      <tbody>
                        <TableRow>
                          <TableCell component="td" scope="row">
                            {!this.state.isRenew
                              ? this.props.lng
                                ? "Advertising Fee"
                                : "දැන්වීම් ගාස්තුව"
                              : this.props.lng
                              ? "Renewal Fee"
                              : "අලුත් කිරීමේ ගාස්තුව"}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.priceLoading ? (
                              <CircularProgress
                                size={16}
                                style={{ color: "#000" }}
                              />
                            ) : (
                              `${this.state.currencySymbol} ${
                                this.state.discountPercentage > 0
                                  ? this.state.amountBeforeDiscount.toFixed(2)
                                  : this.state.price.toFixed(2)
                              }`
                            )}
                          </TableCell>
                        </TableRow>
                        {this.state.promoCode ? null : this.state
                            .discountPercentage > 0 ? (
                          <TableRow>
                            <TableCell component="td" scope="row">
                              {this.props.lng
                                ? "20% Renewal Discount"
                                : "20% අලුත් කිරීමේ වට්ටම"}
                            </TableCell>
                            <TableCell align="right">
                              {this.state.priceLoading ? (
                                <CircularProgress
                                  size={16}
                                  style={{ color: "#000" }}
                                />
                              ) : (
                                `- ${this.state.currencySymbol} ${(
                                  this.state.amountBeforeDiscount -
                                  this.state.price
                                ).toFixed(2)}`
                              )}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell component="td" scope="row">
                            <p
                              onClick={() => this.setState({ showPromo: true })}
                              style={{
                                display: this.state.showPromo ? "none" : "",
                                margin: 0,
                                fontSize: 11,
                                color: "#CCA326",
                                cursor: "pointer",
                                fontWeight: 500,
                              }}
                            >
                              {this.props.lng
                                ? "Add Promo Code"
                                : "ප්‍රවර්ධන කේතය අතුලත් කරන්න"}{" "}
                            </p>
                            {this.state.showPromo
                              ? this.props.lng
                                ? "Promo Code"
                                : "ප්‍රවර්ධන කේතය"
                              : null}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.showPromo ? (
                              this.state.isPromoApplied ? (
                                <p
                                  style={{
                                    color: "#28a745",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    margin: 0,
                                  }}
                                >
                                  <i className="fas fa-check"></i> &nbsp;{" "}
                                  {this.props.lng
                                    ? "Promo Applied"
                                    : "ප්‍රවර්ධනය සිදු කලා"}
                                </p>
                              ) : (
                                <React.Fragment>
                                  <input
                                    className="promo-input"
                                    id="outlined-size-small"
                                    variant="outlined"
                                    size="small"
                                    name="promo"
                                    value={this.state.promo}
                                    onChange={this.handleChange}
                                    error={this.state.promoError ? true : false}
                                  />

                                  <Button
                                    variant="contained"
                                    className="apply-btn"
                                    onClick={this.handleApply}
                                  >
                                    {this.state.loading ? (
                                      <CircularProgress
                                        size={16}
                                        style={{ color: "#fff" }}
                                      />
                                    ) : this.props.lng ? (
                                      "Apply"
                                    ) : (
                                      "ඇතුලත් කරන්න"
                                    )}
                                  </Button>
                                </React.Fragment>
                              )
                            ) : null}
                            <p className="promo-error">
                              {this.state.promoError}
                            </p>
                          </TableCell>
                        </TableRow>
                        {this.state.discount ? (
                          <TableRow>
                            <TableCell component="td" scope="row">
                              {this.props.lng
                                ? "Promo Discount"
                                : "ප්‍රවර්ධන වට්ටම්"}
                            </TableCell>
                            <TableCell align="right">
                              - {this.state.currencySymbol}{" "}
                              {this.state.discount
                                ? this.state.discount.toFixed(2)
                                : 0}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell component="td" scope="row">
                            {this.props.lng
                              ? "Total Due"
                              : "ගෙවිය යුතු මුළු මුදල"}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.priceLoading ? (
                              <CircularProgress
                                size={16}
                                style={{ color: "#000" }}
                              />
                            ) : (
                              `${this.state.currencySymbol}
                    ${this.state.price.toFixed(2)}`
                            )}
                          </TableCell>
                        </TableRow>
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div className="invoice-terms pay-method-div">
                  <p className="heading">
                    {this.props.lng ? (
                      "Select Payment Method"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ගෙවීම් ක්‍රමය තෝරන්න
                      </span>
                    )}
                  </p>

                  {this.state.isOnline || this.state.isDeposit ? null : (
                    <>
                      <button
                        className="payment-method-btn"
                        onClick={() =>
                          this.setState({ isOnline: true, isDeposit: false })
                        }
                      >
                        {this.props.lng ? (
                          "Online Payment"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            අන්තර්ජාලය හරහා ගෙවීමේ ක්‍රම
                          </span>
                        )}
                      </button>
                      <p className="payment-method-btn-desc">
                        Visa, Master, Amex, FriMi, eZ Cash, PayPal, Sampath
                        Vishwa and more
                      </p>
                    </>
                  )}

                  {this.state.isOnline || this.state.isDeposit ? null : (
                    <>
                      <button
                        className="payment-method-btn"
                        onClick={() =>
                          this.setState({ isOnline: false, isDeposit: true })
                        }
                      >
                        {this.props.lng ? (
                          "Bank Deposit/Transfer"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            බැංකුවට ගෙවීමේ/තැන්පත් කිරීමේ ක්‍රම
                          </span>
                        )}
                      </button>
                      <p className="payment-method-btn-desc">
                        {this.props.lng
                          ? "Relevant bank account information will be displayed."
                          : "අදාළ ගිණුම් තොරතුරු පෙන්වනු ඇත"}
                      </p>
                    </>
                  )}

                  {this.state.isOnline || this.state.isDeposit ? (
                    <Button
                      variant="contained"
                      className="back-btn"
                      onClick={() =>
                        this.setState({
                          isOnline: false,
                          isDeposit: false,
                          notSelectedError: "",
                        })
                      }
                      style={{ marginLeft: "-10px" }}
                    >
                      <KeyboardArrowLeftIcon />{" "}
                      {this.props.lng ? "Back" : "ආපසු යන්න"}
                    </Button>
                  ) : null}

                  {this.state.isOnline ? (
                    <>
                      <div className="checkout-btn-mobile">
                        {this.state.selected === "paypal" ? (
                          window.innerWidth < 575 ? (
                            <div id="paypal-button"></div>
                          ) : null
                        ) : (
                          <Button
                            variant="contained"
                            className="save-btn"
                            onClick={this.handleCheckout}
                          >
                            {this.state.loading ? (
                              <CircularProgress
                                size={16}
                                style={{ color: "#000" }}
                              />
                            ) : this.props.lng ? (
                              "Checkout"
                            ) : (
                              "ඉදිරියට"
                            )}
                          </Button>
                        )}
                        {this.state.notSelectedError && (
                          <p
                            align="right"
                            style={{
                              fontSize: 12,
                              color: "#f44336",
                            }}
                          >
                            * {this.state.notSelectedError}
                          </p>
                        )}
                      </div>
                      <p className="sub-heading">
                        {this.props.lng ? (
                          "Online Payment Methods"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            අන්තර්ජාලය හරහා ගෙවීමේ ක්‍රම
                          </span>
                        )}
                      </p>
                      <p className="desc">
                        {this.props.lng
                          ? "You will be re-directed to the relevant payment gateway on the next screen."
                          : "කාඩ්පත් තොරතුරු ඇතුලත් කිරීම සඳහා ඔබව අදාල ගෙවීම් පිටුව වෙත යොමු වනු ඇත."}
                      </p>
                      {payMethods.map((el, i) => (
                        <div
                          className={
                            this.state.selected === el.id
                              ? "method method-active"
                              : "method"
                          }
                          key={i}
                          onClick={() => this.createSessionCheckout(el.id)}
                        >
                          <div>
                            <img src={el.icon} alt={el.id} />
                          </div>
                          <div>
                            <p>{el.name}</p>
                          </div>

                          {this.state.selected === el.id && (
                            <i className="fas fa-check method-select-icon"></i>
                          )}
                        </div>
                      ))}{" "}
                    </>
                  ) : null}

                  {this.state.isDeposit ? (
                    <>
                      <p className="sub-heading">
                        {this.props.lng ? (
                          "Bank Deposit/Transfer"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            බැංකුවට ගෙවීමේ/තැන්පත් කිරීමේ ක්‍රම
                          </span>
                        )}
                      </p>
                      <p className="desc">
                        {this.props.lng
                          ? "Relevant account information will be shown on the next screen."
                          : "අදාළ ගිණුම් තොරතුරු ඊළඟ තිරයේ පෙන්වනු ඇත."}
                      </p>

                      {this.state.allPayMethods
                        .filter((el) => el.isBank === true)
                        .sort((a, b) => a.sortOrder - b.sortOrder)
                        .map((el, i) => (
                          <div
                            className={
                              this.state.selected === el.id
                                ? "method method-active"
                                : "method"
                            }
                            key={i}
                            onClick={() => this.setState({ selected: el.id })}
                          >
                            <div>
                              <img src={el.icon} alt={el.id} />
                            </div>
                            <div>
                              <p>{el.name}</p>
                            </div>

                            {this.state.selected === el.id && (
                              <i className="fas fa-check method-select-icon"></i>
                            )}
                          </div>
                        ))}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </Paper>
        </Grid>

        <form
          id="ext-merchant-frm"
          action={process.env.REACT_APP_PAYHERE_URL}
          method="post"
          acceptCharset="UTF-8"
          encType="application/x-www-form-urlencoded"
        >
          <input
            type="hidden"
            name="merchant_id"
            value={this.state.merchantId}
          />
          <input type="hidden" name="return_url" value={this.state.returnUrl} />
          <input type="hidden" name="cancel_url" value={this.state.cancelUrl} />
          <input type="hidden" name="notify_url" value={this.state.notifyUrl} />

          <input type="hidden" name="first_name" value={this.state.firstName} />
          <input type="hidden" name="last_name" value="" />
          <input type="hidden" name="email" value="" />
          <input type="hidden" name="phone" value="" />
          <input type="hidden" name="address" value="" />
          <input type="hidden" name="city" value="" />
          <input type="hidden" name="country" value="" />

          <input type="hidden" name="hash" value={this.state.hash} />
          <input type="hidden" name="order_id" value={this.state.orderId} />
          <input type="hidden" name="items" value={this.state.items} />
          <input type="hidden" name="currency" value={this.state.currency} />
          <input type="hidden" name="amount" value={this.state.dueAmount} />
          <button id="pay-by-genie" hidden>
            Submit
          </button>
        </form>

        <div style={{ paddingBottom: 15, marginTop: 20 }}>
          <Button
            variant="contained"
            className="back-btn"
            onClick={() => this.props.history.push("/search")}
          >
            <KeyboardArrowLeftIcon />{" "}
            {this.props.lng ? "Pay Later" : "පසුව ගෙවන්න"}
          </Button>
          {this.state.selected === "paypal" ? (
            window.innerWidth > 574 ? (
              <div id="paypal-button"></div>
            ) : null
          ) : !this.state.chekoutError ? (
            <Button
              variant="contained"
              className="save-btn"
              onClick={this.handleCheckout}
            >
              {this.state.loading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : this.props.lng ? (
                "Checkout"
              ) : (
                "ඉදිරියට"
              )}
            </Button>
          ) : null}

          {this.state.notSelectedError && (
            <p
              align="right"
              style={{
                fontSize: 12,
                color: "#f44336",
              }}
            >
              * {this.state.notSelectedError}
            </p>
          )}
        </div>

        <p
          align="center"
          style={{
            fontSize: 12,
            color: "#737373",
            marginTop: 50,
            marginBottom: 5,
          }}
        >
          Online Payments are securely processed by
        </p>
        <img
          className="payment-methods-icons"
          src={stripe}
          alt="payment methods"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Pay);
