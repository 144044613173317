import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { Formik } from "formik";

import Unauthorize from "../../../pages/Unauthorize";
import PageHead from "../../components/atoms/page-head";
// import Navbar from "../../../components/common/Navbar";
import FooterMobile from "../../../components/common/FooterMobile";
import FooterDekstop from "../../../components/common/Footer";
import EditMyAdForm from "./edit-my-ad-form";
import FormMeta from "../../meta/edit-self-profile.json";
import { buildModelByFormMeta } from "../../utils";
import Config from "../../../v2/config";

import "./style.css";
import { getAdvertisement } from "../../features/edit-ad";
import NotificationBar from "../create-flow/templates/notification-bar";
import withLanguageMigrator from "../../hoc/language-migrator";
import schema from "./edit-my-ad-form/schema";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const initialValues = buildModelByFormMeta(FormMeta);

const EditMyAdPage = ({ lng }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const isMobiileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authenticated, initialized, authProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { notification } = useSelector((state) => state.editAdPage);
  const contentLoading = useMemo(() => {
    return !initialized || (authenticated && isEmpty(authProfile));
  }, [initialized, authenticated, authProfile]);

  const isUnauthorized = useMemo(() => {
    return !(!initialized || authenticated);
  }, [initialized, authenticated]);

  useEffect(() => {
    if (!isEmpty(authAccount) && !isEmpty(authProfile)) {
      dispatch(getAdvertisement());
    }
  }, [authProfile, authAccount, dispatch]);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const hashElement = hash.slice(1);
      const element = document.getElementById(hashElement);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ block: "center", behavior: "smooth" });
        }, [200]);
      }
    }
  }, [location]);

  if (isUnauthorized) {
    return <Unauthorize title={`Edit My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />;
  }

  return (
    <>
      <PageHead title={`Edit My Ad | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Grid
        container
        direction="row"
        className="edit-my-page-container"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          container
          direction="column"
          alignItems="center"
          className="form-container"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={schema({ t })}
            onSubmit={() => {}}
          >
            <EditMyAdForm contentLoading={contentLoading} />
          </Formik>
        </Grid>

        <Grid item xs={12}>
          {!isMobiileScreen ? (
            <FooterDekstop lng={lng} />
          ) : (
            <FooterMobile lng={lng} />
          )}
        </Grid>

        <NotificationBar
          severity={notification.severity}
          message={notification.message}
        />
      </Grid>
    </>
  );
};

export default withLanguageMigrator(EditMyAdPage);
