import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Config from "../../../../../config";
import CoupleIcon from "../../../../../assets/payment/coupleIcon.png";
import CuratedIcon from "../../../../../assets/payment/curatedIcon.png";
import SatisfactionIcon from "../../../../../assets/payment/satisfactionIcon.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PremiumFeatures from "../features";
import "../../style.css";

const { PRODUCT_LOGO_WHITE } = Config;

const PremiumPackageView = () => {
  const history = useHistory();
  return (
    <Grid container className="premium-package-view">
      <Grid
        container
        direction="column"
        alignItems="center"
        className="premium-package-view-content-wrapper"
      >
        <img src={PRODUCT_LOGO_WHITE} alt="product-logo" />
        <img src="https://static.poruwa.lk/images/payment/premium-txt.png" alt="premium-text" style={{ width: 200 }} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: 600 }}
        >
          <PremiumFeatures text="Higher Success Rate" icon={SatisfactionIcon} />
          <PremiumFeatures text="Dedicated Consultants" icon={CuratedIcon} />
          <PremiumFeatures text="Curated Matches" icon={CoupleIcon} />
        </Grid>
        <Button
          className="read-more-btn"
          onClick={() => history.push("/premium")}
        >
          Read More
        </Button>
      </Grid>
    </Grid>
  );
};

export default PremiumPackageView;
