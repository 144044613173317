import * as Yup from "yup";
import {
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
} from "../../../../utils";
import FormMeta from "../../../../meta/edit-self-profile.json";
import { DISPLAY_NAME_FORMAT_OPTIONS } from "../../../../static-data";

const schema = ({ t }) => {
  return Yup.object().shape({
    fnameReview: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.firstname") })
      )
      .min(
        FormMeta.firstNameReview.min,
        t("errors.formFieldTooShort", { fieldName: t("common.firstname") })
      )
      .max(
        FormMeta.firstNameReview.max,
        t("errors.formFieldTooLong", { fieldName: t("common.firstname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.firstname"),
        }),
      }),
    // .test("validate-name-fields-with-format", "", (value, context) => {
    //   const currentNameFormat = context.parent.displayNameFormat;
    //   const currentDef = DISPLAY_NAME_FORMAT_OPTIONS.find(
    //     (current) => current.value === currentNameFormat
    //   );
    //   const minDisplayNameLength = 3;

    //   if (
    //     (value.length < minDisplayNameLength &&
    //       currentNameFormat === "FIRST") ||
    //     (value.length < minDisplayNameLength &&
    //       currentNameFormat === "FIRST_LAST_CHAR1")
    //   ) {
    //     return context.createError({
    //       message: t("errors.insufficientCharLengthForSelectedNameFormat", {
    //         fieldName: t(currentDef.labelKey),
    //         length: 3,
    //       }),
    //     });
    //   }
    //   return true;
    // }),

    lnameReview: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.lastname") })
      )
      .max(
        FormMeta.lastNameReview.max,
        t("errors.formFieldTooLong", { fieldName: t("common.lastname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.lastname"),
        }),
      }),
    // .test("validate-name-fields-with-format", "", (value, context) => {
    //   const currentNameFormat = context.parent.displayNameFormat;
    //   const currentDef = DISPLAY_NAME_FORMAT_OPTIONS.find(
    //     (current) => current.value === currentNameFormat
    //   );
    //   const minDisplayNameLength = 3;
    //   if (
    //     (value.length < minDisplayNameLength &&
    //       currentNameFormat === "LAST") ||
    //     (value.length < minDisplayNameLength &&
    //       currentNameFormat === "FIRST_CHAR1_LAST")
    //   ) {
    //     return context.createError({
    //       message: t("errors.insufficientCharLengthForSelectedNameFormat", {
    //         fieldName: t(currentDef.labelKey),
    //         length: 3,
    //       }),
    //     });
    //   }
    //   return true;
    // }),

    casteReview: Yup.string()
      .max(
        FormMeta.caste.max,
        t("errors.formFieldTooLong", { fieldName: t("common.caste") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.caste"),
        }),
      }),
  });
};

export default schema;
