import React from "react";
import Config from "../../v2/config";
// import ThumbNail from "static.poruwa.lk/blog/images/Article01/Cover-Image.jpg";
const { CURRENT_PLATFORM, PLATFORMS } = Config;

function BlogPost(props) {
  return (
    <a
      href="/blog"
      className="maganize-card"
      style={{ width: "100%", margin: "0" }}
      id="magasize-heading"
    >
      <div className="filter-maga-div" id="filter-maga-div">
        <h1 className="magasize-heading" style={{ lineHeight: "15px" }}>
          {props.lng ? (
            "Blog"
          ) : (
            <span style={{ fontSize: 18 }}>බ්ලොග් පිටුව</span>
          )}
        </h1>
        {CURRENT_PLATFORM === PLATFORMS.IN ? (
          <img
            src="https://static.poruwa.lk/blog/images/myvivah/covers/myvivah-blog-cover.jpg"
            alt="blog"
            className="maganize-img"
          />
        ) : (
          <img
            src="https://static.poruwa.lk/images/blog-avatar.webp"
            alt="blog"
            className="maganize-img"
            width={150}
            height={150}
            style={{ objectFit: "cover" }}
          />
        )}
      </div>
    </a>
  );
}

export default BlogPost;
