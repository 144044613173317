import { PRICING_DISPLAY_VARIANTS } from "..";
import { SUPPORTED_CURRENCIES } from "../../../config/constant";
import { packageDetails } from "./schema";

export const generatePricingPackages = ({
  pricingTiers = [],
  selectedCountry,
  supportedCountries = [],
  CURRENT_PLATFORM,
  variant,
  selectedCurrency,
  residentCountry,
}) => {
  const packages = { regularPackages: [], vipPackages: [] };
  for (const pricingTier of pricingTiers) {
    const pricingSchema = packageDetails[pricingTier.id] ?? {};

    let currentPackage = { ...pricingSchema };

    currentPackage.pricingTier = pricingTier;
    currentPackage.id = pricingTier.id;

    const primaryCurrency =
      supportedCountries.find((element) => element.code === CURRENT_PLATFORM) ??
      {};
    const internationalCurrency =
      supportedCountries.find((element) => element.code === "other") ?? {};

    if (variant === PRICING_DISPLAY_VARIANTS.PRICING) {
      if (selectedCountry === CURRENT_PLATFORM) {
        currentPackage.currency = primaryCurrency.symbol;
        currentPackage.packageAmount = pricingTier.amountLKR;
        currentPackage.monthlyAmount = `${(
          pricingTier.amountLKR / pricingTier.durationMonths
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      } else {
        currentPackage.currency = internationalCurrency.symbol;
        currentPackage.packageAmount = pricingTier.amountUSD;
        currentPackage.monthlyAmount = `${(
          pricingTier.amountUSD / pricingTier.durationMonths
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      }
    }

    if (variant === PRICING_DISPLAY_VARIANTS.PAY_FOR_AD) {
      if (residentCountry === CURRENT_PLATFORM) {
        currentPackage.currency = primaryCurrency.symbol;
        currentPackage.packageAmount = pricingTier.amountLKR;
        currentPackage.monthlyAmount = `${(
          pricingTier.amountLKR / pricingTier.durationMonths
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      } else {
        if (selectedCurrency === SUPPORTED_CURRENCIES.USD) {
          currentPackage.currency = internationalCurrency.symbol;
          currentPackage.packageAmount = pricingTier.amountUSD;
          currentPackage.monthlyAmount = `${(
            pricingTier.amountUSD / pricingTier.durationMonths
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
        if (selectedCurrency === SUPPORTED_CURRENCIES.LKR) {
          currentPackage.currency = primaryCurrency.symbol;
          currentPackage.packageAmount = pricingTier.amountLKRNonResident;
          currentPackage.monthlyAmount = `${(
            pricingTier.amountLKRNonResident / pricingTier.durationMonths
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
      }
    }

    currentPackage.packageDuration = pricingTier.durationMonths;
    currentPackage.isPreferred = currentPackage.preferred;

    if (pricingTier.id === "6m_vip") {
      packages.vipPackages.push(currentPackage);
    } else {
      packages.regularPackages.push(currentPackage);
    }
  }
  return packages;
};
