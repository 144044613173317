import {
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import PremiumPackageTrustSignalView from "./templates/trust-signal-view";
import InquiryForm from "./templates/inquiry-form";
import { scrollToTop } from "../../utils";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import withLanguageMigrator from "../../hoc/language-migrator";
import CallIcon from "@material-ui/icons/Call";
import "./style.css";

const PremiumPackageLandingPage = (props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    scrollToTop();
  }, []);

  const onClckInquireNow = () => {
    const element = document.getElementById("premium-inquiry-form");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        className="premium-package-content-wrapper"
      >
        <Grid container className="hero-section" justifyContent="center">
          <Grid
            className="hero-image"
            style={{
              backgroundImage: `url(https://static.poruwa.lk/images/payment/premium-package-header-img.png)`,
            }}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="hero-content"
          >
            <img
              src="https://static.poruwa.lk/images/payment/premium-logo.png"
              alt="Premium Logo"
              className="premium-logo"
            />
            <Typography variant="h4" className="hero-section-title">
              Matchmaking with Extra Care
            </Typography>
            <Button className="inquire-btn" onClick={onClckInquireNow}>
              Inquire Now
            </Button>
            <Link href="tel:+94112824814">
              <Typography variant="body1" className="contact-num-txt">
                <CallIcon />
                0112 824 814
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="content-section"
        >
          <PremiumPackageTrustSignalView />
          <InquiryForm />
        </Grid>
      </Grid>

      {isMobileView ? (
        <FooterMobile lng={props.lng} />
      ) : (
        <Footer lng={props.lng} />
      )}
    </>
  );
};

export default withLanguageMigrator(PremiumPackageLandingPage);
