import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { accordianSubTab } from "../../actions/accordianSubTab";
import { getMatchedPosts } from "../../actions/getMatchedPosts";
import { getUnmatchedPosts } from "../../actions/getUnmatchedPosts";
import { getSentInterests } from "../../actions/getSentInterests";
import {
  getArchivedSentInterests,
  setArchivedSentAllFetched,
} from "../../actions/getArchivedSentInterests";
import { getArchivedMatchedPosts } from "../../actions/getArchivedMatchedPosts";
import { getNewMatches } from "../../actions/getNewMatches";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntSideMenuButtonGroup from "./interest-com/IntSideMenuButtonGroup";
import TopicsHeader from "./interest-com/TopicsHeader";
import {
  clearInterestPosts,
  getInterestPosts,
} from "../../actions/getInterestPosts";
import { getArchivedInterestPosts } from "../../actions/getArchivedInterestPosts";
import { setInterestSideMenuLoading } from "../../actions/setInterestSideMenuLoading";
import { fetchNotificationCount } from "../../actions/fetchNotificationCount";
import UnseenMatches from "./interest-com/UnseenInterestMatches";
import MatchItemCards from "./interest-com/MatchItemCards";
import InterestListCards from "./interest-com/InterestListItemCards";
import InterestSideViewWithdrawn from "./interest-com/InterestSideViewWithdrawn";
import SentCard from "./interest-com/SentInterestCard";
import Search from "./interest-com/Search";
import { CircularProgress, Typography } from "@material-ui/core";
import IntersetSideViewUnmatches from "./interest-com/IntersetSideViewUnmatches";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setArchivedReceivedAllFetched } from "../../actions/setArchivedReceivedAllFetched";

const DATA_TYPE = Object.freeze({
  message: "message",
  received: "received",
  sent: "sent",
});

const LOCATION_VIEW_VARAINT_MAP = Object.freeze({
  [DATA_TYPE.message]: "messages",
  [DATA_TYPE.received]: "interests",
  [DATA_TYPE.sent]: "sent-interests",
});

function InterestSideView(props) {
  const [search, setSearch] = useState("");
  const [externalRequests, setExternalRequests] = useState([]);
  const [hideMoreBtn, setHideMoreBtn] = useState(false);
  const [matchedPostPage, setMatchedPostPage] = useState(0);
  const [updatingId, setUpdatingId] = useState("");
  const [unmatchedPostPage, setUnmatchedPostPage] = useState(0);
  const [receivedPostPage, setReceivedPostPage] = useState(0);
  const [archivedMatchedPostPage, setArchivedMatchedPostPage] = useState(0);
  const [archivedReceivedPostPage, setArchivedReceivedPostPage] = useState(0);
  const [showSecondaryItems, setShowSecondaryItems] = useState(false);
  const [loadUnmatches, setLoadUnmatches] = useState(false);
  const [sentInterestPage, setSentInterestPage] = useState(0);
  const [sentArchivedInterestsPage, setSentArchivedInterestsPage] = useState(0);
  const [page, setPage] = useState(undefined);

  const location = useLocation();

  const {
    setInterestSideMenuLoading,
    setArchivedReceivedAllFetched,
    getArchivedInterestPosts,
    setArchivedSentAllFetched,
    getArchivedSentInterests,
  } = props;

  useEffect(() => {
    const [viewVaraint] = Object.entries(LOCATION_VIEW_VARAINT_MAP).find(
      ([_, resourcePath]) => {
        return resourcePath.includes(location.pathname.replace("/", ""));
      }
    );
    setPage(viewVaraint);
  }, [location]);

  useEffect(() => {
    switch (page) {
      case DATA_TYPE.message:
        props.getMatchedPosts();
        props.getNewMatches();
        props.setInterestSideMenuLoading();
        break;
      case DATA_TYPE.received:
        props.getInterestPosts();
        props.setInterestSideMenuLoading();
        break;
      case DATA_TYPE.sent:
        props.getSentInterests();
        props.setInterestSideMenuLoading();
      default:
        break;
    }
  }, [page]);

  useEffect(() => {
    const { sideMenuUnmatches } = props;
    if (
      loadUnmatches &&
      page === DATA_TYPE.message &&
      Array.isArray(sideMenuUnmatches) &&
      sideMenuUnmatches.length === 0
    ) {
      props.getUnmatchedPosts();
    }
  }, [loadUnmatches]);

  useEffect(() => {
    setUpdatingId(props.updatedId);
  }, [props.updatedId]);

  useEffect(() => {
    if (page === DATA_TYPE.received && showSecondaryItems) {
      setInterestSideMenuLoading();
      setArchivedReceivedAllFetched(false);
      getArchivedInterestPosts();
      setArchivedReceivedPostPage(0);
    }
  }, [
    showSecondaryItems,
    page,
    setInterestSideMenuLoading,
    setArchivedReceivedAllFetched,
    getArchivedInterestPosts,
  ]);

  useEffect(() => {
    if (page === DATA_TYPE.sent && showSecondaryItems) {
      setInterestSideMenuLoading();
      setArchivedSentAllFetched(false);
      getArchivedSentInterests();
      setSentArchivedInterestsPage(0);
    }
  }, [
    page,
    showSecondaryItems,
    setArchivedSentAllFetched,
    getArchivedSentInterests,
    setInterestSideMenuLoading,
  ]);

  const getMatchedArchived = () => {
    setInterestSideMenuLoading();
    props.getArchivedMatchedPosts();
    setHideMoreBtn(true);
  };

  const geInterestArchived = () => {
    setInterestSideMenuLoading();
    getArchivedInterestPosts(archivedReceivedPostPage + 1);
    setArchivedReceivedPostPage(archivedReceivedPostPage + 1);
  };

  const getSentArchived = () => {
    setInterestSideMenuLoading();
    getArchivedSentInterests(sentArchivedInterestsPage + 1);
    setSentArchivedInterestsPage(sentArchivedInterestsPage + 1);
  };

  const onIgoreRequest = () => {
    props.clearInterestPosts();
    props.getInterestPosts();
    props.fetchNotificationCount();
    setReceivedPostPage(0);
  };

  const handleScroll = (event) => {
    const node = event.target;
    const scrollMargin = 10;

    const bottom =
      Math.abs(node.scrollHeight - node.clientHeight - node.scrollTop) <
      scrollMargin;
    if (!bottom) {
      return;
    }

    switch (page) {
      case DATA_TYPE.message:
        if (!showSecondaryItems && !props.sideMenuMatchesAllFetched) {
          props.setInterestSideMenuLoading();
          props.getMatchedPosts(matchedPostPage + 1);
          setMatchedPostPage(matchedPostPage + 1);
        } else if (!props.sideMenuUnmatchesAllFetched) {
          props.setInterestSideMenuLoading();
          props.getUnmatchedPosts(unmatchedPostPage + 1);
          setUnmatchedPostPage(unmatchedPostPage + 1);
        } else if (hideMoreBtn && !props.sideMenuArchivedMatchesAllFetched) {
          props.setInterestSideMenuLoading();
          props.getArchivedMatchedPosts(archivedMatchedPostPage + 1);
          setArchivedMatchedPostPage(archivedMatchedPostPage + 1);
        }
        break;
      case DATA_TYPE.received:
        if (props.sideMenuInterestsLoading) {
          return;
        }
        if (!showSecondaryItems && !props.sideMenuReceivedAllFetched) {
          setInterestSideMenuLoading();
          props.getInterestPosts(receivedPostPage + 1);
          setReceivedPostPage(receivedPostPage + 1);
        } else if (!props.sideMenuReceivedAllFetched) {
          setInterestSideMenuLoading();
          props.getInterestPosts(receivedPostPage + 1);
          setReceivedPostPage(receivedPostPage + 1);
        } else if (
          showSecondaryItems &&
          !props.sideMenuArchivedReceivedAllFetched
        ) {
          setInterestSideMenuLoading();
          getArchivedInterestPosts(archivedReceivedPostPage + 1);
          setArchivedReceivedPostPage(archivedReceivedPostPage + 1);
        }
        break;
      case DATA_TYPE.sent:
        if (props.sideMenuInterestsLoading) {
          return;
        }
        if (!showSecondaryItems && !props.sideMenuSentAllFetched) {
          setInterestSideMenuLoading();
          props.getSentInterests(sentInterestPage + 1);
          setSentInterestPage(sentInterestPage + 1);
        } else if (
          showSecondaryItems &&
          !props.sideMenuArchivedSentAllFetched
        ) {
          setInterestSideMenuLoading();
          props.getArchivedSentInterests(sentArchivedInterestsPage + 1);
          setSentArchivedInterestsPage(sentArchivedInterestsPage + 1);
        }
        break;
      default:
        return;
    }

    // if (props.isInterestPosts) {
    //   if (!showSecondaryItems && !props.sideMenuReceivedAllFetched) {
    //     props.setInterestSideMenuLoading();
    //     props.getInterestPosts(receivedPostPage + 1);
    //     setReceivedPostPage(receivedPostPage + 1);
    //   } else if (hideMoreBtn && !props.sideMenuArchivedReceivedAllFetched) {
    //     props.setInterestSideMenuLoading();
    //     props.getArchivedInterestPosts(archivedReceivedPostPage + 1);
    //     setArchivedReceivedPostPage(archivedReceivedPostPage + 1);
    //   }
    // }
  };
  const markAllSeen = ({
    intId: id,
    isMatchedPosts: isMessage,
    isInterestPosts: isInterest,
  }) => {
    if (isMessage) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/chat_messages/mark_seen`,
          qs.stringify({
            token: sessionStorage.getItem("chat_token"),
            interestId: id,
            allInterests: id ? false : true,
          }),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              ContentType: "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          document.body.click();
          props.fetchNotificationCount();
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (isInterest) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
            "postId"
          )}/interests/seen`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          document.body.click();
          props.fetchNotificationCount();
          props.clearInterestPosts();
          props.setInterestSideMenuLoading();
          props.getInterestPosts();
          setReceivedPostPage(0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getHeadingText = () => {
    if (page === DATA_TYPE.message) {
      return props.lng ? "Messages" : "පණිවිඩ";
    }
    if (page === DATA_TYPE.received) {
      return props.lng ? "Received Interests" : "කැමැත්ත එවූ අය";
    }
    if (page === DATA_TYPE.sent) {
      return props.lng ? "Sent Interests" : "යැවූ කැමැත්තන්";
    }
  };

  const PageContent = () => {
    switch (page) {
      case DATA_TYPE.message:
        if (showSecondaryItems) {
          return (
            <IntersetSideViewUnmatches
              lng={props.lng}
              intId={props.intId}
              archivedClick={getMatchedArchived}
              hideMoreBtn={hideMoreBtn}
            />
          );
        } else {
          const filteredList = props.sideMenuMatches.filter((element) => {
            return (
              !element.ignored &&
              element.post &&
              (element.matchSeen || element.latestChat)
            );
          });

          if (filteredList && filteredList.length > 0) {
            return filteredList.map((element) => (
              <MatchItemCards
                post={element}
                lng={props.lng}
                key={`match-item-${element.id}`}
                intId={props.intId}
              />
            ));
          } else {
            return (
              <Typography
                align="center"
                style={{ marginTop: "50px", padding: "20px 20px" }}
              >
                {props.sideMenuMatchesAllFetched &&
                  (props.lng ? (
                    <span className="availability-messsage">
                      No messages at the moment. Your matches and messages will
                      appear here.
                    </span>
                  ) : (
                    <span className="availability-messsage">
                      ඔබට මේ වන විට ගැලපීම් හෝ පණිවුඩ නොමැත. වෙනත් පාර්ශවයක් ඔබට
                      පණිවුඩයක් එවූ විට හෝ ඔබ සමග ගැලපුණු විට, එය මෙම කොටසෙහි
                      දිස්වනු ඇත.
                    </span>
                  ))}
              </Typography>
            );
          }
        }

      case DATA_TYPE.received:
        if (showSecondaryItems) {
          return (
            <InterestSideViewWithdrawn
              lng={props.lng}
              intId={props.intId}
              archivedClick={geInterestArchived}
              hideMoreBtn={hideMoreBtn}
            />
          );
        } else {
          if (
            props.sideMenuInterests.filter((el) => el.ignored == false).length >
            0
          ) {
            return (
              <InterestListCards
                intId={props.intId}
                posts={props.sideMenuInterests
                  .filter((el) => el.ignored === false)
                  .filter(
                    (el) =>
                      el.post.personalInfo.displayName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                  )
                  .concat(
                    externalRequests.filter(
                      (el) =>
                        el.extName
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1
                    )
                  )
                  .sort((a, b) => b.likedAt.localeCompare(a.likedAt))}
                lng={props.lng}
                setReceivedPostPage={setReceivedPostPage}
                onIgoreRequest={onIgoreRequest}
              />
            );
          } else {
            return (
              <Typography
                align="center"
                style={{ marginTop: "50px", padding: "20px 20px" }}
              >
                {props.lng ? (
                  <span className="availability-messsage">
                    Interests from other users will appear here as they show
                    interest in your profile.
                  </span>
                ) : (
                  <span className="availability-messsage">
                    වෙනත් පාර්ශවයක් ඔබ වෙත කැමැත්ත පල කල විට, එය මෙම කොටසෙහි
                    දිස්වනු ඇත.
                  </span>
                )}
              </Typography>
            );
          }
        }

      case DATA_TYPE.sent:
        if (showSecondaryItems) {
          return (
            <>
              <Typography className="message-header-text">
                {props.lng
                  ? "Withdrawn / Ignored"
                  : "කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම"}
              </Typography>

              {props.sideMenuArchivedSentInterests.filter((el) => el.ignored)
                .length > 0 && (
                <SentCard
                  intId={props.intId}
                  posts={props.sideMenuArchivedSentInterests.filter(
                    (el) => el.ignored
                  )}
                  lng={props.lng}
                />
              )}

              {!props.sideMenuInterestsLoading &&
                props.sideMenuArchivedSentInterests.filter((el) => el.ignored)
                  .length < 1 && (
                  <Typography
                    align="center"
                    style={{ marginTop: "50px", padding: "20px 20px" }}
                  >
                    {props.lng ? (
                      <span className="availability-messsage">
                        No ignored/withdrawn profiles found
                      </span>
                    ) : (
                      <span className="availability-messsage">
                        {" "}
                        කැමැත්ත ඉවත් කල හෝ නොසලකා හරින ලද ගැලපීම් නොමැත.
                      </span>
                    )}
                  </Typography>
                )}

              {/* {props.sideMenuSentInterests.filter((el) => el.ignored).length >
              0 ? (
                <SentCard
                  intId={props.intId}
                  posts={props.sideMenuSentInterests
                    .filter((el) => el.ignored)
                    .filter(
                      (el) =>
                        el.post.personalInfo.displayName
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1
                    )}
                  lng={props.lng}
                />
              ) : (
                <Typography
                  align="center"
                  style={{ marginTop: "50px", padding: "20px 20px" }}
                >
                  {props.lng ? (
                    <span className="availability-messsage">
                      No ignored/withdrawn profiles found
                    </span>
                  ) : (
                    <span className="availability-messsage">
                      {" "}
                      කැමැත්ත ඉවත් කල හෝ නොසලකා හරින ලද ගැලපීම් නොමැත.
                    </span>
                  )}
                </Typography>
              )} */}
              {!props.sideMenuInterestsLoading &&
                !props.sideMenuArchivedSentAllFetched && (
                  <p className="see-more-btn" onClick={getSentArchived}>
                    See more...
                  </p>
                )}
            </>
          );
        } else {
          if (
            props.sideMenuSentInterests.filter((el) => el.ignored === false)
              .length > 0
          ) {
            return (
              <SentCard
                intId={props.intId}
                posts={props.sideMenuSentInterests
                  .filter((el) => el.ignored === false)
                  .filter(
                    (el) =>
                      el.post.personalInfo.displayName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                  )}
                lng={props.lng}
              />
            );
          } else {
            return (
              <Typography
                align="center"
                style={{ marginTop: "50px", padding: "20px 20px" }}
              >
                {props.lng ? (
                  <span className="availability-messsage">
                    When you send a interest they will appear in here.
                  </span>
                ) : (
                  <span className="availability-messsage">
                    ඔබ කැමැත්තක් යැවූ පසු එය මෙහි දිස්වනු ඇත.
                  </span>
                )}
              </Typography>
            );
          }
        }

      default:
        return null;
    }
  };

  return (
    <div
      className={
        props.intId ? "interest-view-div view-hide" : "interest-view-div"
      }
      id="interest-view-div-unique"
      onScroll={(e) => handleScroll(e)}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IntSideMenuButtonGroup
          pageViewType={page}
          lng={props.lng}
          sentInterestsCount={props.sideMenuSentInterests.length}
        />
      </div>
      <Search lng={props.lng} search={props.search} setSearch={setSearch} />
      {page === DATA_TYPE.message &&
        props?.newMatches?.filter((el) => el.post).length > 0 && (
          <p className="heading">
            {props.lng ? (
              "New Matches"
            ) : (
              <span className="sinhala-w-600">නව ගැලපිම්</span>
            )}
          </p>
        )}
      {page === DATA_TYPE.message &&
        (props?.sideMenuNewMatchesLoading ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <CircularProgress
              size={16}
              className="loading-circle"
              style={{ color: "#c19d0d" }}
            />
          </div>
        ) : (
          props.newMatches
            .filter((el) => el.id !== props.intId && el.post)
            .map((el, i) => <UnseenMatches post={el} key={i} />)
        ))}

      <TopicsHeader
        heading={getHeadingText()}
        language={props.lng}
        isSecondryItems={showSecondaryItems}
        onClickBack={() => setShowSecondaryItems(false)}
        menuItems={
          page === DATA_TYPE.message
            ? [
                {
                  label: "Mark All Seen",
                  onClick: () => {
                    markAllSeen({
                      intId: props.id,
                      isMatchedPosts: page === DATA_TYPE.message,
                      isInterestPosts: page === DATA_TYPE.received,
                    });
                  },
                },
                {
                  label: "Unmatched",
                  onClick: () => {
                    setLoadUnmatches(true);
                    setShowSecondaryItems(true);
                  },
                },
              ]
            : page === DATA_TYPE.received
            ? [
                {
                  label: "Mark All Seen",
                  onClick: () => {
                    markAllSeen({
                      intId: props.id,
                      isMatchedPosts: page === DATA_TYPE.message,
                      isInterestPosts: page === DATA_TYPE.received,
                    });
                  },
                },
                {
                  label: "Withdrawn / Ignored",
                  onClick: () => {
                    setShowSecondaryItems(true);
                  },
                },
              ]
            : [
                {
                  label: "Withdrawn / Ignored",
                  onClick: () => {
                    setShowSecondaryItems(true);
                  },
                },
              ]
        }
      />

      <PageContent />

      {props.sideMenuInterestsLoading && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CircularProgress
            size={16}
            className="loading-circle"
            style={{ color: "#c19d0d" }}
          />
        </div>
      )}
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getArchivedSentInterests,
      getSentInterests,
      getInterestPosts,
      accordianSubTab,
      getMatchedPosts,
      getUnmatchedPosts,
      getNewMatches,
      getArchivedMatchedPosts,
      getArchivedInterestPosts,
      setInterestSideMenuLoading,
      fetchNotificationCount,
      setArchivedReceivedAllFetched,
      clearInterestPosts,
      setArchivedSentAllFetched,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenuUnmatches: state.matches.sideMenuUnmatched.unmatched,
    sideMenuMatches: state.matches.sideMenuPosts.matched,
    sideMenuSentInterests: state.matches.sideMenuSentInterests.sent,
    sideMenuSentAllFetched: state.matches.sideMenuSentAllFetched,
    sideMenuArchivedSentAllFetched:
      state.matches.sideMenuArchivedSentAllFetched,
    sideMenuArchivedMatches:
      state.matches.sideMenuArchivedMatched.archivedMatched,
    sideMenuArchivedInterests: state.matches.sideMenuArchivedInterests.archived,
    sideMenuInterests: state.matches.sideMenuInterests.interests,
    newMatches: state.matches.sideMenuNewMatches.newMatches,
    isReceivedActive: state.matches.activeBtnType.received,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
    updatedId: state.matches.updatedId,
    sideMenuMatchedPage: state.matches.sideMenuPosts.page,
    sideMenuNewMatchesLoading: state.matches.sideMenuNewMatchesLoading,
    sideMenuMatchesAllFetched: state.matches.sideMenuMatchesAllFetched,
    sideMenuUnmatchesAllFetched: state.matches.sideMenuUnmatchesAllFetched,
    sideMenuReceivedAllFetched: state.matches.sideMenuReceivedAllFetched,
    sideMenuInterestsLoading: state.matches.sideMenuInterestsLoading,
    sideMenuArchivedMatchesAllFetched:
      state.matches.sideMenuArchivedMatchesAllFetched,
    sideMenuArchivedReceivedAllFetched:
      state.matches.sideMenuArchivedReceivedAllFetched,
    sideMenuArchivedSentInterests:
      state.matches.sideMenuArchivedSentInterests.archived,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(InterestSideView));
