import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import qs from "qs";
import Loader from "react-loader-spinner";
import "../../styles/settings.css";
import moment from "moment";
import 'moment/locale/si';
import 'moment/locale/en-gb';
import Config from "../../v2/config";

const { CURRENT_PLATFORM, PLATFORMS } = Config;

const initialStates = {
  // disabled
  dissableBtn: false,

  // loadings
  loading: false,
  loadingCancel: false,

  // alerts
  successAlert: false,
  failAlert: false,

  imageSrc: "",
  verificationStatus: "",
  uploadingId: "",
  nicImageLoading: false,
  uploadedDate: "",

  image2Src: "",
  uploading2Id: "",
  nicImage2Loading: false,
  nicDataId: "",
  rejectedReason : ""
};

export default class NicUpload extends Component {
  state = initialStates;

  componentDidMount = () => {
    this.getIDVerifications();
  }

  handleSave = () => {
    this.setState({
      loading: true,
    });
    let objectData;
    if (this.state.uploading2Id) {
      objectData = qs.stringify({
        nicImageId : this.state.uploadingId,
        nicImage2Id : this.state.uploading2Id,
      })
    } else {
      objectData = qs.stringify({
        nicImageId : this.state.uploadingId,
      })
    }

    if (this.state.verificationStatus === "REJECTED") {
      let putImageUrl = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        putImageUrl = `operator/managed_posts/${localStorage.getItem("postId")}/id_verifications/${this.state.nicDataId}`;
      } else {
        putImageUrl = `account/id_verifications/${this.state.nicDataId}`;
      }
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v1/${putImageUrl}`,
            {},
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.setState({
            uploadingNIC: false,
            successAlert: true,
            loading: false,
            dissableBtn: false,
            rejectedReason: "",
            verificationStatus: "PENDING"
          });
          this.getIDVerifications();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            uploadingNIC: false,
            failAlert: true,
            loading: false,
          });
        });
    } else {
      let postIDUrl = "";
      if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
        postIDUrl = `operator/managed_posts/${localStorage.getItem("postId")}/id_verifications`;
      } else {
        postIDUrl = "account/id_verifications";
      }
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/${postIDUrl}`,
            objectData,
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("auth_token")}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.setState({
            uploadingNIC: false,
            successAlert: true,
            loading: false,
            dissableBtn: false,
          });
          this.getIDVerifications();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            uploadingNIC: false,
            failAlert: true,
            loading: false,
          });
        });
    }
  };

  handleClose = () => {
    this.setState({ successAlert: false, failAlert: false });
  };

  getIDVerifications = () => {
    this.setState({
      nicImageLoading : true
    });
    let getIDVerificationsUrl = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      getIDVerificationsUrl = `operator/managed_posts/${localStorage.getItem("postId")}/id_verifications`;
    } else {
      getIDVerificationsUrl = "account/id_verifications";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${getIDVerificationsUrl}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          (res.data).sort((a, b) => {
            let time1 = a.createdAt;
            let time2 = b.createdAt;
            return time2.localeCompare(time1);
          })
          this.setState({
            uploadingId : res.data[0].nicImageId,
            nicDataId: res.data[0].id
          });
          let image1Url = "";
          if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
            image1Url = `operator/managed_posts/${localStorage.getItem("postId")}/id_verifications/${res.data[0].id}/images/${res.data[0].nicImageId}`;
          } else {
            image1Url = `account/id_verifications/${res.data[0].id}/images/${res.data[0].nicImageId}`;
          }
          axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/${image1Url}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            this.setState({
              imageSrc : data,
              nicImageLoading : false
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              nicImageLoading : false
            });
            return [];
          });
          if (res.data[0].nicImage2Id) {
            this.setState({
              uploading2Id : res.data[0].nicImage2Id
            });
            let image2Url = "";
            if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
              image2Url = `operator/managed_posts/${localStorage.getItem("postId")}/id_verifications/${res.data[0].id}/images/${res.data[0].nicImage2Id}`;
            } else {
              image2Url = `account/id_verifications/${res.data[0].id}/images/${res.data[0].nicImage2Id}`;
            }
            axios
            .get(
              `${process.env.REACT_APP_API_URL}/v1/${image2Url}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                },
                responseType: "blob",
              }
            )
            .then((res) => {
              let data = URL.createObjectURL(res.data);
              this.setState({
                image2Src : data,
                nicImage2Loading : false
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                nicImage2Loading : false
              });
              return [];
            });
          } else {
            this.setState({
              nicImage2Loading : false
            });
          }
          this.setState({
            requestRegLetter : res.data[0].id,
            verificationStatus : res.data[0].status,
            uploadedDate: res.data[0].createdAt
          });
          if (res.data[0].status === "REJECTED") {
            this.setState({
              dissableBtn : true,
              rejectedReason: res.data[0].rejectReason
            });
          }
        } else {
          this.setState({
            nicImageLoading : false
          });
        }
      })
      .catch((err) => {
        this.setState({
          nicImageLoading : false
        });
        console.log(err);
      });
  };

  handleCancel = () => {
    this.setState({dissableBtn: false, imageSrc: "", image2Src: "" });
    this.getIDVerifications();
  }
 
  handleUpload = (e) => {
    this.setState({
      nicImageLoading: true,
    });
    let uploadedId = "";
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    imageFile.append("noWatermark", true);
    var src = URL.createObjectURL(e.target.files[0]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
        imageFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`
          },
        }
      )
      .then((res) => {
        uploadedId = res.data.name;
        this.setState({ uploadingId: uploadedId, dissableBtn: true, nicImageLoading: false, imageSrc: src, verificationStatus: "" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          nicImageLoading: false,
          failAlert: true
        });
      });
  };

  handleAddNewImages = (e) => {
    this.setState({ uploadingId: "", dissableBtn: false, imageSrc: "", uploading2Id: "", image2Src: "", verificationStatus: "" });
  }

  handleUploadSecondImage = (e) => {
    this.setState({
      nicImage2Loading: true,
    });
    let uploaded2Id = "";
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    imageFile.append("noWatermark", true);
    var src = URL.createObjectURL(e.target.files[0]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
        imageFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`
          },
        }
      )
      .then((res) => {
        uploaded2Id = res.data.name;
        this.setState({ uploading2Id: uploaded2Id, nicImage2Loading: false, image2Src: src, verificationStatus: "" });
        if (this.state.imageSrc) {
          this.setState({ dissableBtn: true});
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          nicImage2Loading: false,
          failAlert: true
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} id="nic-div">
          <Paper
            elevation={0}
            style={{ minHeight: "25vh", position: "relative" }}
            className="complete-ad-div settings-container"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <p className="sec-heading" style={{marginBottom:"5px"}}>
                    {this.props.lng ? (
                      "ID Verification"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        අනන්‍යතාවය තහවුරු කිරීම
                      </span>
                    )}
                  </p>

                  <p className="heading-desc" style={{lineHeight:"1.43", letterSpacing: "0.01071em", marginBottom: "10px", color: "#333333"}}>
                    {CURRENT_PLATFORM === PLATFORMS.LK ? (
                      this.props.lng ? (
                        "Upload a photo of your national identity card, driving license, passport or any other photo ID. Once verified other users will see a blue verified check mark and label when viewing your profile."
                      ) : (
                        <span className="sinhala-size-11">
                          ඔබගේ ජාතික හැඳුනුම්පතේ, රියදුරු බලපත්‍රයේ, විදේශ ගමන්
                          බලපත්‍රයේ හෝ වෙනත් හැඳුනුම්පතක ඡායාරූපයක් ඇතුලත්
                          කරන්න. අනන්‍යතාවය තහවුරු කළ පසු අනෙකුත් පරිශීලකයන්
                          ඔබගේ දැන්වීම බැලීමේදී නිල් පැහැති පිරික්සුම් සලකුණක්
                          සහ ලේබලයක් දකිනු ඇත.
                        </span>
                      )
                    ) : (
                      "Upload a photo of your Aadhaar (ID Card), driving license, passport or any other photo ID. Once verified other users will see a blue verified check mark and label when viewing your profile."
                    )}
                  </p>
                  {this.state.verificationStatus === "VERIFIED" &&
                    <Alert icon={<i style={{color:"#2979ff", fontSize:"15px", margin:"auto"}} className="fas fa-check-circle"></i>} severity="info" style={{ marginBottom: 25, padding: "0 10px", background: "#daf1e4" }}>
                      {this.props.lng ? (
                        "Your ID is verified."
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          ඔබගේ හැඳුනුම්පත තහවුරු කර ඇත.
                        </span>
                      )}
                    </Alert>
                  }
                  {this.state.verificationStatus === "REJECTED" &&
                    <Alert severity="error" style={{ marginBottom: 25, padding: "0 10px" }}>
                      {this.props.lng ? (
                        "Your ID verification request was not accepted. You can amend your profile or upload a different document and submit the request again."
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          ඔබගේ හැඳුනුම්පත් සත්‍යාපන ඉල්ලීම පිළිගත්තේ නැත. ඔබට ඔබගේ හැඳුනුම්පත් ඡායාරූප සංශෝධනය කිරීමට හෝ වෙනත් ඡායාරූප උඩුගත කර නැවත ඉල්ලීම ඉදිරිපත් කිරීමට හැකිය.
                        </span>
                      )}
                    </Alert>
                  }
                  {this.state.verificationStatus === "" &&
                    <Alert severity="info" style={{ marginBottom: 25, padding: "0 10px" }}>
                      {this.props.lng ? (
                        "By verifying your profile you can increase the credibility and trustworthiness of your Ad."
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          ඔබගේ හැඳුනුම්පත තහවුරු කිරීමෙන් ඔබට ඔබගේ දැන්වීමේ විශ්වසනීයත්වය වැඩි කර ගත හැක.
                        </span>
                      )}
                    </Alert>
                  }
                  {this.state.verificationStatus === "PENDING" &&
                    <Alert severity="info" style={{ marginBottom: 25, padding: "0 10px" }}>
                      {this.props.lng ? (
                        `You have a pending ID verification request. Please allow 48hours for review and contact ${Config.SUPPORT_EMAIL} if it is not approved within this time.`
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-12">
                          ඔබට සත්‍යාපන ඉල්ලීමක් ඇත. කරුණාකර සමාලෝචනය සඳහා පැය 48ක් ඉඩ දෙන්න. මෙම කාලය තුළ එය අනුමත නොකළහොත් {Config.SUPPORT_EMAIL} අමතන්න.
                        </span>
                      )}
                    </Alert>
                  }
                  {this.state.verificationStatus !== "" &&
                    <div>
                      <div>
                        <div style={{color: "grey", fontSize: "12px", marginBottom: "5px"}}>{this.props.lng ? ("Submitted on") : (<span className="sinhala-w-600 sinhala-size-12">ඉදිරිපත් කළ දිනය</span>)}</div>
                        <div style= {{fontSize: "14px", color: "#333333"}}>{this.props.lng ? moment(this.state.uploadedDate, 'YYYY-MM-DD hh:mm a').locale('en-gb').format('MMMM DD, YYYY hh:mm a') : moment(this.state.uploadedDate, 'YYYY-MM-DD hh:mm a').locale('si').format('MMMM DD, YYYY hh:mm a')}</div>
                      </div>
                      <div>
                        <div style={{color: "grey", fontSize: "12px", marginBottom: "5px", marginTop:"15px"}}>{this.props.lng ? ("Status") : (<span className="sinhala-w-600 sinhala-size-12">තත්ත්වය</span>)}</div>
                        {this.state.verificationStatus === "VERIFIED" && <div style= {{fontSize: "14px", color: "#333333"}}>{this.props.lng ? "Verified" : "තහවුරු කර ඇත"}</div>}
                        {this.state.verificationStatus === "PENDING" && <div style= {{fontSize: "14px", color: "#333333"}}>{this.props.lng ? "Pending Review" : "සමාලෝචනය කරමින්"}</div>}
                        {this.state.verificationStatus === "REJECTED" && <div style= {{fontSize: "14px", color: "#333333"}}>{this.props.lng ? "Not Accepted" : "ප්‍රතික්ෂේප කර ඇත"}</div>}
                      </div>
                      {(this.state.rejectedReason && this.state.verificationStatus === "REJECTED") &&
                        <div>
                          <div style={{color: "grey", fontSize: "12px", marginBottom: "5px", marginTop:"15px"}}>{this.props.lng ? ("Reason for rejection") : (<span className="sinhala-w-600 sinhala-size-12">ප්‍රතික්ෂේප කිරීමට හේතුව</span>)}</div>
                          <div style= {{fontSize: "14px", color: "#333333"}}>{this.state.rejectedReason}</div>
                        </div>
                      }
                      <div>
                        <div style={{color: "grey", fontSize: "12px", marginBottom: "8px", marginTop:"15px"}}>{this.props.lng ? ("Uploaded Images") : (<span className="sinhala-w-600 sinhala-size-12">ඉදිරිපත් කළ පින්තූර</span>)}</div>
                      </div>
                    </div>
                  }
                  <div
                    className="image-input-div"
                    style={{ marginTop: 0, marginBottom: 15 }}
                  >
                    <input
                      type="file"
                      name="nicPhoto"
                      className="imgUpload"
                      onChange={this.handleUpload}
                      id="nicPhoto"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                    <label
                      className="img-upload-label"
                      style={{
                        cursor:
                          this.state.status === "PENDING"
                            ? "default"
                            : "pointer",
                      }}
                    >
                      {this.state.imageSrc && (
                        <img
                          src={this.state.imageSrc}
                          alt="preview"
                          className="profilePic"
                        />
                      )}
                      {this.state.nicImageLoading ? (
                        <Loader
                          type="Oval"
                          color="#c19d0d"
                          height={25}
                          width={25}
                        />
                      ) : (this.state.verificationStatus != "PENDING" && this.state.verificationStatus != "VERIFIED" && this.state.verificationStatus != "REJECTED" )? (
                        <AddIcon
                          color="disabled"
                          fontSize="large"
                          onClick={() => document.getElementById("nicPhoto").click() }
                        />
                      ) : null}
                    </label>
                  </div>
                  {(this.state.verificationStatus == "VERIFIED" && !this.state.image2Src) ?
                    null :
                    <div
                      className="image-input-div"
                      style={{ marginTop: 0, marginBottom: 15 }}
                    >
                      <input
                        type="file"
                        name="nicPhoto2"
                        className="imgUpload"
                        onChange={this.handleUploadSecondImage}
                        id="nicPhoto2"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      <label
                        className="img-upload-label"
                        style={{
                          cursor:
                            this.state.status === "PENDING"
                              ? "default"
                              : "pointer",
                        }}
                      >
                        {this.state.image2Src && (
                          <img
                            src={this.state.image2Src}
                            alt="preview"
                            className="profilePic"
                          />
                        )}
                        {this.state.nicImage2Loading ? (
                          <Loader
                            type="Oval"
                            color="#c19d0d"
                            height={25}
                            width={25}
                          />
                        ) : (this.state.verificationStatus != "PENDING" && this.state.verificationStatus != "VERIFIED" && this.state.verificationStatus != "REJECTED")? (
                          <AddIcon
                            color="disabled"
                            fontSize="large"
                            onClick={() => document.getElementById("nicPhoto2").click() }
                          />
                        ) : null}
                      </label>
                    </div>
                  }
                  {this.state.verificationStatus == "REJECTED" &&
                    <div>
                      <Button variant="text" style={{color:"#5cabe1", textDecoration: "underline", fontSize: "12px"}} onClick={() => this.handleAddNewImages() }>{this.props.lng ? "Add New Images" : "නව පින්තූර එකතු කරන්න"}</Button>
                    </div>
                  }
                  {(this.state.verificationStatus !== "PENDING" && this.state.verificationStatus !== "VERIFIED") &&
                    <div className="nic-actions-div">
                      {this.state.dissableBtn ? (
                        <React.Fragment>
                          <Button
                            variant="contained"
                            className="edit-btn"
                            onClick={this.handleSave}
                            style={{width:"fit-content", minWidth: "200px"}}
                          >
                            {this.state.loading ? (
                              <CircularProgress
                                size={25}
                                style={{ color: "#fff" }}
                              />
                            ) : (
                              <span style={{paddingLeft: "17px", paddingRight: "17px"}}>
                                {this.props.lng === true ? (
                                  this.state.verificationStatus == "REJECTED" ? "Resubmit for verification" : "Submit for verification"
                                ) : (
                                  <span className="sinhala-w-600 sinhala-size-10">
                                    {this.state.verificationStatus == "REJECTED" ? "නැවත ඉදිරිපත් කරන්න" : "තහවුරු කරන්න"}
                                  </span>
                                )}
                              </span>
                            )}
                          </Button>
                          {this.state.verificationStatus != "REJECTED" &&
                            <Button
                              variant="contained"
                              className="nic-cancel-btn"
                              onClick={this.handleCancel}
                            >
                              {this.state.loadingCancel ? (
                                <CircularProgress
                                  size={25}
                                  style={{ color: "#000" }}
                                />
                              ) : (
                                <span>
                                  {this.props.lng === true ? (
                                    "Cancel"
                                  ) : (
                                    <span className="sinhala-w-600 sinhala-size-10">
                                      අවලංගු කරන්න
                                    </span>
                                  )}
                                </span>
                              )}
                            </Button>
                          }
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Button
                            variant="contained"
                            className="edit-btn-dissable"
                            disabled
                            style={{width:"fit-content", marginRight:"0", minWidth: "200px"}}
                          >
                            <span style={{paddingLeft: "17px", paddingRight: "17px"}}>
                              {this.props.lng === true ? (
                                this.state.verificationStatus == "REJECTED" ? "Resubmit for verification" : "Submit for verification"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  {this.state.verificationStatus == "REJECTED" ? "නැවත ඉදිරිපත් කරන්න" : "තහවුරු කරන්න"}
                                </span>
                              )}
                            </span>
                          </Button>
                          <Button
                            variant="contained"
                            className="nic-cancel-btn-dissable"
                            disabled
                          >
                            {this.props.lng === true ? (
                              "Cancel"
                            ) : (
                              <span className="sinhala-w-600 sinhala-size-10">
                                අවලංගු කරන්න
                              </span>
                            )}
                          </Button>{" "}
                        </React.Fragment>
                      )}
                    </div>
                  }
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Successfully submitted for verification."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    අනන්‍යතාවය තහවුරු කිරීම සඳහා ඉදිරිපත් කරන ලදී.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    මුරපදය සාර්ථකව වෙනස් වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
