import Config from "..";
import Routes from "./routes";

class PageConfig {
  constructor(pathname, title, description) {
    this.pathname = pathname;
    this.title = title;
    this.description = description;
  }
}

class _PageCofigContainer {
  constructor() {
    this.container = {};
  }

  addConfig({ pathname, title, description }) {
    this.container[pathname] = new PageConfig(pathname, title, description);
  }

  getConfig({ pathname }) {
    const pageConfig = this.container[pathname];
    return pageConfig;
  }
}

const PageConfigContainer = new _PageCofigContainer();
export default PageConfigContainer;
