import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BootstrapTooltip } from "../../../../components/molecules/tooltip";
import IconizedText from "../iconized-text";
import bride from "../../../../../images/bride.png";
import groom from "../../../../../images/groom.png";
import heart from "../../../../../images/heart.png";
import locationIcon from "../../../../../images/location.png";
import { useTranslation } from "react-i18next";
import Config from "../../../../config";
import isEmpty from "lodash/isEmpty";
import ReactCountryFlag from "react-country-flag";
import {
  getResidentCountry,
  getResidentCountryCode,
} from "../../../../utils/data-generator";

const { OTHER_COUNTRY_CODE } = Config;

const CardHeader = ({ advertisment }) => {
  const [cardHeaderWidth, setCardHeaderWidth] = useState(0);
  const { t, i18n } = useTranslation();

  const subHeading = useMemo(() => {
    const personalInfo = advertisment.personalInfo ?? {};
    const countryCode = personalInfo.residentCountry?.code ?? "";
    const city = personalInfo.residentCity ?? "";
    let country = "";
    if (countryCode === OTHER_COUNTRY_CODE) {
      country = personalInfo.residentCountryOther?.name || "";
    } else {
      const extractKey =
        i18n.language === Config.APP_LANGS.EN
          ? "name"
          : `name_${i18n.language}`;
      country = (personalInfo.residentCountry ?? {})[extractKey] ?? "";
    }
    const output = `${city}, ${country}`;
    return output;
  }, [advertisment.personalInfo, i18n.language]);

  const interestType = useMemo(() => {
    let interest = { visibility: false, text: "" };

    if (advertisment.interestInfo) {
      if (advertisment.interestInfo?.liked) {
        if (advertisment.interestInfo?.liked === "OUTGOING") {
          interest = { visibility: true, text: "Interest Sent" };
        } else if (advertisment.interestInfo?.liked === "INCOMING") {
          interest = { visibility: true, text: "Interest Received" };
        }
      }
      if (advertisment.interestInfo?.matched) {
        interest = { visibility: true, text: "Matched" };
      }
    }

    return interest;
  }, [advertisment]);

  const residentCountryCode = useMemo(() => {
    return getResidentCountryCode(advertisment.personalInfo);
  }, [advertisment]);

  const getCardHeaderWidth = () => {
    const collection = document.getElementsByClassName(
      "ad-card-header-name-container"
    );
    if (collection.length > 0) {
      const item = collection[0];
      return item?.getBoundingClientRect().width ?? 100
    }
    return 100;
  };

  useEffect(() => {
    setCardHeaderWidth(getCardHeaderWidth());
    const handleResize = ({ unmounting = false }) => {
      if (!unmounting) {
        setCardHeaderWidth(100);
      }
      setTimeout(() => {
        setCardHeaderWidth(getCardHeaderWidth());
      }, 100);
    };
    window.addEventListener("resize", () =>
      handleResize({ unmounting: false })
    );
    return () =>
      window.removeEventListener("resize", () =>
        handleResize({ unmounting: true })
      );
  }, []);

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid
        item
        xs
        container
        direction="row"
        className="column-gap-8"
        wrap="nowrap"
      >
        <Grid item>
          <img
            src={advertisment.personalInfo?.gender === "MALE" ? groom : bride}
            className="gender-img"
            alt="gender"
          />
        </Grid>
        <Grid
          item
          xs
          container
          direction="column"
          className="ad-card-header-name-container"
        >
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className="column-gap-4"
            style={{
              width: cardHeaderWidth,
              maxWidth: cardHeaderWidth,
              display: cardHeaderWidth ? "flex" : "none",
            }}
          >
            <Typography className="display-name text-truncate">
              {advertisment.personalInfo?.displayName ?? ""}
            </Typography>
            {advertisment.idVerified && (
              <BootstrapTooltip title={t("common.idVerified")}>
                <i className="fas fa-check-circle nic-verified-icon" />
              </BootstrapTooltip>
            )}
          </Grid>
          <IconizedText
            icon={locationIcon}
            text={subHeading}
            containerProps={{
              className: "column-gap-4",
              style: {
                maxWidth: cardHeaderWidth,
              },
            }}
            iconProps={{ className: "data-icon" }}
            labelProps={{
              className: "card-sub-heading-label text-truncate",
            }}
          />

          {interestType.visibility && (
            <IconizedText
              icon={heart}
              text={interestType.text}
              containerProps={{ className: "column-gap-8" }}
              iconProps={{ className: "data-icon" }}
              labelProps={{ className: "interest-type" }}
            />
          )}
        </Grid>
      </Grid>
      <Grid item>
        {!isEmpty(residentCountryCode) ? (
          <ReactCountryFlag
            countryCode={residentCountryCode}
            svg
            style={{ fontSize: "1.22em" }}
            title={
              getResidentCountry(advertisment.personalInfo, i18n.language) || ""
            }
          />
        ) : (
          <i className="fas fa-globe-americas" title="Other Country"></i>
        )}
      </Grid>
    </Grid>
  );
};

export default CardHeader;
