import { Button, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericDataAPI from "../../services/api/generic";
import isEmpty from "lodash/isEmpty";
import { Skeleton } from "@material-ui/lab";

import "./style.css";
import FormDropdown, {
  LABEL_POSITION,
} from "../../components/molecules/form-dropdown";
import PostServiceApi from "../../services/api/post-service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const MakertingResourceSubmission = ({ authProfile }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [marketingResources, setMarketingResources] = useState([]);
  const [sourceId, setSourceId] = useState("");

  const isNewProfile = useMemo(() => {
    if (
      isEmpty(authProfile) ||
      isEmpty(authProfile.firstPublishedAt) ||
      isEmpty(authProfile.publishedAt)
    ) {
      return true;
    }
    const firstPublishedAt = moment(authProfile.firstPublishedAt);
    const publishedAt = moment(authProfile.publishedAt);
    return publishedAt.isSame(firstPublishedAt);
  }, [authProfile]);

  useEffect(() => {
    GenericDataAPI.getMarketingResources().then((response) => {
      const { success, body } = response;
      setMarketingResources(success ? body : []);
    });
  }, []);

  const onClickAttractMoreResponses = useCallback(async () => {
    const response = await PostServiceApi.postUserMakertingResource({
      postId: authProfile.id,
      sourceId,
    });
    if (!response.success) {
      // TODO: Error Handling
      return;
    }
    history.replace("/complete-your-ad");
  }, [authProfile, history, sourceId]);

  const onClickGoToAllAds = useCallback(async () => {
    history.replace("/search");
  }, [history]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingInline: 24, rowGap: 16 }}
      className="marketing-resource-submission"
    >
      {isEmpty(marketingResources) || isEmpty(authProfile) ? (
        <React.Fragment>
          <Skeleton width="60%" height={36} />
          <Skeleton width="80%" height={72} />
        </React.Fragment>
      ) : isNewProfile ? (
        <React.Fragment>
          <FormDropdown
            fieldName="soruceId"
            value={sourceId}
            onChange={(e) => {
              setSourceId(e.target.value);
            }}
            label={t("payment.markertingResourceQuestion")}
            labelPosition={LABEL_POSITION.top}
            options={marketingResources}
            keyExtractor="id"
            labelExtractor="title"
            lang={language}
          />
          <Button
            variant="contained"
            className="primary-button"
            onClick={onClickAttractMoreResponses}
            fullWidth
            disabled={isEmpty(sourceId)}
          >
            {t("common.attractMoreResponses")}
          </Button>
        </React.Fragment>
      ) : (
        <Button
          variant="contained"
          className="primary-button"
          onClick={onClickGoToAllAds}
          fullWidth
        >
          {t("common.gotoAllAds")}
        </Button>
      )}
    </Grid>
  );
};

export default MakertingResourceSubmission;
