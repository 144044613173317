import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../../styles/discount-banner.css";
import moment from "moment";

const MAX_BANNER_VIEW_COUNT = 2;
const LOCAL_STORAGE_KEY = "nic-verification-banner-view-count";

function NICBanner({ lng, profile }) {
  const [showBanner, setShowBanner] = useState(false);
  const history = useHistory();

  const calculateBannerVisibility = () => {
    if (profile.idVerified) {
      return false;
    }
    let viewCounts = localStorage.getItem(LOCAL_STORAGE_KEY);
    viewCounts = JSON.parse(viewCounts) ?? 0;
    return (
      moment().isAfter(moment(profile.firstPublishedAt).add(7, "days")) &&
      viewCounts < MAX_BANNER_VIEW_COUNT
    );
  };

  useEffect(() => {
    setShowBanner(calculateBannerVisibility());
  }, []);

  const handleBannerClose = () => {
    try {
      const viewCounts =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? 0;
      localStorage.setItem(LOCAL_STORAGE_KEY, viewCounts + 1);
      setShowBanner(false);
    } catch (error) {
      console.log(">>>> error >>> ", error);
    }
  };

  const goToVerification = () => {
    handleBannerClose();
    history.push({pathname: "/settings", hash: "nic-div"})
  };

  return (
    showBanner && (
      <div className="xmas-banner">
        <div>
          <i
            onClick={handleBannerClose}
            className="fa fa-times"
            style={{
              color: "black",
              display: "flex",
              justifyContent: "right",
              cursor: "pointer",
            }}
          ></i>
          <p
            style={{
              paddingBottom: "15px",
              maxWidth: "960px",
              textAlign: "left",
              margin: "auto",
            }}
          >
            {lng ? (
              <span>
                Now you can increase the credibility of your ad by completing an
                ID verification. Verified accounts are displayed with a{" "}
                <i
                  style={{ color: "#2979ff", fontSize: "12px" }}
                  className="fas fa-check-circle"
                ></i>{" "}
                badge.
              </span>
            ) : (
              <span className="sinhala-size-12">
                දැන් ඔබට හැඳුනුම්පත සත්යාපනය සම්පූර්ණ කිරීමෙන් ඔබේ දැන්වීමට වැඩි
                විශ්වසනීයත්වයක් ලබා ගත හැක. තහවුරු කළ ගිණුම්{" "}
                <i
                  style={{ color: "#2979ff", fontSize: "12px" }}
                  className="fas fa-check-circle"
                ></i>{" "}
                ලාංඡනය සමඟ පෙන්වනු ලැබේ.
              </span>
            )}
          </p>
          <div style={{ maxWidth: "960px", margin: "auto" }}>
            <Button
              onClick={() => goToVerification()}
              style={{
                borderColor: "black",
                color: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              <span>
                {lng ? (
                  "Get Verified"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">
                    තහවුරු කර ගන්න
                  </span>
                )}
              </span>
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default NICBanner;
