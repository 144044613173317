import { Grid, Typography } from "@material-ui/core";
import React from "react";
import CoupleIcon from "../../../../assets/payment/coupleIcon.png";
import CuratedIcon from "../../../../assets/payment/curatedIcon.png";
import SatisfactionIcon from "../../../../assets/payment/satisfactionIcon.png";
import IconText from "./icon-text";

const PremiumPackageTrustSignalView = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className="trust-signals-view"
    >
      <img
        src="https://static.poruwa.lk/images/payment/premium-txt.png"
        alt="premium-text"
        className="premium-txt-image"
      />
      <Typography variant="h4" className="trust-signal-title">
        BENEFITS
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{ maxWidth: 960 }}
      >
        <IconText text="Higher Success Rate" icon={SatisfactionIcon} />
        <IconText text="Dedicated Consultants" icon={CuratedIcon} />
        <IconText text="Curated Matches" icon={CoupleIcon} />
      </Grid>
    </Grid>
  );
};

export default PremiumPackageTrustSignalView;
