import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import MailResentModal from "./MailResentModal";

import "../../styles/pwd-reset.css";

class Reset extends Component {
  state = {
    password: "",
    confirmPassword: "",

    passwordError: "",
    confirmPasswordError: "",

    showPassword: false,
    showPasswordConfirm: false,

    // alert
    resetSuccess: false,
    resetFail: "",

    // email activation mail resend
    resendEmailLink: false,
    mailResentModal: false,
    resendEmail: "",
    resendEmailError: "",

    showResendSuccess: false,
    showResendFail: false,

    // loading
    loading: false,
    resetLoading: false,
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleClickShowPwd = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowPwdConfirm = () => {
    this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // validate
  validate = () => {
    let passwordError = "";
    let confirmPasswordError = "";

    if (this.state.password === "") {
      passwordError =
        this.props.lng === true ? "Password is required" : "මුරපදය අවශ්‍ය වේ";
    } else if (this.state.password.length < 8) {
      passwordError =
        this.props.lng === true
          ? "Password should be between 6 and 60 characters"
          : "මුරපදය අක්ෂර 6 ත් 60 ත් අතර විය යුතුය";
    }

    if (this.state.confirmPassword === "") {
      confirmPasswordError =
        this.props.lng === true
          ? "Confirm password is required"
          : "තහවුරු කිරීමේ මුරපදය අවශ්‍ය වේ";
    } else if (this.state.password !== this.state.confirmPassword) {
      confirmPasswordError =
        this.props.lng === true
          ? "Passwords should match"
          : "මුරපද ගැලපිය යුතුය";
    }

    if (passwordError || confirmPasswordError) {
      this.setState({ passwordError, confirmPasswordError });
      return false;
    }

    return true;
  };

  // login when press the enter key
  handleSubmitKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("submit").click();
    }
  };

  // submit sign-up form
  handleSubmit = () => {
    if (this.validate()) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("t");

      // set loading
      this.setState({ loading: true });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/reset_password`,
          `password=${this.state.password}&token=${token}`
        )
        .then((res) => {
          this.setState({
            resetSuccess: true,
            resetFail: "",
            loading: false,

            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
          });

          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("userId", res.data.memberId);
          let ownerUrl = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            ownerUrl = `operator/managed_posts/${localStorage.getItem(
              "postId"
            )}/owner`;
          } else {
            ownerUrl = "account";
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${ownerUrl}`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem(
                "user_email",
                res.data.email ? res.data.email : ""
              );
            })
            .catch((err) => {
              console.log(err);
            });

          let url = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            url = `operator/managed_posts/${localStorage.getItem("postId")}`;
          } else {
            url = "account/posts";
          }
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (
                localStorage.getItem("user_role") === "OPERATOR" ||
                localStorage.getItem("user_role") === "OPERATOR_L2"
              ) {
                res.data[0] = res.data;
              }
              if (res.data.length > 0) {
                localStorage.setItem(
                  "post_details",
                  JSON.stringify(res.data[0])
                );
                localStorage.setItem("postId", res.data[0].id);

                if (
                  this.props.history.location.pathname === "/access-denied" ||
                  this.props.history.location.pathname === "/unauthorized-user"
                ) {
                  this.props.history.goBack();
                } else if (
                  this.props.history.location.pathname === "/register"
                ) {
                  this.props.history.push("/search");
                }
                this.setState({ loadingLogin: false });
              } else {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "auth_token"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("personalInfo");
                    localStorage.removeItem("parentInfo");
                    localStorage.removeItem("privateInfo");

                    if (res.data !== "") {
                      if (res.data.postData.personalInfo) {
                        localStorage.setItem(
                          "personalInfo",
                          JSON.stringify(res.data.postData.personalInfo)
                        );
                      }

                      if (res.data.postData.parentInfo) {
                        localStorage.setItem(
                          "parentInfo",
                          JSON.stringify(res.data.postData.parentInfo)
                        );
                      }

                      if (
                        res.data.postData.horoscopeMatching ||
                        res.data.postData.makePhonePublic
                      ) {
                        localStorage.setItem(
                          "privateInfo",
                          JSON.stringify({
                            phoneId: res.data.postData.phoneId,
                            makePhonePublic: res.data.postData.makePhonePublic,
                            horoscopeMatching:
                              res.data.postData.horoscopeMatching,
                            birthTime: res.data.postData.birthTime,
                            birthCity: res.data.postData.birthCity,
                            images: res.data.postData.images,
                            phone: res.data.postData.phone,
                          })
                        );
                      }
                    }

                    this.props.history.push("/complete-ad-review");
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              this.setState({ loadingLogin: false });
            });
        })
        .catch((err) => {
          if (err.response.data.code === 1101) {
            this.setState({
              resetSuccess: false,
              resetFail:
                this.props.lng === true
                  ? "Your password reset token has expired. Please reset your password again to resend it"
                  : "ඔබගේ මුරපද යළි පිහිටුවීමේ ටෝකනය කල් ඉකුත් වී ඇත. කරුණාකර ඔබගේ මුරපදය නැවත යලි සකසන්න",
              loading: false,
              password: "",
              confirmPassword: "",
              passwordError: "",
              confirmPasswordError: "",
            });
          } else if (err.response.data.code === 1103) {
            this.setState({
              resendEmailLink: true,
              resetFail:
                this.props.lng === true
                  ? "Email is not verified yet. Please, verify before password reset."
                  : "විද්‍යුත් තැපෑල තවම තහවුරු කර නොමැත. කරුණාකර, මුරපද යළි පිහිටුවීමට පෙර තහවුරු කරන්න.",
              loading: false,
            });
          } else {
            this.setState({
              resetFail:
                this.props.lng === true
                  ? "Opps! Something Went Wrong"
                  : "Opps! මොකක්හරි වැරැද්දක් වෙලා",
              loading: false,
            });
          }
        });
    }
  };

  showResendModal = () => {
    this.setState({ mailResentModal: true });
  };

  // validate
  validateReset = (type) => {
    let resendEmailError = "";

    if (this.state.resendEmail === "") {
      resendEmailError = this.props.lng
        ? "Email is required"
        : "විද්‍යුත් තැපැල් අවශ්‍ය වේ";
    } else if (!this.state.resendEmail.includes("@")) {
      resendEmailError = this.props.lng
        ? "Invalid email"
        : "විද්‍යුත් තැපැල් වලංගු එකක් නොවේ";
    }

    if (resendEmailError) {
      this.setState({
        resendEmailError,
      });
      return false;
    }

    return true;
  };

  // resend verification email
  handleResendEmail = () => {
    if (this.validateReset()) {
      this.setState({ resetLoading: true });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/resend_activation`,
          `email=${this.state.resendEmail}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.setState({
            showResendSuccess: true,
            resetLoading: false,
            resendEmail: "",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ resetLoading: false, showResendFail: true });
        });
    }
  };

  handleClose = () => {
    this.setState({ mailResentModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ minHeight: 650 }}>
          <div className="reset-pwd-div">
            <Typography
              variant="h6"
              align="center"
              style={{ marginBottom: 30 }}
            >
              {this.props.lng === true ? (
                "Password Reset"
              ) : (
                <span className="sinhala-w-600 sinhala-size-16">
                  මුරපදය යළි පිහිටුවිම
                </span>
              )}{" "}
            </Typography>
            {this.state.resetSuccess ? (
              <p
                style={{
                  color: "#28a745",
                  fontSize: "0.75rem",
                  marginTop: 0,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                <i className="fas fa-check"> </i>{" "}
                {this.props.lng === true
                  ? "Your password has been reset successfully!"
                  : "ඔබගේ මුරපදය සාර්ථකව යළි පිහිටුවන ලදි!"}{" "}
              </p>
            ) : null}
            {this.state.resetFail ? (
              <p
                style={{
                  color: "#f44336",
                  fontSize: "0.75rem",
                  marginTop: 0,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                {this.state.resetFail}{" "}
                {this.state.resendEmailLink && (
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={this.showResendModal}
                  >
                    {this.props.lng ? "Resend" : "නැවත යවන්න"}
                  </span>
                )}
              </p>
            ) : null}

            {this.state.resetSuccess ? (
              <Button
                variant="contained"
                className="login-btn"
                onClick={() => this.props.history.push("/")}
                style={{
                  display: "block",
                  width: 100,
                  padding: "6px 30px",
                  margin: "auto",
                }}
              >
                {this.props.lng === true ? "Home" : "ආපසු යන්න"}
              </Button>
            ) : (
              <React.Fragment>
                <FormControl className="inputs" variant="outlined" size="small">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {this.props.lng === true ? "Password" : "මුරපදය"}
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 300 }}
                    id="outlined-adornment-password"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleChange}
                    error={this.state.passwordError ? true : false}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPwd}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: ".25rem",
                    fontSize: "80%",
                    color: "#f44336",
                    marginLeft: 13,
                  }}
                >
                  {this.state.passwordError}
                </div>
                <FormControl
                  className="inputs"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: 15 }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    style={{ background: "#fff" }}
                  >
                    {this.props.lng === true
                      ? "Confirm Password"
                      : "මුරපදය තහවුරු කරන්න"}
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 300 }}
                    id="outlined-adornment-password"
                    name="confirmPassword"
                    type={this.state.showPasswordConfirm ? "text" : "password"}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    onKeyUp={this.handleSubmitKeyUp}
                    error={this.state.confirmPasswordError ? true : false}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPwdConfirm}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPasswordConfirm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: ".25rem",
                    fontSize: "80%",
                    color: "#f44336",
                    marginLeft: 13,
                  }}
                >
                  {this.state.confirmPasswordError}
                </div>

                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={this.handleSubmit}
                  id="submit"
                >
                  {this.state.loading ? (
                    <React.Fragment>
                      <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                      <span>
                        {" "}
                        &nbsp;{" "}
                        {this.props.lng === true ? (
                          "Submiting..."
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-14">
                            ඉදිරිපත් කිරීම...
                          </span>
                        )}
                      </span>
                    </React.Fragment>
                  ) : this.props.lng === true ? (
                    "Submit"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      ඉදිරිපත් කරන්න
                    </span>
                  )}
                </Button>
              </React.Fragment>
            )}
          </div>
        </Grid>

        <MailResentModal
          lng={this.props.lng}
          mailResentModal={this.state.mailResentModal}
          resendEmail={this.state.resendEmail}
          resendEmailError={this.state.resendEmailError}
          handleChange={this.handleChange}
          handleResendEmail={this.handleResendEmail}
          resetLoading={this.state.resetLoading}
          handleClose={this.handleClose}
          showResendSuccess={this.state.showResendSuccess}
          showResendFail={this.state.showResendFail}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Reset);
