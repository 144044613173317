import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  PROFILE_STATUS,
  SUBSCRIPTION_STATUSES,
} from "../../../v2/config/constant";
import IncompleteView from "./profile-status-views/in-complete-view";
import PaymentPendingView from "./profile-status-views/payment-pending-view";
import RenewalView from "./profile-status-views/renewal-view";
import FeatureAdSection from "./feature-ad-section";
import SubscriptionHandler from "./subscription-handler";
import "./style.css";
import InReviewView from "./profile-status-views/in-review-view";

const ManagePayments = () => {
  const { authProfile, initialized } = useSelector(
    (state) => state.application
  );
  const { t } = useTranslation();

  const renderSubscriptionView = () => {
    const profileStatus = authProfile.status ?? "";

    const validSubscriptionStatuses = [
      SUBSCRIPTION_STATUSES.STARTED,
      SUBSCRIPTION_STATUSES.USER_CANCELLED,
      SUBSCRIPTION_STATUSES.SYSTEM_CANCELLED,
      SUBSCRIPTION_STATUSES.GRACE_PERIOD,
      SUBSCRIPTION_STATUSES.RENEWED,
      SUBSCRIPTION_STATUSES.RENEW_FAILED,
    ];

    if (validSubscriptionStatuses.includes(authProfile.subscriptionStatus)) {
      return <SubscriptionHandler />;
    } else {
      switch (profileStatus) {
        case PROFILE_STATUS.INCOMPLETE:
          return <IncompleteView />;
        case PROFILE_STATUS.IN_REVIEW:
          return <InReviewView />;
        case PROFILE_STATUS.PAYMENT_PENDING:
          return <PaymentPendingView />;
        case PROFILE_STATUS.LIVE:
        case PROFILE_STATUS.EXPIRED:
          return <RenewalView authProfile={authProfile} />;
        default:
          break;
      }
    }
  };

  const subscriptionView = renderSubscriptionView();

  return (
    <Grid
      id="top-ad-div"
      container
      direction="column"
      className="settings-manage-payment-section"
    >
      <Typography className="sec-heading settings-manage-payment-section-title">
        {t("setting.paymentAndFeatures")}
      </Typography>
      <Grid
        container
        direction="column"
        className="subscription-manage-section"
      >
        {initialized ? (
          <Grid container direction="column" className="row-gap-16">
            {subscriptionView}
            <FeatureAdSection authProfile={authProfile} />
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="center">
            <CircularProgress size={24} style={{ color: "#cca326" }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ManagePayments;
