import React from "react";
import PageHead from "../../components/atoms/page-head";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import Config from "../../config";
import withLanguageMigrator from "../../hoc/language-migrator";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { DOMAIN_DISPLAY_NAME } = Config;

const UpdateCardFailed = ({ lng }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <PageHead title={`Subscription status | ${DOMAIN_DISPLAY_NAME}`} />
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="cus-container card-update-failed-container row-gap-16"
      >
        <i
          className="far fa-times-circle fa-4x card-update-failed-icon"
          align="center"
        />
        <Typography variant="h6" className="card-update-title-txt">
          {t("message.failedToUpdateCardDetails")}
        </Typography>

        <Typography variant="body1" className="card-update-failed-notice-txt">
          {t("message.cardUpdateFailedNotice")}
        </Typography>
        <Button
          variant="contained"
          className="primary-button margin-top-16"
          onClick={() =>
            history.push({ pathname: "/settings", hash: "update-card-details" })
          }
        >
          {t("payment.tryAgain")}
        </Button>
      </Grid>
      {isMobileScreen ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </>
  );
};

export default withLanguageMigrator(UpdateCardFailed);
