import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import sunglasses from "../../../../../../images/sunglasses.svg";
import face from "../../../../../../images/emoticon-excited-outline.svg";
import blurFace from "../../../../../../images/face-recognition.svg";
import group from "../../../../../../images/account-group.svg";
import children from "../../../../../../images/human-female-boy.svg";
import famous from "../../../../../../images/account-star.svg";
import adults from "../../../../../../images/account-plus.svg";
import blackWhite from "../../../../../../images/black-white-images.svg";
import { useTranslation } from "react-i18next";
import "./style.css";
import AppContext from "../../../../../context";

const ImageRestrictionNotice = ({ lang }) => {
  const { t } = useTranslation();
  const { appLanguage } = useContext(AppContext);

  return (
    <Grid container direction="column" style={{ rowGap: 16 }}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={sunglasses} alt="Sunglasses" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.sunglassesText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={face} alt="Face" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.partOfFaceText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={blurFace} alt="BlurFace" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.blurFaceText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={blackWhite} alt="BlackWhite" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.blackWhiteText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={group} alt="Group" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.moreThanOnePersonText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={children} alt="Children" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.nudityText")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
        wrap="nowrap"
      >
        <img src={famous} alt="Famous" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.minorstext")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 16 }}
      >
        <img src={adults} alt="Adults" />
        <Typography
          className={`image-notice-text image-notice-text-${appLanguage}`}
        >
          {t("createflow.publicFiguresText")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ImageRestrictionNotice;
