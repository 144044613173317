import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Loader from "react-loader-spinner";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PaymentSuccess extends Component {
  state = {
    firstPublishedAt: true,
    postId: "",
    marketingSources: [],
    sourceId: "",
    triggeredPurchaseEvent: false,
    loading: false,
  };

  componentDidMount = () => {
    let url = "";
    if (
      localStorage.getItem("user_role") === "OPERATOR" ||
      localStorage.getItem("user_role") === "OPERATOR_L2"
    ) {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    if (window.FB !== undefined && !this.state.triggeredPurchaseEvent) {
      const paidPackage = JSON.parse(
        sessionStorage.getItem("selected-subscription-package") ?? "{}"
      );
      const paidCurrency = sessionStorage.getItem("paying_currency") ?? "LKR";
      window.FB.AppEvents.logPurchase({
        purchaseAmount:
          paidCurrency === "LKR"
            ? paidPackage.amountLKR
            : paidPackage.amountUSD,
        currency: paidCurrency,
        parameters: {
          package: sessionStorage.getItem("selected_package") ?? "N/A",
        },
      });
      this.setState({ triggeredPurchaseEvent: true });
    }
    this.setState({ loading: true });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        if (res.data.length > 0) {
          localStorage.setItem("post_details", JSON.stringify(res.data[0]));
        }

        this.setState({
          postId: res.data[0].id,
          loading: false,
          firstPublishedAt: res.data[0].firstPublishedAt ? true : false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/marketing_sources`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ marketingSources: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState({ sourceId: e.target.value });
  };

  handleSubmit = () => {
    const data = qs.stringify({
      sourceId: this.state.sourceId,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/marketing_source`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then(() => {
        this.props.history.push("/complete-your-ad");
      })
      .catch((err) => {
        console.log(err);
        this.props.history.push("/complete-your-ad");
      });
  };

  render() {
    return (
      <>
        <PageHead
          title={`Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`}
        />

        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="success-error-div">
            <p align="center">
              <i className="far fa-check-circle fa-4x" align="center"></i>
            </p>
            <p className="message">
              {this.props.lng ? "Payment Successful!" : "ගෙවීම සාර්ථකයි!"}
            </p>

            {this.state.loading ? (
              <div style={{ alignItems: "center" }}>
                <Loader type="Oval" color="#c19d0d" height={50} width={50} />
              </div>
            ) : (
              <>
                {!this.state.firstPublishedAt ? (
                  <p align="center">
                    {this.props.lng ? (
                      "Your ad has been submitted for review."
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ඔබගේ දැන්වීම සමාලෝචනය සඳහා ඉදිරිපත් කර ඇත.
                      </span>
                    )}
                  </p>
                ) : (
                  <p align="center">
                    {this.props.lng ? (
                      "Your ad has been renewed."
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ඔබගේ දැන්වීම අලුත් කර ඇත.
                      </span>
                    )}
                  </p>
                )}
                {!this.state.firstPublishedAt ? (
                  <>
                    <p className="how-did-you-hear">
                      {this.props.lng ? (
                        "How did you hear about us?"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          ඔබ අප ගැන දැනගත්තේ කොහොම ද?
                        </span>
                      )}
                    </p>
                    <select
                      className="marketing-source"
                      name="sourceId"
                      value={this.state.sourceId}
                      onChange={this.handleChange}
                    >
                      <option selected hidden value="-- Select --">
                        {this.props.lng ? "-- Select --" : "-- තෝරන්න --"}
                      </option>
                      {this.state.marketingSources.map((el) => (
                        <option value={el.id}>
                          {this.props.lng ? el.title : el.title_si}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}

                {!this.state.firstPublishedAt ? (
                  <Button
                    variant="contained"
                    className={
                      this.state.sourceId
                        ? "all-ads-btn"
                        : "all-ads-btn disabled-btn"
                    }
                    onClick={this.handleSubmit}
                    style={{ width: 200 }}
                    disabled={!this.state.sourceId}
                  >
                    {this.props.lng ? (
                      "ATTRACT MORE RESPONSES"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        දැන්වීම ආකර්ෂණීය කරන්න
                      </span>
                    )}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={
                      this.state.sourceId ? "all-ads-btn" : "all-ads-btn"
                    }
                    onClick={() => this.props.history.push("/search")}
                    style={{ width: 200 }}
                  >
                    {this.props.lng ? (
                      "Go To ALL Ads"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        සියලුම දැන්වීම් වෙත යන්න
                      </span>
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </Grid>
        <Footer lng={this.props.lng} />
      </>
    );
  }
}

export default withRouter(withLanguageMigrator(PaymentSuccess));
