import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import InterestListCards from "./InterestListItemCards";

const InterestSideViewWithdrawn = (props) => {
  const { lng, sideMenuArchivedInterests, intId, archivedClick } = props;

  return (
    <div>
      <Typography className="message-header-text">
        {lng
          ? "Withdrawn / Ignored"
          : "කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම"}
      </Typography>

      {Array.isArray(sideMenuArchivedInterests) &&
        sideMenuArchivedInterests.length > 0 && (
          <InterestListCards
            intId={intId}
            posts={sideMenuArchivedInterests}
            lng={lng}
          />
        )}

      {!props.sideMenuInterestsLoading &&
        Array.isArray(sideMenuArchivedInterests) &&
        sideMenuArchivedInterests.length < 1 && (
          <Typography
            align="center"
            style={{ marginTop: 50, padding: 20 }}
            className="availability-messsage"
          >
            {lng
              ? "No ignored/withdrawn profiles found"
              : "කැමැත්ත ඉවත් කල හෝ නොසලකා හරින ලද ගැලපීම් නොමැත."}
          </Typography>
        )}

      {!props.sideMenuArchivedReceivedAllFetched &&
        !props.sideMenuInterestsLoading && (
          <p className="see-more-btn" onClick={archivedClick}>
            See more...
          </p>
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenuReceivedAllFetched: state.matches.sideMenuReceivedAllFetched,
    sideMenuInterests: state.matches.sideMenuInterests.interests,
    sideMenuArchivedInterests: state.matches.sideMenuArchivedInterests.archived,
    sideMenuArchivedReceivedAllFetched:
      state.matches.sideMenuArchivedReceivedAllFetched,
    sideMenuInterestsLoading: state.matches.sideMenuInterestsLoading,
  };
};

export default connect(mapStateToProps)(withRouter(InterestSideViewWithdrawn));
