import {
  setDrinkingPreferences,
  setFormOptions,
  setGenders,
  setGenericDataInitilized,
  setGenericDisplayNameFormats,
  setGenericGothraOptions,
  setGenericHaveDoshOptions,
  setGenericMotherTounges,
  setGenericStarOptions,
  setGenericStarSignOptions,
  setHasChildrenOpitons,
  setHeightOptions,
  setSmokingPreferences,
  setSortedCivilStatus,
  setSortedCountries,
  setSortedEducationLevels,
  setSortedEthnicities,
  setSortedFoodPreferences,
  setSortedProfessions,
  setSortedReligions,
  setSortedSecondaryCountries,
  setSortedVisaStatus,
  setAccountOwnershipOptions,
  setDifferentlyAbledOptions,
  setNicVerifiedOptions,
} from "../../store/actions/generic-data";
import {
  DISPLAY_NAME_FORMAT_OPTIONS,
  GENDER_OPTIONS,
  FORM_OPTIONS,
  HEIGHT_OPTIONS,
  HAS_CHILDREN_OPTIONS,
  DRINKING_PREFERENCES,
  SMOKING_PREFERENCES,
  HAVE_DOSH_OPTIONS,
  DIFFERENTLY_ABLED_OPTIONS,
  ACCOUNT_CREATED_BY_OPTIONS,
  NIC_VERIFIED_OPTIONS,
} from "../../static-data";
import GenericDataAPI from "../../services/api/generic";
import { sortBy, isFunction } from "lodash";
import { APP_LANG } from "../../utils/locale-handler";
import { HttpCode, OTHER_PROFESSION_ID } from "../../config/constant";
import { setAppSnackNotification } from "../../store/actions/application";
import Config from "../../config";

const GENERIC_DATA_LOCALE_ORDER = {
  countries: { [APP_LANG.EN]: "name", [APP_LANG.SI]: "name_si" },
};

const GENERIC_SORT_KEYS = ["sortOrder"];

export const initializedGenericOptions = (appLanguage) => async (dispatch) => {
  try {
    let apiResponse = await GenericDataAPI.getGenericFilters();
    if (!apiResponse.success) {
      throw new Error("Failed GenericDataAPI.getGenericFilters()");
    }
    dispatch(setGenericDisplayNameFormats(DISPLAY_NAME_FORMAT_OPTIONS));
    dispatch(setGenders(GENDER_OPTIONS));
    dispatch(setFormOptions(FORM_OPTIONS));
    const {
      civilStatuses,
      ethnicities,
      religions,
      countries,
      educationLevels,
      foodPreferences,
      professions,
      visaStatuses,
      gothras,
      motherTongues,
      stars,
      starSigns,
    } = apiResponse.body;
    // Constant static data
    dispatch(setHeightOptions(HEIGHT_OPTIONS));
    dispatch(setHasChildrenOpitons(HAS_CHILDREN_OPTIONS));
    dispatch(setDrinkingPreferences(DRINKING_PREFERENCES));
    dispatch(setSmokingPreferences(SMOKING_PREFERENCES));
    dispatch(setGenericHaveDoshOptions(HAVE_DOSH_OPTIONS));
    dispatch(setAccountOwnershipOptions(ACCOUNT_CREATED_BY_OPTIONS));
    dispatch(setDifferentlyAbledOptions(DIFFERENTLY_ABLED_OPTIONS));
    dispatch(setNicVerifiedOptions(NIC_VERIFIED_OPTIONS));
    // Generics values from backend
    if (Array.isArray(civilStatuses)) {
      dispatch(setSortedCivilStatus(sortBy(civilStatuses, GENERIC_SORT_KEYS)));
    }
    if (Array.isArray(ethnicities)) {
      dispatch(setSortedEthnicities(sortBy(ethnicities, GENERIC_SORT_KEYS)));
    }
    if (Array.isArray(religions)) {
      let sortedReligions = [];
      switch (Config.CURRENT_PLATFORM) {
        case Config.PLATFORMS.LK:
          sortedReligions = sortBy(religions, GENERIC_SORT_KEYS);
          break;
        case Config.PLATFORMS.IN:
          const otherOption = religions.find(
            (current) => current.id === "other"
          );
          sortedReligions = religions.filter(
            (current) => current.id !== "other"
          );
          if (otherOption !== null) {
            sortedReligions.push(otherOption);
          }
          break;
        default:
          sortedReligions = religions;
          break;
      }
      dispatch(setSortedReligions(sortedReligions));
    }
    if (Array.isArray(educationLevels)) {
      dispatch(
        setSortedEducationLevels(sortBy(educationLevels, GENERIC_SORT_KEYS))
      );
    }
    if (Array.isArray(foodPreferences)) {
      dispatch(
        setSortedFoodPreferences(sortBy(foodPreferences, GENERIC_SORT_KEYS))
      );
    }
    if (Array.isArray(professions)) {
      dispatch(
        setSortedProfessions(
          sortGenericOptionsByLanguage(professions, appLanguage, [
            OTHER_PROFESSION_ID,
          ])
        )
      );
    }
    if (Array.isArray(visaStatuses)) {
      dispatch(setSortedVisaStatus(sortBy(visaStatuses, GENERIC_SORT_KEYS)));
    }
    if (Array.isArray(gothras)) {
      dispatch(setGenericGothraOptions(gothras));
    }
    if (Array.isArray(motherTongues)) {
      dispatch(
        setGenericMotherTounges(
          motherTongues.sort((a, b) => a.name.localeCompare(b.name))
        )
      );
    }
    if (Array.isArray(stars)) {
      dispatch(setGenericStarOptions(stars));
    }
    if (Array.isArray(starSigns)) {
      dispatch(setGenericStarSignOptions(starSigns));
    }
    if (Array.isArray(countries)) {
      dispatch(
        sortCountryOptionsByLanguage(appLanguage, {
          countries,
        })
      );
    }

    apiResponse = await GenericDataAPI.getAllCountries();
    if (!apiResponse.success) {
      throw new Error("Failed GenericDataAPI.getAllCountries()");
    }
    const secondaryCountries = apiResponse.body;
    dispatch(setSortedSecondaryCountries(secondaryCountries));
    dispatch(setGenericDataInitilized(true));
  } catch (error) {
    // integrate crash analytics here such as Sentry
    dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unable to fetch generic data",
      })
    );
  }
};

export const sortCountryOptionsByLanguage =
  (appLanguage, payload = {}) =>
  (dispatch) => {
    const countries = payload.countries ?? [];
    dispatch(
      setSortedCountries(
        sortBy(countries, [GENERIC_DATA_LOCALE_ORDER.countries[appLanguage]])
      )
    );
  };

const sortGenericOptionsByLanguage = (options, appLanguage, excludes = []) => {
  let sortedOptions = sortBy(options, [
    GENERIC_DATA_LOCALE_ORDER.countries[appLanguage],
  ]);
  if (Array.isArray(excludes) && excludes.length > 0) {
    const excludeItems = sortedOptions.filter((current) =>
      excludes.includes(current.id)
    );
    sortedOptions = sortedOptions.filter(
      (current) => !excludes.includes(current.id)
    );
    sortedOptions.push(...excludeItems);
  }
  return sortedOptions;
};

export const getRegionsByCountryCode = async ({ countryCode, appLanguage }) => {
  try {
    const apiResponse = await GenericDataAPI.getRegionsByCountryCode({
      countryCode,
    });
    if (!apiResponse.success) {
      throw new Error("Unable to get country regions");
    }
    let sortKey = "regionName";
    if (appLanguage !== APP_LANG.EN) {
      sortKey = `${sortKey}_${appLanguage}`;
    }
    const dataset = sortBy(apiResponse.body, [sortKey]);
    return dataset;
  } catch (error) {
    // plug error reporting mechanism
    throw error;
  }
};

export const checkSensitiveData = async ({ content }) => {
  try {
    const response = await GenericDataAPI.checkSensitiveData({ content });
    return response.statusCode === HttpCode.OK;
  } catch (error) {
    // plug error reporting mechanism
    throw error;
  }
};

export const getCommunitiesByReligionId = async ({
  religionId,
  setCommunities,
}) => {
  const response = await GenericDataAPI.getCommunitiesByReligionId({
    religionId,
  });
  if (response.success && isFunction(setCommunities)) {
    setCommunities(response.body);
  }
  return response;
};

export const fetchCommunitiesByReligionId =
  ({ religionId, setCommunities }) =>
  async () => {
    const response = await GenericDataAPI.getCommunitiesByReligionId({
      religionId,
    });
    if (response.success && isFunction(setCommunities)) {
      setCommunities(response.body);
    }
    return response;
  };

export const getPricingTiers = async () => {
  try {
    const response = await GenericDataAPI.getPricingTiers();
    if (!response.success) {
      throw new Error("Unable to get country regions");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGenericReportReasons = async () => {
  try {
    const response = await GenericDataAPI.getGenericReportReasons();
    if (!response.success) {
      throw new Error("Unable to get Report Reasons");
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
