import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import "../../../style.css";

const InvoiceHeader = ({ paymentSessionData }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className="invoice-header-view"
    >
      {isEmpty(paymentSessionData) ? (
        <CircularProgress size={15} style={{ color: "#000" }} />
      ) : (
        <Typography variant="h6" className="invoice-header-view-txt">
          {t("common.months", { months: paymentSessionData.durationMonths })}
        </Typography>
      )}
      <Grid container xs direction="column" alignItems="flex-end">
        {isEmpty(paymentSessionData) ? (
          <CircularProgress size={15} style={{ color: "#000" }} />
        ) : (
          <>
            <Typography variant="h6" className="invoice-header-view-txt">
              {paymentSessionData.amountBeforeDiscount}
            </Typography>
            <Typography variant="caption" className="invoice-header-view-txt">
              {t("pricing.perMonth", {
                monthlyAmount: paymentSessionData.monthlyAmount,
              })}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default InvoiceHeader;
