import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "../../style.css";
import { useTranslation } from "react-i18next";

const PaymentOption = ({ title, description, price, action }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    className="payment-option-card"
    onClick={typeof action === "function" && action}
    wrap="nowrap"
  >
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="option-content"
    >
      <Typography variant="body2" className="plan-txt-color font-weight-600">
        {title}
      </Typography>
      <Typography
        variant="caption"
        className="plan-txt-color"
        style={{ textAlign: "center" }}
      >
        {description}
      </Typography>
      <Typography variant="body2" className="plan-txt-color font-weight-600">
        {price}
      </Typography>
    </Grid>
    <NavigateNextIcon />
  </Grid>
);

const PaymentOptions = ({
  packageAmount,
  currency,
  onContinuePackage,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const getOneTimePaymentPrice = () => {
    return `${currency} ${packageAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const calculateSubscriptionPrice = () => {
    const discountPercentage = 20;
    const discountedPrice =
      packageAmount - (packageAmount * discountPercentage) / 100;
    return `${currency} ${discountedPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} (20% off)`;
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="payment-options-container"
    >
      <Typography variant="body2" className="paymment-options-title">
        {t("payment.selectPaymentOption")}
      </Typography>
      <Divider className="paymment-options-divider" />
      <PaymentOption
        title={t("payment.subscriptionPlan")}
        description={t("payment.subscriptionInfo")}
        price={calculateSubscriptionPrice()}
        action={() => onContinuePackage({ isRecurringPayment: true })}
      />
      <PaymentOption
        title={t("payment.oneTimePayment")}
        description={t("payment.oneTimePaymentInfo")}
        price={getOneTimePaymentPrice()}
        action={() => onContinuePackage({ isRecurringPayment: false })}
      />
      <Typography
        className="payment-options-cancel-link"
        onClick={onClickCancel}
      >
        Cancel
      </Typography>
    </Grid>
  );
};

export default PaymentOptions;
