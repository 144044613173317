import React from "react";
import { Helmet } from "react-helmet";

export default function PageHead({ title, name, description }) {
  return (
    <Helmet>
      <title>
        {title}
        {name ?? ""}
      </title>
      <meta name="description" content={description} />
    </Helmet>
  );
}
