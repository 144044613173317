import React from "react";

export const packageDetails = {
  "2m_basic": {
    name: "Bronze",
    durationMonths: 2,
    features: [
      {
        label: "pricing.sendUnlimitedRequests",
      },
      {
        label: "pricing.setDetailedPreferencesToFindMatch",
      },
      // {
      //   transComponent: {
      //     key: "pricing.automaticRepublishingAd",
      //     values: { days: 30 },
      //     components: [<strong />, <i />],
      //   },
      // },
      {
        label: "pricing.receiveOfflineResponsesFromUsers",
      },
      {
        label: "pricing.connectWithAnyUser",
      },
      {
        label: "pricing.getOneTopAdToIncreaseVisibility",
        isHighlight: true,
      },
    ],
  },
  "2m_pro": {
    name: "Silver",
    durationMonths: 2,
    features: [
      {
        label: "pricing.sendUnlimitedRequests",
      },
      {
        label: "pricing.setDetailedPreferencesToFindMatch",
      },
      // {
      //   transComponent: {
      //     key: "pricing.automaticRepublishingAd",
      //     values: { days: 30 },
      //     components: [<strong />, <i />],
      //   },
      // },
      {
        label: "pricing.receiveOfflineResponsesFromUsers",
      },
      {
        label: "pricing.connectWithAnyUser",
      },
      {
        label: "pricing.getTwoTopAdsToIncreaseVisibility",
        isHighlight: true,
      },
      {
        label: "pricing.receiveMagazine",
        isHighlight: true,
      },
      {
        label: "pricing.personalizedMatchSuggestionaViaEmail",
        isHighlight: true,
      },
    ],
    preferred: true,
  },
  "3m_pro": {
    name: "Gold",
    durationMonths: 3,
    features: [
      {
        label: "pricing.sendUnlimitedRequests",
      },
      {
        label: "pricing.setDetailedPreferencesToFindMatch",
      },
      // {
      //   transComponent: {
      //     key: "pricing.automaticRepublishingAd",
      //     values: { days: 30 },
      //     components: [<strong />, <i />],
      //   },
      // },
      {
        label: "pricing.receiveOfflineResponsesFromUsers",
      },
      {
        label: "pricing.connectWithAnyUser",
      },
      {
        label: "pricing.getThreeTopAdsToIncreaseVisibility",
        isHighlight: true,
      },
      {
        label: "pricing.receiveMagazine",
        isHighlight: true,
      },
      {
        label: "pricing.personalizedMatchSuggestionaViaEmail",
        isHighlight: true,
      },
      {
        label: "pricing.dedicatedRelationshipManager",
        isHighlight: true,
      },
    ],
  },
  "6m_vip": {
    name: "VIP",
    durationMonths: 6,
    features: [
      {
        label: "Send unlimited interest requests",
        isHighlight: true,
      },
      {
        label: "Connect with any user on Poruwa.lk",
      },
      {
        label: "Set detailed preferences to find your ideal match",
      },
      {
        transComponent: {
          key: "pricing.automaticRepublishingAd",
          values: { days: 30 },
          components: [<strong />, <i />],
        },
        isHighlight: true,
      },
      {
        label: "Receive offline responses from users",
      },
      {
        label: "Ad is always a top ad to increase your visibility",
        isHighlight: true,
      },
      {
        label: "Receive a printed monthly magazine",
      },
      {
        label: "Personalized daily match suggestions via email",
        isHighlight: true,
      },
      {
        label: "A dedicated relationship manager from Poruwa.lk",
        isHighlight: true,
      },
      {
        label: "Agent managed account",
        isHighlight: true,
      },
      {
        label: "Bi weekly meeting with your dedicated agent",
        isHighlight: true,
      },
      {
        label: "Family meeting arrangement",
        isHighlight: true,
      },
    ],
  },
};
