import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "../../style.css";

const PremiumFeatures = ({ text, icon }) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={12}
      sm={4}
      style={{ rowGap: 16 }}
    >
      <img src={icon} alt="trust-sign" />
      <Typography
        variant="body2"
        className="feature-txt"
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default PremiumFeatures;
