import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import "../../style.css";
import FormDropdown from "../../../../components/molecules/form-dropdown";
import { ACCOUNT_CREATED_BY_OPTIONS } from "../../../../static-data";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormTextInput from "../../../../components/molecules/form-text-input";
import { postVipRequest } from "../../../../features/generic-data";
import { setAppSnackNotification } from "../../../../store/actions/application";
import { useDispatch } from "react-redux";

const InquiryForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    accountOwner: "",
  };

  const { t, i18n } = useTranslation();

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.yourName") })
    ),
    phoneNumber: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.contactNumber"),
      })
    ),
  });

  const onSubmitFields = async ({ values, actions }) => {
    try {
      const response = await postVipRequest({ formData: values });

      if (response.success) {
        actions.resetForm();
        setIsSubmitted(true);
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: "Submitted successfully!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Submission failed. Please try again.",
        })
      );
      console.log("Error", error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={(values, actions) => onSubmitFields({ values, actions })}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Grid
          id="premium-inquiry-form"
          container
          direction="column"
          alignItems="center"
          className="premium-inquiry-form-container"
        >
          <Typography variant="h5" className="contact-title">
            CONNECT WITH US
          </Typography>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            className="contact-form"
            xs={12}
            sm={8}
            md={6}
            lg={4}
          >
            <FormTextInput
              fieldName="name"
              variant="outlined"
              className="contact-form-txt-field"
              placeholder="YOUR NAME"
              value={values.name}
              errorText={errors.name}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormTextInput
              fieldName="phoneNumber"
              variant="outlined"
              className="contact-form-txt-field"
              placeholder="CONTACT NUMBER"
              value={values.phoneNumber}
              errorText={errors.phoneNumber}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormTextInput
              fieldName="email"
              variant="outlined"
              className="contact-form-txt-field"
              placeholder="EMAIL"
              value={values.email}
              errorText={errors.email}
              showLabel={false}
              hideCharactorLength={true}
              onChange={handleChange}
            />
            <FormDropdown
              fieldName={"accountOwner"}
              options={ACCOUNT_CREATED_BY_OPTIONS}
              keyExtractor="value"
              labelExtractor="label"
              value={values.accountOwner}
              lang={i18n.language}
              errorText={errors.accountOwner}
              onChange={handleChange}
              containerStyles={{ width: "100%" }}
              selectLabel={t("createflow.accountCreatedBy")}
            />
            <Button
              type="submit"
              className="contact-form-submit-btn"
              onClick={handleSubmit}
              disabled={isSubmitted}
              style={{ width: "40%" }}
            >
              Submit
            </Button>
          </Grid>
          <Typography variant="h5" className="notice-txt">
            AN AGENT WILL GET IN TOUCH WITH YOU SOON
          </Typography>
        </Grid>
      )}
    </Formik>
  );
};

export default InquiryForm;
