import isEmpty from "lodash/isEmpty";

import Config from "../../../../config";
import { checkSensitiveData } from "../../../../features/generic-data";
import { getAuthTempProfile } from "../../../../features/application";
import store from "../../../../../store";
import UserApi from "../../../../services/api/user";
import { setAppSnackNotification } from "../../../../store/actions/application";
import { sanitizeEmptyNullUndefinedValues } from "../../../../utils";
import { isFunction } from "lodash";
import FormMeta from "../schema/meta.json";

export const ParentType = Object.freeze({
  FATHER: "FATHER",
  MOTHER: "MOTHER",
});

export const bindTemporaryProfile = ({ input, parent }) => {
  const { CURRENT_PLATFORM, PLATFORMS } = Config;
  // Common Bindings
  input.religionId = parent.religionId ?? "";
  input.professionId = parent.professionId ?? "";
  input.otherProfession = parent.otherProfession ?? "";
  input.residentCountryCode = parent.residentCountryCode ?? "";
  input.additionalInfo = parent.additionalInfo ?? "";
  //LK specifi bindings
  if (CURRENT_PLATFORM === PLATFORMS.LK) {
    input.ethnicityId = parent.ethnicityId ?? "";
    input.caste = parent.caste ?? "";
  }
  // IN specifi bindings
  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    if (parent.communityId) {
      input.communityId = parent.communityId;
    } else {
      input.communityId = parent.communityOther || "";
    }
    input.subCommunity = parent.subCommunity;
  }
};

export const validateAdditionalInfo = async ({ content }) => {
  try {
    const validity = await checkSensitiveData({ content });
    return validity;
  } catch (e) {
    console.error("Error! unable to validate additional info ", e);
    throw new Error("Error! unable to validate additional info");
  }
};

// const validateAdditionalInfoFields = async (parentsInfomation) => {
//   // validate father form additionalInfo
//   const fatherInformation = parentsInfomation.find(
//     (element) => element.type === ParentType.FATHER
//   );
//   let { additionalInfo } = fatherInformation;
//   if (!isEmpty(additionalInfo)) {
//     const validation = await validateAdditionalInfo({
//       content: additionalInfo,
//     });
//     if (!validation) {
//       setFatherAdditonalInfoError(
//         t("createflow.descriptionSensitiveDataError")
//       );
//       return;
//     }
//   }

const validateAdditionalInfoField = async ({
  parentsInformation,
  type,
  setErrorMsg,
  t,
}) => {
  const filterEntity = parentsInformation.find(
    (parent) => parent.type === type
  );
  if (!isEmpty(filterEntity.additionalInfo)) {
    const validation = await validateAdditionalInfo({
      content: filterEntity.additionalInfo,
    });
    if (!validation) {
      setErrorMsg(t("createflow.descriptionSensitiveDataError"));
      return false;
    }
  }
  return true;
};

export const saveParentCreateData = async ({
  dispatch,
  history,
  parentInfomation,
  fatherSetError,
  motherSetError,
  t,
  setSubmitBtnDisability,
}) => {
  try {
    if (isFunction(setSubmitBtnDisability)) {
      setSubmitBtnDisability(true);
    }
    // AdditionaInfo fields validations
    const fatherAdditionInfoValidity = await validateAdditionalInfoField({
      parentsInformation: parentInfomation,
      type: ParentType.FATHER,
      setErrorMsg: fatherSetError,
      t,
    });
    const motherAdditionalInfoValidity = await validateAdditionalInfoField({
      parentsInformation: parentInfomation,
      type: ParentType.MOTHER,
      setErrorMsg: motherSetError,
      t,
    });
    if (!(fatherAdditionInfoValidity && motherAdditionalInfoValidity)) {
      return;
    }
    // Save TempProfile
    const { authTempProfile } = store.getState().application;
    const postData = authTempProfile.postData ?? {};
    let tempParentInfo = [];
    // postData.parentInfo = parentInfomation;
    if (Array.isArray(parentInfomation)) {
      for (const current of parentInfomation) {
        let currentOutput = {};

        currentOutput.additionalInfo = current.additionalInfo ?? "";

        currentOutput[FormMeta.professionId.fieldName] = current.professionId;
        currentOutput[FormMeta.otherProfession.fieldName] =
          parseInt(current.professionId) === Config.OTHER_PROFESSION_ID
            ? current[FormMeta.otherProfession.fieldName]
            : null;

        currentOutput.religionId = current.religionId ?? "";
        currentOutput.residentCountryCode = current.residentCountryCode ?? "";
        currentOutput.type = current.type ?? "";

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
          currentOutput.ethnicityId = current.ethnicityId ?? "";
          currentOutput.caste = current.caste ?? "";
        }

        if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
          if (
            current.communityId === "OTHER" ||
            current.communityId === "UNDISCLOSED"
          ) {
            currentOutput.communityId = null;
            currentOutput.communityOther = current.communityId;
          } else {
            currentOutput.communityOther = null;
            currentOutput.communityId = current.communityId ?? "";
          }
          currentOutput.subCommunity = current.subCommunity ?? "";
        }
        tempParentInfo.push(currentOutput);
      }
    }
    postData.parentInfo = tempParentInfo;

    const response = await UserApi.postUserTempProfile({
      tempProfile: sanitizeEmptyNullUndefinedValues(postData, [
        "images",
        "horoscopeImages",
      ]),
    });
    if (!response.success) {
      // TODO: Error Handling
      return;
    }
    dispatch(
      setAppSnackNotification({
        severity: "success",
        message: "Data Saved Successfully",
      })
    );
    await dispatch(getAuthTempProfile());
    history.push("complete-ad-private");
  } catch (e) {
    dispatch(
      setAppSnackNotification({
        severity: "error",
        message: "Unknown Error Occurred, Try Again",
      })
    );
  } finally {
    if (isFunction(setSubmitBtnDisability)) {
      setSubmitBtnDisability(false);
    }
  }
};
