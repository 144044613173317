import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Grid, Button, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Unsubscribed extends Component {
  state = {
    successAlert: false,
    failAlert: false,
    unsubscribeSuccess: false,
  };

  handleUnsubscribe = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("t");
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/unsubscribe?t=${token}`)
      .then((res) => {
        this.setState({ successAlert: true, unsubscribeSuccess: true });
        setTimeout(() => {
          this.setState({
            successAlert: false,
          });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ failAlert: true });
        setTimeout(() => {
          this.setState({
            failAlert: false,
          });
        }, 2000);
      });
  };

  handleClose = () => {
    this.setState({ successAlert: false, failAlert: false });
  };

  render() {
    return (
      <React.Fragment>
        <PageHead title={`Unsubscribe | ${Config.DOMAIN_DISPLAY_NAME}`} />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container unsubscribe-page-main"
        >
          <div className="success-error-div">
            <p align="center">
              {this.state.unsubscribeSuccess ? (
                <i className="far fa-check-circle fa-4x" align="center"></i>
              ) : (
                <i className="fas fa-exclamation fa-4x" align="center"></i>
              )}
            </p>

            {this.state.unsubscribeSuccess ? (
              <p className="message">
                {this.props.lng === true
                  ? "Successfully unsubscribed from Poruwa magazine mailing list."
                  : "පෝරුව. lk සඟරාවට අදාල විද්‍යුත් තැපැල් ලැයිස්තුවෙන් ඔබව සාර්ථකව ඉවත් කරන ලදී."}
              </p>
            ) : (
              <p className="message">
                {this.props.lng === true
                  ? "Unsubscribe from Poruwa magazine mailing list?"
                  : "පෝරුව.lk සඟරාවට අදාල විද්‍යුත් තැපැල් ලැයිස්තුවෙන් ඉවත් වන්න?"}
              </p>
            )}

            {!this.state.unsubscribeSuccess ? (
              <p align="center">
                {this.props.lng === true ? (
                  "Click Unsubscribe button if you don't want to receive Poruwa.lk monthly magazine via email."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">
                    පෝරුව.lk මාසික සඟරාව විද්‍යුත් තැපෑලෙන් ලබා ගැනීමට ඔබට
                    අවශ්‍ය නැතිනම් “ඉවත් වන්න“ බොත්තම ඔබන්න.
                  </span>
                )}
              </p>
            ) : null}

            {this.state.unsubscribeSuccess ? (
              <Button
                variant="contained"
                className="all-ads-btn"
                onClick={() => this.props.history.push("/search")}
                style={{ width: 200 }}
              >
                {this.props.lng === true ? (
                  "Go To ALL Ads"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    සියලුම දැන්වීම් වෙත යන්න
                  </span>
                )}
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="all-ads-btn"
                  onClick={this.handleUnsubscribe}
                  style={{ width: 200 }}
                  disabled={this.state.unsubscribeSuccess}
                >
                  {this.props.lng === true ? (
                    "Unsubscribe"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ඉවත් වන්න
                    </span>
                  )}
                </Button>
                <Button
                  variant="contained"
                  className="unsubscribe-cancel-btn"
                  onClick={() => this.props.history.push("/search")}
                  disabled={this.state.unsubscribeSuccess}
                >
                  {this.props.lng === true ? (
                    "Cancel"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      අවලංගු කරන්න
                    </span>
                  )}
                </Button>
              </>
            )}
          </div>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.successAlert}
          onClose={this.handleClose}
        >
          <Alert severity="success">
            {this.props.lng === true ? (
              "You have been unsubscribed successfully"
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">
                ඔබ සාර්ථකව දායකත්වයෙන් ඉවත් වී ඇත
              </span>
            )}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.failAlert}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            {this.props.lng === true ? (
              "Oops, something went wrong."
            ) : (
              <span className="sinhala-w-600 sinhala-size-10">
                ඔබ සාර්ථකව දායකත්වයෙන් ඉවත් වූයේ නැත
              </span>
            )}
          </Alert>
        </Snackbar>

        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(Unsubscribed));
