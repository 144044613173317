import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Footer from "../../../../components/common/Footer";
import FooterMobile from "../../../../components/common/FooterMobile";
import { getReviews } from "../../../features/generic-data";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import withLanguageMigrator from "../../../hoc/language-migrator";
import PackagesView, {
  PRICING_DISPLAY_VARIANTS,
} from "../../../templates/payment-package-view";
import faqSchema from "./schema/faq-schema/index.json";
import FaqSegment from "./templates/faq-segment";
import ReviewComponent from "../../../../components/common/ReviewComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PremiumPackageView from "./templates/premium-package-view";
import "./style.css";

const Pricing = (props) => {
  const [reviews, setReviews] = useState([]);

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const ReviewSlidersettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const reviewsParams = { pageNum: 0, pageSize: 6 };
    try {
      getReviews({ params: reviewsParams }).then((res) => {
        setReviews(res.body?.results ?? []);
      });
    } catch (error) {
      console.log("Error >>>", error);
    }
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ backgroundColor: "#f7f7f7", padding: "20px 10px", rowGap: 20 }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="pricing-page-content-container"
          xs={12}
          sm={12}
          md={8}
        >
          <PackagesView variant={PRICING_DISPLAY_VARIANTS.PRICING} />
          <PremiumPackageView/>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={8}
          direction="column"
          className="pricing-faq-container"
        >
          <Typography variant="h4" align="center">
            {t("supports.faq")}
          </Typography>

          <Grid container direction="row" className="mt-30">
            <Grid item container xs={12} md={6} className="faq-column">
              {faqSchema.leftColumn.map((QuestionItem) => (
                <FaqSegment
                  question={QuestionItem.question}
                  answer={QuestionItem.answer}
                  listItems={QuestionItem.listItems}
                />
              ))}
            </Grid>
            <Grid item container xs={12} md={6} className="faq-column">
              {faqSchema.rightColumn.map((QuestionItem) => (
                <FaqSegment
                  question={QuestionItem.question}
                  answer={QuestionItem.answer}
                  listItems={QuestionItem.listItems}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          className="all-ads-container reviews-slider-grid"
        >
          <Grid
            item
            className="reviews-slider-sub-grid"
            style={{ paddingTop: "10px" }}
          >
            <Slider {...ReviewSlidersettings}>
              {reviews.map((review, i) => (
                <ReviewComponent review={review} lang={props.lng} />
              ))}
            </Slider>
            <Typography
              className="load-all-reviews-btn"
              onClick={() => history.push("/reviews")}
            >
              {t("supports.loadAllReviews")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isMobileView ? (
        <FooterMobile lng={props.lng} />
      ) : (
        <Footer lng={props.lng} />
      )}
    </>
  );
};

export default withLanguageMigrator(Pricing);
