import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IconText from "./icon-text";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import "../style.css";
import PaymentOptions from "./payment-options";
import { PAYMENT_IDS } from "../../../config/constant";

const PackageCard = ({
  id,
  packageKey,
  pricingTier,
  isPreferred,
  packageAmount,
  monthlyAmount,
  currency,
  name,
  duration,
  features = [],
  selectedPackage,
  setSelectedPackage,
}) => {
  const [displayPaymentOptions, setDisplayPaymentOptions] = useState(false);

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { authenticated, initialized, authProfile } = useSelector(
    (state) => state.application
  );

  const onContinuePackage = ({ isRecurringPayment = false }) => {
    localStorage.setItem(PAYMENT_IDS.SELECTED_PACKAGE_ID, id);
    if (initialized && !authenticated) {
      history.push("/auth");
      return;
    }
    if (initialized && authenticated && !isEmpty(authProfile)) {
      history.push({
        pathname: "pay-for-ad",
        search: `?id=${id}`,
        state: { pricingTier, isRecurringPayment },
      });
    } else {
      history.push("/complete-ad-personal");
    }
  };

  const onSelectPackage = () => {
    setSelectedPackage(id);
  };

  const onClickCancelPaymentOptions = () => {
    setDisplayPaymentOptions(false);
    setSelectedPackage("");
    localStorage.removeItem(PAYMENT_IDS.SELECTED_PACKAGE_ID);
  };

  const getpackageAmount = () => {
    return typeof packageAmount === "number"
      ? `${currency} ${packageAmount.toLocaleString(undefined, {
        minimumFractionDigits: packageAmount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
      })}`
      : "";
  };

  const getMonthlyAmount = () => {
    return `${currency} ${t("pricing.perMonth", {
      monthlyAmount: monthlyAmount,
    })}`;
  };

  useEffect(() => {
    if (selectedPackage === id) {
      setDisplayPaymentOptions(true);
    } else {
      setDisplayPaymentOptions(false);
    }
  }, [id, selectedPackage]);

  return (
    <Grid direction="column" className="pricing-package-card" key={packageKey}>
      <Box className={`featured-tag-wrapper ${isPreferred && "featured-tag"}`}>
        {isPreferred && (
          <Typography variant="body2" className="featured-tag-text">
            Best value
          </Typography>
        )}
      </Box>

      <Grid
        direction="column"
        className={`package-content-wrapper content-height-${
          i18n.language
        } ${isPreferred && "package-content-wrapper-preferred"}`}
      >
        <Typography variant="body1" className="package-name">
          {name}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="package-header"
        >
          <Typography className="package-header-txt" variant="body1">
            {t("common.months", { months: duration })}
          </Typography>

          <Grid container xs direction="column" alignItems="flex-end">
            <Typography variant="body1" className="package-header-txt">
              {getpackageAmount()}
            </Typography>
            <Typography variant="caption" className="package-header-txt">
              {getMonthlyAmount()}
            </Typography>
          </Grid>
        </Grid>

        {!displayPaymentOptions && (
          <Grid
            container
            direction="column"
            className={`package-features-wrapper-${i18n.language}`}
          >
            {features.map((element) => (
              <IconText
                text={element.label}
                isHighlight={element.isHighlight}
                transComponent={element.transComponent}
                values={element.values}
              />
            ))}
          </Grid>
        )}

        {displayPaymentOptions && (
          <PaymentOptions
            packageAmount={packageAmount}
            currency={currency}
            onContinuePackage={onContinuePackage}
            onClickCancel={onClickCancelPaymentOptions}
          />
        )}

        {!displayPaymentOptions && (
          <Grid
            container
            direction="column"
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button className="select-package-btn" onClick={onSelectPackage}>
              {t("pricing.selectPackage")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PackageCard;
