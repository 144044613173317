import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import SectionView from "../../section-view";

const InReviewView = () => {
  const { t } = useTranslation();
  return (
    <SectionView title={t("setting.PostUnderReview")}>
      <Grid
        container
        direction="column"
        className="payment-features-inner-view"
      >
        <Typography variant="body2" className="in-review-txt font-weight-600">
          {t("payment.postUnderReview")}
        </Typography>
      </Grid>
    </SectionView>
  );
};

export default InReviewView;
