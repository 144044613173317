import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { ButtonGroup, Button } from "@material-ui/core";
import Config from "../../v2/config";

import { handleIntBtns } from "../../actions/handleIntBtns";
import { removePost } from "../../actions/removePost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Groom from "../../images/groom.png";
import Bride from "../../images/bride.png";
import Pontin from "../../images/user.png";
import AppContext from "../../v2/context";
import Constants from "../../v2/config/constant";

class UserAccountDrawer extends Component {
  state = {
    postDetails: {},
    expiresAt: "",
    publishedAt: "",
    freeRenewEligible: false,
    gender: "PONTIN",
    fName: "",
    email: "",
    postId: "",
    userId: "",
    postStatus: "INCOMPLETE",

    // redirect
    redirectToMyAd: false,
    redirectToEditAd: false,
    redirectMatches: false,
    redirectToPayment: false,
    redirectReview: false,
    firstPublishedAt: false,

    // renew modal
    confirmRenewalModal: false,

    loading: false,
  };
  componentDidMount = () => {
    const postDetails = this.props.authProfile ?? {};
    this.setState({
      postDetails,
      expiresAt: postDetails.expiresAt ?? "",
      publishedAt: postDetails.publishedAt ?? "",
      freeRenewEligible: postDetails?.freeRenewEligible ?? false,
      gender: postDetails?.personalInfo?.gender ?? "PONTIN",
      fName:
        postDetails?.personalInfo?.fName ??
        localStorage.getItem("user_name") ??
        "",
      email: this.props.authAccount?.email ?? "",
      postId: postDetails.id,
      userId: localStorage.getItem("userId"),
      postStatus: postDetails.status ?? "INCOMPLETE",
    });
    // REFACTORATION CODES END

    let url = "";
    const userRole = this.props.authAccount.role ?? "";
    if (userRole === "OPERATOR" || userRole === "OPERATOR_L2") {
      url = `operator/managed_posts/${this.props.authProfile?.id}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role") === "OPERATOR" ||
          localStorage.getItem("user_role") === "OPERATOR_L2"
        ) {
          res.data[0] = res.data;
        }
        if (res.data.length > 0) {
          this.setState({
            expiresAt: res.data[0].expiresAt,
            publishedAt: res.data[0].publishedAt,
            freeRenewEligible: res.data[0].freeRenewEligible,
            freeRenewDone: res.data[0].freeRenewDone,
            gender: res.data[0].personalInfo.gender,
            fName: res.data[0].personalInfo.fname,
            postId: res.data[0].id,
            postStatus: res.data[0].status,
            firstPublishedAt: res.data[0].firstPublishedAt
              ? res.data[0].firstPublishedAt
              : false,
          });
          console.log(this.state);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  redirectToMyAd = () => {
    this.setState({ redirectToMyAd: true });
  };

  redirectToEditAd = () => {
    this.setState({ redirectToEditAd: true });
  };

  redirectMatches = () => {
    this.setState({ redirectMatches: true });
  };

  handleClose = () => {
    this.setState({ confirmRenewalModal: false });
  };

  goToMessages = () => {
    this.props.history.push("/messages");

    this.props.handleIntBtns("match");
    document.getElementById("close-user-drawer-icon").click();
  };

  goToMatches = () => {
    this.props.history.push("/interests-matched");

    this.props.handleIntBtns("match");
  };

  goToReceived = () => {
    this.props.history.push("/interests");

    this.props.handleIntBtns("received");

    this.props.removePost();

    document.getElementById("close-user-drawer-icon").click();
  };

  goToMismatchedInterests = () => {
    this.props.history.push("/preference-mismatch-interests");
    document.getElementById("close-user-drawer-icon").click();
  };

  goToInterests = () => {
    this.props.history.push("/sent-interests");

    this.props.removePost();

    document.getElementById("close-user-drawer-icon").click();
  };

  goToMyAd = () => {
    this.props.history.push("/my-ad");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToEditAd = () => {
    this.props.history.push("/edit-my-ad");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToMagazine = () => {
    this.props.history.push("/magazine");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToBlog = () => {
    window.location.href = "/blog";

    document.getElementById("close-user-drawer-icon").click();
  };

  goToHelp = () => {
    window.location.href = "/help";

    document.getElementById("close-user-drawer-icon").click();
  };

  goToReviews = () => {
    this.props.history.push("/reviews");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToPartnerPref = () => {
    this.props.history.push("/interest-preferences");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToSettings = () => {
    this.props.history.push("/settings");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToCompleteYourAd = () => {
    this.props.history.push("/complete-your-ad");

    document.getElementById("close-user-drawer-icon").click();
  };

  goToManagePosts = () => {
    this.props.history.push("/manage-posts");

    document.getElementById("close-user-drawer-icon").click();
  };

  render() {
    return (
      <AppContext.Consumer>
        {({ setAppLanguage, handleLng }) => (
          <div className="user-account">
            {this.state.redirectToMyAd ? <Redirect to="/my-ad" /> : null}
            {this.state.redirectToEditAd ? <Redirect to="/edit-my-ad" /> : null}
            {this.state.redirectMatches ? (
              <Redirect to="/my-matches?page=matched" />
            ) : null}
            {this.state.redirectReview ? (
              <Redirect to="/complete-ad-review" />
            ) : null}
            {this.state.redirectToPayment ? (
              <Redirect to="/pay-for-ad" />
            ) : null}

            <div className="dark-div">
              <i
                id="close-user-drawer-icon"
                className="far fa-times-circle close-profile"
                onClick={this.props.toggleDrawer(false)}
              ></i>

              {this.state.userId && (
                <p className="ad-id">
                  <span>Ad ID: </span>
                  {this.state.userId}
                </p>
              )}

              {this.state.gender === "MALE" ? (
                <img
                  src={Groom}
                  alt="groom"
                  className="profile-img"
                  onClick={() => {
                    this.props.toggleMenuDrawer({ rightDrawer: false });
                    this.props.history.push("/edit-my-ad");
                  }}
                />
              ) : this.state.gender === "FEMALE" ? (
                <img
                  src={Bride}
                  alt="bride"
                  className="profile-img"
                  onClick={() => {
                    this.props.toggleMenuDrawer({ rightDrawer: false });
                    this.props.history.push("/edit-my-ad");
                  }}
                />
              ) : (
                <img
                  src={Pontin}
                  alt="No Gender"
                  className="profile-img"
                  style={{ width: 60, height: 60, border: 0 }}
                />
              )}
              <h3 className="name">{this.state.fName}</h3>
              {this.state.email && <p className="email">{this.state.email}</p>}
              <p className="status">
                {this.props.lng ? (
                  "Ad Status"
                ) : (
                  <span className="sinhala-size-10">දැන්වීම තත්ත්වය</span>
                )}
              </p>

              {this.state.postStatus === "LIVE" ? (
                <p className="live">
                  {this.props.lng ? (
                    "Live"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">සජීවි</span>
                  )}{" "}
                </p>
              ) : this.state.postStatus === "PAYMENT_PENDING" ? (
                <p className="pending-payment">
                  {this.props.lng ? (
                    "Payment Pending"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ගෙවීම් අපේක්ෂිතය
                    </span>
                  )}
                </p>
              ) : this.state.postStatus === "IN_REVIEW" ? (
                <p className="in-review">
                  {this.props.lng ? (
                    "In Review"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      සමාලෝචනය කරමින්
                    </span>
                  )}
                </p>
              ) : this.state.postStatus === "DISABLED" ? (
                <p className="unpublished">
                  {this.props.lng ? (
                    "Unpublished"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ඉවත් කර ඇත
                    </span>
                  )}
                </p>
              ) : this.state.postStatus === "EXPIRED" ? (
                <p className="expired">
                  {this.props.lng ? (
                    "Expired"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      කල් ඉකුත් වී ඇත
                    </span>
                  )}
                </p>
              ) : (
                <p className="incomplete">
                  {this.props.lng ? (
                    "Incomplete"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      අසම්පූර්ණයි
                    </span>
                  )}
                </p>
              )}

              {this.state.postStatus === "LIVE" && (
                <React.Fragment>
                  <p className="dates" style={{ marginTop: 20 }}>
                    <span>
                      {this.props.lng ? (
                        "Published on "
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          ප්‍රකාශිත දිනය
                        </span>
                      )}{" "}
                      {moment
                        .utc(this.state.publishedAt)
                        .local()
                        .format("MMM DD YYYY")}
                    </span>
                  </p>
                  <p className="dates">
                    <span>
                      {this.props.lng ? (
                        "Expiring on "
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          කල් ඉකුත් වන දිනය
                        </span>
                      )}{" "}
                      {moment
                        .utc(moment.utc(this.state.expiresAt))
                        .local()
                        .format("MMM DD YYYY")}{" "}
                    </span>
                    {moment
                      .utc(moment.utc(this.state.expiresAt))
                      .local()
                      .format() > moment().format() ? (
                      <span style={{ color: "#FFC107" }}>
                        (
                        {moment
                          .utc(moment.utc(this.state.expiresAt))
                          .local()
                          .fromNow()}
                        )
                      </span>
                    ) : null}
                  </p>
                </React.Fragment>
              )}
            </div>

            {this.state.postStatus === "PAYMENT_PENDING" ? (
              <Link
                to="/pay-for-ad"
                style={{ textDecoration: "none" }}
                onClick={() =>
                  this.props.toggleMenuDrawer({ rightDrawer: false })
                }
              >
                <Button
                  className="renew-btn"
                  variant="contained"
                  style={{ width: 200 }}
                >
                  {this.props.lng ? (
                    "Pay Now"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ගෙවන්න
                    </span>
                  )}
                </Button>
              </Link>
            ) : null}

            {(this.state.postStatus === "LIVE" ||
              this.state.postStatus === "EXPIRED") &&
            moment
              .utc(moment.utc(this.state.expiresAt).subtract(7, "days"))
              .local()
              .format() < moment().format() &&
            moment().format() <
              moment
                .utc(moment.utc(this.state.expiresAt).add(7, "days"))
                .local()
                .format() ? (
              <p className="free-newal-left">
                <i
                  className="fas fa-info-circle"
                  style={{ verticalAlign: "top", paddingTop: 2 }}
                ></i>{" "}
                &nbsp;
                {this.props.lng ? (
                  <span
                    style={{
                      display: "inline-block",
                      width: "90%",
                    }}
                  >{`20% discount if you renew before ${moment
                    .utc(this.state.expiresAt)
                    .add(7, "days")
                    .local()
                    .format("Do MMM")}`}</span>
                ) : (
                  <span
                    className="sinhala-w-600 sinhala-size-12"
                    style={{ display: "inline-block", width: "90%" }}
                  >
                    {moment
                      .utc(this.state.expiresAt)
                      .add(7, "days")
                      .local()
                      .format("Do MMM")}{" "}
                    ට පෙර අලුත් කළහොත් 20% වට්ටමක්
                  </span>
                )}
              </p>
            ) : null}

            {(this.state.postStatus === "LIVE" ||
              this.state.postStatus === "EXPIRED") &&
            moment
              .utc(moment.utc(this.state.expiresAt).subtract(7, "days"))
              .local()
              .format() < moment().format() ? (
              <Button
                className="renew-btn"
                variant="contained"
                style={{ width: 150 }}
                onClick={() => {
                  this.props.history.push("/pay-for-ad");
                  this.props.toggleMenuDrawer({ rightDrawer: false });
                }}
              >
                {this.props.lng ? (
                  "Renew"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    අලුත් කරන්න
                  </span>
                )}
              </Button>
            ) : null}

            {this.state.postStatus === "INCOMPLETE" ? (
              <Button
                className="renew-btn"
                variant="contained"
                // onClick={() => this.setState({ redirectReview: true })}
                onClick={() => {
                  this.props.history.push("/complete-ad-personal");
                  this.props.toggleMenuDrawer({ rightDrawer: false });
                }}
                style={{ width: 200 }}
              >
                {this.props.lng ? (
                  "Complete Ad"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    දැන්වීම සම්පූර්ණ කරන්න
                  </span>
                )}
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                  onClick={
                    this.props.history.location.pathname === "/complete-your-ad"
                      ? this.props.toggleDrawer(false)
                      : this.goToCompleteYourAd
                  }
                >
                  {this.props.lng ? (
                    "Attract More Responses"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      දැන්වීම ආකර්ෂණීය කරන්න
                    </span>
                  )}
                  <i
                    class="fa fa-circle"
                    aria-hidden="true"
                    onClick={() => this.props.history.push("/complete-your-ad")}
                    style={{
                      color: "#eb5757",
                      cursor: "pointer",
                      fontSize: "9px",
                      position: "absolute",
                    }}
                  ></i>
                </Button>
                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                  onClick={
                    this.props.history.location.pathname ===
                    "/interests-matched"
                      ? this.props.toggleDrawer(false)
                      : this.goToMessages
                  }
                >
                  {this.props.lng ? (
                    "Matches"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      ගැලපිම්
                    </span>
                  )}
                </Button>
                {/* <Button
              className="my-ad-btn"
              style={{ marginTop: 0 }}
              onClick={
                this.props.history.location.pathname === "/interests-matched"
                  ? this.props.toggleDrawer(false)
                  : this.goToMatches
              }
            >
              {this.props.lng ? (
                "Matches"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">ගැලපිම්</span>
              )}
            </Button> */}

                {/* <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0 }}
                  onClick={
                    this.props.isReceivedActive
                      ? this.props.toggleDrawer(false)
                      : this.goToReceived
                  }
                >
                  {this.props.lng ? (
                    "Interests Received"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      කැමැත්ත එවූ අය
                    </span>
                  )}
                </Button>

                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                  onClick={
                    this.props.history.location.pathname === "/sent-interests"
                      ? this.props.toggleDrawer(false)
                      : this.goToInterests
                  }
                >
                  {this.props.lng ? (
                    "Interests Sent"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      යවන ලද කැමැත්තන්
                    </span>
                  )}
                </Button> */}

                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0 }}
                  onClick={
                    this.props.history.location.pathname === "/my-ad-btn"
                      ? this.props.toggleDrawer(false)
                      : this.goToMyAd
                  }
                >
                  {this.props.lng ? (
                    "View My Ad"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      මගේ දැන්වීම බලන්න
                    </span>
                  )}
                </Button>

                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                  onClick={
                    this.props.history.location.pathname === "/edit-my-ad"
                      ? this.props.toggleDrawer(false)
                      : this.goToEditAd
                  }
                >
                  {this.props.lng ? (
                    "Edit My Ad"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      මගේ දැන්වීම වෙනස් කරන්න
                    </span>
                  )}
                </Button>
                <div style={{ position: "relative" }}>
                  <Button
                    className="my-ad-btn"
                    style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                    onClick={
                      this.props.history.location.pathname ===
                      "/interest-preferences"
                        ? this.props.toggleDrawer(false)
                        : this.goToPartnerPref
                    }
                  >
                    {this.props.lng ? (
                      "Interest preferences"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        කැමැත්තන් පිළිගැනීමේ නිර්ණායක
                      </span>
                    )}
                    {/* <span
                  style={{
                    background: "#f1c71e",
                    fontSize: 10,
                    padding: "2px 7px",
                    fontWeight: 400,
                    borderRadius: 30,
                    marginLeft: 5,
                  }}
                >
                  New
                </span> */}
                  </Button>
                </div>

                {(localStorage.getItem("user_role") === "OPERATOR" ||
                  localStorage.getItem("user_role") === "OPERATOR_L2") && (
                  <Button
                    className="my-ad-btn"
                    style={{ marginTop: 0, borderBottom: "1px solid #ccc" }}
                    onClick={
                      this.props.history.location.pathname === "/manage-posts"
                        ? this.props.toggleDrawer(false)
                        : this.goToManagePosts
                    }
                  >
                    {this.props.lng ? (
                      "Manage Posts"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        Manage Posts
                      </span>
                    )}
                  </Button>
                )}

                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0 }}
                  onClick={this.goToMismatchedInterests}
                >
                  {this.props.lng ? (
                    "Preference Mismatched Interests"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      පිළිගැනීමේ නිර්ණායක නොගැලපෙන කැමැත්තන්
                    </span>
                  )}
                </Button>

                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0 }}
                  onClick={
                    this.props.history.location.pathname === "/settings"
                      ? this.props.toggleDrawer(false)
                      : this.goToSettings
                  }
                >
                  {this.props.lng ? (
                    "Settings"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      සැකසුම්
                    </span>
                  )}
                </Button>
                {Config.MAGAZINE_VISIBILITY && (
                  <Button
                    className="my-ad-btn"
                    style={{ marginTop: 0, borderTop: "1px solid #ccc" }}
                    onClick={
                      this.props.history.location.pathname === "/magazine"
                        ? this.props.toggleDrawer(false)
                        : this.goToMagazine
                    }
                  >
                    {this.props.lng ? (
                      "Monthly Magazine"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        මාසික සඟරාව
                      </span>
                    )}
                  </Button>
                )}

                {Config.BLOG_PAGE_VISIBILITY && (
                  <Button
                    className="my-ad-btn"
                    style={{ marginTop: 0 }}
                    onClick={
                      this.props.history.location.pathname === "/blog"
                        ? this.props.toggleDrawer(false)
                        : this.goToBlog
                    }
                  >
                    {this.props.lng ? (
                      "Blog"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        බ්ලොග් පිටුව
                      </span>
                    )}
                  </Button>
                )}

                {/* <Button
              className="my-ad-btn"
              style={{ marginTop: 0 }}
              onClick={
                this.props.history.location.pathname === "/reviews"
                  ? this.props.toggleDrawer(false)
                  : this.goToReviews
              }
            >
              {this.props.lng ? (
                "Reviews"
              ) : (
                <span className="sinhala-w-600 sinhala-size-10">
                  නිර්දේශ කිරීම්
                </span>
              )}
            </Button> */}
                <Button
                  className="my-ad-btn"
                  style={{ marginTop: 0 }}
                  onClick={
                    this.props.history.location.pathname === "/help"
                      ? this.props.toggleDrawer(false)
                      : this.goToHelp
                  }
                >
                  {this.props.lng ? (
                    "Help"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      උපදෙස් සහ සහාය
                    </span>
                  )}
                </Button>
                {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
                  <Button
                    className="my-ad-btn"
                    style={{ marginTop: 0 }}
                    onClick={
                      this.props.history.location.pathname === "/reviews"
                        ? this.props.toggleDrawer(false)
                        : this.goToReviews
                    }
                  >
                    {this.props.lng ? (
                      "Reviews and Ratings"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-10">
                        නිර්දේශ කිරීම් සහ ශ්‍රේණිගත කිරීම්
                      </span>
                    )}
                  </Button>
                )}
              </React.Fragment>
            )}

            {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
              <ButtonGroup
                color="primary"
                size="small"
                aria-label="small outlined button group"
                className="lng-selection-drawer"
              >
                <Button
                  className={this.props.lng ? "active-lng" : ""}
                  onClick={() => {
                    this.props.handleLng(true);
                    setAppLanguage(Constants.APP_LANG.EN);
                  }}
                >
                  English
                </Button>
                <Button
                  className={this.props.lng === false ? "active-lng" : ""}
                  onClick={() => {
                    this.props.handleLng(false);
                    setAppLanguage(Constants.APP_LANG.SI);
                  }}
                >
                  සිංහල
                </Button>
              </ButtonGroup>
            )}

            <div
              style={{
                width: 300,
                background: "#fff",
                height: 35,
                position: "fixed",
                bottom: 0,
              }}
            >
              <Button className="logout-btn" onClick={this.props.logout}>
                <i className="fas fa-sign-out-alt"></i> &nbsp;{" "}
                {this.props.lng ? (
                  "Sign Out"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    ඉවත්වන්න
                  </span>
                )}
              </Button>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ handleIntBtns, removePost }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    authAccount: state.application.authAccount,
    authProfile: state.application.authProfile,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(UserAccountDrawer));
