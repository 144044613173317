import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { scrollToTop } from "../../../../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../style.css";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  HttpCode,
  PROFILE_STATUS,
  SUPPORTED_CURRENCIES,
} from "../../../../config/constant";
import { createPayementSession } from "../../../../features/payment";
import { formatPaymentData } from "./extension";
import PromoCodeSection from "./templates/promo-code-section";
import PaymentDiscount from "./templates/discount-section";
import { useTranslation } from "react-i18next";
import Config from "../../../../config";
import FormCheckbox from "../../../../components/molecules/checkbox";
import InvoiceHeader from "./templates/invoice-header";

const { DOMAIN_DISPLAY_NAME, TWO_THREE_MONTH_PACKAGES_PROMOS } = Config;

const renewalProfileStatus = [PROFILE_STATUS.EXPIRED, PROFILE_STATUS.LIVE];

const PaymentInvoice = ({
  packageId,
  selectedCurrency,
  paymentSessionData,
  setPaymentSessionData,
  setPaymentErrors,
  paymentAgreement,
  SetPaymentAgreement,
  isFetchingData,
  setIsFetchingData,
  isRecurringPayment,
}) => {
  const [promoCodeError, setPromoCodeError] = useState("");

  const history = useHistory();
  const { t } = useTranslation();
  const { authProfile } = useSelector((state) => state.application);

  const handlePaymentSession = useCallback(
    async ({ promo = null }) => {
      if (isEmpty(authProfile)) {
        return;
      }

      const postId = authProfile.id;
      const isRenewAccount = renewalProfileStatus.includes(authProfile.status);

      let data = {
        ...(promo && { promoCode: promo.trim() }),
        ...(isRecurringPayment && { isRecurring: isRecurringPayment }),
        isRenew: isRenewAccount,
        forceLKR: selectedCurrency !== SUPPORTED_CURRENCIES.USD,
        pricingTierId: packageId,
        simulate: true,
      };

      try {
        setIsFetchingData(true);
        const response = await createPayementSession({
          postId,
          data,
        });

        if (!response.success) {
          if (response.statusCode === HttpCode.BAD_REQUEST) {
            const errorCode = response.body?.code;
            switch (errorCode) {
              case 1400:
              case 1401:
              case 1402:
              case 1403:
              case 1311:
              case 1312:
                setPromoCodeError(t(`serverError.${errorCode}`));
                break;
              case 1404:
                if (TWO_THREE_MONTH_PACKAGES_PROMOS.includes(promo)) {
                  setPromoCodeError(
                    t("payment.promoCodeValidForTwoAndThreeMonthsPackages")
                  );
                } else {
                  setPromoCodeError(t(`serverError.${errorCode}`));
                }
                break;
              case 1504:
              case 1512:
                setPaymentErrors({
                  checkoutError: false,
                  renewalError: true,
                });
                break;
              default:
                setPaymentErrors({
                  checkoutError: true,
                  renewalError: false,
                });
                break;
            }
          }
        }

        if (response.success) {
          const sessionData = formatPaymentData({
            sessionData: response.body,
            packageId,
          });
          setPaymentSessionData(sessionData);
          localStorage.setItem(
            "session_creation",
            JSON.stringify(response.body)
          );
          if (response.body.promoCode) {
            localStorage.setItem("promoCode", response.body.promoCode);
          } else {
            localStorage.removeItem("promoCode");
          }
        }
      } catch (error) {
        console.log(">>>> Error", error);
        setPaymentErrors({
          checkoutError: true,
          renewalError: false,
        });
      } finally {
        setIsFetchingData(false);
      }
    },
    [
      authProfile,
      packageId,
      selectedCurrency,
      setPaymentSessionData,
      t,
      setPaymentErrors,
      setIsFetchingData,
      isRecurringPayment,
    ]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    handlePaymentSession({});
  }, [handlePaymentSession]);

  return (
    <Grid
      container
      direction="column"
      className="payment-invoice-container"
      style={{ rowGap: 20 }}
    >
      <Grid container>
        <Typography
          variant="body2"
          className="pricing-options-link"
          onClick={() => history.push("/pay-for-ad")}
        >
          <ArrowBackIcon style={{ width: 20 }} />
          {t("payment.backToPricing")}
        </Typography>
      </Grid>

      <InvoiceHeader paymentSessionData={paymentSessionData} />

      <Grid container direction="column" className="invoice-content-wrapper">
        {paymentSessionData.discountPercentage &&
        !paymentSessionData.promoCode ? (
          <PaymentDiscount
            discountPercentage={paymentSessionData.discountPercentage}
            discountAmount={paymentSessionData.discountAmount}
            isFetchingData={isFetchingData}
            paymentSessionData={paymentSessionData}
          />
        ) : null}

        {!isRecurringPayment && (
          <PromoCodeSection
            paymentSessionData={paymentSessionData}
            handlePaymentSession={handlePaymentSession}
            promoCodeError={promoCodeError}
            setPromoCodeError={setPromoCodeError}
            isFetchingData={isFetchingData}
          />
        )}

        {paymentSessionData.discountPercentage &&
        paymentSessionData.promoCode ? (
          <PaymentDiscount
            discountPercentage={paymentSessionData.discountPercentage}
            discountAmount={paymentSessionData.discountAmount}
            isFetchingData={isFetchingData}
            paymentSessionData={paymentSessionData}
          />
        ) : null}

        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{t("payment.totalDue")}</Typography>
          {isFetchingData ? (
            <CircularProgress size={15} style={{ color: "#cca326" }} />
          ) : (
            <Typography>{paymentSessionData.amount}</Typography>
          )}
        </Grid>
        <Divider />
        <FormCheckbox
          value={paymentAgreement?.agreeToTerms}
          handleChange={(e) => {
            SetPaymentAgreement({
              agreeToTerms: e.target.checked,
              termsError: false,
            });
          }}
          label={t("payment.agreeToTerms", {
            domainName: DOMAIN_DISPLAY_NAME,
          })}
          containerProps={{
            ...(paymentAgreement?.termsError && { border: "1px solid red" }),
            marginTop: 5,
          }}
          labelProps={{ fontWeight: 400, color: "#000" }}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentInvoice;
