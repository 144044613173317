import Config from "./v2/config";
import PageConfigContainer from "./v2/config/page-configs";
import Routes from "./v2/config/page-configs/routes";

export default () => {
  // PageConfig: Privacy Page
  PageConfigContainer.addConfig({
    pathname: Routes.privacy,
    title: `Privacy | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "100% secured and privacy-focused Sri Lankan mangala yojana ads. Poruwa.lk connects professionals in Sri Lanka and abroad with privacy and data security."
        : undefined,
  });
  // PageConfig: Pricing Page
  PageConfigContainer.addConfig({
    pathname: Routes.pricing,
    title: `Pricing | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Explore affordable pricing on Poruwa.lk, Sri Lanka's trusted mangala yojana platform. Connect globally for Sri Lankan matrimony needs with privacy and security."
        : undefined,
  });
  // PageConfig: Reviews Page
  PageConfigContainer.addConfig({
    pathname: Routes.reviews,
    title: `Reviews | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Read reviews of Poruwa.lk, Sri Lanka's top mangala yojana platform for matrimony Sri Lanka. Safely connect brides, grooms, and families globally for a successful journey"
        : undefined,
  });
  // PageConfig: Magazine Page
  PageConfigContainer.addConfig({
    pathname: Routes.magazine,
    title: `Magazines | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find your Sri Lankan match on Poruwa.lk. Explore expert advice, Mangala Yojana tips, and success stories. Privacy-focused for your peace of mind."
        : undefined,
  });
  // PageConfig: FAQ Page
  PageConfigContainer.addConfig({
    pathname: Routes.faq,
    title: `Insights | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find Sri Lankan matches on Poruwa.lk! Get Mangala Yojana help, FAQs, & privacy info. Secure & successful matrimony journey."
        : undefined,
  });
  // PageConfig: Help Page
  PageConfigContainer.addConfig({
    pathname: Routes.help,
    title: `Help | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find your Sri Lankan match with Poruwa.lk! Get expert guidance, Mangala Yojana help, & secure your happily ever after. Trusted Sri Lankan matrimony."
        : undefined,
  });
  // PageConfig: Terms Page
  PageConfigContainer.addConfig({
    pathname: Routes.terms,
    title: `Terms | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Secure your Sri Lankan matrimony journey on Poruwa.lk. Understand our terms & conditions for a private & safe Mangala Yojana experience."
        : undefined,
  });
};
