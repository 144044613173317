import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import RePlayIcon from "@material-ui/icons/Replay";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PaymentError = ({
  paymentErrors,
  setPaymentErrors,
  setSelectedPackage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ padding: 20, rowGap: 20 }}
    >
      {paymentErrors.checkoutError ? (
        <Typography>{t("payment.somethingWentWrong")}</Typography>
      ) : paymentErrors.renewalError ? (
        <Typography>{t("payment.postNotWithinRenewalPeriod")}</Typography>
      ) : (
        <></>
      )}
      {paymentErrors.checkoutError && (
        <Button
          startIcon={<RePlayIcon />}
          onClick={() => {
            setPaymentErrors({
              checkoutError: false,
              renewalError: false,
            });
            setSelectedPackage(null);
            history.push("/pay-for-ad");
          }}
          className="secondary-button margin-top-16 margin-bottom-16"
        >
          {t("payment.tryAgain")}
        </Button>
      )}

      {paymentErrors.renewalError && (
        <Button
          variant="contained"
          className="secondary-button margin-top-16 margin-bottom-16"
          startIcon={<HomeIcon />}
          onClick={() => history.push("/")}
        >
          {t("payment.goToHome")}
        </Button>
      )}
    </Grid>
  );
};

export default PaymentError;
