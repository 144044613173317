import React from "react";
import { Grid, Typography, Select } from "@material-ui/core";
import "./style.css";

import { APP_LANG } from "../../../utils/locale-handler";
import FormInteractiveButton from "../form-interactive-button";
import FormItemLabel from "../../atoms/form-item-label";

const hiddenOption = {
  label_en: "-- Select --",
  label_si: "-- තෝරන්න --",
};

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

/**
 * options schema
 *  { value, sortOrder, label_en, label_si  }
 */
const DEFAULT_KEY_EXTRACTOR = "value";
const DEFAULT_LABEL_EXTRACTOR = "label";

const FormDropdown = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  onClickInteractiveClear,
  fieldName,
  value,
  onChange,
  options,
  label,
  labelPosition = LABEL_POSITION.left,
  lang,
  labelStyles = {},
  containerStyles = {},
  keyExtractor = DEFAULT_KEY_EXTRACTOR,
  labelExtractor = DEFAULT_LABEL_EXTRACTOR,
  igonoreLocaleLabels = false,
  visibility = true,
  disabled = false,
  errorText = "",
  isOptional = false,
  selectLabel = "",
}) => {
  let computedLabelExtractor = labelExtractor;
  if (!Boolean(igonoreLocaleLabels) && lang !== APP_LANG.EN) {
    computedLabelExtractor = `${labelExtractor}_${lang}`;
  }

  if (!Boolean(visibility)) {
    return false;
  }

  return (
    <Grid
      container
      direction="row"
      className="form-text-input"
      alignItems="center"
      style={containerStyles}
    >
      {label && (
        <FormItemLabel
          text={label}
          lang={lang}
          labelPosition={labelPosition}
          styleOverrides={labelStyles}
        />
      )}
      <Grid item xs>
        <Select
          name={fieldName}
          value={value}
          onChange={(e) => onChange(e)}
          variant="outlined"
          fullWidth
          native
          inputProps={{
            className: `form-dropdown-select-text-${lang}`,
          }}
          disabled={disabled}
          error={Boolean(errorText)}
        >
          <option selected hidden={!isOptional} value="" style={{ fontStyle: "italic", color: "gray" }}>
            {selectLabel || hiddenOption[`label_${lang ?? APP_LANG.EN}`]}
          </option>
          {Array.isArray(options) &&
            options.map((item) => (
              <option
                className={`form-dropdown-select-text-${
                  igonoreLocaleLabels ? "" : lang
                }`}
                key={item[keyExtractor]}
                value={item[keyExtractor]}
              >
                {item[computedLabelExtractor]}
              </option>
            ))}
        </Select>
        <Typography
          variant="caption"
          className={`form-error-text form-error-text-${lang}`}
        >
          {errorText}
        </Typography>
      </Grid>
      {mode === Mode.interactive && showInteractiveActions && (
        <FormInteractiveButton
          onClickInteractiveClear={onClickInteractiveClear}
          onClickInteractiveSave={onClickInteractiveSave}
        />
      )}
    </Grid>
  );
};

export default FormDropdown;
