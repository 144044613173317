import { useState, useEffect } from "react";
import { uniqBy } from "lodash";
import Config from "../config";
import store from "../../store";
import { sanitizeWhiteSpaces } from "../utils";

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export const useViewportWidth = () => {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined
  );

  //👇
  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowSize(window.innerWidth);
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener("resize", setSize);

      //un-register the listener
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);
  //☝️

  return windowSize;
};

export const useFilteredProfessions = ({ needle }) => {
  const {
    EXCLUDED_SELF_PROFESSION_IDS,
    OTHER_PROFESSION_ID,
    CURRENT_PLATFORM,
    PLATFORMS,
    APP_LANGS,
  } = Config;
  const options = store.getState().genericData.professions;
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (!needle) {
      setFilteredOptions([]);
      return;
    }
    let filteredOptions = options.filter(
      (current) =>
        !EXCLUDED_SELF_PROFESSION_IDS.includes(current.id) &&
        current.id !== OTHER_PROFESSION_ID
    );
    if (typeof needle !== "string") {
      setFilteredOptions(filteredOptions);
      return;
    }
    const comparator = sanitizeWhiteSpaces(needle).toLowerCase();
    const labelExtractor = "name";
    const filteredOptionsEN = filteredOptions.filter((current) => {
      const label = current[labelExtractor].toLowerCase();
      return label.includes(comparator);
    });
    if (CURRENT_PLATFORM === PLATFORMS.LK) {
      const filteredOptionSI = filteredOptions.filter((current) => {
        const label = current[`${labelExtractor}_${APP_LANGS.SI}`];
        return label.includes(comparator);
      });
      const uniqeItems = uniqBy(
        [...filteredOptionsEN, ...filteredOptionSI],
        "id"
      );
      setFilteredOptions(uniqeItems);
      return;
    }
    setFilteredOptions(filteredOptionsEN);
  }, [
    APP_LANGS.SI,
    CURRENT_PLATFORM,
    EXCLUDED_SELF_PROFESSION_IDS,
    OTHER_PROFESSION_ID,
    PLATFORMS.LK,
    needle,
    options,
  ]);

  return filteredOptions;
};
