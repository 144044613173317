import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import ageIcon from "../../../../images/age.png";
import ethnicityIcon from "../../../../images/ethnicity.png";
import casteIcon from "../../../../images/caste.png";
import heightIcon from "../../../../images/height.png";
import religionIcon from "../../../../images/religion.png";
import jobIcon from "../../../../images/job.png";
import civilStatusIcon from "../../../../images/civil-status.png";

import "./style.css";
import IconizedText from "./iconized-text";
import { buildModelByFormMeta, fieldVisibility } from "../../../utils";
import ModelMeta from "../../../meta/home-post-card.json";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Config from "../../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FieldBox from "./field-box";
import {
  centimertsIntoFeetInches,
  getLocaleLabelByFieldMeta,
} from "../../../utils/data-generator";
import CardHeader from "./card-header";
import CardFooter from "./card-footer";

const LAST_SEEN_PROFILE_ID = "lastSeenProfileId";

const AdvertisementCard = ({
  advertisment = {},
  isFeatured = false,
  position,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const model = useMemo(() => {
    const model = buildModelByFormMeta(ModelMeta);
    if (!isEmpty(advertisment)) {
      if (fieldVisibility(ModelMeta.age)) {
        model[ModelMeta.age.fieldName] =
          t("createflow.ageDisplayFormat", {
            age: advertisment.personalInfo?.age,
          }) ?? "";
      }

      if (fieldVisibility(ModelMeta.ethnicity)) {
        model[ModelMeta.ethnicity.fieldName] = getLocaleLabelByFieldMeta({
          fieldMeta: ModelMeta.ethnicity,
          input: advertisment.personalInfo?.ethnicity,
          language: i18n.language,
        });
      }

      if (fieldVisibility(ModelMeta.caste)) {
        model[ModelMeta.caste.fieldName] =
          advertisment.personalInfo?.caste ?? "";
      }

      if (fieldVisibility(ModelMeta.height)) {
        model[ModelMeta.height.fieldName] = centimertsIntoFeetInches(
          advertisment.personalInfo?.height ?? ""
        );
      }

      if (fieldVisibility(ModelMeta.religion)) {
        model[ModelMeta.religion.fieldName] = getLocaleLabelByFieldMeta({
          fieldMeta: ModelMeta.religion,
          input: advertisment.personalInfo?.religion,
          language: i18n.language,
        });
      }

      if (fieldVisibility(ModelMeta.profession)) {
        if (advertisment.personalInfo?.profession?.name === "Other") {
          model[ModelMeta.profession.fieldName] =
            advertisment.personalInfo?.professionOther ?? "";
        } else {
          model[ModelMeta.profession.fieldName] = getLocaleLabelByFieldMeta({
            fieldMeta: ModelMeta.profession,
            input: advertisment.personalInfo?.profession,
            language: i18n.language,
          });
        }
      }

      if (fieldVisibility(ModelMeta.civilStatus)) {
        if (advertisment.personalInfo?.civilStatus) {
          model[ModelMeta.civilStatus.fieldName] =
            advertisment.personalInfo?.civilStatus?.status || "";
        }
      }

      if (fieldVisibility(ModelMeta.community)) {
        if (advertisment.personalInfo?.community) {
          model[ModelMeta.community.fieldName] = getLocaleLabelByFieldMeta({
            fieldMeta: ModelMeta.community,
            input: advertisment.personalInfo?.community,
            language: i18n.language,
          });
        }
      }
    }
    return model;
  }, [advertisment, i18n.language, t]);

  const handleOnAdvertisementClick = () => {
    if (!isFeatured) {
      sessionStorage.setItem(LAST_SEEN_PROFILE_ID, advertisment.id);
    }
    history.push(`/post?id=${advertisment.id}`);
  };

  useEffect(() => {
    const lastSeenProfileId = sessionStorage.getItem(LAST_SEEN_PROFILE_ID);
    if (!isEmpty(lastSeenProfileId) && lastSeenProfileId === advertisment.id) {
      const element = document.getElementById(lastSeenProfileId);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      sessionStorage.removeItem(LAST_SEEN_PROFILE_ID);
    }
  }, [advertisment.id]);

  return (
    <Grid
      container
      direction="column"
      className="home-advertisement-wrapper"
      id={advertisment.id}
    >
      {isFeatured && (
        <Box className="top-ad-wrapper">
          <Typography className="top-ad-txt" title="Top Ad">
            Top ad
          </Typography>
        </Box>
      )}
      <a
        className="anchor-link"
        href={`/post?id=${advertisment.id}`}
        onClick={(event) => {
          event.preventDefault();
          handleOnAdvertisementClick();
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          className={`home-advertisement-container ${
            isFeatured && `home-advertisement-container-featured`
          }`}
        >
          {advertisment.isSuggested && (
            <Box className="suggested-ad-wrapper">
              <Typography className="suggested-ad-txt">
                Suggested for you
              </Typography>
            </Box>
          )}
          <Grid container direction="row" style={{ marginTop: 0 }}>
            <CardHeader advertisment={advertisment} />

            {/* Card Content */}
            <Grid
              container
              direction="row"
              className={`content-container ${
                isFeatured && `content-container-featured`
              } row-gap-8`}
            >
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK ? (
                <FieldBox
                  desktopViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.ethnicity.fieldName,
                    ModelMeta.caste.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                  mobileViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.ethnicity.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.profession.fieldName,
                    ModelMeta.caste.fieldName,
                  ]}
                >
                  <IconizedText
                    icon={ageIcon}
                    fieldName={ModelMeta.age.fieldName}
                    text={model[ModelMeta.age.fieldName]}
                    tooltip={t(ModelMeta.age.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.age) &&
                      (model[ModelMeta.age.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={ethnicityIcon}
                    fieldName={ModelMeta.ethnicity.fieldName}
                    text={model[ModelMeta.ethnicity.fieldName]}
                    tooltip={t(ModelMeta.ethnicity.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.ethnicity) &&
                      (model[ModelMeta.ethnicity.fieldName] ||
                        !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={casteIcon}
                    fieldName={ModelMeta.caste.fieldName}
                    text={model[ModelMeta.caste.fieldName]}
                    tooltip={t(ModelMeta.caste.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.caste) &&
                      (model[ModelMeta.caste.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={heightIcon}
                    fieldName={ModelMeta.height.fieldName}
                    text={model[ModelMeta.height.fieldName]}
                    tooltip={t(ModelMeta.height.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.height) &&
                      (model[ModelMeta.height.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={religionIcon}
                    fieldName={ModelMeta.religion.fieldName}
                    text={model[ModelMeta.religion.fieldName]}
                    tooltip={t(ModelMeta.religion.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.religion) &&
                      (model[ModelMeta.religion.fieldName] || isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={jobIcon}
                    fieldName={ModelMeta.profession.fieldName}
                    text={model[ModelMeta.profession.fieldName]}
                    tooltip={t(ModelMeta.profession.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.profession) &&
                      (model[ModelMeta.profession.fieldName] ||
                        isMobileViewport)
                    }
                  />
                </FieldBox>
              ) : (
                <FieldBox
                  desktopViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.civilStatus.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.community.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                  mobileViewOrder={[
                    ModelMeta.age.fieldName,
                    ModelMeta.religion.fieldName,
                    ModelMeta.civilStatus.fieldName,
                    ModelMeta.height.fieldName,
                    ModelMeta.community.fieldName,
                    ModelMeta.profession.fieldName,
                  ]}
                >
                  <IconizedText
                    icon={ageIcon}
                    fieldName={ModelMeta.age.fieldName}
                    text={model[ModelMeta.age.fieldName]}
                    tooltip={t(ModelMeta.age.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.age) &&
                      (model[ModelMeta.age.fieldName] || !isMobileViewport)
                    }
                  />

                  <IconizedText
                    icon={religionIcon}
                    fieldName={ModelMeta.religion.fieldName}
                    text={model[ModelMeta.religion.fieldName]}
                    tooltip={t(ModelMeta.religion.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.religion) &&
                      (model[ModelMeta.religion.fieldName] || isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={civilStatusIcon}
                    fieldName={ModelMeta.civilStatus.fieldName}
                    text={model[ModelMeta.civilStatus.fieldName]}
                    tooltip={t(ModelMeta.civilStatus.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.civilStatus) &&
                      (model[ModelMeta.civilStatus.fieldName] ||
                        !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={heightIcon}
                    fieldName={ModelMeta.height.fieldName}
                    text={model[ModelMeta.height.fieldName]}
                    tooltip={t(ModelMeta.height.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.height) &&
                      (model[ModelMeta.height.fieldName] || !isMobileViewport)
                    }
                  />
                  <IconizedText
                    icon={ethnicityIcon}
                    fieldName={ModelMeta.community.fieldName}
                    text={model[ModelMeta.community.fieldName]}
                    tooltip={t(ModelMeta.community.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.community) &&
                      (model[ModelMeta.community.fieldName] ||
                        !isMobileViewport)
                    }
                  />

                  <IconizedText
                    icon={jobIcon}
                    fieldName={ModelMeta.profession.fieldName}
                    text={model[ModelMeta.profession.fieldName]}
                    tooltip={t(ModelMeta.profession.label)}
                    containerProps={{
                      className: "content-row-item column-gap-8",
                    }}
                    labelProps={{
                      className: "contet-text text-truncate-2-lines",
                    }}
                    iconProps={{ className: "data-icon" }}
                    visibility={
                      fieldVisibility(ModelMeta.profession) &&
                      (model[ModelMeta.profession.fieldName] ||
                        isMobileViewport)
                    }
                  />
                </FieldBox>
              )}
            </Grid>

            <CardFooter
              advertisment={advertisment}
              isFeatured={isFeatured}
              position={position}
            />
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default AdvertisementCard;
