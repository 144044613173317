import { Button, Grid, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../../v2/features/payment";
import UpdateCardModal from "./update-card-details-dialog-box";
import CloseModal from "./close-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  PAYMENT_NETWORKS,
  PROFILE_STATUS,
  SUBSCRIPTION_STATUSES,
} from "../../../../v2/config/constant";
import { setAppSnackNotification } from "../../../../v2/store/actions/application";
import SectionView from "../section-view";
import { initializeAuth } from "../../../../v2/features/application";
import { isEmpty } from "lodash";
import { SUBSCRIPTION_STATUS_INFO } from "../../../../v2/static-data";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import VisaIcon from "../../../../images/visa.png";
import AmexIcon from "../../../../images/amex.png";
import MasterCardIcon from "../../../../images/masterCard.png";
import CreditCardIcon from "../../../../images/credit-card.png";

const SubscriptionHandler = () => {
  const [autoRenewalcancelModaVisibility, setAutoRenewalcancelModaVisibility] =
    useState(false);
  const [displayUpdateCardModal, setDisplayUpdateCardModal] = useState(false);

  const { authProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const subscriptionStatus = authProfile.subscriptionStatus;

  const adExpireDate = () => {
    const adExpireDate = authProfile.expiresAt;
    return adExpireDate
      ? subscriptionStatus === SUBSCRIPTION_STATUSES.GRACE_PERIOD
        ? moment(adExpireDate).add(7, "d").format("DD MMMM YYYY")
        : moment(adExpireDate).format("DD MMMM YYYY")
      : "";
  };

  const isCancelledSubscription =
    subscriptionStatus === SUBSCRIPTION_STATUSES.USER_CANCELLED ||
    subscriptionStatus === SUBSCRIPTION_STATUSES.SYSTEM_CANCELLED;

  const isActiveSubscription =
    (subscriptionStatus === SUBSCRIPTION_STATUSES.STARTED ||
      subscriptionStatus === SUBSCRIPTION_STATUSES.RENEWED) &&
    authProfile.status === PROFILE_STATUS.LIVE;

  const updateCardDetailsBtnVisibility =
    authAccount.cardSaved &&
    subscriptionStatus !== SUBSCRIPTION_STATUSES.USER_CANCELLED &&
    subscriptionStatus !== SUBSCRIPTION_STATUSES.SYSTEM_CANCELLED;

  const featuresEndDateVisibility =
    (subscriptionStatus === SUBSCRIPTION_STATUSES.GRACE_PERIOD ||
      subscriptionStatus === SUBSCRIPTION_STATUSES.USER_CANCELLED) &&
    authProfile.status === PROFILE_STATUS.LIVE;

  const activateAdButtonVisibility =
    (isCancelledSubscription &&
      authProfile.status === PROFILE_STATUS.EXPIRED) ||
    subscriptionStatus === SUBSCRIPTION_STATUSES.SYSTEM_CANCELLED;

  const subscriptionInfo = useMemo(() => {
    return (
      SUBSCRIPTION_STATUS_INFO.find(
        (element) => element.id === subscriptionStatus
      ) ?? {}
    );
  }, [subscriptionStatus]);

  const cardNumber = useMemo(() => {
    const cardLastDigits = authAccount.cardLast4 ?? "";
    const maskedCardNumber = cardLastDigits.padStart(16, "*");
    const chunks = maskedCardNumber.match(/.{1,4}/g);
    const number = chunks.join(" ");
    return number;
  }, [authAccount]);

  const getCardIcon = () => {
    const cardBrand = (authAccount.cardBrand ?? "").toUpperCase();
    switch (cardBrand) {
      case PAYMENT_NETWORKS.VISA:
        return VisaIcon;
      case PAYMENT_NETWORKS.MASTER:
      case PAYMENT_NETWORKS.MASTERCARD:
        return MasterCardIcon;
      case PAYMENT_NETWORKS.AMEX:
        return AmexIcon;
      default:
        return CreditCardIcon;
    }
  };

  const onClickCancelSubscription = async () => {
    try {
      const response = await cancelSubscription({ postId: authProfile.id });
      if (response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "success",
            message: t("setting.subscriptionCancelSuccessNotice"),
          })
        );
        dispatch(initializeAuth());
        setAutoRenewalcancelModaVisibility(false);
      } else {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: t("setting.subscriptionCancelFailedNotice"),
          })
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <SectionView title={t("setting.manageSubscription")}>
      <Grid
        id="update-card-details"
        container
        direction="column"
        className="payment-features-inner-view"
      >
        {!isEmpty(subscriptionInfo) && (
          <Typography
            variant="body2"
            className={`subscription-status-${subscriptionInfo.labelType}`}
          >
            {t(subscriptionInfo.labelKey)}
          </Typography>
        )}

        {authProfile.status === PROFILE_STATUS.IN_REVIEW && (
          <Typography variant="body2" className="in-review-txt font-weight-600">
            {t("payment.postUnderReview")}
          </Typography>
        )}

        {featuresEndDateVisibility && (
          <Grid container direction="column">
            <Typography variant="caption" className="secondary-txt-color">
              {t("setting.packageFeaturesEndDate")}
            </Typography>
            <Typography variant="body2" className="primary-txt-color">
              {adExpireDate()}
            </Typography>
          </Grid>
        )}

        <Grid container direction="row" style={{ rowGap: 8 }}>
          {isActiveSubscription && (
            <Grid container direction="column" xs={12} md={6}>
              <Typography variant="caption" className="secondary-txt-color">
                {t("setting.nextPaymentDate")}
              </Typography>
              <Typography variant="body2" className="primary-txt-color">
                {adExpireDate()}
              </Typography>
            </Grid>
          )}
          {authAccount.cardSaved &&
            authAccount.cardLast4 &&
            authAccount.cardBrand && (
              <Grid container direction="column" xs={12} md={6}>
                <Typography variant="caption" className="secondary-txt-color">
                  {t("setting.cardNumber")}
                </Typography>
                <Typography className="card-info-txt" variant="body2" noWrap>
                  {cardNumber}
                  <img
                    src={getCardIcon()}
                    style={{ width: 30 }}
                    alt="payment-network"
                  />
                </Typography>
              </Grid>
            )}
        </Grid>

        {updateCardDetailsBtnVisibility && (
          <Button
            onClick={() => setDisplayUpdateCardModal(true)}
            className="primary-action-btn"
          >
            <Typography variant="caption" className="font-weight-600">
              {t("setting.updateCardDetails")}
            </Typography>
          </Button>
        )}

        {activateAdButtonVisibility && (
          <Button
            onClick={() => history.push("/pay-for-ad")}
            className="primary-action-btn"
          >
            <Typography variant="caption" className="font-weight-600">
              {t("setting.payNow")}
            </Typography>
          </Button>
        )}

        {!isCancelledSubscription && (
          <Typography
            variant="body2"
            className="cancel-auto-renew-link"
            onClick={() => setAutoRenewalcancelModaVisibility(true)}
          >
            {t("setting.cancelSubscription")}
          </Typography>
        )}
      </Grid>

      {displayUpdateCardModal && (
        <UpdateCardModal
          open={displayUpdateCardModal}
          handleClose={() => setDisplayUpdateCardModal(false)}
          authProfile={authProfile}
        />
      )}
      {autoRenewalcancelModaVisibility && (
        <CloseModal
          visibility={autoRenewalcancelModaVisibility}
          handleClose={setAutoRenewalcancelModaVisibility}
          primaryBtnAction={onClickCancelSubscription}
        />
      )}
    </SectionView>
  );
};

export default SubscriptionHandler;
