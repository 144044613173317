import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import {
  AppBar,
  Grid,
  Dialog,
  IconButton,
  Button,
  Drawer,
} from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import MenuIcon from "@material-ui/icons/Menu";

import UserAccountDrawer from "../drawers/UserAccountDrawer";
import MenuDrawer from "../drawers/MenuDrawer";
import PwdResetModal from "../password-reset/PwdResetModal";
import ComErrorAlert from "../create-flow/ComErrorAlert";
import Banner from "../banners/Banner";
import ManageAccountBanner from "../banners/ManageAccountBanner";
import Main from "../login/Main";
import RegisterAcc from "../register/RegisterAcc";
import PayhereFailReminder from "../modals/PayhereFailReminder";

import user from "../../images/user.png";

import { handleIntBtns } from "../../actions/handleIntBtns";
import { fetchNotificationCount } from "../../actions/fetchNotificationCount";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";

import "../../styles/navbar.css";
import "../../styles/user-account-drawer.css";
import Config from "../../v2/config";
import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";
import { setAuthenticated } from "../../v2/store/actions/application";
import {
  setHomePageInitialResultLoaded,
  setHomePageShouldForceReload,
} from "../../v2/store/actions/home-page";

class Navbar extends Component {
  state = {
    // account registration
    postDetails: {},
    postStatus: "",
    showFirstView: true,
    email: "",
    phone: "",
    password: "",
    showPassword: false,
    memberId: "",

    isUsingPhone: false,
    isUserId: false,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: sessionStorage.getItem("countryDialCode")
      ? sessionStorage.getItem("countryDialCode")
      : Config.DIAL_CODE,
    externalRequestsCount: 0,

    // error
    emailError: "",
    phoneError: "",
    passwordError: "",
    authError: "",
    failAlert: false,

    discardModal: false,
    loginDialogModal: false,

    // drawer
    rightDrawer: false,
    menuDrawer: false,

    // gender: localStorage.getItem("post_details")
    //   ? JSON.parse(localStorage.getItem("post_details")).personalInfo.gender
    //   : "",
    gender: "",

    // resend email
    resendEmail: false,
    sentSuccess: false,

    // reset password
    resetEmail: "",
    // resetPhone: "",
    showPwdResetModal: false,
    isForcedReset: false,

    // error
    resetEmailError: "",

    // alert
    showResendSuccess: false,
    showResendFail: false,
    error: "",

    // loading
    loading: false,
    loadingLogin: false,
    resetLoading: false,

    // redirect
    redirectToPayment: false,
    redirectToReview: false,
    redirectToPricing: false,
    redirectToTerms: false,

    // alert
    errorAlert: false,
    errorCode: "",

    // fail payhere payment modal
    isShowFailPayhereModal: false,
    showCompleteAdIcon: false,
  };

  componentDidMount = () => {
    // try {
    //   const postDetails = isEmpty(localStorage.getItem("post_details"))
    //     ? {}
    //     : JSON.parse(localStorage.getItem("post_details"));
    //   this.setState({
    //     postDetails,
    //     gender: postDetails?.personalInfo?.gender ?? "",
    //   });
    // } catch (error) {
    //   console.log(">>>>> error >>>> ", error);
    // }
    // if (localStorage.getItem("auth_token") && localStorage.getItem("postId")) {
    //   let url = "";
    //   if (
    //     localStorage.getItem("user_role") === "OPERATOR" ||
    //     localStorage.getItem("user_role") === "OPERATOR_L2"
    //   ) {
    //     url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    //   } else {
    //     url = "account/posts";
    //   }
    //   axios
    //     .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    //       },
    //     })
    //     .then((res) => {
    //       if (
    //         localStorage.getItem("user_role") === "OPERATOR" ||
    //         localStorage.getItem("user_role") === "OPERATOR_L2"
    //       ) {
    //         res.data[0] = res.data;
    //       }
    //       if (res.data.length > 0) {
    //         localStorage.setItem("post_details", JSON.stringify(res.data[0]));
    //         this.setState({
    //           showCompleteAdIcon:
    //             !res.data[0].firstPublishedAt ||
    //             moment().format() <
    //               moment
    //                 .utc(
    //                   moment.utc(res.data[0].firstPublishedAt).add(8, "days")
    //                 )
    //                 .local()
    //                 .format()
    //               ? true
    //               : false,
    //         });
    //       }
    //       this.setState({ postStatus: res.data[0].status });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    // }
    // this.props.fetchNotificationCount();

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/ipinfo`)
      .then((res) => {
        if (res.data.country) {
          if (res.data.country.callingCode) {
            // set user dial code
            sessionStorage.setItem(
              "countryDialCode",
              `+${res.data.country.callingCode}`
            );

            this.setState({
              code: `+${res.data.country.callingCode}`,
            });
          }

          if (res.data.country.code) {
            // set user country code
            sessionStorage.setItem("countryCode", res.data.country.code);
          }
        }
      })
      .catch((err) => console.log(err));

    // country
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
      .then((res) => {
        const sortedCountries = res.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.setState({
          countries: sortedCountries,
        });
      })
      .catch((err) => console.log(err));

    this.checkPayhereFailStatus();
    if (localStorage.getItem("auth_token") && localStorage.getItem("postId")) {
      this.getExtInterestCount();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(this.props.authProfile, prevProps.authProfile)) {
      const postDetails = this.props.authProfile;
      const postStatus = this.props.authProfile?.status ?? "";
      let showCompleteAdIcon = false;
      if (!isEmpty(postDetails)) {
        showCompleteAdIcon =
          !postDetails.firstPublishedAt ||
          moment().format() <
            moment
              .utc(moment.utc(postDetails.firstPublishedAt).add(8, "days"))
              .local()
              .format()
            ? true
            : false;
        this.props.fetchNotificationCount();
      }

      this.setState({
        postDetails,
        postStatus,
        showCompleteAdIcon,
      });
    }

    if (!isEqual(this.props.authAccount, prevProps.authAccount)) {
      this.setState({ memberId: this.props.authAccount?.memberId ?? "" });
    }
  };

  checkPayhereFailStatus = () => {
    if (
      this.props.history.location.pathname !== "/payment-success" &&
      this.props.history.location.pathname !== "/renew/success" &&
      this.props.history.location.pathname !== "/subscription-success"
    ) {
      if (
        localStorage.getItem("payment_payhere_reminder") &&
        ((localStorage.getItem("post_details") &&
          JSON.parse(localStorage.getItem("post_details")).status ===
            "PAYMENT_PENDING") ||
          (localStorage.getItem("post_details") &&
            JSON.parse(localStorage.getItem("post_details")).status ===
              "EXPIRED")) &&
        moment(localStorage.getItem("payment_payhere_reminder"))
          .add(30, "minutes")
          .format() > moment().format()
      ) {
        setTimeout(() => this.setState({ isShowFailPayhereModal: true }), 2000);
      } else {
        localStorage.removeItem("payment_payhere_reminder");
      }
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleCountryCode = (code) => {
    this.setState({ code, showCountryCodes: false });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ phone: parseInt(str, 10) });
  };

  handleEmailMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: false,
      isUserId: false,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
    });
  };

  handlePhoneMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: true,
      isUserId: false,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
      sentSuccess: "",
    });
  };

  handleUserIdMethod = () => {
    this.setState({
      showFirstView: false,
      isUsingPhone: false,
      isUserId: true,
      showCountryCodes: false,
      emailError: "",
      passwordError: "",
      phoneError: "",
      sentSuccess: "",
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickOpen = () => {
    this.setState({ discardModal: true });
  };

  handleClose = () => {
    this.setState({
      discardModal: false,
      loginDialogModal: false,
      showFirstView: true,
      showPwdResetModal: false,
      email: "",
      password: "",
      showPassword: false,
      emailError: "",
      passwordError: "",
      authError: "",
      resendEmail: false,
      sentSuccess: false,
      resetEmail: "",
      resetEmailError: "",
      showResendSuccess: false,
      showResendFail: false,
      showCountryCodes: false,
      isUsingPhone: false,
    });
  };

  // validate
  validate = () => {
    let emailError = "";
    let phoneError = "";
    let passwordError = "";

    if (this.state.isUsingPhone) {
      if (this.state.phone === "") {
        phoneError = this.props.lng
          ? "Phone number is required"
          : "දුරකථන අංකය අවශ්‍ය වේ";
      }
    } else {
      if (this.state.email === "") {
        emailError = this.props.lng
          ? "Email / Ad ID is required"
          : "විද්‍යුත් තැපැල් / දැන්වීම් කේතය අවශ්‍ය වේ";
      }
    }

    if (this.state.password === "") {
      passwordError = this.props.lng
        ? "Password is required"
        : "මුරපදය අවශ්‍ය වේ";
    }

    if (emailError || passwordError || phoneError) {
      this.setState({
        emailError,
        phoneError,
        passwordError,
      });
      return false;
    }

    return true;
  };

  handleSaveProfile = () => {
    if (this.validate()) {
      this.setState({ loadingLogin: true });
      const data = qs.stringify({
        userId: this.state.isUsingPhone
          ? `${this.state.code}${this.state.phone}`
          : this.state.email,
        password: this.state.password,
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/auth/create_session`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;",
          },
        })
        .then((res) => {
          localStorage.setItem("user_role", "USER");
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("userId", res.data.memberId);

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem("user_role", res.data.role);
              localStorage.setItem(
                "user_email",
                res.data.email ? res.data.email : ""
              );
              if (
                res.data.role === "OPERATOR" ||
                res.data.role === "OPERATOR_L2"
              ) {
                this.setState({ loadingLogin: false });
                this.setState({ email: "", password: "", discardModal: false });
                this.props.history.push("/manage-posts");
              } else {
              }
            })
            .catch((err) => {
              console.log(err);
            });

          let url = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            url = `operator/managed_posts/${localStorage.getItem("postId")}`;
          } else {
            url = "account/posts";
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (
                localStorage.getItem("user_role") === "OPERATOR" ||
                localStorage.getItem("user_role") === "OPERATOR_L2"
              ) {
                res.data[0] = res.data;
              }
              if (res.data.length > 0) {
                localStorage.setItem(
                  "post_details",
                  JSON.stringify(res.data[0])
                );
                localStorage.setItem("postId", res.data[0].id);

                this.props.fetchNotificationCount();

                this.handleClose();

                if (this.props.history.location.pathname === "/register") {
                  this.props.history.push("/?page=1");
                } else {
                  window.location.reload();
                }
                this.setState({ loadingLogin: false });
              } else {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "auth_token"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("personalInfo");
                    localStorage.removeItem("parentInfo");
                    localStorage.removeItem("privateInfo");

                    if (res.data !== "") {
                      if (res.data.postData.personalInfo) {
                        localStorage.setItem(
                          "personalInfo",
                          JSON.stringify(res.data.postData.personalInfo)
                        );
                      }

                      if (res.data.postData.parentInfo) {
                        localStorage.setItem(
                          "parentInfo",
                          JSON.stringify(res.data.postData.parentInfo)
                        );
                      }

                      if (
                        res.data.postData.horoscopeMatching ||
                        res.data.postData.makePhonePublic
                      ) {
                        localStorage.setItem(
                          "privateInfo",
                          JSON.stringify({
                            createdBy: res.data.postData.createdBy
                              ? res.data.postData.createdBy
                              : "SELF",
                            phoneId: res.data.postData.phoneId,
                            makePhonePublic: res.data.postData.makePhonePublic,
                            horoscopeMatching:
                              res.data.postData.horoscopeMatching,
                            birthTime: res.data.postData.birthTime,
                            birthCity: res.data.postData.birthCity,
                            images: res.data.postData.images,
                            phone: res.data.postData.phone,
                            horoscopeDetail: res.data.postData.horoscopeDetail
                              ? res.data.postData.horoscopeDetail
                              : "",
                            horoscopeImages: res.data.postData.horoscopeImages
                              ? res.data.postData.horoscopeImages
                              : [],
                          })
                        );
                      }
                    }

                    this.props.history.push("/complete-ad-review");
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              console.log(err);

              this.setState({ loadingLogin: false });
            });

          this.setState({ email: "", password: "", discardModal: false });
        })
        .catch((err) => {
          if (err.response.data.code === 1103) {
            this.setState({
              emailError: this.props.lng
                ? "Your email has not been verified. Please check your inbox for the verification email or click Resend to send it again."
                : "ඔබගේ විද්‍යුත් ලිපිනය සත්‍යාපනය කර නොමැත. කරුණාකර සත්‍යාපන විද්‍යුත් තැපෑල සඳහා ඔබගේ එන ලිපි පරීක්ෂා කරන්න හෝ නැවත යැවීමට නැවත යවන්න ක්ලික් කරන්න.",
              resendEmail: true,
            });
          } else if (err.response.data.code === 1102) {
            this.setState({
              authError: this.props.lng
                ? "Username and password does not match"
                : "පරිශීලකයා සහ මුරපදය නොගැලපේ",
            });
          } else if (err.response.data.code === 1117) {
            this.setState({
              authError: this.props.lng
                ? "Password login disabled due to suspicious login attempts. Please click 'Forgot your password' to reset your password."
                : "සැක සහිත පිවිසුම් උත්සාහයන් හේතුවෙන් මුරපදය අක්‍රිය කර ඇත. කරුණාකර පහත 'මුරපදය අමතක වුනාද?' මගින් ඔබගේ මුරපදය අලුත් කරන්න.",
            });
          } else if (err.response.data.code === 1116) {
            this.setState({ isForcedReset: true });
            this.showPwdResetModal();
          } else {
            this.setState({
              errorAlert: true,
              errorCode: err.response.data.code,
            });
          }
          this.setState({ loadingLogin: false });
        });
    } else {
      this.setState({ loadingLogin: false });
    }
  };

  showPwdResetModal = () => {
    this.setState({
      discardModal: false,
      loginDialogModal: false,
      showPwdResetModal: true,
      resetEmail: "",
      resetEmailError: "",
      showResendSuccess: false,
      showResendFail: false,
    });
  };

  // drawer user account
  toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ rightDrawer: open });
  };

  // drawer menu
  menuDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ menuDrawer: open });
  };

  toggleMenuDrawer = (state) => {
    !isEmpty(state) && this.setState(state);
  };

  // resend verification email
  handleResendEmail = () => {
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/auth/resend_activation`,
        `email=${this.state.email}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          resendEmail: false,
          authError: "",
          sentSuccess: true,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  logout = () => {
    this.setState({ rightDrawer: false }, () => {
      // -----------------@deprecated: Remove when doing a QA--------------------
      // localStorage.removeItem("auth_token");
      // localStorage.removeItem("userId");
      // localStorage.removeItem("post_details");
      // localStorage.removeItem("postId");
      // localStorage.removeItem("session_creation");
      // localStorage.removeItem("personalInfo");
      // localStorage.removeItem("parentInfo");
      // localStorage.removeItem("privateInfo");
      // localStorage.removeItem("user_name");
      // localStorage.removeItem("user_email");
      // localStorage.removeItem("user_role");
      // localStorage.removeItem("operator_id");

      // clear entire storages where can assume next login as fresh
      localStorage.clear();
      sessionStorage.clear();
      this.props.history.push("/search");
      setTimeout(() => {
        window.location.reload();
      }, [100]);
      // if (this.props.history.location.pathname === "/") {
      //   window.location.reload();
      // }
    });
  };

  // login when press the enter key
  handleLoginKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-login-btn").click();
    }
  };

  openRegisterModal = () => {
    if (this.props.history.location.pathname === "/login") {
      this.setState({ menuDrawer: false });
    } else {
      this.setState({ discardModal: true, menuDrawer: false });
    }
  };

  openLoginModal = () => {
    this.setState({ loginDialogModal: true });
  };

  handleCreateAdFlow = () => {
    // this.props.history.push("/register");
    this.props.history.push("/auth");
  };

  discardPost = () => {
    localStorage.removeItem("personalInfo");
    localStorage.removeItem("parentInfo");
    localStorage.removeItem("privateInfo");

    this.setState({ redirectToReview: true });
  };

  goToHome = () => {
    sessionStorage.removeItem("male");
    sessionStorage.removeItem("female");
    sessionStorage.removeItem("showHideInt");
    sessionStorage.removeItem("minAge");
    sessionStorage.removeItem("maxAge");
    sessionStorage.removeItem("checkedEthnicities");
    sessionStorage.removeItem("checkedReligions");
    sessionStorage.removeItem("checkedStatus");
    sessionStorage.removeItem("checkedProf");
    sessionStorage.removeItem("minEdu");
    sessionStorage.removeItem("checkedFoodPref");
    sessionStorage.removeItem("checkedAccountOwner");
    sessionStorage.removeItem("checkedSmoking");
    sessionStorage.removeItem("checkedDrinking");
    sessionStorage.removeItem("checkedResidentCountries");
    sessionStorage.removeItem("checkedRegions");
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("topPosts");
    sessionStorage.removeItem("post_timeout");
    sessionStorage.removeItem("page");
    sessionStorage.removeItem("scrollAmount");
    sessionStorage.removeItem("ageAcco");
    sessionStorage.removeItem("ethnicityAcco");
    sessionStorage.removeItem("religionAcco");
    sessionStorage.removeItem("statusAcco");
    sessionStorage.removeItem("professionAcco");
    sessionStorage.removeItem("minEduAcco");
    sessionStorage.removeItem("foodPrefAcco");
    sessionStorage.removeItem("drinkingAcco");
    sessionStorage.removeItem("smokingAcco");
    sessionStorage.removeItem("dissabledAcco");
    sessionStorage.removeItem("nicVerifiedAcco");
    sessionStorage.removeItem("accountOwnerAcco");
    sessionStorage.removeItem("resCountryAcco");
    sessionStorage.removeItem("regionAcco");
    sessionStorage.setItem("isDefaultSearch", "false");
    sessionStorage.removeItem("sortBy");
    sessionStorage.removeItem("maxHeight");
    sessionStorage.removeItem("minHeight");
    sessionStorage.removeItem("heightAcco");

    // if (
    //   this.props.history.location.search &&
    //   this.props.history.location.search.includes("?page")
    // ) {
    //   window.location.reload();
    // } else {
    //   this.props.history.push(`/?page=1`);
    // }
    window.location.href = "/?page=1";
    // this.props.history.push(`/`);
    // window.location.reload();

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleCloseAlert = () => {
    this.setState({ errorAlert: false });
  };

  handleCountryCodes = () => {
    this.setState({ showCountryCodes: true });
  };

  handleCloseResetPwdModal = () => {
    this.setState({
      showPwdResetModal: false,
      isForcedReset: false,
      showFirstView: true,
    });
  };

  gotoMatch = () => {
    this.props.history.push("/messages");

    this.props.handleIntBtns("match");
  };

  handleBackBtn = () => {
    this.setState({ showCountryCodes: false });
  };

  handleShowCountryCodes = () => {
    this.setState({ showCountryCodes: true });
  };

  handleShowFirstView = () => {
    this.setState({ showFirstView: true });
  };

  handleFailPayhereModalClose = () => {
    this.setState({ isShowFailPayhereModal: false });
    localStorage.removeItem("payment_payhere_reminder");
  };

  getExtInterestCount = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/ext_interests`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ externalRequestsCount: res.data.length });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onClickProductLogo = () => {
    const { history, setHomePageShouldForceReload } = this.props;
    localStorage.removeItem("lastSeenProfileId");
    setHomePageShouldForceReload(true);
    history.push({
      pathname: "/search",
      state: { fromProductLogoClick: true },
    });
  };

  render() {
    const filteredCountries = this.state.countries.filter(
      (filter) =>
        filter.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
        -1
    );

    return (
      <React.Fragment>
        {this.state.redirectToPayment ? <Redirect to="/pay-for-ad" /> : null}
        {this.state.redirectToReview ? (
          <Redirect to="/complete-ad-review" />
        ) : null}
        {this.state.redirectToPricing ? <Redirect to="/pricing" /> : null}
        {this.state.redirectToTerms ? <Redirect to="/terms" /> : null}

        <AppBar
          position="sticky"
          className="reg-nav"
          style={{
            height:
              this.props.history.location.pathname === "/register"
                ? "45px"
                : "70px",
          }}
        >
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className={
              this.props.history.location.pathname === "/my-matches" ||
              this.props.history.location.pathname === "/interests-matched" ||
              this.props.history.location.pathname === "/interests-sent" ||
              this.props.history.location.pathname === "/messages" ||
              this.props.history.location.pathname === "/interests" ||
              this.props.history.location.pathname === "/sent-interests" ||
              this.props.history.location.pathname ===
                "/preference-mismatch-interests"
                ? "nav-cus-container nav-cus-con-matches"
                : "nav-cus-container"
            }
            style={{
              paddingBottom:
                this.props.history.location.pathname === "/register" ? 0 : "",
            }}
          >
            {this.props.history.location.pathname !== "/register" && (
              // <picture style={{ marginRight: "auto" }}>
              //   {/* <source srcSet={logo} type="image/webp" /> */}
              //   <img
              //     src={`${process.env.PUBLIC_URL}/images/logo_white.png`}
              //     alt="poruwa logo"
              //     className="logo-poruwa"
              //     onClick={this.goToHome}
              //     style={{ cursor: "pointer" }}
              //     width={150}
              //   />
              // </picture>
              <div style={{ marginRight: "auto" }}>
                <ProductLogo
                  variant={LogoVariant.white}
                  size={LogoSize.medium}
                  className={"logo-poruwa common-cursor-pointer"}
                  onClick={this.onClickProductLogo}
                />
              </div>
            )}

            <Button
              type="button"
              onClick={this.openRegisterModal}
              id="drawer-register-btn"
              hidden
              style={{
                display: "none",
                cursor: "inherit",
              }}
            ></Button>

            <Button
              type="button"
              onClick={this.openLoginModal}
              id="drawer-login-dialog-btn"
              hidden
              style={{
                display: "none",
                cursor: "inherit",
              }}
            ></Button>

            {localStorage.getItem("auth_token") &&
              localStorage.getItem("postId") && (
                <span className="message-btn-nav" onClick={this.gotoMatch}>
                  <span id="logged_msg_icon">
                    <i className="far fa-envelope"></i>
                  </span>

                  {/* {((this.props.receivedPosts &&
                    this.props.receivedPosts.filter(
                      (el) => el.ignored === false && el.likeSeen === false
                    ).length > 0) ||
                    (this.props.matchedPosts &&
                      this.props.matchedPosts.filter(
                        (el) => el.matchSeen === false && !el.latestChat
                      ).length > 0) ||
                    (this.props.matchedPosts &&
                      this.props.matchedPosts.filter(
                        (el) =>
                          el.latestChat &&
                          el.latestChat.sender === "OTHER_PARTY" &&
                          !el.latestChat.seen
                      ).length > 0) ||
                    (this.state.externalRequestsCount > 0)
                    ) && (
                    <sup className="badge">
                      {(this.props.receivedPosts &&
                        this.props.receivedPosts.filter(
                          (el) => el.ignored === false && el.likeSeen === false
                        ).length) +
                        (this.props.matchedPosts &&
                          this.props.matchedPosts.filter(
                            (el) => el.matchSeen === false && !el.latestChat
                          ).length) +
                        (this.props.matchedPosts &&
                          this.props.matchedPosts.filter(
                            (el) =>
                              el.latestChat &&
                              el.latestChat.sender === "OTHER_PARTY" &&
                              !el.latestChat.seen
                          ).length) +
                          this.state.externalRequestsCount}
                    </sup>
                  )} */}
                  {this.props.allNotificationCount > 0 && (
                    <sup className="badge">
                      {this.props.allNotificationCount}
                    </sup>
                  )}
                </span>
              )}

            {!localStorage.getItem("auth_token") ? (
              this.props.history.location.pathname === "/" ||
              this.props.history.location.pathname === "/search" ||
              this.props.history.location.pathname === "/login" ||
              this.props.history.location.pathname === "/post" ||
              this.props.history.location.pathname === "/privacy" ||
              this.props.history.location.pathname === "/terms" ||
              this.props.history.location.pathname === "/pricing" ||
              this.props.history.location.pathname === "/magazine" ||
              this.props.history.location.pathname === "/reset-password" ? (
                <span
                  onClick={this.handleCreateAdFlow}
                  className="ad-post-btn"
                  style={{ marginLeft: "auto" }}
                >
                  {this.props.lng ? (
                    "Create/Login"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      සාදන්න​/පිවිසෙන්න​
                    </span>
                  )}
                </span>
              ) : null
            ) : null}

            {localStorage.getItem("auth_token") && (
              <button
                variant="contained"
                id="logged_menu_btn"
                className={
                  localStorage.getItem("post_details") &&
                  JSON.parse(localStorage.getItem("post_details")).status ===
                    "LIVE"
                    ? "login-link profile-btn active"
                    : localStorage.getItem("post_details") &&
                      JSON.parse(localStorage.getItem("post_details"))
                        .status === "IN_REVIEW"
                    ? "login-link profile-btn in-review"
                    : localStorage.getItem("post_details") &&
                      JSON.parse(localStorage.getItem("post_details"))
                        .status === "PAYMENT_PENDING"
                    ? "login-link profile-btn payment-pending"
                    : localStorage.getItem("post_details") &&
                      JSON.parse(localStorage.getItem("post_details"))
                        .status === "EXPIRED"
                    ? "login-link profile-btn expired"
                    : localStorage.getItem("post_details") &&
                      JSON.parse(localStorage.getItem("post_details"))
                        .status === "DISABLED"
                    ? "login-link profile-btn unpublished"
                    : !localStorage.getItem("post_details")
                    ? "login-link profile-btn incomplete"
                    : "login-link profile-btn"
                }
                onClick={this.toggleDrawer("right", true)}
                style={{
                  padding: 0,
                  marginLeft:
                    localStorage.getItem("auth_token") &&
                    localStorage.getItem("postId")
                      ? "unset"
                      : "auto",
                }}
              >
                <img src={user} alt="user" className="login-photo" />
                <span style={{ fontWeight: 300, verticalAlign: "middle" }}>
                  Ad ID:
                </span>{" "}
                <span style={{ verticalAlign: "middle" }}>
                  {this.state.memberId}
                </span>
              </button>
            )}
            {localStorage.getItem("auth_token") &&
              this.state.showCompleteAdIcon && (
                <i
                  class="fa fa-circle"
                  aria-hidden="true"
                  onClick={() => this.props.history.push("/complete-your-ad")}
                  style={{
                    color: "#eb5757",
                    marginLeft: "-5px",
                    cursor: "pointer",
                    fontSize: "9px",
                  }}
                ></i>
              )}
            {(window.screen.width > 768 &&
              !localStorage.getItem("auth_token")) ||
            (window.screen.width < 769 &&
              !localStorage.getItem("auth_token")) ? (
              <React.Fragment>
                {this.props.history.location.pathname ===
                  "/complete-ad-personal" ||
                this.props.history.location.pathname ===
                  "/complete-ad-parent" ||
                this.props.history.location.pathname ===
                  "/complete-ad-private" ||
                this.props.history.location.pathname ===
                  "/complete-ad-partner" ||
                this.props.history.location.pathname ===
                  "/complete-ad-review" ? null : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.menuDrawer("right", true)}
                    color="inherit"
                    className="main-menu-btn"
                    style={{
                      padding: 0,
                      marginLeft:
                        this.props.history.location.pathname === "/register" ||
                        this.props.history.location.pathname ===
                          "/access-denied" ||
                        this.props.history.location.pathname ===
                          "/pay-for-ad" ||
                        this.props.history.location.pathname === "/checkout" ||
                        this.props.history.location.pathname ===
                          "/my-matches" ||
                        this.props.history.location.pathname ===
                          "/interests-matched" ||
                        this.props.history.location.pathname ===
                          "/interests-sent" ||
                        this.props.history.location.pathname === "/my-ad" ||
                        this.props.history.location.pathname ===
                          "/edit-my-ad" ||
                        this.props.history.location.pathname === "/settings" ||
                        this.props.history.location.pathname ===
                          "/interest-preferences" ||
                        this.props.history.location.pathname ===
                          "/invalid-payment-link" ||
                        this.props.history.location.pathname.split("/")[1] ===
                          "pay" ||
                        this.props.history.location.pathname ===
                          "/unsubscribe" ||
                        this.props.history.location.pathname ===
                          "/already-activated" ||
                        this.props.history.location.pathname ===
                          "/payments/top-ad"
                          ? "auto"
                          : "12px",
                    }}
                  >
                    <MenuIcon style={{ fontSize: "2rem" }} />
                    <span className="menu-tag">Menu</span>
                  </IconButton>
                )}
              </React.Fragment>
            ) : null}
          </Grid>
        </AppBar>

        <Banner />
        {localStorage.getItem("user_role") !== "OPERATOR" &&
          localStorage.getItem("user_role") !== "OPERATOR_L2" &&
          localStorage.getItem("post_details") &&
          JSON.parse(localStorage.getItem("post_details")).isManaged && (
            <ManageAccountBanner lng={this.props.lng} />
          )}

        <Dialog
          open={this.state.discardModal}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          className="login-modal"
        >
          <RegisterAcc lng={this.props.lng} handleClose={this.handleClose} />
        </Dialog>

        <Dialog
          open={this.state.loginDialogModal}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          className="login-modal"
        >
          <Main
            lng={this.props.lng}
            filteredCountries={filteredCountries}
            showCountryCodes={this.state.showCountryCodes}
            showFirstView={this.state.showFirstView}
            sentSuccess={this.state.sentSuccess}
            resendEmail={this.state.resendEmail}
            code={this.state.code}
            email={this.state.email}
            phone={this.state.phone}
            password={this.state.password}
            isUserId={this.state.isUserId}
            isUsingPhone={this.state.isUsingPhone}
            search={this.state.search}
            loadingLogin={this.state.loadingLogin}
            handlePhone={this.handlePhone}
            handleChange={this.handleChange}
            handleResendEmail={this.handleResendEmail}
            handleCountryCode={this.handleCountryCode}
            handleEmailMethod={this.handleEmailMethod}
            handlePhoneMethod={this.handlePhoneMethod}
            handleUserIdMethod={this.handleUserIdMethod}
            authError={this.state.authError}
            emailError={this.state.emailError}
            passwordError={this.state.passwordError}
            phoneError={this.state.phoneError}
            showPwdResetModal={this.showPwdResetModal}
            handleSaveProfile={this.handleSaveProfile}
            handleLoginKeyUp={this.handleLoginKeyUp}
            handleBackBtn={this.handleBackBtn}
            handleShowCountryCodes={this.handleShowCountryCodes}
            handleShowFirstView={this.handleShowFirstView}
            loading={this.state.loading}
            handleClose={this.handleClose}
            isOTPLogin={true}
            isModalMode={true}
          />
        </Dialog>

        <PwdResetModal
          showPwdResetModal={this.state.showPwdResetModal}
          handleCountryCodes={this.handleCountryCodes}
          showCountryCodes={this.state.showCountryCodes}
          handleChange={this.handleChange}
          handleCountryCode={this.handleCountryCode}
          handleCloseResetPwdModal={this.handleCloseResetPwdModal}
          search={this.state.search}
          code={this.state.code}
          filteredCountries={filteredCountries}
          lng={this.props.lng}
          isForcedReset={this.state.isForcedReset}
        />

        {this.state.rightDrawer ? (
          <Drawer
            anchor={"right"}
            open={this.state.rightDrawer}
            onClose={this.toggleDrawer(false)}
          >
            <UserAccountDrawer
              lng={this.props.lng}
              logout={this.logout}
              toggleDrawer={this.toggleDrawer}
              toggleMenuDrawer={this.toggleMenuDrawer}
              handleLng={this.props.handleLng}
            />
          </Drawer>
        ) : null}

        {this.state.menuDrawer ? (
          <Drawer
            anchor={"right"}
            open={this.state.menuDrawer}
            onClose={this.menuDrawer(false)}
          >
            <MenuDrawer
              lng={this.props.lng}
              menuDrawer={this.menuDrawer}
              toggleMenuDrawer={this.toggleMenuDrawer}
              openRegisterModal={this.openRegisterModal}
              handleLng={this.props.handleLng}
            />
          </Drawer>
        ) : null}

        <ComErrorAlert
          lng={this.props.lng}
          errorAlert={this.state.errorAlert}
          errorCode={this.state.errorCode}
          handleClose={this.handleCloseAlert}
        />

        <ReactTooltip className="tooltip-cus" />

        {this.state.isShowFailPayhereModal && (
          <PayhereFailReminder
            lng={this.props.lng}
            isShowFailPayhereModal={this.state.isShowFailPayhereModal}
            handleFailPayhereModalClose={this.handleFailPayhereModalClose}
          />
        )}
      </React.Fragment>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchNotificationCount: fetchNotificationCount,
      handleIntBtns: handleIntBtns,
      setAppAuthenticated: setAuthenticated,
      setHomePageInitialResultLoaded,
      setHomePageShouldForceReload,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    // matchedPosts: state.matches.allInterests.matches
    //   ? state.matches.allInterests.matches.filter((el) => !el.ignored)
    //   : [],
    // receivedPosts: state.matches.allInterests.received
    //   ? state.matches.allInterests.received
    //   : [],
    allNotificationCount: state.matches.notifications.all,
    messagesNotificationCount: state.matches.notifications.messages,
    matchesNotificationCount: state.matches.notifications.matches,
    interestsNotificationCount: state.matches.notifications.interests,
    authProfile: state.application.authProfile,
    authAccount: state.application.authAccount,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(Navbar));
