import React from "react";
import { Grid, Typography } from "@material-ui/core";
import FormItemLabel from "../../atoms/form-item-label";

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

const FormControl = ({
  label,
  labelStyles = {},
  labelPosition = LABEL_POSITION.left,
  lang,
  errorText = "",
  children,
  classes = {},
  id = "",
}) => {
  return (
    <Grid
      container
      direction="row"
      className={`${classes.rootContainer || ""} form-text-input`}
      alignItems="center"
      id={id}
    >
      {label && (
        <FormItemLabel
          text={label}
          lang={lang}
          labelPosition={labelPosition}
          styleOverrides={labelStyles}
        />
      )}
      <Grid item xs container direction="column">
        {children}
        {errorText && (
          <Typography
            variant="caption"
            className={`form-error-text form-error-text-${lang}`}
          >
            {errorText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default FormControl;
