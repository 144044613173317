import React from "react";
import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useTranslation } from "react-i18next";

const CloseModal = ({ visibility, handleClose, primaryBtnAction }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={visibility} onClose={() => handleClose(false)}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className="auto-renewal-cancel-modal"
      >
        <HighlightOffIcon className="auto-renewal-cancel-modal-header-icon" />
        <Typography variant="h6" className="auto-renewal-modal-title">
          {t("setting.cancelSubscription")}
        </Typography>
        <Typography
          variant="body2"
          className="auto-renewal-cancel-modal-body-txt"
        >
          {t("setting.cancelAutoRenewNotice")}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className="column-gap-8 margin-top-16"
          wrap="nowrap"
        >
          <Button
            className="auto-renewal-cancel-modal-secondary-btn"
            onClick={() => handleClose(false)}
          >
            {t("common.no")}
          </Button>
          <Button
            className="auto-renewal-cancel-modal-primary-btn"
            onClick={primaryBtnAction}
          >
            {t("common.yes")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CloseModal;
