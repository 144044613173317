import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import store from "./store";
import * as serviceWorker from "./serviceWorker";

import "@fontsource-variable/noto-sans-sinhala";
import LocaleHandler from "./v2/utils/locale-handler";
import {ErrorBoundary} from "@sentry/react";
import ErrorFallbackView from "./ErrorFallback";
import PageConfigRegistry from "./PageConfigRegistry";

LocaleHandler.initialize();
PageConfigRegistry();

function isAutomated() {
    if(navigator.webdriver){
        // run bot detection if webdriver is detected
        var cdpDetected = false;
        var e = new Error();
        Object.defineProperty(e, 'stack', {
           get() {
            cdpDetected = true;
           }
        });

        console.log(e);

        if (cdpDetected) {
            return true;
        }
    }

    return false;
}

if (isAutomated()) {
    //Prevent site from loading under automation tools
    console.log("Browser automation not allowed.")
    ReactDOM.render(
        <div>
            <h4>Browser automation not allowed. Please disable automation tools and plugins.</h4>
        </div>,
        document.getElementById("root")
    )
} else {
    // load site if not bot browser
    ReactDOM.render(
        <BrowserRouter>
            <Provider store={store}>
                <ErrorBoundary fallback={ErrorFallbackView}>
                    <App/>
                </ErrorBoundary>
            </Provider>
        </BrowserRouter>,
        document.getElementById("root")
    );

    serviceWorker.unregister();
}
