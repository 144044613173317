import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import NewFeatureBanner from "../components/banners/NewFeatureBanner";
import GoogleReviewBanner from "../components/banners/GoogleReviewBanner";
import HelpBar from "../components/help-bar/HelpBar";
import Footer from "../components/common/Footer";
import Container from "../components/home/Container";
import PageHead from "../components/common/PageHead";
import Config from "../v2/config";
import FooterMobile from "../components/common/FooterMobile";
import NICBanner from "../components/banners/NICBanner";
// import HytchAwarenessBanner from "../components/hytch-awareness-banner";
import PageMetaDescription from "../components/common/PageMetaDescription";
import Constants from "../v2/config/constant";
// import AppContext from "../v2/context/index.js";
// import AppLogger from "../v2/utils/logger/index.js";
// import SupportServiceUnavailableBanner from "../components/banners/SupportServiceUnavailable.jsx";

import ProfileCompletionAlert from "../components/banners/profile-completion-alert/index.jsx";
import withLanguageMigrator from "../v2/hoc/language-migrator/index.jsx";
import "../styles/all-ads.css";
import "../v2/pages/home/style.css";

const HomePage = ({ lng }) => {
  const { authProfile  } = useSelector(state => state.application);

  return (
    <>
      <PageHead
        title={`${Config.DOMAIN_DISPLAY_NAME} | ${
          Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
            ? "Sri Lankan Matrimony"
            : "Indian Matrimony"
        }`}
      />
      <PageMetaDescription name="Home" content={Constants.META_DESCRIPTION} />
      {/* <SupportServiceUnavailableBanner lng={lng} /> */}
      <ProfileCompletionAlert lng={lng} />
      {!isEmpty(authProfile) && (
        <NICBanner lng={lng} profile={authProfile} />
      )}

      {Config.REVIEWS_AND_RATINGS_VISIBILITY && (
        <GoogleReviewBanner lng={lng} />
      )}
      <NewFeatureBanner lng={lng} />
      <HelpBar />
      {/* {userProfile !== undefined &&
            userProfile.personalInfo?.gender === "FEMALE" &&
            userProfile.createdBy === "SELF" &&
            ENABLE_HYTCH && (
              <div
                style={{
                  backgroundColor: "#dfdfdf",
                  paddingBottom: 10,
                  paddingTop: 10,
                }}
              >
                <HytchAwarenessBanner />
              </div>
            )} */}
      <div
        className="all-ads-main-container"
        style={{ marginTop: 0, paddingTop: 1 }}
      >
        <Grid container className="all-ads-container">
          <Container lng={lng} />
        </Grid>
      </div>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default withLanguageMigrator(HomePage);