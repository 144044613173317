import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { SectionSubHeading } from "../../custom-typography";
import { useFormikContext } from "formik";
import FormMeta from "../../../../../../meta/edit-self-profile.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown, {
  Mode,
} from "../../../../../../components/molecules/form-dropdown";
import { fieldVisibility, trimFieldValue } from "../../../../../../utils";
import FormTextInput from "../../../../../../components/molecules/form-text-input";
import { hasChangedFormValue } from "../../../../../../utils/data-generator";
import {
  resetChangedField,
  updateAdField,
} from "../../../../../../features/edit-ad";
import Config from "../../../../../../config";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { fetchCommunitiesByReligionId } from "../../../../../../features/generic-data";
import FormAutoComplete from "../../../../../../components/molecules/form-auto-complete";
import { useFilteredProfessions } from "../../../../../../hooks";

export const ParentType = Object.freeze({
  FATHER: "FATHER",
  MOTHER: "MOTHER",
});

const ParentInformation = ({ type }) => {
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    initialValues,
    submitCount,
    setFieldError,
  } = useFormikContext();
  const { t, i18n } = useTranslation();
  const { ethnicities, religions, countries, professions } = useSelector(
    (state) => state.genericData
  );
  const { oldShadow } = useSelector((state) => state.editAdPage);
  const [communities, setCommunities] = useState([]);

  const sectionSubHeading =
    type === ParentType.FATHER ? "common.father" : "common.mother";

  const meta = useMemo(() => {
    const meta = {};
    switch (type) {
      case ParentType.FATHER:
        meta.ethnnicityId = FormMeta.fEthnicityId;
        meta.religionId = FormMeta.fReligionId;
        meta.communityId = FormMeta.fCommunityId;
        meta.subCommunity = FormMeta.fSubCommunity;
        meta.residentCountryCode = FormMeta.fResidentCountryCode;
        meta.caste = FormMeta.fCaste;
        meta.professionId = FormMeta.fProfessionId;
        meta.otherProfession = FormMeta.fOtherProfession;
        break;
      case ParentType.MOTHER:
        meta.ethnnicityId = FormMeta.mEthnicityId;
        meta.religionId = FormMeta.mReligionId;
        meta.communityId = FormMeta.mCommunityId;
        meta.subCommunity = FormMeta.mSubCommunity;
        meta.residentCountryCode = FormMeta.mResidentCountryCode;
        meta.caste = FormMeta.mCaste;
        meta.professionId = FormMeta.mProfessionId;
        meta.otherProfession = FormMeta.mOtherProfession;
        break;
      default:
        break;
    }

    return meta;
  }, [type]);
  const [professionSearch, setProfessionSearch] = useState(
    values[meta.otherProfession.fieldName] ?? ""
  );
  const religionId = values[meta.religionId.fieldName];

  useEffect(() => {
    let initialReligionId = oldShadow[meta.religionId.fieldName];
    if (!isEmpty(religionId) && !isEqual(religionId, initialReligionId)) {
      dispatch(fetchCommunitiesByReligionId({ religionId, setCommunities }));
    }
  }, [
    dispatch,
    initialValues,
    meta.religionId.fieldName,
    oldShadow,
    religionId,
    type,
  ]);

  const professionOtherVisibility = useMemo(() => {
    let professionId = values[meta.professionId.fieldName];
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    return (
      fieldVisibility(meta.otherProfession) &&
      professionId === Config.OTHER_PROFESSION_ID
    );
  }, [meta.otherProfession, meta.professionId, values]);

  const otherProfessionFieldMode = useMemo(() => {
    let professionId = values[meta.professionId.fieldName];
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    let initialProfessionId = initialValues[meta.professionId.fieldName];
    if (typeof initialProfessionId === "string") {
      initialProfessionId = parseInt(initialProfessionId);
    }

    if (!isEqual(professionId, initialProfessionId)) {
      return Mode.standard;
    }
    return Mode.interactive;
  }, [initialValues, meta.professionId.fieldName, values]);

  const communityFieldMode = useMemo(() => {
    const religionId = values[meta.religionId.fieldName];
    const initialReligionId = initialValues[meta.religionId.fieldName];
    return isEqual(religionId, initialReligionId)
      ? Mode.interactive
      : Mode.standard;
  }, [initialValues, meta.religionId.fieldName, values]);

  const hasChangedFormField = (fieldMeta) => {
    return hasChangedFormValue({ initialValues, values, meta: fieldMeta });
  };

  const updateFormField = (fieldMeta) => {
    dispatch(updateAdField(fieldMeta, values, t, setFieldError));
  };

  const resetFormField = (fieldMeta) => {
    dispatch(resetChangedField(fieldMeta, initialValues, setFieldValue));
  };
  const otherProfessionPredictions = useFilteredProfessions({
    needle: professionSearch,
  });

  useEffect(() => {
    const searchedInput = professionSearch ?? "";
    if (typeof searchedInput === "string") {
      setFieldValue(meta.otherProfession.fieldName, searchedInput);
    }
    if (searchedInput.constructor === Object) {
      setFieldValue(
        meta.professionId.fieldName,
        searchedInput[meta.professionId.keyExtractor]
      );
      setFieldValue(meta.otherProfession.fieldName, "");
    }
  }, [
    meta.otherProfession.fieldName,
    meta.professionId.fieldName,
    meta.professionId.keyExtractor,
    professionSearch,
    setFieldValue,
  ]);

  return (
    <Grid container direction="column" className="row-gap-8">
      <SectionSubHeading labelKey={sectionSubHeading} />
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.religionId)}
            onClickInteractiveSave={() => updateFormField(meta.religionId)}
            onClickInteractiveClear={() => resetFormField(meta.religionId)}
            fieldName={meta.religionId.fieldName}
            value={values[meta.religionId.fieldName]}
            onChange={handleChange}
            options={religions}
            keyExtractor={meta.religionId.keyExtractor}
            labelExtractor={meta.religionId.labelExtractor}
            label={t(meta.religionId.label)}
            lang={i18n.language}
            errorText={errors[meta.religionId.fieldName]}
            visibility={fieldVisibility(meta.religionId)}
          />
        </Grid>
        {fieldVisibility(meta.ethnnicityId) && (
          <Grid item xs={12} md={8} lg={6}>
            <FormDropdown
              mode="interactive"
              showInteractiveActions={hasChangedFormField(meta.ethnnicityId)}
              onClickInteractiveSave={() => {
                updateFormField(meta.ethnnicityId);
              }}
              onClickInteractiveClear={() => {
                resetFormField(meta.ethnnicityId);
              }}
              fieldName={meta.ethnnicityId.fieldName}
              value={values[meta.ethnnicityId.fieldName]}
              onChange={handleChange}
              options={ethnicities}
              keyExtractor={meta.ethnnicityId.keyExtractor}
              labelExtractor={meta.ethnnicityId.labelExtractor}
              label={t(meta.ethnnicityId.label)}
              lang={i18n.language}
              errorText={errors[meta.ethnnicityId.fieldName]}
              visibility={fieldVisibility(meta.ethnnicityId)}
            />
          </Grid>
        )}
        {fieldVisibility(meta.communityId) && (
          <Grid item xs={12} md={8} lg={6}>
            <FormDropdown
              mode={communityFieldMode}
              showInteractiveActions={hasChangedFormField(meta.communityId)}
              onClickInteractiveSave={() => updateFormField(meta.communityId)}
              onClickInteractiveClear={() => resetFormField(meta.communityId)}
              fieldName={meta.communityId.fieldName}
              value={values[meta.communityId.fieldName]}
              onChange={handleChange}
              options={communities}
              keyExtractor={meta.communityId.keyExtractor}
              labelExtractor={meta.communityId.labelExtractor}
              label={t(meta.communityId.label)}
              lang={i18n.language}
              errorText={errors[meta.communityId.fieldName]}
              visibility={fieldVisibility(meta.communityId)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        {fieldVisibility(meta.caste) && (
          <Grid item xs={12} md={8} lg={6}>
            <FormTextInput
              mode="interactive"
              showInteractiveActions={hasChangedFormField(meta.caste)}
              onClickInteractiveSave={() => {
                updateFormField(meta.caste);
              }}
              onClickInteractiveClear={() => {
                resetFormField(meta.caste);
              }}
              fieldName={meta.caste.fieldName}
              value={values[meta.caste.fieldName]}
              onChange={handleChange}
              label={t(meta.caste.label)}
              lang={i18n.language}
              // labelStyles={labelStyles}
              placeholder={t(meta.caste.placeholder)}
              errorText={errors[meta.caste.fieldName]}
              visibility={fieldVisibility(meta.caste)}
              onBlur={() => {
                trimFieldValue({
                  fieldName: meta.caste.fieldName,
                  value: values[meta.caste.fieldName],
                  setFieldValue,
                });
              }}
              maxCharactorCount={meta.caste.max}
              max={meta.caste.max}
            />
          </Grid>
        )}
        {fieldVisibility(meta.subCommunity) && (
          <Grid item xs={12} md={8} lg={6}>
            <FormTextInput
              mode="interactive"
              showInteractiveActions={hasChangedFormField(meta.subCommunity)}
              onClickInteractiveSave={() => updateFormField(meta.subCommunity)}
              onClickInteractiveClear={() => resetFormField(meta.subCommunity)}
              fieldName={meta.subCommunity.fieldName}
              value={values[meta.subCommunity.fieldName]}
              onChange={handleChange}
              label={t(meta.subCommunity.label)}
              lang={i18n.language}
              visibility={fieldVisibility(meta.subCommunity)}
              onBlur={() => {
                trimFieldValue({
                  fieldName: meta.subCommunity.fieldName,
                  value: values[meta.subCommunity.fieldName],
                  setFieldValue,
                });
              }}
              maxCharactorCount={meta.subCommunity.max}
              max={meta.subCommunity.max}
            />
          </Grid>
        )}
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(
              meta.residentCountryCode
            )}
            onClickInteractiveSave={() =>
              updateFormField(meta.residentCountryCode)
            }
            onClickInteractiveClear={() =>
              resetFormField(meta.residentCountryCode)
            }
            fieldName={meta.residentCountryCode.fieldName}
            value={values[meta.residentCountryCode.fieldName]}
            onChange={handleChange}
            options={countries}
            keyExtractor={meta.residentCountryCode.keyExtractor}
            labelExtractor={meta.residentCountryCode.labelExtractor}
            label={t(meta.residentCountryCode.label)}
            lang={i18n.language}
            errorText={errors[meta.residentCountryCode.fieldName]}
            visibility={fieldVisibility(meta.residentCountryCode)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className="row-gap-8">
        <Grid item xs={12} md={8} lg={6}>
          <FormDropdown
            mode="interactive"
            showInteractiveActions={hasChangedFormField(meta.professionId)}
            onClickInteractiveSave={() => updateFormField(meta.professionId)}
            onClickInteractiveClear={() => resetFormField(meta.professionId)}
            fieldName={meta.professionId.fieldName}
            value={values[meta.professionId.fieldName]}
            onChange={handleChange}
            options={professions}
            keyExtractor={meta.professionId.keyExtractor}
            labelExtractor={meta.professionId.labelExtractor}
            label={t(meta.professionId.label)}
            lang={i18n.language}
            isOptional={true}
            errorText={submitCount > 0 && errors[meta.professionId.fieldName]}
            visibility={fieldVisibility(meta.professionId)}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <FormAutoComplete
            mode={otherProfessionFieldMode}
            showInteractiveActions={hasChangedFormField(meta.otherProfession)}
            onClickInteractiveSave={() => updateFormField(meta.otherProfession)}
            onClickInteractiveClear={() => resetFormField(meta.otherProfession)}
            fieldName={meta.otherProfession.fieldName}
            onChange={(_fieldName, value) => {
              setProfessionSearch(value);
            }}
            onSearchChange={(_event, value) => {
              setProfessionSearch(value);
            }}
            // value={professionSearch}
            value={values[meta.otherProfession.fieldName]}
            label={t(meta.otherProfession.label)}
            visibility={
              fieldVisibility(meta.otherProfession) && professionOtherVisibility
            }
            lang={i18n.language}
            errorText={errors[meta.otherProfession.fieldName]}
            options={otherProfessionPredictions}
            getOptionLabel={(option) => {
              const { language } = i18n;
              let extractor = meta.professionId.labelExtractor;
              if (language === Config.APP_LANGS.SI) {
                extractor = `${meta.professionId.labelExtractor}_${Config.APP_LANGS.SI}`;
              }
              return typeof option === "string" ? option : option[extractor];
            }}
            freeSolo={true}
            disableClearable={false}
            autoSelect={true}
            onOpen={() => false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ParentInformation;
