import React from "react";
import Payment from "../../../../components/payment/Payment";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FlowStepper from "../../create-flow/templates/stepper";

const PaymentContainerLkOld = ({
  lng,
  contentTopMargin,
  isRenewProfile,
  headingContainerRef,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: "100vh",
        backgroundColor: "#f7f7f7",
      }}
    >
      <Grid item>
        <Grid container direction="column" ref={headingContainerRef}>
          <Typography variant="h3" align="center" className="create-new-ad">
            {t(
              isRenewProfile
                ? "createflow.renewAd"
                : "createflow.create_a_new_ad"
            )}
          </Typography>
          {!isRenewProfile && <FlowStepper />}
        </Grid>
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container complete-ad-container"
          style={{ marginTop: contentTopMargin }}
        >
          <Payment align="center" lng={lng} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentContainerLkOld;
