import React, { useContext, useEffect, useState } from "react";
import DataColumn from "../../../../components/molecules/data-column";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../features/profile-view";
import AppContext from "../../../../context";
import { isEmpty } from "lodash";
import DataSection from "../../../../components/organisms/data-section";
import { fieldVisibility } from "../../../../utils";
import formMeta from "../../form-meta/family-form-meta";
import LightTextColumn from "../../../../components/molecules/light-text-column";
import { useSelector } from "react-redux";

const FamilyInformation = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { initialized } = useSelector((state) => state.genericData);

  const [parentInfo, setParentInfo] = useState([]);
  const [siblingInfo, setSiblingInfo] = useState([]);

  useEffect(() => {
    if (isEmpty(data) || !initialized) return;
    try {
      generateDisplayValues({
        section: DataDisplaySection.parentInformation,
        data: data,
        lang: appLanguage,
      }).then((values) => {
        setParentInfo([values["FATHER"], values["MOTHER"]]);
        setSiblingInfo(values.siblingInfo ?? []);
      });
    } catch (e) {
      console.log(e);
    }
  }, [data, appLanguage, initialized]);

  return (
    !isEmpty(data) && (
      <DataSection heading={t("post.familyInfo")}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="parent-data-view"
        >
          {parentInfo.map((value) => (
            <Grid
              container
              direction="column"
              className="data-single-column-container"
              style={{
                width: isMobileScreen ? "100%" : "48%",
              }}
            >
              <Typography
                className={`parent-data-view-title typo-h5-${i18n.language} margin-bottom-8 font-weight-500`}
              >
                {value.displayName}
              </Typography>
              <DataColumn
                label={t(formMeta.ethnicityId.label)}
                value={value.ethnicity}
                visibility={fieldVisibility(formMeta.ethnicityId)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.religionId.label)}
                value={value.religion}
                visibility={fieldVisibility(formMeta.religionId)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.communityId.label)}
                value={value.community}
                visibility={fieldVisibility(formMeta.communityId)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.subCommunity.label)}
                value={value.subCommunity}
                visibility={fieldVisibility(formMeta.subCommunity)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.caste.label)}
                value={value.caste}
                visibility={fieldVisibility(formMeta.caste)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.professionId.label)}
                value={value.profession}
                visibility={fieldVisibility(formMeta.professionId)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.residentCountryCode.label)}
                value={value.residentCountry}
                visibility={fieldVisibility(formMeta.residentCountryCode)}
                className="parent-data-column"
              />
              <DataColumn
                label={t(formMeta.additionalInfo.label)}
                value={value.additionalInfo}
                visibility={fieldVisibility(formMeta.additionalInfo)}
                className="parent-data-column"
              />
            </Grid>
          ))}
          {!isEmpty(siblingInfo) && (
            <>
              <Typography
                className={`parent-data-view-title typo-h5-${i18n.language} font-weight-500`}
              >
                {t("common.siblings")}
              </Typography>

              <Grid container direction="row" justifyContent="flex-start">
                {siblingInfo.map((sibling) => (
                  <LightTextColumn
                    label={sibling.relationship}
                    value={sibling.status}
                    containerProps={{ xs: 12, sm: 6, md: 4 }}
                  />
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </DataSection>
    )
  );
};

export default FamilyInformation;
