import {
  FETCH_ARCHIVED_SENT_INTERESTS,
  SET_ARCHIVED_SENT_ALL_FETCHED,
} from "./types";
import axios from "axios";

export const getArchivedSentInterests =
  (page = 0) =>
  (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
          "postId"
        )}/interests/sent?ignored=true&pageNum=${page}&pageSize=25`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        let allMatches = res.data.results;
        let page = res.data.pageNum;
        dispatch({
          type: FETCH_ARCHIVED_SENT_INTERESTS,
          payload: {
            archived: allMatches,
            page: page,
            pageSize: 25,
          },
        });
      });
  };

export const setArchivedSentAllFetched = (status) => (dispatch) => {
  dispatch({
    type: SET_ARCHIVED_SENT_ALL_FETCHED,
    payload: {
      status: status,
    },
  });
};
