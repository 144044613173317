import { Grid, Typography } from "@material-ui/core";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { Trans, useTranslation } from "react-i18next";
import "../../style.css";

const IconText = ({ text, isHighlight, transComponent, values = {} }) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid container direction="row" wrap="nowrap" style={{ columnGap: 5 }}>
      <CheckIcon className="pricing-package-card-feature-icon" />
      {transComponent ? (
        <Typography
          variant="body2"
          className="pricing-package-card-feature-txt-en"
        >
          <Trans
            i18nKey={transComponent.key}
            values={transComponent.values}
            components={transComponent.components}
          />
        </Typography>
      ) : (
        <Typography
          variant="body2"
          className={`pricing-package-card-feature-txt-${i18n.language} ${
            isHighlight && "pricing-package-card-feature-highlight-txt"
          }`}
        >
          {t(text, values)}
        </Typography>
      )}
    </Grid>
  );
};

export default IconText;
