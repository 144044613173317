import React, { useMemo } from "react";
import PageHead from "../../components/atoms/page-head";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PageConfigContainer from "../../config/page-configs";

const WebDocument = ({ children }) => {
  const location = useLocation();

  const pageConfig = useMemo(() => {
    const pageConfig = PageConfigContainer.getConfig(location);
    return pageConfig;
  }, [location]);

  return (
    <>
      {pageConfig !== undefined && (
        <PageHead
          title={pageConfig.title}
          description={pageConfig.description}
        />
      )}
      {children}
    </>
  );
};

export default WebDocument;
