import { base64ToBlob } from "base64-blob";
import FileResizer from "react-image-file-resizer";
import Config from "../config";
import { APP_LANG } from "../config/constant";

export const createReduxAction = (type, payload) => ({ type, payload });

export const FileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Base64ToBlob = async (base64) => {
  const blob = await base64ToBlob(base64);
  return blob;
};

export const resizeImageToUpload = ({ blob, onResize }) => {
  return FileResizer.imageFileResizer(
    blob,
    1920,
    1080,
    "JPEG",
    80,
    0,
    (outputBlob) => onResize(outputBlob),
    "blob",
    200,
    200
  );
};

export const fieldVisibility = (fieldMeta) => {
  const metaPlatforms =
    Array.isArray(fieldMeta.platforms) && fieldMeta.platforms;
  const currentPlatform = Config.CURRENT_PLATFORM;
  return metaPlatforms ? metaPlatforms.includes(currentPlatform) : true;
};

export const buildModelByFormMeta = (formMeta) => {
  const currentPlatform = Config.CURRENT_PLATFORM;
  const formEntries = Object.values(formMeta);
  const model = {};
  for (const meta of formEntries) {
    const platforms = meta.platforms ?? [];
    if (meta.ignoreField || !platforms.includes(currentPlatform)) {
      continue;
    }
    const fieldName = meta.fieldName;
    const fieldType = meta.type;
    const defaultValue = meta.defaultValue;
    switch (fieldType) {
      case "array":
        model[fieldName] = [];
        break;
      case "boolean":
        model[fieldName] = defaultValue ? JSON.parse(defaultValue) : false;
        break;
      default: {
        model[fieldName] = !isEmptyOrNullOrUndefined(defaultValue)
          ? defaultValue
          : "";
        break;
      }
    }
  }
  return model;
};

export const isEmptyOrNullOrUndefined = (value) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === "string" && value.length === 0) {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (value.constructor === Object && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};

export const sanitizeEmptyNullUndefinedValues = (input, ignoreKeys = []) => {
  if (input.constructor !== Object && input.constructor !== Array) {
    return input;
  }
  if (input.constructor === Object) {
    for (const [key, value] of Object.entries(input)) {
      if (ignoreKeys.includes(key)) continue;
      if (isEmptyOrNullOrUndefined(value)) {
        delete input[key];
        continue;
      }
      if (value.constructor === Object || value.constructor === Array) {
        sanitizeEmptyNullUndefinedValues(value);
      }
    }
  }
  if (input.constructor === Array) {
    for (const value of input) {
      if (value.constructor === Array || value.constructor === Object) {
        sanitizeEmptyNullUndefinedValues(value);
      }
    }
  }
  return input;
};

export const scrollOnError = () => {
  try {
    const errorElements = document.getElementsByClassName("form-error-text");

    for (const temp of errorElements) {
      if (typeof temp.textContent === "string" && temp.textContent.length > 0) {
        temp.scrollIntoView({
          behavior: "smooth",
          block: "center",
          // inline: "center",
        });
        break;
      }
    }
  } catch (e) {
    console.log("Error! ", e);
  }
};

export const scrollToTop = () => {
  if (document.body?.scrollTop) {
    document.body.scrollTop = 0;
  } else if (document.documentElement?.scrollTop) {
    document.documentElement.scrollTop = 0;
  }
};

export const isOtherProfession = (input) => {
  if (isEmptyOrNullOrUndefined(input)) {
    return false;
  }
  const comparator = typeof input === "string" ? parseInt(input) : input;
  return comparator === Config.OTHER_PROFESSION_ID;
};

export const FormErrorMessage = Object.freeze({
  REQUIRED_FIELD: "REQUIRED_FIELD",
});
export const buildFormErrorMessage = (t, type, fieldName) => {
  let output = "";

  if (type === FormErrorMessage.REQUIRED_FIELD) {
    output = t("errors.formRequiredField", { fieldName: t(fieldName) });
  }
  return output;
};

export const parseToInteger = (input) => {
  let output = input;
  if (typeof input === "string") {
    output = parseInt(input);
  }
  return output;
};

export const isPasswordValid = function (password) {
  const numbers = password.replace(new RegExp("[^0-9]", "gm"), "").length;
  const lowercase = password.replace(new RegExp("[^a-z]", "gm"), "").length;
  const uppercase = password.replace(new RegExp("[^A-Z]", "gm"), "").length;
  const specialChars = password.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g);
  let specialCharCount = 0;
  if (specialChars) {
    specialCharCount = specialChars.length;
  }

  return (
    password.length > 7 &&
    lowercase + uppercase > 0 &&
    numbers + specialCharCount > 1
  );
};

export const trimFieldValue = ({ fieldName, value, setFieldValue }) => {
  const trimmedValue = typeof value === "string" ? value.trim() : "";
  setFieldValue(fieldName, trimmedValue);
};

export const trimEditAdFieldValues = (values) => {
  const obj = {};
  for (const [key, value] of Object.entries(values)) {
    if (typeof value !== "object") {
      obj[key] = typeof value === "string" ? value.trim() : value;
    } else {
      obj[key] = value;
    }
  }
  return obj;
};

export const isStringContainsEmoji = (value) => {
  const regex = new RegExp(
    "(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])"
  );
  return regex.test(value);
};

export const isStringContainsNumbers = (value) => {
  const regex = /\d/;
  return regex.test(value);
};

export const isStringContainsSpecialCharacters = (value) => {
  const regex = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;
  return regex.test(value);
};

export const generateDisplayValueByMeta = ({ meta, extractionKey, lang }) => {
  let value = "";
  if (
    typeof meta === "object" &&
    !Array.isArray(meta) &&
    extractionKey &&
    lang
  ) {
    const key =
      lang === APP_LANG.EN ? extractionKey : `${extractionKey}_${lang}`;
    value = meta[key];
  }
  return value;
};

export const isStringEqual = (str1, str2) => {
  if (typeof str1 !== "string" || typeof str2 !== "string") {
    throw new Error("argument type is invalid");
  }
  return str1.toLowerCase() === str2.toLowerCase();
};

export const sanitizeWhiteSpaces = (input) => {
  if (typeof input !== "string") {
    throw new Error("argument type is invalid");
  }
  let chunks = input.trim().split(" ");
  chunks = chunks.filter((current) => !!current);
  return chunks.join("");
};

export const checkLocallyAuthenticated = () => {
  return localStorage.getItem("auth_token") !== null;
};
