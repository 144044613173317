import React, { useEffect, useRef } from "react";
import PaymentView from "../../../templates/payment-view";
import PackagesView, {
  PRICING_DISPLAY_VARIANTS,
} from "../../../templates/payment-package-view";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
import PaymentInvoice from "./invoice-view";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SUPPORTED_CURRENCIES } from "../../../config/constant";
import { isEmpty } from "lodash";
import PaymentError from "./invoice-view/templates/payment-error";
import Config from "../../../config";

const { PAYMENT_OPTIONS } = Config;

const PaymentContainerLk = () => {
  const currency = useRef("");

  const [paymentErrors, setPaymentErrors] = useState({
    checkoutError: false,
    renewalError: false,
  });
  const [selectedpackage, setSelectedPackage] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [paymentSessionData, setPaymentSessionData] = useState({});
  const [paymentAgreement, SetPaymentAgreement] = useState({
    agreeToTerms: false,
    termsError: false,
  });
  const [isFetchingData, setIsFetchingData] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const handlePaymentContinue = () => {
    if (isEmpty(location.state)) {
      return;
    }

    if (!paymentAgreement.agreeToTerms) {
      SetPaymentAgreement({
        agreeToTerms: false,
        termsError: true,
      });
      return;
    }

    if (location.state.pricingTier?.id !== selectedpackage) {
      return;
    }

    setSelectedPackage(null);
    if (selectedCurrency) {
      if (selectedCurrency === SUPPORTED_CURRENCIES.USD) {
        sessionStorage.setItem("force_lkr", false);
      } else {
        sessionStorage.setItem("force_lkr", true);
      }
    } else {
      sessionStorage.removeItem("force_lkr");
    }

    if (location.state?.isRecurringPayment) {
      sessionStorage.setItem(
        PAYMENT_OPTIONS.IS_RECURRING,
        location.state?.isRecurringPayment
      );
    } else {
      sessionStorage.removeItem(PAYMENT_OPTIONS.IS_RECURRING);
    }

    sessionStorage.setItem(
      "paying_currency",
      paymentSessionData.session?.currency
    );
    sessionStorage.setItem("selected_package", selectedpackage);
    sessionStorage.setItem(
      "selected-subscription-package",
      JSON.stringify(location.state.pricingTier)
    );
    history.push("/checkout");
  };

  const navigateToHome = () => {
    history.push("/");
  };

  useEffect(() => {
    SetPaymentAgreement({ agreeToTerms: false, termsError: false });
    setPaymentSessionData({});

    if (isEmpty(location.state)) {
      setSelectedPackage(null);
      return;
    }
    const query = new URLSearchParams(location.search);
    const param = query.get("id");
    if (param !== null) {
      setSelectedPackage(param);
    } else {
      setSelectedPackage(null);
    }
  }, [location]);

  return (
    <PaymentView
      displayActionButtons={
        selectedpackage &&
        !paymentErrors.checkoutError &&
        !paymentErrors.renewalError
      }
      onClickPrimaryAction={handlePaymentContinue}
      onClickSecondaryAction={navigateToHome}
      isDisabledPrimaryAction={isEmpty(paymentSessionData) || isFetchingData}
    >
      {paymentErrors.checkoutError || paymentErrors.renewalError ? (
        <PaymentError
          paymentErrors={paymentErrors}
          setSelectedPackage={setSelectedPackage}
          setPaymentErrors={setPaymentErrors}
        />
      ) : (
        <>
          {!selectedpackage && (
            <PackagesView
              variant={PRICING_DISPLAY_VARIANTS.PAY_FOR_AD}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              currency={currency}
            />
          )}
          {selectedpackage && (
            <PaymentInvoice
              packageId={selectedpackage}
              selectedCurrency={selectedCurrency}
              paymentSessionData={paymentSessionData}
              setPaymentSessionData={setPaymentSessionData}
              setPaymentErrors={setPaymentErrors}
              paymentAgreement={paymentAgreement}
              SetPaymentAgreement={SetPaymentAgreement}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
              isRecurringPayment={location.state?.isRecurringPayment ?? false}
            />
          )}
        </>
      )}
    </PaymentView>
  );
};

export default PaymentContainerLk;
