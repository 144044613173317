import { packageDetails } from "../../../../../templates/payment-package-view/extension/schema";

export const formatPaymentData = ({ sessionData, packageId }) => {
  const pricingSchema = packageDetails[packageId] ?? {};
  const output = { ...pricingSchema };

  output.session = sessionData;

  const amount = sessionData.amount.toFixed(2);

  output.amount = `${sessionData.currency} ${amount}`;

  const amountBeforeDiscount = sessionData.amountBeforeDiscount.toFixed(2);
  output.amountBeforeDiscount = `${sessionData.currency} ${amountBeforeDiscount}`;

  const monthlyAmount = (
    amountBeforeDiscount / pricingSchema.durationMonths
  ).toFixed(2);
  output.monthlyAmount = `${sessionData.currency} ${monthlyAmount}`;

  output.durationMonths = pricingSchema.durationMonths;

  output.promoCode = sessionData.promoCode;

  const discountAmount = (
    sessionData.amountBeforeDiscount - sessionData.amount
  ).toFixed(2);
  output.discountPercentage = sessionData.discountPercentage;
  output.discountAmount = `${sessionData.currency} ${discountAmount}`;

  return output;
};
