import { Grid, Typography } from "@material-ui/core";
import React from "react";

const IconText = ({ text, icon }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="row-gap-8"
      xs={4}
    >
      <img src={icon} alt="" />
      <Typography variant="body2" className="feature-icon-txt">
        {text}
      </Typography>
    </Grid>
  );
};

export default IconText;
