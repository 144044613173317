import {
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import "../../style.css";
import { getGenericPaymentMethods } from "../../../../../v2/features/generic-data";
import { isEmpty } from "lodash";
import { updateCardDetails } from "../../../../../v2/features/payment";
import Constants, {
  PAYMENT_METHOD_IDS,
  SUPPORTED_CURRENCIES,
} from "../../../../../v2/config/constant";
import PayhereForm from "./payhere-form";
import PaymentMethodView from "./payment-method-view";
import { useTranslation } from "react-i18next";
import Config from "../../../../../v2/config";
import CancelIcon from "@material-ui/icons/Cancel";

const { CURRENCY_META } = Config;

const UpdateCardModal = ({ open, handleClose, authProfile }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [payhereSession, setPayhereSession] = useState({});
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    SUPPORTED_CURRENCIES.USD
  );

  const { t } = useTranslation();

  const onClickPaymentMethod = async (paymentMethodId) => {
    const forceLKR =
      authProfile.personalInfo?.residentCountry?.code !==
        Constants.LANKA_COUNTRY_CODE &&
      selectedCurrency === SUPPORTED_CURRENCIES.LKR;
    const data = {
      forceLKR: forceLKR,
      paymentMethodId,
    };
    try {
      setIsRedirecting(true);
      const response = await updateCardDetails({
        postId: authProfile.id,
        data,
      });
      if (response.success) {
        if (response.body?.ipg === "STRIPE") {
          const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
          stripe.redirectToCheckout({
            sessionId: response.body.sessionId,
          });
        }
        if (response.body?.ipg === "PAYHERE") {
          setPayhereSession(response.body?.payhereParams);
          document.getElementById("pay-by-payhere").click();
        }
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleClose(false);
    }
  };

  const filteredPaymentMethods = useMemo(() => {
    let tempPaymentMethods = [];
    let cardPaymentMethods = [PAYMENT_METHOD_IDS.FOREIGN_CC];
    const residentCountryCode =
      authProfile.personalInfo?.residentCountry?.code ?? "";

    if (
      residentCountryCode === Constants.LANKA_COUNTRY_CODE ||
      selectedCurrency === SUPPORTED_CURRENCIES.LKR
    ) {
      cardPaymentMethods.unshift(PAYMENT_METHOD_IDS.LOCAL_CC);
    }

    if (!isEmpty(paymentMethods)) {
      const tempFilteredMethods = [];
      cardPaymentMethods.forEach((id) => {
        const method = paymentMethods.find((el) => el.id === id);
        if (method) {
          tempFilteredMethods.push(method);
        }
      });
      tempPaymentMethods = tempFilteredMethods;
    }
    return tempPaymentMethods;
  }, [authProfile, paymentMethods, selectedCurrency]);

  const supportedCurrencies = useMemo(() => {
    return CURRENCY_META.filter((current) =>
      Config.SUPPORTED_CURRENCIES.includes(current.code)
    );
  }, []);

  useEffect(() => {
    getGenericPaymentMethods()
      .then((res) => {
        const payemtnMethods = res.body ?? [];
        setPaymentMethods(payemtnMethods);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  useEffect(() => {
    //stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid container direction="column" style={{ padding: 24, rowGap: 8 }}>
        <CancelIcon
          className="auto-renew-cancel-modal-close-icon"
          onClick={() => handleClose(false)}
        />
        <Typography variant="h6" className="auto-renewal-modal-title">
          {t("setting.updateCardDetails")}
        </Typography>
        <Divider style={{ width: "100%" }} />

        {authProfile.personalInfo?.residentCountry?.code !==
          Constants.LANKA_COUNTRY_CODE &&
          !isRedirecting && (
            <FormControl
              variant="outlined"
              className="update-card-currency-selector"
              style={{ alignItems: "center" }}
            >
              <Select
                value={selectedCurrency}
                style={{ width: 160 }}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {supportedCurrencies.map((currency) => (
                  <MenuItem value={currency.code} key={currency.code}>
                    {currency.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

        {isRedirecting ? (
          <Typography variant="body2" className="primary-txt-color">
            {t("payment.cardUpdateRedirectMessage")}
          </Typography>
        ) : (
          <Typography variant="body2" className="primary-txt-color">
            {t("setting.updateCardDetailsNotice")}
          </Typography>
        )}
        <Grid
          container
          direction="column"
          alignItems="center"
          className="update-card-modal-payment-methods-container"
        >
          {isEmpty(filteredPaymentMethods) || isRedirecting ? (
            <CircularProgress className="spinner" />
          ) : (
            filteredPaymentMethods.map((method) => (
              <PaymentMethodView
                label={method.name}
                icon={method.icon}
                id={method.id}
                onSelectMethod={(paymentMethodId) =>
                  onClickPaymentMethod(paymentMethodId)
                }
              />
            ))
          )}
        </Grid>
      </Grid>
      <PayhereForm data={payhereSession} />
    </Dialog>
  );
};

export default UpdateCardModal;
