import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import PackageCard from "./package-card";
import {
  COUNTRY_CURRENCY_META,
  PAYMENT_IDS,
  SUPPORTED_CURRENCIES,
} from "../../config/constant";
import Config from "../../config";
import "./style.css";
import { getPricingTiers } from "../../features/generic-data";
import { generatePricingPackages } from "./extension/methods";
import Slider from "react-slick";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const PRICING_DISPLAY_VARIANTS = Object.freeze({
  PRICING: "PRICING",
  PAY_FOR_AD: "PAY_FOR_AD",
});

const { CURRENT_PLATFORM, CURRENCY_META } = Config;

const PackagesView = ({
  variant,
  selectedCurrency,
  setSelectedCurrency,
  currency,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("LK");
  const [pricingTiers, setPricingTiers] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");

  const { authProfile } = useSelector((state) => state.application);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  // const isTabView = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isTabView = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:800px)"
  );

  const supportedCountries = useMemo(() => {
    return (
      COUNTRY_CURRENCY_META.filter(
        (current) =>
          current.code === Config.CURRENT_PLATFORM || current.code === "other"
      ) ?? []
    );
  }, []);

  const supportedCurrencies = useMemo(() => {
    return CURRENCY_META.filter((current) =>
      Config.SUPPORTED_CURRENCIES.includes(current.code)
    );
  }, []);

  const pricingPackages = useMemo(() => {
    const residentCountry = authProfile.personalInfo?.residentCountry?.code;
    return generatePricingPackages({
      pricingTiers,
      selectedCountry,
      supportedCountries,
      CURRENT_PLATFORM,
      variant,
      selectedCurrency,
      residentCountry,
    });
  }, [
    pricingTiers,
    selectedCountry,
    supportedCountries,
    variant,
    selectedCurrency,
    authProfile,
  ]);

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: isMobileView ? 1 : isTabView ? 2 : 3,
    // swipe: false,
    // centerMode: isMobileView && true,
    // centerPadding: isMobileView ? "10px 0px 0px 0px" : "",
    initialSlide: 1,
  };

  const currencySelectorVisibility = useMemo(() => {
    const residentCountry = authProfile.personalInfo?.residentCountry?.code;
    if (!isEmpty(authProfile) && residentCountry !== CURRENT_PLATFORM) {
      return true;
    }
    return false;
  }, [authProfile]);

  useEffect(() => {
    getPricingTiers({ showNew: true })
      .then((res) => {
        setPricingTiers(res.body);
      })
      .catch((e) => {
        console.log("Error >>>>", e);
      });
  }, []);

  useEffect(() => {
    if (variant === PRICING_DISPLAY_VARIANTS.PAY_FOR_AD) {
      if (currency?.current) {
        setSelectedCurrency(currency.current);
        return;
      }
      const residentCountry = authProfile.personalInfo?.residentCountry?.code;
      if (!isEmpty(authProfile) && residentCountry !== CURRENT_PLATFORM) {
        setSelectedCurrency(SUPPORTED_CURRENCIES.USD);
      }
    }
  }, [authProfile, setSelectedCurrency, currency, variant]);

  useEffect(() => {
    setSelectedPackage(
      localStorage.getItem(PAYMENT_IDS.SELECTED_PACKAGE_ID) ?? ""
    );
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className="packages-view-container"
    >
      {variant === PRICING_DISPLAY_VARIANTS.PRICING && (
        <>
          <Typography variant="h3" style={{ color: "#333" }}>
            {t("supports.pricing")}
          </Typography>
          <Typography variant="body1" style={{ color: "#333" }}>
            {t("supports.whereDoYouLive")}
          </Typography>

          <FormControl variant="outlined">
            <Select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              style={{ width: 160 }}
            >
              {supportedCountries.map((country, i) => (
                <MenuItem value={country.code} key={i}>
                  {t(country.translationKey)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {variant === PRICING_DISPLAY_VARIANTS.PAY_FOR_AD &&
        currencySelectorVisibility && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className="package-currency-selector margin-top-8"
          >
            <FormControl variant="outlined">
              <Select
                value={selectedCurrency}
                onChange={(e) => {
                  setSelectedCurrency(e.target.value);
                  currency.current = e.target.value;
                }}
                style={{ width: 160 }}
              >
                {supportedCurrencies.map((currency, i) => (
                  <MenuItem value={currency.code} key={currency.code}>
                    {currency.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

      <Grid
        item
        direction="row"
        xs={12}
        style={{
          marginTop: 30,
          padding: "0 20px 0 20px",
        }}
      >
        {!isEmpty(pricingPackages.regularPackages) ? (
          <Slider
            {...settings}
            // onTouchStart={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            // }}
            waitForAnimate={false}
            // beforeChange={() => setIsDragging(true)}
            // afterChange={() => setIsDragging(false)}
          >
            {pricingPackages.regularPackages.map((element, key) => (
              <PackageCard
                packageKey={key}
                id={element.id}
                pricingTier={element.pricingTier}
                name={element.name}
                duration={element.packageDuration}
                isPreferred={element.isPreferred}
                selectedPackage={selectedPackage}
                setSelectedPackage={setSelectedPackage}
                packageAmount={element.packageAmount}
                monthlyAmount={element.monthlyAmount}
                currency={element.currency}
                features={element.features}
              />
            ))}
          </Slider>
        ) : (
          <CircularProgress style={{ color: "#cca326" }} />
        )}
      </Grid>
    </Grid>
  );
};

export default PackagesView;
