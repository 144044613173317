import { SET_ARCHIVED_RECEIVED_ALL_FETCHED } from "./types";

export const setArchivedReceivedAllFetched = (status) => (dispatch) => {
  dispatch({
    type: SET_ARCHIVED_RECEIVED_ALL_FETCHED,
    payload: {
      status: status,
    },
  });
};
