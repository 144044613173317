import {
  Grid,
  Typography,
  Button,
  Divider,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import RePlayIcon from "@material-ui/icons/Replay";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import Config from "../../../../config";
import GenericDataAPI from "../../../../services/api/generic";
import { createPayementSession } from "../../../../features/payment";
import PaymentPackageCard from "../../../../templates/profile-view/payment/payment-package-card";
import FormCheckbox from "../../../../components/molecules/checkbox";
import { ArrowBackIos, Close } from "@material-ui/icons";

import "./style.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FormDropdown from "../../../../components/molecules/form-dropdown";
import { HttpCode } from "../../../../config/constant";
import AppLogger from "../../../../utils/logger";
import CorrectIcon from "@material-ui/icons/Check";
import notices from "./schema";
import PaymentView from "../../../../templates/payment-view";

const {
  CURRENCY_META,
  BASE_CURRENCY,
  CURRENT_PLATFORM,
  DOMAIN_DISPLAY_NAME,
  TWO_THREE_MONTH_PACKAGES_PROMOS,
} = Config;

const renewalProfileStatus = ["EXPIRED", "LIVE"];

const PaymentContainerIn = () => {
  const { authProfile } = useSelector((state) => state.application);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [checkoutError, setCheckoutError] = useState(false);
  const [postRenewalError, setPostRenewalError] = useState(false);
  const [currency, setCurrency] = useState(BASE_CURRENCY);
  const [pricingPackages, setPricingPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [paymentSessionInfo, setPaymentSessionInfo] = useState({});
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [showPromoCodeSection, setShowPromocodeSection] = useState(false);
  const [discountInfo, setDiscountInfo] = useState("");
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [hasAgreedToTermsError, setHasAgreedToTermsError] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    if (!isEmpty(authProfile)) {
      if (
        authProfile?.personalInfo?.residentCountry?.code !== CURRENT_PLATFORM
      ) {
        setCurrency("USD");
      }
    }
    GenericDataAPI.getPricingTiers({})
      .then((response) => {
        if (!response.success) {
          return; // TODO: Error Handling
        }
        const packages = response.body;
        setPricingPackages(packages);
        const preferredPackage = packages.find((current) => current.preferred);
        if (!isEmpty(preferredPackage)) {
          setSelectedPackage(preferredPackage);
        }
      })
      .catch((error) => {
        // TODO: Error Handling
        console.log(">>>> error ", error);
      });
  }, [authProfile]);

  const handlePaymentSession = useCallback(
    async ({ promo = null }) => {
      if (isEmpty(authProfile)) {
        return;
      }

      setIsFetchingData(true);
      const postId = authProfile.id;

      const isRenewAccount = renewalProfileStatus.includes(authProfile.status);
      const forceLKR = currency !== "USD";
      const pricingTierId = selectedPackage.id ?? "";

      let data = {
        ...(promo && { promoCode: promo.trim() }),
        isRenew: isRenewAccount,
        forceLKR: forceLKR,
        pricingTierId: pricingTierId,
        simulate: true,
      };

      try {
        const paymentSessionResponse = await createPayementSession({
          postId,
          data,
        });

        if (!paymentSessionResponse.success) {
          setPaymentSessionInfo({
            amount:
              currency === "USD"
                ? selectedPackage.amountUSD
                : selectedPackage.amountLKR,
          });
          if (paymentSessionResponse.statusCode === HttpCode.BAD_REQUEST) {
            const errorCode = paymentSessionResponse.body.code;
            switch (errorCode) {
              case 1400:
              case 1401:
              case 1402:
              case 1403:
              case 1311:
              case 1312:
                setPromoCodeError(t(`serverError.${errorCode}`));
                setShowPromocodeSection(true);
                break;
              case 1404:
                if (TWO_THREE_MONTH_PACKAGES_PROMOS.includes(promo)) {
                  setPromoCodeError(
                    t("payment.promoCodeValidForTwoAndThreeMonthsPackages")
                  );
                  setShowPromocodeSection(true);
                } else {
                  setPromoCodeError(t(`serverError.${errorCode}`));
                  setShowPromocodeSection(true);
                }
                break;
              case 1504:
              case 1512:
                setPostRenewalError(true);
                break;
              default:
                setPromoCodeError("");
                break;
            }
          }
          return;
        }

        localStorage.setItem(
          "session_creation",
          JSON.stringify(paymentSessionResponse.body)
        );

        const paymentSession = paymentSessionResponse.body;

        if (paymentSessionResponse?.body?.promoCode) {
          const discountPrice = (
            paymentSession.amountBeforeDiscount - paymentSession.amount
          ).toFixed(2);

          const currencyLabel = CURRENCY_META.find(
            (element) => element.code === paymentSession.currency
          );

          const promoDiscount = `${currencyLabel.shortName} ${discountPrice}`;

          const discountPercentage = paymentSession.discountPercentage;

          setDiscountInfo({
            promoDiscount,
            discountPercentage,
          });

          localStorage.setItem(
            "promoCode",
            paymentSessionResponse?.body?.promoCode ?? ""
          );
          setPromoCode(paymentSessionResponse?.body?.promoCode ?? "");
          setIsPromoCodeApplied(true);
          setShowPromocodeSection(true);
        } else {
          localStorage.removeItem("promoCode");
          setIsPromoCodeApplied(false);
          setShowPromocodeSection(false);
        }
        setPaymentSessionInfo(paymentSessionResponse.body);
      } catch (error) {
        setCheckoutError(true);
        AppLogger.exception(error);
      } finally {
        setIsFetchingData(false);
      }
    },
    [
      authProfile,
      currency,
      selectedPackage.amountLKR,
      selectedPackage.amountUSD,
      selectedPackage.id,
      t,
    ]
  );

  const handlePayment = useCallback(() => {
    if (isEmpty(pricingPackages) || isEmpty(selectedPackage)) {
      return;
    }
    if (currency === "USD") {
      sessionStorage.setItem("force_lkr", false);
    } else {
      sessionStorage.setItem("force_lkr", true);
    }

    handlePaymentSession({});
  }, [currency, handlePaymentSession, pricingPackages, selectedPackage]);

  useEffect(() => {
    handlePayment();
  }, [handlePayment]);

  const handleApplyPromoCode = () => {
    if (isEmpty(promoCode)) {
      setPromoCodeError(t("payment.promoCodeIsRequired"));
    } else {
      const promo = promoCode || null;
      handlePaymentSession({ promo });
    }
  };

  const handleRemovePromoCode = () => {
    setPromoCode("");
    handlePaymentSession({});
  };

  const handleClosePromoCodeSection = () => {
    setPromoCode("");
    setShowPromocodeSection(false);
    setPromoCodeError("");
  };

  const handlePaymentContinue = () => {
    if (!hasAgreedToTerms) {
      setHasAgreedToTermsError(true);
      return;
    }
    sessionStorage.setItem("paying_currency", currency);
    sessionStorage.setItem("selected_package", selectedPackage.id);
    sessionStorage.setItem(
      "selected-subscription-package",
      JSON.stringify(selectedPackage)
    );
    history.push("/checkout");
  };

  const navigateToHome = () => {
    history.push("/");
  };

  const supportedCurrencies = useMemo(() => {
    const output = CURRENCY_META.filter((current) =>
      Config.SUPPORTED_CURRENCIES.includes(current.code)
    );
    return output;
  }, []);

  const currencyDropdownVisibility = useMemo(() => {
    let output = true;
    if (!isEmpty(authProfile)) {
      if (
        authProfile.personalInfo?.residentCountry?.code === CURRENT_PLATFORM
      ) {
        output = false;
      }
    }
    return output;
  }, [authProfile]);

  return (
    <PaymentView
      displayActionButtons={
        !checkoutError &&
        !postRenewalError &&
        !isEmpty(pricingPackages) &&
        !isEmpty(paymentSessionInfo)
      }
      onClickPrimaryAction={handlePaymentContinue}
      onClickSecondaryAction={navigateToHome}
      isDisabledPrimaryAction={isFetchingData || isEmpty(pricingPackages)}
    >
      <Grid container direction="column" className="payment-page-content">
        <Grid container direction="column" style={{ maxWidth: 550 }}>
          {(postRenewalError || checkoutError) && (
            <Typography
              variant="body1"
              className={`checkout-error checkout-error-${i18n.language}`}
            >
              {postRenewalError
                ? t("payment.postNotWithinRenewalPeriod")
                : checkoutError
                ? t("payment.somethingWentWrong")
                : null}
            </Typography>
          )}
          {!checkoutError &&
          !postRenewalError &&
          !isEmpty(pricingPackages) &&
          !isEmpty(paymentSessionInfo) ? (
            <>
              <Grid
                container
                direction="column"
                className="packages-inner-panel"
                style={{ rowGap: 15 }}
              >
                {currencyDropdownVisibility && (
                  <FormDropdown
                    options={supportedCurrencies}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    keyExtractor="code"
                    labelExtractor="label"
                    lang={i18n.language}
                    containerStyles={{ width: 160 }}
                  />
                )}
                <Grid container direction="row" justifyContent="center">
                  {isEmpty(pricingPackages) ? (
                    <CircularProgress size={25} className="circular-progress" />
                  ) : (
                    pricingPackages.map((currentPackage, index) => {
                      return (
                        <PaymentPackageCard
                          key={index}
                          durationMonths={currentPackage.durationMonths}
                          isPreferred={currentPackage.preferred}
                          currency={currency}
                          packagesCount={pricingPackages.length}
                          displayAmount={currentPackage.amountLKR}
                          displayAmountUsd={currentPackage.amountUSD}
                          payAmount={currentPackage.amountLKR}
                          payAmountUsd={currentPackage.amountUSD}
                        />
                      );
                    })
                  )}
                </Grid>

                <Grid container className="promotion-notice-container">
                  {notices.promotionalNotices.map((item) => (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{ columnGap: 2 }}
                    >
                      <CorrectIcon className="correct-icon" />
                      <Typography variant="caption">{t(item)}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid container direction="column" className="promo-section">
                <Grid container direction="row" justifyContent="space-between">
                  {!showPromoCodeSection ? (
                    <Typography
                      className="add-promo-code-txt"
                      onClick={() => setShowPromocodeSection(true)}
                    >
                      {t("payment.addPromoCode")}
                    </Typography>
                  ) : (
                    <Grid></Grid>
                  )}
                  {showPromoCodeSection && !isPromoCodeApplied && (
                    <Close
                      className="close-icon"
                      onClick={() => handleClosePromoCodeSection()}
                    />
                  )}
                </Grid>

                {(showPromoCodeSection || !isEmpty(promoCode)) && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography className="promo-code-txt">
                      {t("payment.promoCode")}
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      style={{
                        width: isMobileScreen ? "70%" : "50%",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        style={{ columnGap: 5 }}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <FormControl
                          variant="outlined"
                          className="select-wrapper"
                        >
                          <OutlinedInput
                            id="outlined-adornment-password"
                            value={promoCode}
                            disabled={isPromoCodeApplied}
                            onChange={(e) => {
                              setPromoCode(e.target.value);
                              setPromoCodeError("");
                            }}
                            className="input-container"
                            endAdornment={
                              isPromoCodeApplied && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleRemovePromoCode()}
                                    edge="end"
                                    className="input-icon-container"
                                  >
                                    <Close
                                      className="promo-code-remove-icon"
                                      style={{
                                        pointerEvents: isPromoCodeApplied
                                          ? "auto"
                                          : "none",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          />
                        </FormControl>
                        {!isPromoCodeApplied &&
                          (isFetchingData ? (
                            <CircularProgress
                              size={16}
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <Button
                              style={{
                                width: "30%",
                                backgroundColor: "#cca326",
                                fontSize: 11,
                                color: "#fff",
                              }}
                              onClick={() => handleApplyPromoCode()}
                            >
                              {t("payment.apply")}
                            </Button>
                          ))}
                      </Grid>
                      {isPromoCodeApplied && !isFetchingData && (
                        <Typography
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          className="promo-applied-btn"
                        >
                          <i className="fas fa-check"></i> &nbsp;{" "}
                          {t("payment.promoApplied")}
                        </Typography>
                      )}
                      {promoCodeError && (
                        <Typography
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          className="promo-code-error-txt"
                        >
                          {promoCodeError}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {isPromoCodeApplied && (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className="discount-section"
                  >
                    <Typography className="discount-txt">{`${t(
                      "payment.promoDiscount",
                      { discount: discountInfo.discountPercentage }
                    )}`}</Typography>
                    {isFetchingData ? (
                      <CircularProgress size={16} style={{ color: "#000" }} />
                    ) : (
                      <Typography className="discount-txt">{`- ${discountInfo.promoDiscount}`}</Typography>
                    )}
                  </Grid>
                  <Divider className="section-divider" />
                </>
              )}

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className="total-price-section"
              >
                <Typography>{t("payment.totalDue")}</Typography>
                {isFetchingData ? (
                  <CircularProgress size={16} style={{ color: "#000" }} />
                ) : (
                  <Typography>{`${currency} ${(
                    paymentSessionInfo.amount ?? 0
                  ).toFixed(2)}`}</Typography>
                )}
              </Grid>

              <Divider className="section-divider" />
              <Grid className="terms-section">
                <FormCheckbox
                  value={hasAgreedToTerms}
                  handleChange={(e) => {
                    setHasAgreedToTermsError(false);
                    setHasAgreedToTerms(e.target.checked);
                  }}
                  label={t("payment.agreeToTerms", {
                    domainName: DOMAIN_DISPLAY_NAME,
                  })}
                  containerProps={{
                    ...(hasAgreedToTermsError && { border: "1px solid red" }),
                    paddingRight: 5,
                  }}
                  labelProps={{ fontWeight: 400, color: "#000" }}
                />
              </Grid>
            </>
          ) : !postRenewalError && !checkoutError ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              className="loading-container"
            >
              <CircularProgress
                size={25}
                style={{
                  color: "#cca326",
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {postRenewalError && (
          <Grid container direction="row" justifyContent="center">
            <Button
              variant="contained"
              className="secondary-button margin-top-16 margin-bottom-16"
              startIcon={<HomeIcon />}
              onClick={() => history.push("/")}
            >
              {t("payment.goToHome")}
            </Button>
          </Grid>
        )}

        {checkoutError && (
          <Grid container direction="row" justifyContent="center">
            <Button
              variant="contained"
              className="secondary-button margin-top-16 margin-bottom-16"
              startIcon={<RePlayIcon />}
              onClick={() => handlePayment()}
            >
              {t("payment.tryAgain")}
            </Button>
          </Grid>
        )}
      </Grid>
    </PaymentView>
  );
};

export default PaymentContainerIn;
