import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { accordianSubTab } from "../../actions/accordianSubTab";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {clearSpamInterestPosts, getSpamInterestPosts} from "../../actions/getSpamInterestPosts";
import {getArchivedSpamInterestPosts} from "../../actions/getArchivedSpamInterestPosts";
import {setSpamInterestSideMenuLoading} from "../../actions/setSpamInterestSideMenuLoading";
import InterestListCards from "./interest-com/InterestListItemCards";
import { getInnerHeight } from "./methods/getInnerHeight";
import Search from "./interest-com/Search";
import { CircularProgress } from "@material-ui/core";
import { SettingsRounded } from "@material-ui/icons";

function InterestSideView(props) {

  const { setSpamInterestSideMenuLoading, getArchivedSpamInterestPosts } =
    props;

  const [search, setSearch] = useState("");
  // const [receivedArchivedCount, setReceivedArchivedCount] = useState(0);
  const [isOpenAccodian, setIsOpenAccodian] = useState(false);
  const [hideMoreBtn, setHideMoreBtn] = useState(false);
  const [updatingId, setUpdatingId] = useState("");
  const [receivedPostPage, setReceivedPostPage] = useState(0);
  const [archivedReceivedPostPage, setArchivedReceivedPostPage] = useState(0);

  useEffect(() => {
    props.setSpamInterestSideMenuLoading();
    props.getSpamInterestPosts();
  }, [props.location.pathname]);

  useEffect(() => {
    setUpdatingId(props.updatedId);
  }, [props.updatedId])

  useEffect(() => {
    if (isOpenAccodian) {
      setArchivedReceivedPostPage(0);
      setSpamInterestSideMenuLoading();
      getArchivedSpamInterestPosts();
    }
  }, [
    isOpenAccodian,
    setSpamInterestSideMenuLoading,
    getArchivedSpamInterestPosts,
  ]);

  // useEffect(() => {
  //   var element = document.getElementById('interest-view-div-unique');
  //   if (element.clientHeight >= element.scrollHeight) {
  //     if (!props.sideMenuReceivedAllFetched) {
  //       props.setSpamInterestSideMenuLoading();
  //       props.getSpamInterestPosts(receivedPostPage+1);
  //       setReceivedPostPage(receivedPostPage+1);
  //     }
  //   }
  // }, [props.sideMenuInterests])

  // useEffect(() => {
  //   var element = document.getElementById('interest-view-div-unique');
  //   if (element.clientHeight >= element.scrollHeight) {
  //     if (hideMoreBtn && props.sideMenuReceivedAllFetched && !props.sideMenuArchivedReceivedAllFetched) {
  //       props.setSpamInterestSideMenuLoading();
  //       props.getArchivedSpamInterestPosts(archivedReceivedPostPage+1);
  //       setArchivedReceivedPostPage(archivedReceivedPostPage+1);
  //     }
  //   }
  // }, [props.sideMenuArchivedInterests])

  // useEffect(() => {
  //   getInnerHeight("interest-view-div-unique", 70);

  //   if (props.history.pathname === "/interests-matched") {
  //     props.handleIntBtns("match");
  //   }

  //   const intId = document.location.href.split("=")[1];
  //   setTimeout(() => {
  //     if (intId) {
  //       let el = document.getElementById(intId);

  //       if (el) {
  //         el.click();
  //       }        
  //     }
  //   }, 2000);
  // }, []);

  // useEffect(() => {
  //   const intId = document.location.href.split("=")[1];
  //   if(intId && !props.sideMenuReceivedAllFetched && props.sideMenuInterests.filter((el) => el.id === intId).length == 0) {
  //     props.setSpamInterestSideMenuLoading();
  //     props.getSpamInterestPosts(receivedPostPage+1);
  //     setReceivedPostPage(receivedPostPage+1);
  //   }
  // }, [props.sideMenuInterests]);

  const geInterestArchived = () => {
    props.setSpamInterestSideMenuLoading();
    props.getArchivedSpamInterestPosts(archivedReceivedPostPage + 1);
    setArchivedReceivedPostPage(archivedReceivedPostPage + 1);
  }

  const onIgoreRequest = () => {
    props.clearSpamInterestPosts();
    props.getSpamInterestPosts();
    setReceivedPostPage(0);
  }

  const handleScroll = (event) => {
    if (props.sideMenuInterestsLoading) {
      return;
    }
    const node = event.target;
    const scrollMargin = 100;

    // const bottom = node.scrollHeight - Math.round(node.scrollTop) === node.clientHeight;
    const bottom =
      Math.abs(node.scrollHeight - node.clientHeight - node.scrollTop) <
      scrollMargin;

    if (bottom) {
      if (!props.sideMenuReceivedAllFetched) {
        props.setSpamInterestSideMenuLoading();
        props.getSpamInterestPosts(receivedPostPage+1);
        setReceivedPostPage(receivedPostPage+1);
      }
      if (isOpenAccodian && props.sideMenuReceivedAllFetched && !props.sideMenuArchivedReceivedAllFetched) {
        props.setSpamInterestSideMenuLoading();
        props.getArchivedSpamInterestPosts(archivedReceivedPostPage+1);
        setArchivedReceivedPostPage(archivedReceivedPostPage+1);
      }
    }
  };

  return (
    <div
      className={
        props.intId ? "interest-view-div view-hide" : "interest-view-div"
      }
      id="interest-view-div-unique"
      onScroll={(e) => handleScroll(e)}
    >
      <Search lng={props.lng} search={props.search} setSearch={setSearch} />
      <InterestListCards
        intId={props.intId}
        posts={(props.sideMenuInterests
          .filter((el) => el.ignored === false)
          .filter(
            (el) =>
              el.post.personalInfo.displayName
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
          )).sort((a, b) => b.likedAt.localeCompare(a.likedAt))}
        lng={props.lng}
        onIgoreRequest={onIgoreRequest}
      />
      
      {(props.sideMenuReceivedAllFetched) ? (
        <div
          className={isOpenAccodian ? "accordion active" : "accordion"}
          onClick={() => setIsOpenAccodian(!isOpenAccodian)}
        >
          {props.lng ? (
            "Withdrawn / Ignored"
          ) : (
            <span className="sinhala-w-600">
              කැමැත්ත ඉවත් කර ගන්නා ලදී / නොසලකා හැරීම
            </span>
          )}
        </div>
      ) : null}

      {isOpenAccodian ? (
        <div className="panel">
          {props.sideMenuReceivedAllFetched &&
            <>
              {(props.sideMenuInterests.filter((el) => (el.ignored === true) || (el.archived === true))).length > 0 ?
                <InterestListCards
                  intId={props.intId}
                  posts={props.sideMenuInterests
                    .filter((el) => ((el.ignored === true) || (el.archived === true)))}
                  lng={props.lng}
                />
                :
                null}
              {(props.sideMenuArchivedInterests) &&
                <InterestListCards
                  intId={props.intId}
                  posts={props.sideMenuArchivedInterests}
                  lng={props.lng}
                />
              }
              {!props.sideMenuInterestsLoading &&
                !props.sideMenuArchivedReceivedAllFetched && (
                  <p className="see-more-btn" onClick={geInterestArchived}>
                    See more...
                  </p>
                )}
            </>
          }
        </div>
      ) : null}
      {(props.sideMenuInterestsLoading) &&
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <CircularProgress className="loading-circle" style={{color: '#c19d0d'}} />
        </div>
      }
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSpamInterestPosts, accordianSubTab, getArchivedSpamInterestPosts, setSpamInterestSideMenuLoading, clearSpamInterestPosts }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    sideMenuArchivedInterests: state.matches.sideMenuSpamArchivedInterests.archived,
    sideMenuInterests: state.matches.sideMenuSpamInterests.interests,
    updatedId: state.matches.updatedId,
    sideMenuReceivedAllFetched: state.matches.sideMenuSpamReceivedAllFetched,
    sideMenuInterestsLoading: state.matches.sideMenuSpamInterestsLoading,
    sideMenuArchivedReceivedAllFetched: state.matches.sideMenuSpamArchivedReceivedAllFetched
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(InterestSideView));
