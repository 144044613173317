import React, { useEffect, useMemo } from "react";
import Config from "../../config";
import PageHead from "../../../components/common/PageHead";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FooterMobile from "../../../components/common/FooterMobile";
import Footer from "../../../components/common/Footer";
import withLanguageMigrator from "../../hoc/language-migrator";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import MakertingResourceSubmission from "../../templates/marketing-resource-submission";
import "./style.css";
import PostServiceApi from "../../services/api/post-service";
import { getAuthUserProfile } from "../../features/application";
import moment from "moment";

const BankTransferSuccessPage = ({ lng }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authProfile } = useSelector((state) => state.application);

  const subHeading = useMemo(() => {
    if (
      isEmpty(authProfile) ||
      isEmpty(authProfile.firstPublishedAt) ||
      isEmpty(authProfile.publishedAt)
    ) {
      return "";
    }
    const firstPublishedAt = moment(authProfile.firstPublishedAt);
    const publishedAt = moment(authProfile.publishedAt);
    const isNewProfile = publishedAt.isSame(firstPublishedAt);
    return isEmpty(isNewProfile)
      ? t("payment.adHasBeenSubmittedReview")
      : t("payment.adHasBeenRenewed");
  }, [authProfile, t]);

  useEffect(() => {
    if (isEmpty(authProfile)) {
      return;
    }
    if (
      !isEmpty(authProfile.bankTxRedirectStatus) &&
      authProfile.bankTxRedirectStatus !==
        Config.BANK_TRANSFER_STATUS.NOT_REQUIRED
    ) {
      PostServiceApi.postConfirmBankTransaction({
        postId: authProfile.id,
      }).then((response) => {
        if (!response.success) {
          // TODO: error handling
          return;
        }
        dispatch(getAuthUserProfile());
      });
    }
  }, [authProfile, dispatch]);

  return (
    <React.Fragment>
      <PageHead title={`Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Box className="app-root-container">
        <Grid
          container
          direction="column"
          alignItems="center"
          className="flex-1 bg-page-container"
        >
          <Grid
            item
            container
            direction="column"
            className="page-content-container flex-1 cus-container "
            alignItems="center"
          >
            <Box className="bank-transfer-success-page row-gap-32">
              <Grid
                container
                direction="column"
                className="font-color-success"
                alignItems="center"
              >
                <i className="far fa-check-circle fa-4x" align="center"></i>
                <Typography variant="h5">
                  {t("payment.paymentSuccess")}
                </Typography>
                {!isEmpty(authProfile) && (
                  <Typography variant="body1" align="center">
                    {subHeading}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className="marketing-resource-form-container"
              >
                <MakertingResourceSubmission authProfile={authProfile} />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box className="create-floor-footer-desktop">
            <Footer lng={lng} />
          </Box>
          <Box className="create-floor-footer-mobile">
            <FooterMobile lng={lng} />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withLanguageMigrator(BankTransferSuccessPage);
