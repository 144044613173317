import React, { useEffect, useMemo, useState } from "react";
import PageHead from "../../../../components/common/PageHead";
import PageMetaDescription from "../../../../components/common/PageMetaDescription";
// import Navbar from "../../../components/common/Navbar";
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PaymentPackageCard from "../../../templates/profile-view/payment/payment-package-card";
import Footer from "../../../../components/common/Footer";
import { getPricingTiers } from "../../../features/generic-data";
import FaqSegment from "./templates/faq-segment";
import "./style.css";
import Config from "../../../config";
import { COUNTRY_CURRENCY_META } from "../../../config/constant";
import Schema from "./schema";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import notices from "../../pay-for-ad/IN/invoice-view/schema/index.json";
import CorrectIcon from "@material-ui/icons/Check";
import withLanguageMigrator from "../../../hoc/language-migrator";

const { CURRENT_PLATFORM, DOMAIN_DISPLAY_NAME, META_DESCRIPTION } = Config;

const PricingPageIn = ({ lng }) => {
  const [selectedCountry, setselectedCountry] = useState(CURRENT_PLATFORM);
  const [pricingPackages, setPricingPackages] = useState([]);
  const [pricingPackageCount, setPricingCount] = useState(1);

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobiileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const getPricingPackages = async () => {
    try {
      const pricingTiers = await getPricingTiers({});

      setPricingPackages(pricingTiers.body);
      setPricingCount(pricingTiers.body.length);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPricingPackages();
  }, []);

  const supportedCountries = useMemo(() => {
    const output = COUNTRY_CURRENCY_META.filter(
      (current) =>
        current.code === Config.CURRENT_PLATFORM || current.code === "other"
    );
    return output;
  }, []);

  const currency = useMemo(() => {
    let currentCurrency = "";
    const countryCurrencyMeta = COUNTRY_CURRENCY_META.find(
      (current) => current.code === selectedCountry
    );

    currentCurrency = countryCurrencyMeta.currency ?? "";
    return currentCurrency;
  }, [selectedCountry]);

  return (
    <>
      <PageHead title={`Pricing | ${DOMAIN_DISPLAY_NAME}`} />
      <PageMetaDescription name="pricing" content={META_DESCRIPTION} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Grid
        container
        direction="column"
        className="pricing-page-container"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          xs={11}
          sm={10}
          md={8}
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="pricing-package-container"
        >
          <Typography variant="h4" align="center" className="section-heading">
            {t("supports.pricing")}
          </Typography>

          <Typography variant="body1" align="center" className="mt-30">
            {t("supports.whereDoYouLive")}
          </Typography>
          <FormControl variant="outlined" className="country-dropdown">
            <Select
              value={selectedCountry}
              onChange={(e) => setselectedCountry(e.target.value)}
              style={{ width: 160 }}
            >
              {supportedCountries.map((country, i) => (
                <MenuItem value={country.code} key={i}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid
            direction="row"
            container
            justifyContent="center"
            alignItems="flex-end"
            style={{
              width: isMobiileScreen ? "100%" : "50%",
              marginTop: 20,
            }}
          >
            {isEmpty(pricingPackages) ? (
              <CircularProgress size={25} style={{ color: "#cca326" }} />
            ) : (
              pricingPackages.map((currentPackage) => {
                return (
                  <PaymentPackageCard
                    key={currentPackage.id}
                    durationMonths={currentPackage.durationMonths}
                    isPreferred={currentPackage.preferred}
                    currency={currency}
                    packagesCount={pricingPackageCount}
                    displayAmount={currentPackage.amountLKR}
                    displayAmountUsd={currentPackage.amountUSD}
                    payAmount={currentPackage.amountLKR}
                    payAmountUsd={currentPackage.amountUSD}
                  />
                );
              })
            )}
          </Grid>

          <Grid container className="promotion-notice-container">
            {notices.promotionalNotices.map((item) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ columnGap: 2 }}
              >
                <CorrectIcon className="correct-icon" />
                <Typography variant="caption">{t(item)}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          container
          xs={11}
          sm={10}
          md={8}
          direction="column"
          className="faq-container"
        >
          <Typography variant="h4" align="center" className="section-heading">
            {t("supports.faq")}
          </Typography>

          <Grid container direction="row" className="mt-30">
            <Grid item container xs={12} md={6} className="faq-column">
              {Schema.leftColumn.map((QuestionItem) => (
                <FaqSegment
                  question={QuestionItem.question}
                  answer={QuestionItem.answer}
                />
              ))}
            </Grid>
            <Grid item container xs={12} md={6} className="faq-column">
              {Schema.rightColumn.map((QuestionItem) => (
                <FaqSegment
                  question={QuestionItem.question}
                  answer={QuestionItem.answer}
                  listItems={QuestionItem.listItems}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer lng={lng} />
    </>
  );
};

export default withLanguageMigrator(PricingPageIn);
