import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import "../../styles/settings.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initializeAuth } from "../../v2/features/application";

class HideAd extends Component {
  state = {
    postId: "",
    status: false,
    isExclude: false,

    excludeModal: false,

    // alerts
    loading: false,
    loadingUnhide: false,
  };

  componentDidMount = () => {
    this.initialStates();
  };

  initialStates = () => {
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        this.setState({
          postId: res.data[0].id,
          status: res.data[0].status === "DISABLED" ? true : false,
          isExclude: res.data[0].excludeFromSearch,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleExcludeModal = () => {
    if (this.state.isExclude) {
      this.setState({ loadingUnhide: true });
      const data = {
        excludeFromSearch: false,
      };
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then(async (res) => {
          this.setState({ loadingUnhide: false, successAlert: true });

          setTimeout(() => {
            this.setState({
              successAlert: false,
            });
          }, 2000);

          this.initialStates();
          await this.props.initializeAuth();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loadingUnhide: false, failAlert: true });

          setTimeout(() => {
            this.setState({
              failAlert: false,
            });
          }, 2000);
        });
    } else {
      this.setState({ excludeModal: true });
    }
  };

  // handle inputs
  handleChange = () => {
    this.setState({ isExclude: true });
  };

  // submit sign-up form
  handleExclude = () => {
    this.setState({ loading: true });

    const data = {
      excludeFromSearch: true,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then(async (res) => {
        this.setState({
          loading: false,
          excludeModal: false,
          successAlert: true,
        });

        setTimeout(() => {
          this.setState({
            successAlert: false,
          });
        }, 2000);

        this.initialStates();
        await this.props.initializeAuth();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, failAlert: true });

        setTimeout(() => {
          this.setState({
            failAlert: false,
          });
        }, 2000);
      });
  };

  handleClose = () => {
    this.setState({
      excludeModal: false,
      successAlert: false,
      failAlert: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} id="unpublish">
          <Paper
            elevation={0}
            style={
              !this.state.isExclude
                ? {
                    minHeight: "10vh",
                    position: "relative",
                  }
                : {
                    minHeight: "10vh",
                    position: "relative",
                    background: "#eab585",
                  }
            }
            className="complete-ad-div"
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  {!this.state.isExclude ? (
                    <p className="sec-heading" style={{ marginBottom: 5 }}>
                      {this.props.lng ? (
                        "Hide Ad"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දැන්වීම සඟවන්න
                        </span>
                      )}
                    </p>
                  ) : (
                    <p className="sec-heading" style={{ marginBottom: 5 }}>
                      {this.props.lng ? (
                        "Ad Hidden"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දැන්වීම සඟවා ඇත
                        </span>
                      )}
                    </p>
                  )}
                  {!this.state.isExclude ? (
                    <p className="heading-desc">
                      {this.props.lng ? (
                        "Your ad will be hidden from the public search and visible only to the profiles you send requests to, and you will not receive requests from others within the hidden period. You can change this setting back to default anytime."
                      ) : (
                        <span className="sinhala-size-11">
                          ඔබගේ දැන්වීම පොදු සෙවුමෙන් සඟවනු ලබන අතර ඔබ ඉල්ලීම් යවන පාර්ශවයන්ට පමණක් පෙන්වනු ලබයි. සැඟවුණු කාල සීමාව තුළ ඔබට අන් අයගෙන් ඉල්ලීම් නොලැබෙනු ඇත. ඔබට ඕනෑම වේලාවක මෙම සැකසුම වෙනස් කළ හැක.
                        </span>
                      )}
                    </p>
                  ) : (
                    <p className="heading-desc">
                      {this.props.lng ? (
                        "Your ad is currently hidden from the public search. It will be visible only to the profiles you send requests."
                      ) : (
                        <span className="sinhala-size-11">
                          ඔබේ දැන්වීම පොදු සෙවුමෙන් සඟවා ඇත.
                        </span>
                      )}
                    </p>
                  )}

                  <div style={{ paddingBottom: 70 }}>
                    {!this.state.isExclude ? (
                      <Button
                        variant="contained"
                        className="edit-btn"
                        onClick={this.handleExcludeModal}
                      >
                        {this.props.lng === true ? (
                          "Hide"
                        ) : (
                          <span className="sinhala-w-600 sinhala-size-10">
                            සඟවන්න
                          </span>
                        )}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          className="edit-btn"
                          onClick={this.handleExcludeModal}
                        >
                          {this.state.loadingUnhide ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <span>
                              {this.props.lng === true ? (
                                "Unhide"
                              ) : (
                                <span className="sinhala-w-600 sinhala-size-10">
                                  පෙන්වන්න
                                </span>
                              )}
                            </span>
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.successAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="success"
              >
                {this.props.lng === true ? (
                  "Change has been applied successfully."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    වෙනස් කිරීම සාර්ථකව යෙදී ඇත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.failAlert}
              onClose={this.handleClose}
            >
              <Alert
                onClose={this.shouldComponentUpdatehandleClose}
                severity="error"
              >
                {this.props.lng === true ? (
                  "Oops, something went wrong."
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    වෙනස් කිරීම සාර්ථකව වූයේ නැත.
                  </span>
                )}
              </Alert>
            </Snackbar>

            <Dialog
              open={this.state.excludeModal}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
                {this.props.lng === true ? (
                  "Remove my ad from public search"
                ) : (
                  <span className="sinhala-size-14 sinhala-w-600">
                    මගේ දැන්වීම පොදු සෙවුමෙන් ඉවත් කරන්න
                  </span>
                )}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: 15 }}
                >
                  {this.props.lng === true ? (
                    <span>
                      Please confirm that you don’t want to display your ad on
                      the public search.
                    </span>
                  ) : (
                    <span className="sinhala-size-12 sinhala-w-600">
                      ඔබගේ දැන්වීම පොදු සෙවුමේ ප්‍රදර්ශනය කිරීමට ඔබට අවශ්‍ය නැති
                      බව කරුණාකර තහවුරු කරන්න.
                    </span>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ textTransform: "capitalize" }}
                >
                  {this.props.lng === true ? "Cancel" : "අවලංගු කරන්න"}
                </Button>
                <Button
                  onClick={this.handleExclude}
                  style={{
                    background: "#000",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  autoFocus
                >
                  {this.state.loading ? (
                    <CircularProgress size={16} style={{ color: "#fff" }} />
                  ) : this.props.lng === true ? (
                    "Confirm"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-10">
                      තහවුරු කරන්න
                    </span>
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { initializeAuth },
    dispatch
  );
};

export default connect(null,matchDispatchToProps)(HideAd)
