import React, { useEffect, useMemo, useState } from "react";
import withLanguageMigrator from "../../hoc/language-migrator";
import PageHead from "../../components/atoms/page-head";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import Config from "../../config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FormDropdown from "../../components/molecules/form-dropdown";
import { submitPostCreatedMarketingSource } from "../../features/post";
import { setAppSnackNotification } from "../../store/actions/application";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getGenericMarketingSources } from "../../features/generic-data";
import "./style.css";

const { DOMAIN_DISPLAY_NAME } = Config;

const SubscriptionSuccess = ({ lng }) => {
  const [markertingSources, setMarkertingSources] = useState([]);
  const [selectedMarketingSourceId, setSelectedMarketingSourceId] =
    useState(undefined);

  const { authProfile } = useSelector((state) => state.application);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const isFirstPublishedProfile = useMemo(() => {
    return !authProfile.firstPublishedAt;
  }, [authProfile]);

  const onSubmit = async () => {
    const postId = authProfile.id;
    const data = {
      sourceId: selectedMarketingSourceId,
    };
    try {
      const reponse = await submitPostCreatedMarketingSource({ postId, data });
      if (reponse.success) {
        history.push("/complete-your-ad");
      }
    } catch (error) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: t("payment.somethingWentWrong"),
        })
      );
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getGenericMarketingSources()
      .then((res) => {
        setMarkertingSources(res.body);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  return (
    <>
      <PageHead title={`Subscription status | ${DOMAIN_DISPLAY_NAME}`} />
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="cus-container subscription-success-container row-gap-16"
      >
        <i className="far fa-check-circle fa-4x subscription-success-icon" />
        <Typography variant="h6" className="subscription-status-title-txt">
          {t("payment.subscriptionActivated")}
        </Typography>
        {isFirstPublishedProfile ? (
          <>
            <Typography variant="body1" className="subscription-status-txt">
              {t("message.yourAdSubmittedForReview")}
            </Typography>
            <Typography className="margin-top-8 marketing-souce-title">
              {t("payment.howDidYouHearAboutUs")}
            </Typography>
            <FormDropdown
              options={markertingSources}
              lang={i18n.language}
              labelExtractor="title"
              keyExtractor="id"
              onChange={(e) => setSelectedMarketingSourceId(e.target?.value)}
              containerStyles={{ width: 280 }}
            />
            <Button
              variant="contained"
              className="attract-responses-btn margin-top-16"
              onClick={onSubmit}
              disabled={!selectedMarketingSourceId}
            >
              {t("supports.attractMoreResponses")}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" className="subscription-status-txt">
              {t("message.thanksForSubscribingToPoruwa")}
            </Typography>

            <Button
              variant="contained"
              className="margin-top-16"
              onClick={() => history.push("/")}
            >
              {t("common.goToAllAds")}
            </Button>
          </>
        )}
      </Grid>
      {isMobileScreen ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </>
  );
};

export default withLanguageMigrator(SubscriptionSuccess);
