import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import { Dialog, Button, CircularProgress } from "@material-ui/core";

import close from "../../images/close.png";

import ProductLogo, {
  LogoSize,
  LogoVariant,
} from "../../v2/templates/branding/product-logo";
import { isPasswordValid } from "../../v2/utils";

export default class PwdResetModal extends Component {
  state = {
    isUsingPhone: true,
    showVerifyCode: false,
    isForcedReset: false,

    // reset password
    resetPhone: "",
    code: "",
    password: "",
    conPassword: "",
    phoneId: "",

    // error
    resetPhoneError: "",
    codeError: "",
    passwordError: "",
    conPasswordError: "",

    // alert
    showResendSuccess: false,
    showResendFail: false,
    error: "",
    pwdResetSuccessMsg: false,

    // loading
    resetLoading: false,
    loadingConfirm: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleResetPhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ resetPhone: parseInt(str, 10), resetPhoneError: "" });
  };

  handleEmailMethod = () => {
    this.setState({
      isUsingPhone: false,
      showCountryCodes: false,
    });
  };

  handlePhoneMethod = () => {
    this.setState({
      isUsingPhone: true,
      showCountryCodes: false,
    });
  };

  // validate
  validateReset = (type) => {
    let resetPhoneError = "";

    if (type !== "email") {
      if (this.state.resetPhone === "") {
        resetPhoneError = this.props.lng
          ? "Phone number is required"
          : "දුරකථන අංකය අවශ්‍ය වේ";
      }
    }

    if (resetPhoneError) {
      this.setState({
        resetPhoneError,
      });
      return false;
    }

    return true;
  };

  handlePasswordReset = (type) => {
    if (this.validateReset(type)) {
      this.setState({
        resetPhoneError: "",
        resetLoading: true,
      });

      const data =
        type === "email"
          ? `email=${this.state.resetEmail}`
          : qs.stringify({
              userId: `${this.props.code}${this.state.resetPhone}`,
            });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/request_reset_password`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.setState({
            isUsingPhone: false,
            phoneId: res.data.id,
            showVerifyCode: type === "email" ? false : true,
            showResendSuccess: type === "email" ? true : false,
            loginFail: false,
            resetLoading: false,
            resetEmail: "",
            resetPhone: "",
            error: "",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            showResendSuccess: false,
            showResendFail: true,
            loginFail: false,
            resetLoading: false,
            error: "",
          });

          if (err.response.data.code === 1001) {
            this.setState({
              resetPhoneError: this.props.lng
                ? "Phone does not exist"
                : "දුරකථන අංකය  නොපවතී",
            });
          } else if (err.response.data.code === 1201) {
            this.setState({
              resetPhoneError: this.props.lng
                ? "Too many attempts. Please try again later."
                : "උත්සාහයන් සීමාව ඉක්මවා ඇත. කරුණාකර පසුව නැවත උත්සාහ කරන්න.",
            });
          } else {
            this.setState({
              error: this.props.lng
                ? "Opps! Something Went Wrong"
                : "අපොයි! යමක් වැරදි ඇත",
            });
          }
        });
    }
  };

  // validate
  validate = () => {
    let codeError = "";
    let passwordError = "";
    let conPasswordError = "";

    if (this.state.code === "") {
      codeError = this.props.lng ? "Code is required" : "කේතය අවශ්‍ය වේ";
    } else if (this.state.conPassword !== this.state.password) {
      conPasswordError = this.props.lng
        ? "Passwords should match"
        : "මුරපද ගැලපිය යුතුය";
    } else if (isPasswordValid(this.state.password) == false) {
      conPasswordError =
        this.props.lng === true
          ? "Password should be at least 8 characters long and contain at least two numbers or special characters. (eg: #$%^&...)"
          : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් වත් විය යුතු අතර අවම වශයෙන් අංක හෝ විශේෂ අක්ෂර (උ.දා: #$%^&...) දෙකක් ඇතුලත් විය යුතුය";
    }

    if (
      codeError == false &&
      passwordError == false &&
      conPasswordError == false
    ) {
      //do password policy validation after checking for other basic issues
      if (isPasswordValid(this.state.password)) {
        return true;
      } else {
        passwordError = "";
        conPasswordError =
          this.props.lng === true
            ? "Password should contain at least two numbers or special characters. (eg: #$%^&...)"
            : "මුරපදයේ අකුරු සමග අවම වශයෙන් අංක හෝ විශේෂ අක්ෂර (උ.දා: #$%^&...) දෙකක් තිබිය යුතුය";
      }
    }

    if (codeError || passwordError || conPasswordError) {
      this.setState({
        codeError,
        passwordError,
        conPasswordError,
      });
      return false;
    }

    return true;
  };

  handleConfirmPassword = () => {
    if (this.validate()) {
      this.setState({
        codeError: "",
        passwordError: "",
        conPasswordError: "",
        loadingConfirm: true,
      });

      const data = `password=${this.state.password}&phoneId=${this.state.phoneId}&code=${this.state.code}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/reset_password_phone`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          this.setState({ loadingConfirm: false, pwdResetSuccessMsg: true });

          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("userId", res.data.memberId);
          let ownerUrl = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            ownerUrl = `operator/managed_posts/${localStorage.getItem(
              "postId"
            )}/owner`;
          } else {
            ownerUrl = "account";
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${ownerUrl}`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem(
                "user_email",
                res.data.email ? res.data.email : ""
              );
            })
            .catch((err) => {
              console.log(err);
            });

          let url = "";
          if (
            localStorage.getItem("user_role") === "OPERATOR" ||
            localStorage.getItem("user_role") === "OPERATOR_L2"
          ) {
            url = `operator/managed_posts/${localStorage.getItem("postId")}`;
          } else {
            url = "account/posts";
          }
          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (
                localStorage.getItem("user_role") === "OPERATOR" ||
                localStorage.getItem("user_role") === "OPERATOR_L2"
              ) {
                res.data[0] = res.data;
              }
              if (res.data.length > 0) {
                localStorage.setItem(
                  "post_details",
                  JSON.stringify(res.data[0])
                );
                localStorage.setItem("postId", res.data[0].id);

                if (
                  this.props.history.location.pathname === "/access-denied" ||
                  this.props.history.location.pathname === "/unauthorized-user"
                ) {
                  this.props.history.goBack();
                } else if (
                  this.props.history.location.pathname === "/register"
                ) {
                  this.props.history.push("/search");
                }
                this.setState({ loadingLogin: false });
              } else {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/v1/account/posts/temp`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "auth_token"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("personalInfo");
                    localStorage.removeItem("parentInfo");
                    localStorage.removeItem("privateInfo");

                    if (res.data !== "") {
                      if (res.data.postData.personalInfo) {
                        localStorage.setItem(
                          "personalInfo",
                          JSON.stringify(res.data.postData.personalInfo)
                        );
                      }

                      if (res.data.postData.parentInfo) {
                        localStorage.setItem(
                          "parentInfo",
                          JSON.stringify(res.data.postData.parentInfo)
                        );
                      }

                      if (
                        res.data.postData.horoscopeMatching ||
                        res.data.postData.makePhonePublic
                      ) {
                        localStorage.setItem(
                          "privateInfo",
                          JSON.stringify({
                            phoneId: res.data.postData.phoneId,
                            makePhonePublic: res.data.postData.makePhonePublic,
                            horoscopeMatching:
                              res.data.postData.horoscopeMatching,
                            birthTime: res.data.postData.birthTime,
                            birthCity: res.data.postData.birthCity,
                            images: res.data.postData.images,
                            phone: res.data.postData.phone,
                          })
                        );
                      }
                    }

                    this.props.history.push("/complete-ad-review");
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              console.log(err);

              this.setState({ loadingLogin: false });
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.code === 1202) {
            this.setState({
              codeError: this.props.lng ? "Invalid code" : "වලංගු කේතයක් නොවේ",
            });
          }
          this.setState({ loadingConfirm: false });
        });
    }
  };

  handleCloseResetPwd = () => {
    window.location.reload();
  };

  handleKeyUpContinue = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("continue-btn").click();
    }
  };

  handleKeyUpConfirm = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("confirm-btn").click();
    }
  };

  handleModalClose = () => {
    this.setState({ showVerifyCode: false, isUsingPhone: true });
    this.props.handleCloseResetPwdModal();
  };

  render() {
    return (
      <Dialog
        open={this.props.showPwdResetModal}
        onClose={this.props.handleCloseResetPwdModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        className="login-modal"
      >
        <div className="create-login-div login-div-container">
          {this.props.showCountryCodes ? (
            <React.Fragment>
              <p
                className="login-signup-back"
                onClick={this.props.hideCountryCodes}
              >
                <i className="fas fa-chevron-left"></i>{" "}
                {this.props.lng ? (
                  "BACK"
                ) : (
                  <span className="sinhala-size-12">ආපසු යන්න</span>
                )}
              </p>
              <p className="modal-heading">
                {this.props.lng ? (
                  "Select Phone Dialing Code"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-17">
                    දුරකථන ඇමතුම් කේතය තෝරන්න
                  </span>
                )}
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.isUsingPhone ? (
                <picture>
                  <ProductLogo
                    variant={LogoVariant.black}
                    size={LogoSize.xsmall}
                    className={"login-logo"}
                  />
                </picture>
              ) : this.state.showVerifyCode ? (
                <React.Fragment>
                  <p
                    className="login-signup-back"
                    onClick={() =>
                      this.setState({
                        showVerifyCode: false,
                        isUsingPhone: true,
                        code: "",
                        password: "",
                        conPassword: "",
                        codeError: "",
                        passwordError: "",
                        conPasswordError: "",
                      })
                    }
                  >
                    <i className="fas fa-chevron-left"></i>{" "}
                    {this.props.lng ? (
                      "BACK"
                    ) : (
                      <span className="sinhala-size-12">ආපසු යන්න</span>
                    )}
                  </p>
                </React.Fragment>
              ) : null}
              <p
                className="modal-heading"
                style={{
                  textAlign: this.state.isUsingPhone ? "center" : "",
                }}
              >
                {this.props.lng ? (
                  "Password Reset"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-17">
                    මුරපදය යළි පිහිටුවීම
                  </span>
                )}
              </p>
            </React.Fragment>
          )}

          <img
            src={close}
            alt="close"
            className="close-icon"
            onClick={this.handleModalClose}
          />

          {this.state.showResendFail ? (
            <p
              style={{
                color: "#f44336",
                fontSize: "0.75rem",
                marginTop: 0,
                textAlign: "center",
              }}
            >
              {this.state.error}
            </p>
          ) : null}

          {this.props.isForcedReset ? (
            <div
              style={{
                padding: "12px",
                borderRadius: "8px",
                backgroundColor: "#e8f4fd",
              }}
            >
              <i
                style={{
                  color: "#2979ff",
                  fontSize: "14px",
                  display: "inline-block",
                  float: "left",
                }}
                className="fas fa-info-circle"
              ></i>
              <p className="primary-text" style={{ paddingLeft: "26px" }}>
                {this.props.lng
                  ? "We have recently updated our password policy to better protect our users accounts from unauthorized access. Please enter the phone number you created the account with to update your password."
                  : "අපගේ පරිශීලකයන්ගේ ගිණුම් හා දැන්වීම් අනවසර ප්‍රවේශ වීමෙන් වඩාත් හොඳින් ආරක්ෂා කිරීම සඳහා අපි මෑතකදී අපගේ මුරපද ප්‍රතිපත්තිය යාවත්කාලීන කර ඇත. ඔබගේ දැන්වීම සෑදීම සඳහා භාවිතා කල දුරකථන අංකය යොදා මුරපදය අලුත් කරන්න."}
              </p>
            </div>
          ) : null}

          {this.state.isUsingPhone || this.state.showVerifyCode ? null : this
              .state.showResendSuccess ? (
            <Button
              variant="contained"
              className="login-btn"
              onClick={this.handleCloseResetPwd}
              style={{
                display: "block",
                width: 100,
                padding: "6px 30px",
                margin: "auto",
                marginTop: 20,
              }}
            >
              {this.props.lng ? "OK" : "ස්තූතියි"}
            </Button>
          ) : null}

          {this.state.isUsingPhone ? (
            <React.Fragment>
              {!this.props.showCountryCodes && (
                <p className="input-headings">
                  {this.props.lng ? (
                    "Phone Number"
                  ) : (
                    <span className="sinhala-size-12">දුරකතන අංකය</span>
                  )}
                </p>
              )}

              {this.props.showCountryCodes ? (
                <React.Fragment>
                  <div style={{ position: "relative" }}>
                    <input
                      autoFocus
                      type="text"
                      name="search"
                      className="search-county-code-input"
                      placeholder="Search"
                      value={this.props.search}
                      onChange={this.props.handleChange}
                      autoComplete="off"
                    />
                    <i className="fas fa-search code-search-icon"></i>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <input
                    className="phone-input-country-code"
                    onClick={this.props.handleCountryCodes}
                    value={this.props.code}
                  />
                  <input
                    type="number"
                    name="resetPhone"
                    className={
                      this.state.resetPhoneError
                        ? "phone-input-custom red-border"
                        : "phone-input-custom"
                    }
                    value={this.state.resetPhone}
                    onChange={this.handleResetPhone}
                    onKeyUp={this.handleKeyUpContinue}
                    autoComplete="off"
                  />
                  <div className="error-handler">
                    {this.state.resetPhoneError}
                  </div>
                </React.Fragment>
              )}

              {this.props.showCountryCodes && (
                <div className="country-code-div">
                  {this.props.filteredCountries.map((code, i) => (
                    <p
                      key={i}
                      onClick={() =>
                        this.props.handleCountryCode(code.dialCode)
                      }
                    >
                      <span>{code.name}</span> <span>{code.dialCode}</span>
                    </p>
                  ))}
                </div>
              )}

              {!this.props.showCountryCodes && (
                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={() => this.handlePasswordReset("phone")}
                  id="continue-btn"
                >
                  {this.state.resetLoading ? (
                    <React.Fragment>
                      <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                    </React.Fragment>
                  ) : this.state.isUsingPhone ? (
                    this.props.lng ? (
                      "Continue"
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ඉදිරියට
                      </span>
                    )
                  ) : null}
                </Button>
              )}
            </React.Fragment>
          ) : null}

          {this.state.pwdResetSuccessMsg ? (
            <React.Fragment>
              <p
                style={{
                  color: "#28a745",
                  fontSize: "0.75rem",
                  marginTop: 25,
                  textAlign: "center",
                }}
              >
                <i className="fas fa-check"> </i>{" "}
                {this.props.lng ? (
                  "Password has been reset successfully"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-13">
                    මුරපදය සාර්ථකව යළි පිහිටුවන ලදි
                  </span>
                )}
              </p>

              <Button
                variant="contained"
                className="login-btn"
                onClick={this.handleCloseResetPwd}
                style={{
                  display: "block",
                  width: 100,
                  padding: "6px 30px",
                  margin: "auto",
                  marginTop: 20,
                }}
              >
                {this.props.lng ? "OK" : "ස්තූතියි"}
              </Button>
            </React.Fragment>
          ) : this.state.showVerifyCode ? (
            <React.Fragment>
              <p className="input-headings">Password Reset Code</p>
              <input
                type="number"
                className={
                  this.state.codeError
                    ? "login-input red-border"
                    : "login-input"
                }
                name="code"
                value={this.state.code}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUpConfirm}
              />
              <div className="error-handler">{this.state.codeError}</div>
              <p className="input-headings">
                {this.props.lng ? (
                  "Create Password"
                ) : (
                  <span className="sinhala-size-12">මුරපදයක් තනන්න</span>
                )}
              </p>
              <input
                type="password"
                name="password"
                className={
                  this.state.passwordError
                    ? "login-input red-border"
                    : "login-input"
                }
                value={this.state.password}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUpConfirm}
              />
              <div className="error-handler">{this.state.passwordError}</div>

              <p className="input-headings">
                {this.props.lng ? (
                  "Confirm Password"
                ) : (
                  <span className="sinhala-size-12">මුරපදය තහවුරු කරන්න</span>
                )}
              </p>
              <input
                type="password"
                name="conPassword"
                className={
                  this.state.conPasswordError
                    ? "login-input red-border"
                    : "login-input"
                }
                value={this.state.conPassword}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUpConfirm}
              />
              <div className="error-handler">{this.state.conPasswordError}</div>
              <Button
                variant="contained"
                className="login-btn"
                onClick={this.handleConfirmPassword}
                id="confirm-btn"
              >
                {this.state.loadingConfirm ? (
                  <React.Fragment>
                    <CircularProgress size={16} style={{ color: "#000" }} />{" "}
                  </React.Fragment>
                ) : this.props.lng ? (
                  "Confirm"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-14">
                    තහවුරු කරන්න
                  </span>
                )}
              </Button>
            </React.Fragment>
          ) : null}
        </div>
      </Dialog>
    );
  }
}
