import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { Grid, TextField, Typography } from "@material-ui/core";
import "./style.css";
import FormInteractiveButton from "../form-interactive-button";
import FormItemLabel from "../../atoms/form-item-label";

export const LABEL_POSITION = Object.freeze({
  top: "top",
  left: "left",
});

export const Mode = Object.freeze({
  standard: "standard",
  interactive: "interactive",
});

const FormAutoComplete = ({
  mode = Mode.standard,
  showInteractiveActions = false,
  onClickInteractiveSave,
  onClickInteractiveClear,
  fieldName,
  value,
  onChange,
  onSearchChange,
  visibility = true,
  lang,
  label = "",
  labelPosition = LABEL_POSITION.left,
  labelStyles = {},
  disabled,
  errorText = "",
  autoSelect = false,
  freeSolo = false,
  disableClearable = false,
  options = [],
  filterOptions,
  getOptionLabel = undefined,
}) => {
  return (
    visibility && (
      <Grid container direction="row" className="form-auto-select">
        <FormItemLabel
          text={label}
          styleOverrides={labelStyles}
          lang={lang}
          labelPosition={labelPosition}
        />
        <Grid item xs>
          <Autocomplete
            value={value}
            onChange={(event, value) => {
              event.preventDefault();
              if (onChange !== undefined) {
                onChange(fieldName, value);
              }
            }}
            options={options}
            getOptionLabel={getOptionLabel}
            onInputChange={onSearchChange}
            disabled={disabled}
            freeSolo={freeSolo}
            autoSelect={autoSelect}
            disableClearable={disableClearable}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={Boolean(errorText)}
                InputProps={{
                  ...params.InputProps,
                  style: { paddingInline: 0, paddingBlock: 0 },
                }}
              />
            )}
          />
          {Boolean(errorText) && (
            <Typography
              variant="caption"
              className={`form-error-text form-error-text-${lang}`}
            >
              {errorText}
            </Typography>
          )}
          {mode === Mode.interactive && showInteractiveActions && (
            <FormInteractiveButton
              onClickInteractiveClear={onClickInteractiveClear}
              onClickInteractiveSave={onClickInteractiveSave}
            />
          )}
        </Grid>
      </Grid>
    )
  );
};
export default FormAutoComplete;
