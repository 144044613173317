import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import FormSection from "../../../../../components/molecules/form-section";
import FormDropdown from "../../../../../components/molecules/form-dropdown";
import AppContext from "../../../../../context";
import { isEmptyOrNullOrUndefined } from "../../../../../utils";
import Config from "../../../../../config";
import FormAutoComplete from "../../../../../components/molecules/form-auto-complete";
import { useFilteredProfessions } from "../../../../../hooks";

const { EXCLUDED_SELF_PROFESSION_IDS } = Config;

const EducationProfession = ({ formMeta }) => {
  const { values, errors, handleChange, setFieldValue, submitCount } =
    useFormikContext();
  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { educationLevels, professions } = useSelector(
    (state) => state.genericData
  );

  const [professionSearch, setProfessionSearch] = useState(
    values[formMeta.otherProfession.fieldName] ?? ""
  );
  const showFieldErrors = submitCount > 0;

  const professionOtherVisibility = useMemo(() => {
    let professionId = values.professionId;
    if (isEmptyOrNullOrUndefined(professionId)) {
      return false;
    }
    if (typeof professionId === "string") {
      professionId = parseInt(professionId);
    }
    return professionId === Config.OTHER_PROFESSION_ID;
  }, [values]);

  const filteredProfessions = professions.filter(
    (current) => !EXCLUDED_SELF_PROFESSION_IDS.includes(current.id)
  );

  const otherProfessionPredictions = useFilteredProfessions({
    needle: professionSearch,
  });

  useEffect(() => {
    const searchedInput = professionSearch ?? "";
    if (typeof searchedInput === "string") {
      setFieldValue(formMeta.otherProfession.fieldName, searchedInput);
    }
    if (searchedInput.constructor === Object) {
      setFieldValue(
        formMeta.profession.fieldName,
        searchedInput[formMeta.profession.keyExtractor]
      );
      setFieldValue(formMeta.otherProfession.fieldName, "");
    }
  }, [
    formMeta.otherProfession.fieldName,
    formMeta.profession.fieldName,
    formMeta.profession.keyExtractor,
    professionSearch,
    setFieldValue,
  ]);

  return (
    <FormSection heading={t("common.educationAndProfession")}>
      <Grid container direction="row" className="row-gap-16">
        <Grid item xs={12} md={6}>
          <FormDropdown
            fieldName={formMeta.educationLevel.fieldName}
            value={values.educationLevelId}
            onChange={handleChange}
            options={educationLevels}
            keyExtractor={formMeta.educationLevel.keyExtractor}
            labelExtractor={formMeta.educationLevel.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.educationLevel.label)}
            errorText={showFieldErrors && errors.educationLevelId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          className="row-gap-16"
        >
          <FormDropdown
            fieldName={formMeta.profession.fieldName}
            value={values.professionId}
            onChange={handleChange}
            options={filteredProfessions}
            keyExtractor={formMeta.profession.keyExtractor}
            labelExtractor={formMeta.profession.labelExtractor}
            lang={appLanguage}
            label={t(formMeta.profession.label)}
            errorText={showFieldErrors && errors.professionId}
          />
          <FormAutoComplete
            fieldName={formMeta.otherProfession.fieldName}
            // value={professionSearch}
            value={values.otherProfession}
            onChange={(_fieldName, value) => {
              setProfessionSearch(value);
            }}
            onSearchChange={(_event, value) => {
              setProfessionSearch(value);
            }}
            label={t(formMeta.otherProfession.label)}
            lang={appLanguage}
            errorText={showFieldErrors && errors.otherProfession}
            options={otherProfessionPredictions}
            getOptionLabel={(option) => {
              const { language } = i18n;
              let extractor = formMeta.profession.labelExtractor;
              if (language === Config.APP_LANGS.SI) {
                extractor = `${formMeta.profession.labelExtractor}_${Config.APP_LANGS.SI}`;
              }
              return typeof option === "string" ? option : option[extractor];
            }}
            freeSolo={true}
            disableClearable={false}
            autoSelect={true}
            visibility={professionOtherVisibility}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default EducationProfession;
