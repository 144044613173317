import React from "react";
import PageHead from "../../components/atoms/page-head";
import Config from "../../config";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import { useTranslation } from "react-i18next";
import withLanguageMigrator from "../../hoc/language-migrator";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { DOMAIN_DISPLAY_NAME } = Config;

const SubscriptionFailed = ({ lng }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <PageHead title={`Subscription status | ${DOMAIN_DISPLAY_NAME}`} />
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="cus-container subscription-failed-container row-gap-16"
      >
        <i
          className="far fa-times-circle fa-4x subscription-error-icon"
          align="center"
        />
        <Typography variant="h6" className="subscription-status-title-txt">
          {t("message.paymentError")}
        </Typography>
        <Typography variant="body1" className="subscription-status-txt">
          {t("message.cardDeclinedAlert")}
        </Typography>
        <Button
          variant="contained"
          className="margin-top-16"
          onClick={() => history.push("/pay-for-ad")}
        >
          {t("payment.tryAgain")}
        </Button>
        <Typography
          variant="body2"
          className="home-navigation-txt"
          onClick={() => history.push("/")}
        >
          {t("payment.goToHome")}
        </Typography>
      </Grid>
      {isMobileScreen ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </>
  );
};

export default withLanguageMigrator(SubscriptionFailed);
