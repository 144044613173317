import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import { isEmpty } from "lodash";
import "../../../style.css";
import { useTranslation } from "react-i18next";

const PromoCodeSection = ({
  paymentSessionData = {},
  handlePaymentSession,
  promoCodeError,
  setPromoCodeError,
  isFetchingData,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [displayPromoSection, setDisplayPromoSection] = useState(false);

  const { t } = useTranslation();

  return (
    <Grid container direction="row">
      <Typography
        variant={displayPromoSection ? "body2" : "caption"}
        onClick={() => setDisplayPromoSection(true)}
        className={
          displayPromoSection ? "promo-code-label" : "promo-code-input-label"
        }
      >
        {t(displayPromoSection ? "payment.promoCode" : "payment.addPromoCode")}
      </Typography>
      {displayPromoSection && (
        <Grid container xs direction="column" alignItems="flex-end">
          <Grid
            container
            xs
            direction="row"
            justifyContent="flex-end"
            style={{ columnGap: 5 }}
          >
            <OutlinedInput
              value={promoCode}
              disabled={paymentSessionData.promoCode}
              style={{ maxHeight: 30, maxWidth: 100 }}
              onChange={(e) => {
                const value = e.target.value;
                typeof value === "string" && setPromoCode(value.toUpperCase());
                setPromoCodeError("");
              }}
              endAdornment={
                !isFetchingData &&
                paymentSessionData.promoCode && (
                  <i
                    className="fa fa-times-circle promo-code-clear-btn"
                    onClick={() => {
                      handlePaymentSession({});
                      setPromoCode("");
                    }}
                  />
                )
              }
            />
            {!paymentSessionData.promoCode && (
              <Button
                className="promo-code-submit-btn"
                onClick={() => {
                  if (isEmpty(promoCode)) {
                    setPromoCodeError(t("payment.promoCodeIsRequired"));
                    return;
                  }
                  handlePaymentSession({ promo: promoCode });
                }}
              >
                {isFetchingData ? (
                  <CircularProgress size={15} style={{ color: "#fff" }} />
                ) : (
                  <Typography variant="caption">
                    {t("payment.apply")}
                  </Typography>
                )}
              </Button>
            )}
            {!paymentSessionData.promoCode && (
              <ClearIcon
                className="close-promocode-section-btn"
                onClick={() => {
                  setPromoCode("");
                  setPromoCodeError("");
                  setDisplayPromoSection(false);
                }}
              />
            )}
          </Grid>
          {promoCodeError && (
            <Typography variant="caption" style={{ color: "red" }}>
              {promoCodeError}
            </Typography>
          )}
          {!isFetchingData && paymentSessionData.promoCode && (
            <Typography variant="body2" className="promocode-success-text">
              <CheckIcon style={{ width: 18 }} />
              {t("payment.promoApplied")}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PromoCodeSection;
