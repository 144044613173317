const Routes = Object.freeze({
  privacy: "/privacy",
  pricing: "/pricing",
  reviews: "/reviews",
  magazine: "/magazine",
  faq: "/faq",
  help: "/help",
  terms: "/terms",
});

export default Routes;
