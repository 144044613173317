import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Paper, Grid, Button, CircularProgress } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {remove} from "lodash"

import stripe from "../../images/stripe.png";

import "../../styles/payment-methods.css";
import Config from "../../v2/config";
import { PAYMENT_METHOD_IDS } from "../../v2/config/constant";

const { PAYMENT_OPTIONS, PAYHERE_PREAPPROVAL_URL, PAYHERE_URL } = Config;

class CheckoutMethod extends Component {
  state = {
    allPayMethods: [],
    selected: "",
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "",
    postId: localStorage.getItem("postId")
      ? localStorage.getItem("postId")
      : "",
    sessionId: localStorage.getItem("session_creation")
      ? JSON.parse(localStorage.getItem("session_creation")).sessionId
      : "",
    ipg: localStorage.getItem("session_creation")
      ? JSON.parse(localStorage.getItem("session_creation")).ipg
      : "",

    isOnline: false,
    isDeposit: false,

    merchantId: "",
    firstName: "",
    orderId: "",
    items: "",
    hash: "",
    returnUrl: "",
    cancelUrl: "",
    notifyUrl: "",

    price: "",
    dueAmount: "",
    discount: "",
    currency: "",
    currencySymbol: "",
    promo: "",
    isRenew: false,
    countryCode: "",
    payingCountryCode: "",

    isPromoApplied: false,
    showPromo: false,

    // errors
    promoError: "",
    notSelectedError: "",
    chekoutError: false,

    agreeChecked: false,
    isNotAgree: false,
    dissableCard: false,
    isRecurring: false,

    // loading
    preLoading: true,
    loading: false,

    // screenWidth: 0,
  };

  componentDidMount = () => {
    sessionStorage.getItem(PAYMENT_OPTIONS.IS_RECURRING) && this.setState({isRecurring: true})
    this.scrollToTop();

    // remove
    localStorage.removeItem("personalInfo");
    localStorage.removeItem("parentInfo");
    localStorage.removeItem("privateInfo");
    localStorage.removeItem("partnerInfo");
    localStorage.removeItem("statesOrDistricts");
    localStorage.removeItem("uploadPhotoFiles");

    // paymentmethods
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
      .then((res) => {
        this.setState({ allPayMethods: res.data });

        setTimeout(
          () => this.setState({ allPayMethods: res.data, preLoading: false }),
          1000
        );
      })
      .catch((err) => {
        console.log(err);

        this.setState({ preLoading: false });
      });

    // get my post
    let url = "";
    if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
      url = `operator/managed_posts/${localStorage.getItem("postId")}`;
    } else {
      url = "account/posts";
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
          res.data[0] = res.data;
        }
        this.setState({
          postId: res.data[0].id,
          isRenew:
            res.data[0].status === "PAYMENT_PENDING"
              ? false
              : res.data[0].status === "EXPIRED"
              ? true
              : res.data[0].status === "LIVE"
              ? true
              : false,
          countryCode: res.data[0].personalInfo.residentCountry.code,
          payingCountryCode: sessionStorage.getItem("paying_currency")
            ? sessionStorage.getItem("paying_currency") === "LKR"
              ? "LK"
              : "AU"
            : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.loadScripts();

    // window.addEventListener("resize", this.updateWindowDimensions);
    // this.setState({ screenWidth: window.innerWidth });
  };

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowDimensions);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isDeposit !== this.state.isDeposit ||
      prevState.isOnline !== this.state.isOnline
    ) {
      this.setState({ selected: "" });
    }
  }

  // updateWindowDimensions = () => {
  //   this.setState({ screenWidth: window.innerWidth });
  //   if (
  //     (this.state.selected === "paypal" && window.innerWidth === 576) ||
  //     window.innerWidth === 574
  //   ) {
  //     this.handlePayPal();
  //   }
  // };

  loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);

    // paypal
    const paypalScript = document.createElement("script");

    paypalScript.src = "https://www.paypalobjects.com/api/checkout.js";
    paypalScript.async = true;

    document.body.appendChild(paypalScript);
  };

  createSessionCheckout = (id) => {
    this.setState({ selected: id });

    if (id === "paypal" && this.state.selected !== "paypal") {
      this.setState({ selected: id }, () => {
        this.handlePayPal();
      });
    }
  };

  redirect = () => {
    var stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);

    this.setState({ loadingCard: true });

    if (this.state.ipg === "STRIPE") {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: this.state.sessionId,
        })
        .then(function (result) {
          this.setState({ loadingCard: false });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
        });
    } else {
      if (this.state.selected === "local_cc") {
        localStorage.setItem("payment_payhere_reminder", moment().format());
      }

      document.getElementById("pay-by-genie").click();

      this.setState({ dissableCard: true });
    }
  };

  handleCheckout = () => {
    this.setState({ loading: true });

    if (this.state.selected) {
      const isBank = this.state.allPayMethods.filter(
        (el) => el.isBank === true && el.id === this.state.selected
      );

      if (isBank.length > 0) {
        this.props.history.push(
          `/checkout/bank-transfer?code=${isBank[0].region}`
        );
      } else {
        let data;
        let forceLKRVal = (sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") ? true : false;
        let selectedPackege = (sessionStorage.getItem("selected_package")) ? sessionStorage.getItem("selected_package") : "2m";
        const isRecurringPayment = sessionStorage.getItem(PAYMENT_OPTIONS.IS_RECURRING) ?? ""; 

        if (localStorage.getItem("promoCode")) {
          data = `promoCode=${localStorage.getItem("promoCode")}&isRenew=${
            this.state.isRenew
          }&paymentMethodId=${this.state.selected}&forceLKR=${forceLKRVal}&pricingTierId=${selectedPackege}&simulate=false`;
        } else {
          data = `isRenew=${this.state.isRenew}&paymentMethodId=${this.state.selected}&forceLKR=${forceLKRVal}&pricingTierId=${selectedPackege}&simulate=false`;
        }

        if(isRecurringPayment){
          data += `&${PAYMENT_OPTIONS.IS_RECURRING}=${isRecurringPayment}`
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${this.state.postId}/checkout_tiered`,
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("session_creation", JSON.stringify(res.data));

            this.setState(
              {
                sessionId: res.data.sessionId,
                ipg: res.data.ipg,
                merchantId:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.merchantId
                    : "",
                firstName:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.firstName
                    : "",
                orderId:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.orderId
                    : "",
                items:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.items
                    : "",
                hash:
                  res.data.ipg === "PAYHERE" ? res.data.payhereParams.hash : "",
                returnUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.returnUrl
                    : "",
                cancelUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.cancelUrl
                    : "",
                notifyUrl:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.notifyUrl
                    : "",
                currency:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.currency
                    : "",
                dueAmount:
                  res.data.ipg === "PAYHERE"
                    ? res.data.payhereParams.amount
                    : "",
              },
              () => {
                this.redirect();
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });

            if (err.response.data.code === 1400) {
              this.setState({
                promoError: this.props.lng
                  ? "Invalid promo code"
                  : "ප්‍රවර්ධනය කේතය වලංගු නොවේ"
              });
            } else if (err.response.data.code === 1401) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code expired"
                  : "ප්‍රවර්ධනය කේතය කල් ඉකුත් වී ඇත"
              });
            } else if (err.response.data.code === 1311) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code valid only for new accounts"
                  : "ප්‍රවර්ධනය කේතය නව ගිණුම් සඳහා පමණක් වලංගු වේ"
              });
            } else if (err.response.data.code === 1312) {
              this.setState({
                promoError: this.props.lng
                  ? "Promo code invalid for the selected package"
                  : "ප්‍රවර්ධනය කේතය තෝරාගත් පැකේජය සඳහා වලංගු නොවේ"
              });
            } else if (err.response.data.code === 1512) {
              this.setState({
                chekoutError: true,
              });
            }
          });
      }
    } else {
      this.setState({
        notSelectedError: this.props.lng
          ? "Please select a payment method"
          : "කරුණාකර ගෙවීම් ක්‍රමයක් තෝරන්න",
        loading: false,
      });
    }
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handlePayPal = () => {
    if (localStorage.getItem("promoCode")) {
      this.runPaypalWithPromo();
    } else {
      this.runPaypalWithoutPromo();
    }
  };

  runPaypalWithoutPromo = () => {
    let isRenew = this.state.isRenew;
    let postId = this.state.postId;

    let envonment =
      Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP
        ? "sandbox"
        : "production";
    const isRecurringPayment = sessionStorage.getItem(PAYMENT_OPTIONS.IS_RECURRING) ?? "";

    window.paypal.Button.render(
      {
        env: envonment, //'sandbox' Or 'production'
        // Set up the payment:
        // 1. Add a payment callback

        payment: function (data, actions) {
          //2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
              {
                isRenew: isRenew,
                paymentMethodId: "paypal",
                forceLKR: (sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") ? true : false,
                pricingTierId: (sessionStorage.getItem("selected_package")) ? sessionStorage.getItem("selected_package") : "2m",
                ...(isRecurringPayment && {
                  [PAYMENT_OPTIONS.IS_RECURRING]: isRecurringPayment,
                }),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                },
              }
            )
            .then(function (res) {
              // 3. Return res.id from the response
              return res.paypalParams.orderId;
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.code === 1512) {
                this.setState({
                  chekoutError: true,
                });
              }
            });
        },
        // Execute the payment:
        // 1. Add an onAuthorize callback
        onAuthorize: function (data, actions) {
          // 2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/confirm_checkout_paypal`,
              {
                paymentId: data.paymentID,
                payerId: data.payerID,
              }
            )
            .then(function (res) {
              // 3. Show the buyer a confirmation message.

              window.alert("Payment Complete!");
            });
        },
      },
      "#paypal-button"
    );
  };

  runPaypalWithPromo = () => {
    let isRenew = this.state.isRenew;
    let postId = this.state.postId;
    let envonment =
      Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP
        ? "sandbox"
        : "production";
    const isRecurringPayment = sessionStorage.getItem(PAYMENT_OPTIONS.IS_RECURRING) ?? "";

    window.paypal.Button.render(
      {
        env: envonment, //'sandbox' Or 'production'
        // Set up the payment:
        // 1. Add a payment callback

        payment: function (data, actions) {
          //2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/checkout_tiered`,
              {
                isRenew: isRenew,
                paymentMethodId: "paypal",
                promoCode: localStorage.getItem("promoCode"),
                forceLKR: (sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") ? true : false,
                pricingTierId: (sessionStorage.getItem("selected_package")) ? sessionStorage.getItem("selected_package") : "2m",
                ...(isRecurringPayment && {
                  [PAYMENT_OPTIONS.IS_RECURRING]: isRecurringPayment,
                }),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                },
              }
            )
            .then(function (res) {
              console.log(res);
              // 3. Return res.id from the response
              return res.paypalParams.orderId;
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.code === 1512) {
                this.setState({
                  chekoutError: true,
                });
              }
            });
        },
        // Execute the payment:
        // 1. Add an onAuthorize callback
        onAuthorize: function (data, actions) {
          // 2. Make a request to your server
          return actions.request
            .post(
              `${process.env.REACT_APP_API_URL}/v1/account/posts/confirm_checkout_paypal`,
              {
                paymentId: data.paymentID,
                payerId: data.payerID,
              }
            )
            .then(function (res) {
              console.log(res);
              // 3. Show the buyer a confirmation message.
              window.alert("Payment Complete!");
            });
        },
      },
      "#paypal-button"
    );
  };

  render() {
    let payMethods = [];

    if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
      payMethods = this.state.allPayMethods
        .filter((el) => el.isBank === false)
        .filter((el) => el.region !== "LK" || el.region === undefined);
    } else {
      payMethods =
      this.state.countryCode === "LK" && this.state.payingCountryCode === "LK"
        ? this.state.allPayMethods
            .filter((el) => el.isBank === false)
            .sort((a, b) => a.sortOrder - b.sortOrder)
        : this.state.countryCode !== "LK" &&
          this.state.payingCountryCode === "LK"
        ? this.state.allPayMethods
            .filter((el) => el.isBank === false && el.id !== "foreign_cc")
            .sort((a, b) => a.sortOrder - b.sortOrder)
        : this.state.allPayMethods
            .filter((el) => el.isBank === false)
            .filter((el) => el.region !== "LK" || el.region === undefined);
    if (sessionStorage.getItem("force_lkr") && sessionStorage.getItem("force_lkr")==="true") {
      payMethods.push(this.state.allPayMethods.filter((el) => el.id === "foreign_cc")[0]);
      payMethods.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    if (this.state.isRecurring) {
      remove(
        payMethods,
        (el) =>
          el.id !== PAYMENT_METHOD_IDS.LOCAL_CC &&
          el.id !== PAYMENT_METHOD_IDS.FOREIGN_CC
      );
    }
  }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{
              minHeight: "35vh",
              position: "relative",
            }}
          >
            {this.state.preLoading ? (
              <div className="loading-div">
                <CircularProgress className="loading-circle" />
              </div>
            ) : (
              <React.Fragment>
                <div className="remember-notice">
                  {this.props.lng === true ? (
                    `Ad ID : ${this.state.userId}`
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-12">
                      දැන්වීම් කේතය : {this.state.userId}
                    </span>
                  )}
                </div>

                {this.state.chekoutError ? (
                  <div
                    className="invoice-terms pay-method-div"
                    style={{ paddingBottom: 30 }}
                  >
                    <div
                      className="invoice"
                      style={{ padding: "25px 15px", textAlign: "center" }}
                    >
                      {this.props.lng
                        ? "Post not within renewal period"
                        : "ඔබගේ දැන්වීම තවම අලුත් කල නොහැක"}
                    </div>
                  </div>
                ) : (
                  <div className="invoice-terms pay-method-div">
                    <p className="heading">
                      {this.props.lng ? (
                        "Select Payment Method"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          ගෙවීම් ක්‍රමය තෝරන්න
                        </span>
                      )}
                    </p>

                    {this.state.isOnline || this.state.isDeposit ? null : (
                      <>
                        <button
                          className="payment-method-btn"
                          onClick={() =>
                            this.setState({ isOnline: true, isDeposit: false })
                          }
                        >
                          {this.props.lng ? (
                            "Online Payment"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              අන්තර්ජාලය හරහා ගෙවීමේ ක්‍රම
                            </span>
                          )}
                        </button>
                        <p className="payment-method-btn-desc">
                          {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
                            ? "Visa, Master, Amex, FriMi, eZ Cash, PayPal, Sampath Vishwa and more"
                            : "Visa, Master, Amex, PayPal and more"}
                        </p>
                      </>
                    )}

                    {this.state.isOnline ||
                    this.state.isDeposit ||
                    this.state.isRecurring ||
                    Config.CURRENT_PLATFORM === Config.PLATFORMS.IN ? null : (
                      <>
                        <button
                          className="payment-method-btn"
                          onClick={() =>
                            this.setState({ isOnline: false, isDeposit: true })
                          }
                        >
                          {this.props.lng ? (
                            "Bank Deposit/Transfer"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              බැංකුවට ගෙවීමේ/තැන්පත් කිරීමේ ක්‍රම
                            </span>
                          )}
                        </button>
                        <p className="payment-method-btn-desc">
                          {this.props.lng
                            ? "Relevant bank account information will be displayed."
                            : "අදාළ ගිණුම් තොරතුරු පෙන්වනු ඇත"}
                        </p>
                      </>
                    )}

                    {this.state.isOnline || this.state.isDeposit ? (
                      <Button
                        variant="contained"
                        className="back-btn"
                        onClick={() =>
                          this.setState({
                            isOnline: false,
                            isDeposit: false,
                            notSelectedError: "",
                          })
                        }
                        style={{ marginLeft: "-10px" }}
                      >
                        <KeyboardArrowLeftIcon />{" "}
                        {this.props.lng ? "Back" : "ආපසු යන්න"}
                      </Button>
                    ) : null}

                    {this.state.isOnline ? (
                      <>
                        {/* <div className="checkout-btn-mobile">
                          {this.state.selected === "paypal" ? (
                            this.state.screenWidth < 575 ? (
                              <div id="paypal-button"></div>
                            ) : null
                          ) : this.state.isOnline || this.state.isDeposit ? (
                            <Button
                              variant="contained"
                              className="save-btn"
                              onClick={this.handleCheckout}
                            >
                              {this.state.loading ? (
                                <CircularProgress
                                  size={16}
                                  style={{ color: "#000" }}
                                />
                              ) : this.props.lng ? (
                                "Checkout"
                              ) : (
                                "ඉදිරියට"
                              )}
                            </Button>
                          ) : null}
                          {this.state.notSelectedError && (
                            <p
                              align="right"
                              style={{
                                fontSize: 12,
                                color: "#f44336",
                              }}
                            >
                              * {this.state.notSelectedError}
                            </p>
                          )}
                        </div> */}
                        <p className="sub-heading">
                          {this.props.lng ? (
                            "Online Payment Methods"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              අන්තර්ජාලය හරහා ගෙවීමේ ක්‍රම
                            </span>
                          )}
                        </p>
                        <p className="desc">
                          {this.props.lng
                            ? "You will be re-directed to the relevant payment gateway on the next screen."
                            : "කාඩ්පත් තොරතුරු ඇතුලත් කිරීම සඳහා ඔබව අදාල ගෙවීම් පිටුව වෙත යොමු වනු ඇත."}
                        </p>
                        {payMethods.map((el, i) => (
                          <div
                            className={
                              this.state.selected === el.id
                                ? "method method-active"
                                : "method"
                            }
                            key={i}
                            onClick={() => this.createSessionCheckout(el.id)}
                          >
                            <div>
                              <img src={el.icon} alt={el.id} />
                            </div>
                            <div>
                              <p>
                                {this.state.countryCode !== "LK"
                                  ? el.name ===
                                    "Non-Sri Lankan Credit/Debit Cards"
                                    ? "Credit/Debit Cards"
                                    : el.name
                                  : el.name}
                              </p>
                            </div>

                            {this.state.selected === el.id && (
                              <i className="fas fa-check method-select-icon"></i>
                            )}
                          </div>
                        ))}{" "}
                      </>
                    ) : null}

                    {this.state.isDeposit ? (
                      <>
                        <p className="sub-heading">
                          {this.props.lng ? (
                            "Bank Deposit/Transfer"
                          ) : (
                            <span className="sinhala-w-600 sinhala-size-14">
                              බැංකුවට ගෙවීමේ/තැන්පත් කිරීමේ ක්‍රම
                            </span>
                          )}
                        </p>
                        <p className="desc">
                          {this.props.lng
                            ? "Relevant account information will be shown on the next screen."
                            : "අදාළ ගිණුම් තොරතුරු ඊළඟ තිරයේ පෙන්වනු ඇත."}
                        </p>

                        {this.state.allPayMethods
                          .filter((el) => el.isBank === true)
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((el, i) => (
                            <div
                              className={
                                this.state.selected === el.id
                                  ? "method method-active"
                                  : "method"
                              }
                              key={i}
                              onClick={() => this.setState({ selected: el.id })}
                            >
                              <div>
                                <img src={el.icon} alt={el.id} />
                              </div>
                              <div>
                                <p>{el.name}</p>
                              </div>

                              {this.state.selected === el.id && (
                                <i className="fas fa-check method-select-icon"></i>
                              )}
                            </div>
                          ))}
                      </>
                    ) : null}
                  </div>
                )}

                <form
                  id="ext-merchant-frm"
                  action={this.state.isRecurring ? PAYHERE_PREAPPROVAL_URL : PAYHERE_URL}
                  method="post"
                  acceptCharset="UTF-8"
                  encType="application/x-www-form-urlencoded"
                >
                  <input
                    type="hidden"
                    name="merchant_id"
                    value={this.state.merchantId}
                  />
                  <input
                    type="hidden"
                    name="return_url"
                    value={this.state.returnUrl}
                  />
                  <input
                    type="hidden"
                    name="cancel_url"
                    value={this.state.cancelUrl}
                  />
                  <input
                    type="hidden"
                    name="notify_url"
                    value={this.state.notifyUrl}
                  />

                  <input
                    type="hidden"
                    name="first_name"
                    value={this.state.firstName}
                  />
                  <input type="hidden" name="last_name" value="" />
                  <input type="hidden" name="email" value="" />
                  <input type="hidden" name="phone" value="" />
                  <input type="hidden" name="address" value="" />
                  <input type="hidden" name="city" value="" />
                  <input type="hidden" name="country" value="" />

                  <input type="hidden" name="hash" value={this.state.hash} />
                  <input
                    type="hidden"
                    name="order_id"
                    value={this.state.orderId}
                  />
                  <input type="hidden" name="items" value={this.state.items} />
                  <input
                    type="hidden"
                    name="currency"
                    value={this.state.currency}
                  />
                  <input
                    type="hidden"
                    name="amount"
                    value={this.state.dueAmount}
                  />
                  <button id="pay-by-genie" value={this.state.isRecurring ? "Preapprove" : ""} hidden>
                    Submit
                  </button>
                </form>
              </React.Fragment>
            )}
          </Paper>
        </Grid>

        <div style={{ paddingBottom: 15, marginTop: 20 }}>
          <Button
            variant="contained"
            className="back-btn"
            onClick={() => this.props.history.push("/pay-for-ad")}
          >
            <KeyboardArrowLeftIcon /> {this.props.lng ? "Back" : "ආපසු යන්න"}
          </Button>

          {this.state.selected === "paypal" ? (
            // this.state.screenWidth > 575 ? (
              <div id="paypal-button"></div>
            // ) : null
          ) : this.state.isOnline || this.state.isDeposit ? (
            <Button
              variant="contained"
              className="save-btn"
              onClick={this.handleCheckout}
            >
              {this.state.loading ? (
                <CircularProgress size={16} style={{ color: "#000" }} />
              ) : this.props.lng ? (
                "Checkout"
              ) : (
                "ඉදිරියට"
              )}
            </Button>
          ) : null}

          {this.state.notSelectedError && (
            <p
              align="right"
              style={{
                fontSize: 12,
                color: "#f44336",
              }}
            >
              * {this.state.notSelectedError}
            </p>
          )}
        </div>

        <p
          align="center"
          style={{
            fontSize: 12,
            color: "#737373",
            marginTop: 50,
            marginBottom: 5,
          }}
        >
          Online Payments are securely processed by
        </p>
        <img
          className="payment-methods-icons"
          src={stripe}
          alt="payment methods"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CheckoutMethod);
