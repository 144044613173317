import { isEmpty, isFunction, isEqual } from "lodash";
import store from "../../../store";
import Config from "../../config";
import UserApi from "../../services/api/user";
import {
  setEditAdPageAdvertisement,
  setEditAdpageHoroscopeImages,
  setEditAdPageNotification,
  setEditAdPageProfileImages,
  setEditAdShowSibilingForm,
} from "../../store/actions/edit-ad-page";
import FormMeta from "../../meta/edit-self-profile.json";
import SiblingFormMeta from "../../meta/sibling-form.json";
import { setAppSnackNotification } from "../../store/actions/application";
import {
  isEmptyOrNullOrUndefined,
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
  sanitizeWhiteSpaces,
  trimEditAdFieldValues,
} from "../../utils";
import GenericDataAPI from "../../services/api/generic";
import AppLogger from "../../utils/logger";
import { getAuthUserProfile } from "../application";
import { DISPLAY_NAME_FORMAT_OPTIONS } from "../../static-data";

const {
  USER_ROLES,
  BASE_COUNTRY_CODE,
  OTHER_PROFESSION_ID,
  PARENT_TYPE,
  CURRENT_PLATFORM,
  PLATFORMS,
  POST_IMAGE_SLOTS,
  POST_HOROSCOPE_IMAGE_SLOTS,
} = Config;
const OPERATOR_ROLES = [USER_ROLES.OPERATOR, USER_ROLES.OPERATOR_L2];

export const REVIEW_FORM_FIELDS = [
  FormMeta.firstNameReview,
  FormMeta.lastNameReview,
  FormMeta.adDescriptionReview,
  FormMeta.subCommunityReview,
  FormMeta.fAdditionalInfoReview,
  FormMeta.mAdditionalInfoReview,
  FormMeta.horoscopeDetailReview,
  FormMeta.casteReview,
];

export const initializeEditAdPage = () => async (dispatch) => {
  dispatch(getAdvertisement());
  dispatch(downloadAdProfileImages());
};

export const getAdvertisement = () => async (dispatch) => {
  try {
    const { authAccount } = store.getState().application;

    const { role: userRole } = authAccount;
    let advertisement = {};
    if (OPERATOR_ROLES.includes(userRole)) {
      // TODO: implement later
    } else {
      const response = await UserApi.getUserProfiles();
      if (!response.success) {
        throw new Error("Unable to fetch profile");
      }
      advertisement = response.body[0] ?? {};
    }
    dispatch(setEditAdPageAdvertisement(advertisement));
  } catch (error) {
    console.log("[Error] initializeEditAdPage ", error);
  }
};

export const downloadAdProfileImages =
  ({ postId, sources }) =>
  async (dispatch) => {
    try {
      let images = [];
      for (const imageId of sources) {
        const response = await UserApi.getPostImages({ postId, imageId });
        if (!response.success) {
          throw new Error("Unable to download images");
        }
        const imgURL = URL.createObjectURL(response.body);
        images.push(imgURL);
      }
      const maxImageSlots = Config.POST_IMAGE_SLOTS;
      const remainSlots = Math.abs(maxImageSlots - images.length);
      if (remainSlots > 0) {
        const tempArr = Array(remainSlots).fill(undefined);
        images = images.concat(tempArr);
      }
      dispatch(setEditAdPageProfileImages(images));
    } catch (e) {
      AppLogger.exception(e);
    }
  };

export const downloadPendingAdImages =
  ({ postId, advertisement }) =>
  async (dispatch) => {
    try {
      const pendingProfileImages =
        advertisement?.pendingEditRequests[0]?.images ?? [];
      const pendingHoroscopeImages =
        advertisement?.pendingEditRequests[0]?.horoscopeImages ?? [];

      if (!isEmpty(pendingProfileImages)) {
        const images = await getPendingImages({
          postId,
          sources: pendingProfileImages,
          imageSlotCount: POST_IMAGE_SLOTS,
        });
        dispatch(setEditAdPageProfileImages(images));
      } else {
        dispatch(
          downloadAdProfileImages({
            postId,
            sources: advertisement.images ?? [],
          })
        );
      }

      if (!isEmpty(pendingHoroscopeImages)) {
        const images = await getPendingImages({
          postId,
          sources: pendingHoroscopeImages,
          imageSlotCount: POST_HOROSCOPE_IMAGE_SLOTS,
        });
        dispatch(setEditAdpageHoroscopeImages(images));
      } else {
        dispatch({
          postId,
          sources: advertisement.horoscopeInfo?.images ?? [],
        });
      }
    } catch (e) {
      AppLogger.exception(e);
    }
  };

const getPendingImages = async ({ postId, sources, imageSlotCount }) => {
  let images = [];
  for (const imageId of sources) {
    const response = await UserApi.getPendingPostImages({
      postId,
      imageId,
    });
    if (!response.success) {
      throw new Error("unable to download pending profile images");
    }
    const imageUrl = URL.createObjectURL(response.body);
    images.push(imageUrl);
  }
  const maxImageSlots = imageSlotCount;
  const remainSlots = Math.abs(maxImageSlots - images.length);
  if (remainSlots > 0) {
    const tempArr = Array(remainSlots).fill(undefined);
    images = images.concat(tempArr);
  }
  return images;
};

export const deleteProfileImages = async ({ postId, imageId }) => {
  try {
    const response = await UserApi.deteteProfileImage({ postId, imageId });
    if (!response.success) {
      throw new Error("Unable to delete image");
    }
    return response;
  } catch (e) {
    AppLogger.exception(e);
  }
};

export const deleteHoroscopeImage = async ({ postId, imageId }) => {
  try {
    const response = await UserApi.deteteHoroscopeImage({ postId, imageId });
    if (!response.success) {
      throw new Error("Unable to delete image");
    }
    return response;
  } catch (e) {
    AppLogger.exception(e);
  }
};

export const downloadHoroscopeImages =
  ({ postId, sources }) =>
  async (dispatch) => {
    try {
      let images = [];
      for (const imageId of sources) {
        const response = await UserApi.getPostHoroscopeImages({
          postId,
          imageId,
        });
        if (!response.success) {
          throw new Error("Unable to download images");
        }
        const imgURL = URL.createObjectURL(response.body);
        images.push(imgURL);
      }
      const maxImageSlots = Config.POST_HOROSCOPE_IMAGE_SLOTS;
      const remainSlots = Math.abs(maxImageSlots - images.length);
      if (remainSlots > 0) {
        const tempArr = Array(remainSlots).fill(undefined);
        images = images.concat(tempArr);
      }
      dispatch(setEditAdpageHoroscopeImages(images));
    } catch (e) {
      AppLogger.exception(e);
    }
  };

export const updateAdField =
  (meta, values, t, setFieldError) => async (dispatch) => {
    //trim field values
    values = trimEditAdFieldValues(values);
    try {
      const data = {};
      const { advertisement } = store.getState().editAdPage;
      // fields post.personalInfo
      const personalInfoFields = [
        FormMeta.firstName,
        FormMeta.lastName,
        FormMeta.displayNameFormat,
        FormMeta.dob,
        FormMeta.religion,
        FormMeta.ethnicityId,
        FormMeta.communityId,
        FormMeta.subCommunity,
        FormMeta.gothraId,
        FormMeta.motherTongueId,
        FormMeta.civilStatus,
        FormMeta.children,
        FormMeta.residentCountry,
        FormMeta.residentRegion,
        FormMeta.residentCountryOther,
        FormMeta.residentCity,
        FormMeta.height,
        FormMeta.visaType,
        FormMeta.educationLevel,
        FormMeta.profession,
        FormMeta.otherProfession,
        FormMeta.drinking,
        FormMeta.smoking,
        FormMeta.foodPreference,
        FormMeta.originCountryCode,
        FormMeta.birthCity,
        FormMeta.starId,
        FormMeta.starSignId,
      ].map((current) => current.fieldName);

      let updatedValue = values[meta.fieldName];
      // handling post.personalInfo fields
      if (personalInfoFields.includes(meta.fieldName)) {
        data.personalInfo = {};
        const fieldValue = values[meta.fieldName];
        const selectedNameFormat = values.displayNameFormat;
        const currentDef = DISPLAY_NAME_FORMAT_OPTIONS.find(
          (current) => current.value === selectedNameFormat
        );
        switch (meta.fieldName) {
          case FormMeta.firstName.fieldName:
          case FormMeta.lastName.fieldName:
            const currentMeta =
              meta.fieldName === FormMeta.firstName.fieldName
                ? FormMeta.firstName
                : FormMeta.lastName.fieldName
                ? FormMeta.lastName
                : {};

            if (isFunction(setFieldError)) {
              if (!fieldValue) {
                setFieldError(
                  currentMeta.fieldName,
                  t("errors.formRequiredField", {
                    fieldName: t(currentMeta.label),
                  })
                );
                return;
              }
              if (currentMeta.min && fieldValue.length < currentMeta.min) {
                setFieldError(
                  currentMeta.fieldName,
                  t("errors.formFieldTooShort", {
                    fieldName: t(currentMeta.label),
                  })
                );
                return;
              }
              if (fieldValue.length > currentMeta.max) {
                setFieldError(
                  currentMeta.fieldName,
                  t("errors.formFieldTooLong", {
                    fieldName: t(currentMeta.label),
                  })
                );
                return;
              }
              if (
                isStringContainsEmoji(fieldValue) ||
                isStringContainsNumbers(fieldValue) ||
                isStringContainsSpecialCharacters(fieldValue)
              ) {
                setFieldError(
                  currentMeta.fieldName,
                  t("errors.invalidFieldValue", {
                    fieldName: t(currentMeta.label),
                  })
                );
                return;
              }
              // if (currentDef) {
              //   const dependByField = currentDef.dependBy.find(
              //     (field) => field === meta.fieldName
              //   );
              //   const minDisplayNameLength = 3;
              //   if (
              //     (dependByField === FormMeta.firstName.fieldName &&
              //       fieldValue.length < minDisplayNameLength) ||
              //     (dependByField === FormMeta.lastName.fieldName &&
              //       fieldValue.length < minDisplayNameLength)
              //   ) {
              //     setFieldError(
              //       currentMeta.fieldName,
              //       t("errors.insufficientCharLengthForSelectedNameFormat", {
              //         fieldName: t(currentDef.labelKey),
              //         length: 3,
              //       })
              //     );
              //     return;
              //   }
              // }
            }
            data.personalInfo[meta.fieldName] = updatedValue;
            break;
          case FormMeta.displayNameFormat.fieldName:
            const dependBy = currentDef?.dependBy ?? [];
            for (const fieldName of dependBy) {
              const fieldMetaDef =
                Object.values(FormMeta).find(
                  (current) => current.fieldName === fieldName
                ) || {};
              const dependentFieldValue = values[fieldName];
              if (
                typeof dependentFieldValue === "string" &&
                dependentFieldValue.length < 3
              ) {
                setFieldError(
                  FormMeta.displayNameFormat.fieldName,
                  t("errors.insufficientCharLength", {
                    fieldName: t(fieldMetaDef.label),
                    length: 3,
                  })
                );
                return;
              }
            }
            data.personalInfo[meta.fieldName] = updatedValue;
            break;
          case FormMeta.religion.fieldName:
            // handle LK flow
            if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
              data.personalInfo[FormMeta.religion.fieldName] = updatedValue;
            }
            // handle IN flow
            if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
              const communityId = values[FormMeta.communityId.fieldName];
              if (isEmpty(communityId)) {
                setFieldError(
                  FormMeta.communityId.fieldName,
                  t("errors.formRequiredField", {
                    fieldName: t(FormMeta.communityId.label),
                  })
                );
                return;
              }
              data.personalInfo[FormMeta.religion.fieldName] = updatedValue;
              data.personalInfo[FormMeta.communityId.fieldName] = communityId;
              if (updatedValue !== "hindu") {
                data.personalInfo[FormMeta.gothraId.fieldName] = null;
              }
            }
            break;
          case FormMeta.ethnicityId.fieldName:
            data.personalInfo[FormMeta.ethnicityId.fieldName] = updatedValue;
            break;
          case FormMeta.civilStatus.fieldName:
            data.personalInfo[FormMeta.civilStatus.fieldName] = updatedValue;
            if (updatedValue === "never_married") {
              data.personalInfo[FormMeta.children.fieldName] = "";
            } else {
              const children = values[FormMeta.children.fieldName];
              if (isEmpty(children)) {
                setFieldError(
                  FormMeta.children.fieldName,
                  t("errors.formRequiredField", {
                    fieldName: t(FormMeta.children.label),
                  })
                );
                return;
              } else {
                data.personalInfo[FormMeta.children.fieldName] =
                  values[FormMeta.children.fieldName];
              }
            }
            break;

          case FormMeta.residentCountry.fieldName:
            const residentCountry = values[FormMeta.residentCountry.fieldName];
            data.personalInfo[meta.fieldName] = updatedValue;
            if (residentCountry === BASE_COUNTRY_CODE) {
              data.personalInfo[FormMeta.visaType.fieldName] = "citizen";
            }
            break;

          case FormMeta.profession.fieldName:
          case FormMeta.otherProfession.fieldName:
            let profession = values[FormMeta.profession.fieldName];
            const otherProfession = values[FormMeta.otherProfession.fieldName];
            if (typeof profession === "string") {
              profession = parseInt(profession);
            }
            if (
              profession === OTHER_PROFESSION_ID &&
              isFunction(setFieldError)
            ) {
              if (isEmpty(otherProfession)) {
                setFieldError(
                  FormMeta.otherProfession.fieldName,
                  t("errors.formRequiredField", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
              if (
                isStringContainsEmoji(otherProfession) ||
                isStringContainsNumbers(otherProfession) ||
                isStringContainsSpecialCharacters(otherProfession)
              ) {
                setFieldError(
                  FormMeta.otherProfession.fieldName,
                  t("errors.invalidFieldValue", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
              if (
                typeof otherProfession === "string" &&
                otherProfession.length > FormMeta.otherProfession.max
              ) {
                setFieldError(
                  FormMeta.otherProfession.fieldName,
                  t("errors.formFieldTooLong", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
            }
            // validated profession scenario
            if (isFunction(setFieldError)) {
              setFieldError(FormMeta.otherProfession.fieldName, "");
            }
            data.personalInfo[FormMeta.profession.fieldName] = profession;
            data.personalInfo[FormMeta.otherProfession.fieldName] =
              profession === OTHER_PROFESSION_ID ? otherProfession : null;
            break;

          default:
            data.personalInfo[meta.fieldName] = updatedValue;
            break;
        }
      }

      const parentInfoFields = [
        // Father Fields
        FormMeta.fEthnicityId,
        FormMeta.fReligionId,
        FormMeta.fCommunityId,
        FormMeta.fSubCommunity,
        FormMeta.fResidentCountryCode,
        FormMeta.fCaste,
        FormMeta.fProfessionId,
        FormMeta.fOtherProfession,
        // Mother Fields
        FormMeta.mEthnicityId,
        FormMeta.mReligionId,
        FormMeta.mCommunityId,
        FormMeta.mSubCommunity,
        FormMeta.mResidentCountryCode,
        FormMeta.mCaste,
        FormMeta.mProfessionId,
        FormMeta.mOtherProfession,
      ].map((current) => current.fieldName);

      if (parentInfoFields.includes(meta.fieldName)) {
        const parents = advertisement.parentInfo || [];
        const parent = parents.find(
          (current) => current.type === meta.parentType
        );

        if (parent == null) {
          return;
        }

        const updatedParent = {};

        switch (meta.fieldName) {
          case FormMeta.fProfessionId.fieldName:
          case FormMeta.mProfessionId.fieldName:
          case FormMeta.fOtherProfession.fieldName:
          case FormMeta.mOtherProfession.fieldName:
            // let professionId = values[meta.fieldName];

            let professionMeta = {};
            let otherProfessionMeta = {};
            let professionId = "";

            if (parent.type === PARENT_TYPE.FATHER) {
              professionId = values[FormMeta.fProfessionId.fieldName];
              professionMeta = FormMeta.fProfessionId;
              otherProfessionMeta = FormMeta.fOtherProfession;
            } else if (parent.type === PARENT_TYPE.MOTHER) {
              professionId = values[FormMeta.mProfessionId.fieldName];
              professionMeta = FormMeta.mProfessionId;
              otherProfessionMeta = FormMeta.mOtherProfession;
            }

            if (typeof professionId === "string") {
              professionId = parseInt(professionId);
            }

            const otherProfession = sanitizeWhiteSpaces(
              values[otherProfessionMeta.fieldName]
            );

            if (
              professionId === OTHER_PROFESSION_ID &&
              isFunction(setFieldError)
            ) {
              if (isEmpty(otherProfession)) {
                setFieldError(
                  otherProfessionMeta.fieldName,
                  t("errors.formRequiredField", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
              if (
                isStringContainsEmoji(otherProfession) ||
                isStringContainsNumbers(otherProfession) ||
                isStringContainsSpecialCharacters(otherProfession)
              ) {
                setFieldError(
                  otherProfessionMeta.fieldName,
                  t("errors.invalidFieldValue", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
              if (
                typeof otherProfession === "string" &&
                otherProfession.length > otherProfessionMeta.max
              ) {
                setFieldError(
                  otherProfessionMeta.fieldName,
                  t("errors.formFieldTooLong", {
                    fieldName: t("common.typeProfession"),
                  })
                );
                return;
              }
            }
            if (
              meta.fieldName === FormMeta.fOtherProfession.fieldName ||
              meta.fieldName === FormMeta.mOtherProfession.fieldName
            ) {
              updatedParent[professionMeta.jsonKey] = professionId;
              updatedParent[otherProfessionMeta.jsonKey] =
                professionId === OTHER_PROFESSION_ID ? otherProfession : null;
            } else {
              updatedParent[meta.jsonKey || meta.fieldName] = professionId;
              updatedParent[otherProfessionMeta.jsonKey] =
                professionId === OTHER_PROFESSION_ID ? otherProfession : null;
            }
            break;
          case FormMeta.fReligionId.fieldName:
          case FormMeta.mReligionId.fieldName:
            const religionId = values[meta.fieldName];
            updatedParent[meta.jsonKey || meta.fieldName] = religionId;

            if (CURRENT_PLATFORM === PLATFORMS.IN) {
              const communityMeta =
                parent.type === PARENT_TYPE.FATHER
                  ? FormMeta.fCommunityId
                  : FormMeta.mCommunityId;
              const communityId = values[communityMeta.fieldName];
              if (isEmptyOrNullOrUndefined(communityId)) {
                if (isFunction(setFieldError)) {
                  setFieldError(
                    communityMeta.fieldName,
                    t("errors.formRequiredField", {
                      fieldName: t(communityMeta.label),
                    })
                  );
                }
                return;
              }

              updatedParent[communityMeta.jsonKey || communityMeta.fieldName] =
                communityId;
            }
            break;
          case FormMeta.fCaste.fieldName:
          case FormMeta.mCaste.fieldName:
            let casteMeta =
              parent.type === PARENT_TYPE.FATHER
                ? FormMeta.fCaste
                : parent.type === PARENT_TYPE.MOTHER
                ? FormMeta.mCaste
                : {};
            const caste = values[meta.fieldName];
            if (typeof caste === "string" && caste.length > casteMeta.max) {
              setFieldError(
                casteMeta.fieldName,
                t("errors.formFieldTooLong", {
                  fieldName: t("common.caste"),
                })
              );
              return;
            }
            if (
              isStringContainsEmoji(caste) ||
              isStringContainsNumbers(caste) ||
              isStringContainsSpecialCharacters(caste)
            ) {
              setFieldError(
                casteMeta.fieldName,
                t("errors.invalidFieldValue", {
                  fieldName: t("common.caste"),
                })
              );
              return;
            }
            updatedParent[casteMeta.jsonKey] = caste;
            break;
          default:
            updatedParent[meta.jsonKey || meta.fieldName] =
              values[meta.fieldName];
            break;
        }

        updatedParent.id = parent.id;
        data.parentInfo = [updatedParent];
      }
      // Root Level Fields
      const rootLevelFields = [
        FormMeta.birthCity,
        FormMeta.birthTime,
        FormMeta.differentlyAbled,
        FormMeta.haveDosh,
        FormMeta.acceptOtherCaste,
        FormMeta.horoscopeMatching,
      ].map((current) => current.fieldName);

      if (rootLevelFields.includes(meta.fieldName)) {
        if (meta.fieldName === FormMeta.differentlyAbled.fieldName) {
          data[meta.jsonKey || meta.fieldName] =
            values[meta.fieldName] === "true";
        } else {
          data[meta.jsonKey || meta.fieldName] = values[meta.fieldName];
        }
      }

      const response = await UserApi.updatePost({
        postId: advertisement.id,
        data,
      });

      if (!response.success) {
        dispatch(
          setEditAdPageNotification({
            severity: "error",
            message: t("common.failedToUpdate"),
          })
        );
        return;
      }
      dispatch(
        setEditAdPageNotification({
          severity: "success",
          message: t("common.updatedsuccessfully"),
        })
      );
      dispatch(getAdvertisement());
      dispatch(getAuthUserProfile());
    } catch (e) {
      AppLogger.exception(e);
    }
  };

export const resetChangedField =
  (meta, initialValues, setFieldValue) => (dispatch) => {
    try {
      const fieldName = meta.fieldName;
      const initalValue = initialValues[fieldName];
      setFieldValue(fieldName, initalValue);
    } catch (e) {
      AppLogger.exception(e);
    }
  };

const mapSiblingModelToJsonPayload = ({ values }) => {
  const json = {};
  const relationship = values[SiblingFormMeta.relationship.fieldName];
  if (!isEmptyOrNullOrUndefined(relationship)) {
    const [relationshipType, siblingRelationship] = relationship.split("-");
    json.relationship = siblingRelationship;
    json.relationshipType = relationshipType;
  }
  json.professionId = values[SiblingFormMeta.profession.fieldName];
  if (!isEmpty(values[SiblingFormMeta.otherProfession.fieldName])) {
    json.otherProfession =
      values[SiblingFormMeta.otherProfession.fieldName].trim();
  }
  json.isMarried = values[SiblingFormMeta.isMarried.fieldName] === "YES";

  return json;
};

export const saveNewSiblingData =
  ({ values, t }) =>
  async (dispatch) => {
    try {
      const { advertisement } = store.getState().editAdPage;
      const sibling = mapSiblingModelToJsonPayload({ values });
      const response = await UserApi.postSibling({
        sibling,
        postId: advertisement.id,
      });

      if (!response.success) {
        dispatch(
          setEditAdPageNotification({
            severity: "error",
            message: t("common.failedToUpdate"),
          })
        );
        return;
      }
      dispatch(setEditAdShowSibilingForm(false));
      dispatch(
        setEditAdPageNotification({
          severity: "success",
          message: t("common.updatedsuccessfully"),
        })
      );
      dispatch(getAdvertisement());
    } catch (error) {
      AppLogger.exception(error);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unexpected Error Occurred, Try Again!",
        })
      );
    }
  };

export const deleteSibling =
  ({ sibling, t }) =>
  async (dispatch) => {
    try {
      const { advertisement } = store.getState().editAdPage;
      const response = await UserApi.deleteSibling({
        postId: advertisement.id,
        siblingId: sibling.id,
      });
      if (!response.success) {
        dispatch(
          setEditAdPageNotification({
            severity: "error",
            message: t("common.failedToUpdate"),
          })
        );
        return;
      }
      dispatch(
        setEditAdPageNotification({
          severity: "success",
          message: t("common.updatedsuccessfully"),
        })
      );
      dispatch(getAdvertisement());
    } catch (error) {
      AppLogger.exception(error);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unexpected Error Occurred, Try Again!",
        })
      );
    }
  };

export const handleFieldOnBlur = (event, setFieldError, t) => async () => {
  try {
    const fieldName = event.target.name;
    const fieldValue = event.target.value.trim();
    const descriptionFields = [
      FormMeta.adDescriptionReview.fieldName,
      FormMeta.fAdditionalInfoReview.fieldName,
      FormMeta.mAdditionalInfoReview.fieldName,
      FormMeta.horoscopeDetailReview.fieldName,
    ];

    if (descriptionFields.includes(fieldName)) {
      if (!isEmptyOrNullOrUndefined(fieldValue)) {
        const response = await GenericDataAPI.checkSensitiveData({
          content: fieldValue,
        });
        if (!response.success) {
          setFieldError(
            fieldName,
            t("createflow.descriptionSensitiveDataError")
          );
          return;
        }
      } else {
        setFieldError(fieldName, "");
      }
    }
  } catch (e) {
    AppLogger.exception(e);
  }
};

const constructEditRequest = ({
  advertisement,
  editRequest,
  fieldMeta,
  value,
}) => {
  switch (fieldMeta.section) {
    case "personalInfo":
      editRequest.personalInfo = editRequest.personalInfo ?? {};
      editRequest.personalInfo[fieldMeta.jsonKey || fieldMeta.fieldName] =
        value;
      break;
    case "parentInfo":
      const parent = advertisement.parentInfo.find(
        (current) => current.type === fieldMeta.parentType
      );
      const parentItem = {};
      if (parent !== undefined) {
        parentItem.id = parent.id;
        parentItem[fieldMeta.jsonKey] = value;
      }
      editRequest.parentInfo = editRequest.parentInfo ?? [];
      editRequest.parentInfo.push(parentItem);
      break;
    default:
      editRequest[fieldMeta.jsonKey] = value;
      break;
  }
};

export const createEditRequest =
  ({ getFieldMeta, t, values, images, horoscopeImages }) =>
  async (dispatch) => {
    try {
      const { advertisement } = store.getState().editAdPage;
      const editRequest = {};
      for (const current of REVIEW_FORM_FIELDS) {
        const fieldMeta = getFieldMeta(current.fieldName);
        if (!isEmptyOrNullOrUndefined(fieldMeta)) {
          const { initialValue, value } = fieldMeta;
          if (!isEqual(value, initialValue)) {
            constructEditRequest({
              advertisement,
              editRequest,
              fieldMeta: current,
              value,
            });
          }
        }
      }

      const updatedProfileImages = await uploadEditRequestImages(images);
      const updatedHoroscopeImages = await uploadEditRequestImages(
        horoscopeImages
      );

      if (
        Array.isArray(updatedProfileImages) &&
        !isEqual(values.images, updatedProfileImages)
      ) {
        editRequest.images = updatedProfileImages;
      }

      if (
        Array.isArray(updatedHoroscopeImages) &&
        !isEqual(values.horoscopeImages, updatedHoroscopeImages)
      ) {
        editRequest.horoscopeImages = updatedHoroscopeImages;
      }

      const response = await UserApi.postEditRequest({
        postId: advertisement.id,
        editRequest,
      });

      if (!response.success) {
        dispatch(
          setAppSnackNotification({
            severity: "error",
            message: t("message.unableToSubmittedForReview"),
          })
        );
        return;
      }
      dispatch(
        setAppSnackNotification({
          severity: "success",
          message: t("message.submittedForReview"),
        })
      );
      dispatch(getAdvertisement());
      dispatch(getAuthUserProfile());
    } catch (error) {
      AppLogger.exception(error);
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unexpected Error Occurred, Try Again!",
        })
      );
    }
  };

const uploadEditRequestImages = async (images, errorMessage) => {
  const formImages = [];
  const filteredImages = [...images].filter(Boolean);

  for (let image of filteredImages) {
    if (image instanceof Blob) {
      const formData = new FormData();
      formData.append("file", image);

      const response = await GenericDataAPI.postFileUpload({ formData });

      if (!response.success) {
        throw new Error(errorMessage);
      }

      const fileName = response.body?.name;
      formImages.push(fileName);
    }
  }

  return formImages;
};

export const isFileOrBlob = (elementList = []) => {
  return (
    Array.isArray(elementList) &&
    elementList.some(
      (element) => element instanceof File || element instanceof Blob
    )
  );
};
