import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment, useContext, useMemo, useRef } from "react";
import "./style.css";
import PageHead from "../../components/atoms/page-head";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import AppContext from "../../context";
import Config from "../../config";
import { useTranslation } from "react-i18next";
import { Home, NavigateBefore } from "@material-ui/icons";
import paymentGateways from "../../../images/stripe.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import FlowStepper from "../../pages/create-flow/templates/stepper";
import { PROFILE_STATUS } from "../../config/constant";

const PaymentView = ({
  advertisementStatus,
  displayActionButtons,
  onClickPrimaryAction,
  onClickSecondaryAction,
  isDisabledPrimaryAction,
  children,
}) => {
  const { authProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { lng } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();

  const isRenewProfile = useMemo(() => {
    const renewalProfileStatus = ["EXPIRED", "LIVE"];
    const output =
      !isEmpty(authProfile) &&
      renewalProfileStatus.includes(authProfile.status);
    sessionStorage.setItem("isRenew", output);
    return output;
  }, [authProfile]);

  return (
    <Fragment>
      <PageHead title={`Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`} />
      <Grid
        container
        direction="column"
        alignItems="center"
        className="payment-view-container"
      >
        {isRenewProfile && (
          <Typography variant="h5" align="center">
            {t("createflow.renewAd")}
          </Typography>
        )}
        <Grid
          container
          direction="column"
          xs={12}
          sm={12}
          md={8}
          alignItems="center"
          style={{
            marginTop: !isRenewProfile ? 80 : 0,
            minHeight: "40vh",
          }}
          className="invoice-container"
        >
          {!isRenewProfile && !isEmpty(authProfile) && <FlowStepper />}

          <Typography className="payment-info-header">
            {t("common.computedAdIdLabel", {
              id: authAccount.memberId,
            })}
          </Typography>

          {(advertisementStatus === PROFILE_STATUS.IN_REVIEW ||
            advertisementStatus === PROFILE_STATUS.DISABLED) && (
            <>
              <Typography variant="body1" className="checkout-error">
                {advertisementStatus === PROFILE_STATUS.IN_REVIEW &&
                  t("payment.postUnderReview")}
                {advertisementStatus === PROFILE_STATUS.DISABLED &&
                  t("payment.postIsDisabled")}
              </Typography>
              <Button
                variant="contained"
                className="secondary-button margin-top-16 margin-bottom-16"
                startIcon={<Home />}
                onClick={() => history.push("/")}
              >
                {t("payment.goToHome")}
              </Button>
            </>
          )}

          {children ||
            (!advertisementStatus && (
              <CircularProgress className="container-loader" />
            ))}
        </Grid>
        {displayActionButtons && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ maxWidth: 960 }}
          >
            <Button
              className="payment-later-btn"
              startIcon={<NavigateBefore />}
              onClick={() => onClickSecondaryAction()}
            >
              {t("payment.payLater")}
            </Button>
            <Button
              variant="contained"
              className="payment-continue-btn"
              disabled={isDisabledPrimaryAction}
              onClick={() => onClickPrimaryAction()}
            >
              {t("payment.continueToPayment")}
            </Button>
          </Grid>
        )}
        {children && (
          <Grid container direction="column" alignItems="center" className="margin-top-8">
            <Typography variant="caption" className="payment-gateway-icons">
              Online Payments are securely processed by
            </Typography>
            <img
              src={paymentGateways}
              alt="payment-gateways"
              className="payment-gateway-icons"
            />
          </Grid>
        )}
      </Grid>
      {isMobileScreen ? <FooterMobile lng={lng} /> : <Footer lng={lng} />}
    </Fragment>
  );
};

export default PaymentView;
