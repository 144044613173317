import React from "react";
import Config from "../../../../../../v2/config";

const { PAYHERE_PREAPPROVAL_URL } = Config;

const PayhereForm = ({ data }) => {
  return (
    <form
      id="ext-merchant-frm"
      action={PAYHERE_PREAPPROVAL_URL}
      method="post"
      acceptCharset="UTF-8"
      encType="application/x-www-form-urlencoded"
    >
      <input type="hidden" name="merchant_id" value={data.merchantId} />
      <input type="hidden" name="return_url" value={data.returnUrl} />
      <input type="hidden" name="cancel_url" value={data.cancelUrl} />
      <input type="hidden" name="notify_url" value={data.notifyUrl} />

      <input type="hidden" name="first_name" value={data.firstName} />
      <input type="hidden" name="last_name" value="" />
      <input type="hidden" name="email" value="" />
      <input type="hidden" name="phone" value="" />
      <input type="hidden" name="address" value="" />
      <input type="hidden" name="city" value="" />
      <input type="hidden" name="country" value="" />

      <input type="hidden" name="hash" value={data.hash} />
      <input type="hidden" name="order_id" value={data.orderId} />
      <input type="hidden" name="items" value={data.items} />
      <input type="hidden" name="currency" value={data.currency} />
      <input type="hidden" name="amount" value={data.amount} />
      <button id="pay-by-payhere" value="Preapprove" hidden>
        Submit
      </button>
    </form>
  );
};

export default PayhereForm;
