import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const PaymentDiscount = ({
  discountPercentage,
  discountAmount,
  isFetchingData,
  paymentSessionData = {},
}) => {
  const { t } = useTranslation();
  return paymentSessionData.discountPercentage && !isFetchingData ? (
    <Grid container direction="row" justifyContent="space-between">
      <>
        <Typography variant="body2">
          {t(
            paymentSessionData.promoCode
              ? "payment.promoDiscount"
              : "payment.subscriptionDiscount",
            { discount: discountPercentage }
          )}
        </Typography>
        <Typography variant="body2">{`- ${discountAmount}`}</Typography>
      </>
    </Grid>
  ) : null;
};

export default PaymentDiscount;
