import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Navbar from "../components/common/Navbar";
import PageHead from "../components/common/PageHead";
import Footer from "../components/common/Footer";
import Config from "../v2/config";

import PNF from "../images/page-not-found.png";

import "../styles/page-not-found.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class PageNotFound extends Component {
  state = {};

  render() {
    return (
      <>
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#dfdfdf", width: "100vw" }}>
          <PageHead title={`Page Not Found | ${Config.DOMAIN_DISPLAY_NAME}`} />
          <div className="page-not-found-div">
            <h1 className="title">
              {this.props.lng === true ? "Page Not Found" : "පිටුව හමු නොවීය"}
            </h1>
            <img src={PNF} alt="page not found" className="pnf-icon" />
            <h1 className="sub-desc">
              {this.props.lng === true
                ? "Sorry, the page you’re looking for is not available."
                : "කණගාටුයි, ඔබ සොයන පිටුව නොමැති බැවින්  වෙබ් ලිපිනය නැවත පරීක්ෂා කරන්න."}
            </h1>
            <p className="link-to-home">
              {this.props.lng === true ? (
                <span>
                  Double check the url or try navigating to{" "}
                  <Link to="/search" className="home-link">
                    {Config.DOMAIN_DISPLAY_NAME}
                  </Link>
                </span>
              ) : (
                <span>
                  ප්‍රධාන පිටුවට{" "}
                  <Link to="/search" className="home-link">
                    මෙතනින්
                  </Link>{" "}
                  පිවිසෙන්න.
                </span>
              )}
            </p>
          </div>
        </div>
        <Footer lng={this.props.lng} />
      </>
    );
  }
}

export default withLanguageMigrator(PageNotFound);
